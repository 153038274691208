@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Events */
/* ---------------------------------------------------------------- */
.events {
  padding-bottom: 36px;
  padding-top: 36px;
  position: relative;
  @media (max-width: 1280px) {
    padding-bottom: 32px;
    padding-top: 32px;
  }
  @media (max-width: 1024px) {
    padding-bottom: 24px;
    padding-top: 24px;
  }
  @media (max-width: 767px) {
    padding-bottom: 32px;
    padding-top: 32px;
  }
  @media (max-width: 375px) {
    padding-bottom: 24px;
    padding-top: 24px;
  }
  &:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
    @media (max-width: 767px) {
      padding-bottom: 32px;
    }
    @media (max-width: 375px) {
      padding-bottom: 24px;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Background Colour */
  /* ---------------------------------------------------------------- */
  &::before {
    background-color: $brandAquaXLightPastel;
    bottom: 0;
    content: '';
    display: block;
    position: absolute;
    right: -72px;
    top: 0;
    width: 100vw;
    z-index: 0;
    @media (max-width: 1664px) {
      right: -64px;
    }
    @media (max-width: 1536px) {
      right: -56px;
    }
    @media (max-width: 1408px) {
      right: -48px;
    }
    @media (max-width: 1280px) {
      right: -40px;
    }
    @media (max-width: 1152px) {
      right: -32px;
    }
    @media (max-width: 1024px) {
      right: -24px;
    }
    @media (max-width: 375px) {
      left: -16px;
      right: -16px;
    }
  }
  &:last-child::before {
    bottom: -72px;
    @media (max-width: 1664px) {
      bottom: -64px;
    }
    @media (max-width: 1536px) {
      bottom: -56px;
    }
    @media (max-width: 1408px) {
      bottom: -48px;
    }
    @media (max-width: 1280px) {
      bottom: -40px;
    }
    @media (max-width: 1152px) {
      bottom: -32px;
    }
    @media (max-width: 1024px) {
      bottom: -24px;
    }
    @media (max-width: 767px) {
      bottom: 0;
    }
  }
  > * {
    position: relative;
    z-index: 1;
  }
  /* ---------------------------------------------------------------- */
  /* Title */
  /* ---------------------------------------------------------------- */
  h2 {
    color: $brandBlackGrape;
    font-family: $secondary;
    font-size: 32px;
    font-weight: $semibold;
    line-height: 40px;
    margin: -8px 0;
    padding-bottom: 36px;
    padding-right: 24px;
    @media (max-width: 1664px) {
      font-size: 29px;
      line-height: 36px;
    }
    @media (max-width: 1536px) {
      font-size: 26px;
      line-height: 33px;
      margin: -6px 0 -7px;
      padding-right: 20px;
    }
    @media (max-width: 1408px) {
      font-size: 23px;
      line-height: 29px;
    }
    @media (max-width: 1280px) {
      font-size: 20px;
      line-height: 26px;
      margin: -6px 0;
      padding-bottom: 32px;
      padding-right: 16px;
    }
    @media (max-width: 1024px) {
      padding-bottom: 24px;
    }
    @media (max-width: 896px) {
      padding-right: 0;
    }
    @media (max-width: 767px) {
      padding-bottom: 32px;
    }
    @media (max-width: 375px) {
      font-size: 19px;
      line-height: 25px;
      margin-top: -5px;
      padding-bottom: 24px;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Events Card Container */
  /* ---------------------------------------------------------------- */
  > ul {
    /* ---------------------------------------------------------------- */
    /* Columns */
    /* ---------------------------------------------------------------- */
    > li {
      /* ---------------------------------------------------------------- */
      /* Large Card Container x 1 */
      /* ---------------------------------------------------------------- */
      &:first-child {
        margin-bottom: 48px;
        @media (max-width: 1664px) {
          margin-bottom: 44px;
        }
        @media (max-width: 1536px) {
          margin-bottom: 40px;
        }
        @media (max-width: 1408px) {
          margin-bottom: 36px;
        }
        @media (max-width: 1280px) {
          margin-bottom: 32px;
        }
        &:only-child {
          margin-bottom: 0;
        }
      }
      /* ---------------------------------------------------------------- */
      /* Small Card Container x 3 */
      /* ---------------------------------------------------------------- */
      &:last-child {
        display: flex;
        @media (max-width: 896px) and (min-width: 768px), (max-width: 576px) {
          flex-direction: column;
        }
        article {
          margin-right: 28px;
          @media (max-width: 1664px) {
            margin-right: 24px;
          }
          @media (max-width: 1536px) {
            margin-right: 20px;
          }
          @media (max-width: 1408px) {
            margin-right: 16px;
          }
          @media (max-width: 1280px) {
            margin-right: 12px;
          }
          @media (max-width: 896px) and (min-width: 768px), (max-width: 576px) {
            margin-right: 0;
          }
          &:only-child {
            h3 {
              font-weight: $bold;
            }
          }
          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Mobile CTA Container */
  /* ---------------------------------------------------------------- */
  > div:last-child {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
    position: relative;
    @media (max-width: 896px) and (min-width: 768px), (max-width: 576px) {
      margin-top: 13px;
    }
  }
}
