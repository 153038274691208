@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Rich Text */
/* ---------------------------------------------------------------- */
.richText {
  margin: 32px 0;
  @include user-select;
  @media (max-width: 1280px) {
    margin: 28px 0;
  }
  @media (max-width: 767px) {
    margin: 32px 0;
  }
  @media (max-width: 576px) {
    margin: 28px 0;
  }
  @media (max-width: 375px) {
    margin: 24px 0;
  }
  &:first-child {
    margin-top: 0;
  }
  &:last-child {
    margin-bottom: 0;
  }
  /* ---------------------------------------------------------------- */
  /* Link */
  /* ---------------------------------------------------------------- */
  a {
    color: $brandBerry;
    @include fast-transition($props: 'color');
    @include no-user-select;
    &:focus {
      color: $productTransformation;
    }
    :global(.platform-desktop) & {
      &:hover {
        color: $brandBerryDark;
      }
      &:active {
        color: $productTransformation;
      }
    }
    :global(.platform-mobile) & {
      &:active {
        color: $brandBerryDark;
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Lists */
  /* ---------------------------------------------------------------- */
  > ul > li > ul > li::before {
    background-color: $white;
    border: 1px solid $brandBlackGrape;
    box-sizing: border-box;
  }
  ul {
    li {
      padding-left: 15px;
      position: relative;
      &::before {
        background-color: $brandBlackGrape;
        border-radius: 50%;
        content: '';
        display: block;
        flex-shrink: 0;
        height: 5px;
        left: 0;
        margin-right: 8px;
        position: absolute;
        top: 10px;
        width: 5px;
      }
      ul:first-child,
      ol:first-child {
        margin-bottom: 0;
        margin-top: 0;
      }
    }
  }
  > ol > li > ol > li::before {
    content: counter(ol-counter, lower-alpha) '.';
  }
  ol {
    counter-reset: ol-counter;
    display: table;
    li {
      counter-increment: ol-counter;
      display: table-row;
      list-style: none;
      position: relative;
      &::before {
        content: counter(ol-counter) '.';
        display: table-cell;
        padding-right: 5px;
        text-align: right;
      }
    }
    ul:first-child,
    ol:first-child {
      margin-bottom: 0;
      margin-top: 0;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Paragraphs, Lists, Link */
  /* ---------------------------------------------------------------- */
  > p,
  li,
  a {
    font-size: 17px;
    line-height: 24px;
    @media (max-width: 1536px) {
      font-size: 16px;
      line-height: 23px;
    }
    @media (max-width: 1280px) {
      font-size: 15px;
      line-height: 22px;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Strong */
  /* ---------------------------------------------------------------- */
  strong {
    font-weight: $bold;
  }
  /* ---------------------------------------------------------------- */
  /* H1, H2, H3, H4, H5, H6 */
  /* ---------------------------------------------------------------- */
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: $brandBlackGrape;
    font-weight: $bold;
  }
  h1 {
    font-size: 28px;
    line-height: 36px;
    @media (max-width: 1536px) {
      font-size: 26px;
      line-height: 34px;
    }
    @media (max-width: 1280px) {
      font-size: 24px;
      line-height: 32px;
    }
    @media (max-width: 767px) {
      font-size: 23px;
      line-height: 31px;
    }
    @media (max-width: 448px) {
      font-size: 22px;
      line-height: 30px;
    }
  }
  h2 {
    font-size: 26px;
    line-height: 34px;
    @media (max-width: 1536px) {
      font-size: 24px;
      line-height: 32px;
    }
    @media (max-width: 1280px) {
      font-size: 22px;
      line-height: 30px;
    }
    @media (max-width: 767px) {
      font-size: 21px;
      line-height: 29px;
    }
    @media (max-width: 448px) {
      font-size: 20px;
      line-height: 28px;
    }
  }
  h3 {
    font-size: 24px;
    line-height: 32px;
    @media (max-width: 1536px) {
      font-size: 22px;
      line-height: 30px;
    }
    @media (max-width: 1280px) {
      font-size: 20px;
      line-height: 28px;
    }
    @media (max-width: 767px) {
      font-size: 19px;
      line-height: 27px;
    }
    @media (max-width: 448px) {
      font-size: 18px;
      line-height: 26px;
    }
  }
  h4 {
    font-size: 22px;
    line-height: 30px;
    @media (max-width: 1536px) {
      font-size: 20px;
      line-height: 28px;
    }
    @media (max-width: 1280px) {
      font-size: 18px;
      line-height: 26px;
    }
    @media (max-width: 767px) {
      font-size: 17px;
      line-height: 25px;
    }
  }
  h5 {
    font-size: 20px;
    line-height: 28px;
    @media (max-width: 1536px) {
      font-size: 18px;
      line-height: 26px;
    }
    @media (max-width: 1280px) {
      font-size: 16px;
      line-height: 24px;
    }
    @media (max-width: 767px) {
      font-size: 15px;
      line-height: 23px;
    }
  }
  h6 {
    font-size: 19px;
    line-height: 27px;
    @media (max-width: 1536px) {
      font-size: 17px;
      line-height: 25px;
    }
    @media (max-width: 1280px) {
      font-size: 15px;
      line-height: 23px;
    }
    @media (max-width: 767px) {
      font-size: 14px;
      line-height: 22px;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Table */
  /* ---------------------------------------------------------------- */
  table {
    border-collapse: collapse;
    border-spacing: 0;
    min-width: 600px;
    text-align: left;
  }
  /* ---------------------------------------------------------------- */
  /* Header Cell */
  /* ---------------------------------------------------------------- */
  th {
    border-bottom: 3px solid $brandBlackGrape;
    border-left: 32px solid $pageBackground;
    border-right: 32px solid $pageBackground;
    font-size: 24px;
    font-style: normal;
    font-weight: normal;
    line-height: 33px;
    padding: 0 24px 36px;
    vertical-align: top;
    @media (max-width: 1536px) {
      border-left-width: 24px;
      border-right-width: 24px;
      font-size: 23px;
      line-height: 32px;
      padding: 0 16px 28px;
    }
    @media (max-width: 1408px) {
      padding: 0 16px 24px;
    }
    @media (max-width: 1280px) {
      border-left-width: 16px;
      border-right-width: 16px;
      font-size: 22px;
      line-height: 31px;
      padding: 0 8px 20px;
    }
    @media (max-width: 1024px) {
      font-size: 21px;
      line-height: 30px;
    }
    @media (max-width: 896px) {
      font-size: 20px;
      line-height: 29px;
    }
    @media (max-width: 375px) {
      font-size: 19px;
      line-height: 28px;
      padding: 0 8px 16px;
    }
    &:first-child {
      border-left-width: 0;
    }
    &:last-child {
      border-right-width: 0;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Data Cell */
  /* ---------------------------------------------------------------- */
  td {
    border-bottom: 1px solid $brandBlackGrape;
    font-size: 17px;
    line-height: 25px;
    padding: 40px 24px;
    vertical-align: top;
    @media (max-width: 1536px) {
      font-size: 16px;
      line-height: 24px;
      padding: 32px 16px;
    }
    @media (max-width: 1408px) {
      padding: 28px 16px;
    }
    @media (max-width: 1280px) {
      font-size: 15px;
      line-height: 23px;
      padding: 24px 8px;
    }
    @media (max-width: 375px) {
      padding: 20px 8px;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Row */
  /* ---------------------------------------------------------------- */
  tr {
    &:first-child td {
      padding-top: 48px;
      @media (max-width: 1536px) {
        padding-top: 40px;
      }
      @media (max-width: 1408px) {
        padding-top: 36px;
      }
      @media (max-width: 1280px) {
        padding-top: 32px;
      }
      @media (max-width: 375px) {
        padding-top: 28px;
      }
    }
    &:nth-child(even) {
      background-color: $mono1;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Image With Caption */
  /* ---------------------------------------------------------------- */
  figure {
    display: table;
    font-size: 0;
    line-height: 0;
    margin: 0;
    position: relative;
    &::after {
      background-image: url('/images/artwork/image-caption-degrees.svg');
      background-position: left bottom;
      background-repeat: no-repeat;
      background-size: cover;
      content: '';
      height: 100%;
      position: absolute;
      right: 0;
      top: 0;
      width: 42%;
      z-index: 1;
    }
  }
  img {
    max-width: 100%;
    width: auto;
  }
  /* ---------------------------------------------------------------- */
  /* Caption */
  /* ---------------------------------------------------------------- */
  figcaption {
    background-color: rgba($color: $brandBlackGrape, $alpha: 0.85);
    bottom: 0;
    box-sizing: border-box;
    color: $white;
    font-size: 14px;
    left: 0;
    line-height: 20px;
    padding: 19px 24px 18px;
    position: relative;
    right: 0;
    width: 100%;
    @media (max-width: 1280px) {
      font-size: 13px;
      line-height: 19px;
      padding: 16px 20px 15px;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Margins */
  /* ---------------------------------------------------------------- */
  > p,
  ul,
  ol,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0 0 20px;
    @media (max-width: 1280px) {
      margin: 0 0 16px;
    }
    @media (max-width: 767px) {
      margin: 0 0 20px;
    }
    @media (max-width: 576px) {
      margin: 0 0 16px;
    }
    @media (max-width: 375px) {
      margin: 0 0 12px;
    }
  }
  :global(.table),
  figure {
    margin: 32px 0;
    @media (max-width: 1280px) {
      margin: 28px 0;
    }
    @media (max-width: 767px) {
      margin: 32px 0;
    }
    @media (max-width: 576px) {
      margin: 28px 0;
    }
    @media (max-width: 375px) {
      margin: 24px 0;
    }
    &:first-child {
      margin-top: 0;
    }
  }
  *:last-child {
    margin-bottom: 0;
  }
  > * {
    &:not(figure):not(:global(.table)):first-child {
      margin-top: -6px;
    }
    &:not(figure):not(:global(.table)):last-child {
      margin-bottom: -6px;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Table */
  /* ---------------------------------------------------------------- */
  :global(.table) {
    overflow: auto;
    scrollbar-color: $brandAqua $mono1;
    scrollbar-width: thin;
    /* ---------------------------------------------------------------- */
    /* Scroll Bar  */
    /* ---------------------------------------------------------------- */
    &::-webkit-scrollbar {
      -webkit-appearance: none;
      height: 8px;
    }
    &::-webkit-scrollbar-track {
      background-color: $mono1;
      border-radius: 8px;
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    }
    &::-webkit-scrollbar-thumb {
      background-color: $brandAqua;
      border-radius: 8px;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Secondary */
  /* ---------------------------------------------------------------- */
  &.secondary {
    font-family: $secondary;
  }
}
