@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Question Payment Method Item */
/* ---------------------------------------------------------------- */
.questionPaymentMethodItem {
  align-items: center;
  background-color: $white;
  border-color: $mono2;
  border-radius: 9px;
  border-style: solid;
  border-width: 1px;
  box-sizing: border-box;
  display: flex;
  margin-top: 24px;
  padding: 32px;
  position: relative;
  width: auto;
  @include no-user-select;
  @media (max-width: 1536px) {
    margin-top: 20px;
  }
  @media (max-width: 1280px) {
    margin-top: 16px;
    padding: 28px;
  }
  @media (max-width: 1024px) {
    padding: 24px;
  }
  @media (max-width: 640px) {
    align-items: flex-start;
    border-radius: 4px;
    flex-direction: column;
    justify-content: center;
    margin-top: 32px;
    padding: 24px 24px 25px;
  }
  @media (max-width: 375px) {
    margin-top: 24px;
    min-height: 56px;
    padding: 16px;
  }
  &:last-of-type {
    margin-bottom: 32px;
    @media (max-width: 1536px) {
      margin-bottom: 28px;
    }
    @media (max-width: 1280px) {
      margin-bottom: 24px;
    }
    @media (max-width: 640px) {
      margin-bottom: 32px;
    }
    @media (max-width: 375px) {
      margin-bottom: 24px;
    }
  }
  /* -------------------------------------------------------------- */
  /* Additional */
  /* -------------------------------------------------------------- */
  & + .questionPaymentMethodItem {
    margin-top: 20px;
    @media (max-width: 1536px) {
      margin-top: 16px;
    }
    @media (max-width: 1280px) {
      margin-top: 12px;
    }
    @media (max-width: 375px) {
      margin-top: 8px;
    }
  }
  /* -------------------------------------------------------------- */
  /* Icon */
  /* -------------------------------------------------------------- */
  > svg {
    flex-shrink: 0;
    height: 36px;
    left: 32px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 36px;
    @media (max-width: 1280px) {
      left: 28px;
    }
    @media (max-width: 1024px) {
      left: 24px;
    }
    @media (max-width: 375px) {
      height: 32px;
      width: 32px;
    }
  }
  /* -------------------------------------------------------------- */
  /* Card */
  /* -------------------------------------------------------------- */
  > h4 {
    color: $brandBlackGrape;
    flex-grow: 1;
    font-size: 20px;
    letter-spacing: normal;
    line-height: 25px;
    padding: 0 16px 0 51px;
    @include user-select;
    @media (max-width: 1536px) {
      font-size: 19px;
      line-height: 25px;
    }
    @media (max-width: 1280px) {
      font-size: 18px;
      line-height: 24px;
    }
    @media (max-width: 640px) {
      font-size: 17px;
      line-height: 23px;
      padding: 0 44px 0 51px;
    }
    @media (max-width: 460px) {
      font-size: 16px;
      line-height: 22px;
    }
    @media (max-width: 375px) {
      font-size: 15px;
      line-height: 21px;
      padding: 0 40px 0 48px;
    }
    > strong {
      font-weight: $bold;
    }
  }
  /* -------------------------------------------------------------- */
  /* Hover */
  /* -------------------------------------------------------------- */
  :global(.platform-desktop) & {
    &:hover {
      background-color: $mono1;
      cursor: pointer;
    }
    &:active {
      background-color: $mono1;
    }
  }
  :global(.platform-mobile) & {
    &:active {
      background-color: $mono1;
    }
  }
  /* -------------------------------------------------------------- */
  /* Invalid */
  /* -------------------------------------------------------------- */
  &.invalid {
    border-color: red;
  }
  /* -------------------------------------------------------------- */
  /* Selected */
  /* -------------------------------------------------------------- */
  &.selected {
    border-color: $brandAqua;
    pointer-events: none;
  }
  /* -------------------------------------------------------------- */
  /* Disabled */
  /* -------------------------------------------------------------- */
  &.disabled {
    background-color: $mono1;
    border-color: $mono2;
    pointer-events: none;
    > h4 {
      color: $mono4;
    }
    > svg {
      filter: grayscale(100%);
    }
  }
}
