@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* -------------------------------------------------------------- */
/* Curated Pages */
/* -------------------------------------------------------------- */
.curatedPages {
  display: block;
  margin-bottom: 48px;
  @media (max-width: 1408px) {
    margin-bottom: 44px;
  }
  @media (max-width: 1024px) {
    margin-bottom: 40px;
  }
  @media (max-width: 767px) {
    margin-bottom: 32px;
  }
  @media (max-width: 375px) {
    margin-bottom: 24px;
  }
  &:last-child {
    margin-bottom: 0;
  }
  /* -------------------------------------------------------------- */
  /* Title */
  /* -------------------------------------------------------------- */
  h2 {
    align-items: center;
    color: rgba($brandBlackGrape, 50%);
    display: flex;
    font-family: $secondary;
    font-size: 18px;
    font-weight: $semibold;
    letter-spacing: 0.16px;
    line-height: 22px;
    margin-bottom: -5px;
    margin-top: -4px;
    text-transform: uppercase;
    @media (max-width: 1536px) {
      font-size: 17px;
      line-height: 21px;
    }
    @media (max-width: 1280px) {
      font-size: 16px;
      line-height: 20px;
      margin-top: -3px;
    }
    @media (max-width: 1024px) {
      font-size: 15px;
      line-height: 19px;
    }
  }
  /* -------------------------------------------------------------- */
  /* Key Template Container */
  /* -------------------------------------------------------------- */
  > div {
    display: grid;
    margin-top: 24px;
    position: relative;
    @media (min-width: 1537px), (max-width: 767px) {
      grid-auto-rows: 1fr;
      grid-gap: 24px;
    }
    @media (max-width: 1536px) {
      margin-top: 20px;
    }
    @media (max-width: 1536px) and (min-width: 1153px) {
      grid-gap: 0;
    }
    @media (max-width: 1152px) and (min-width: 768px), (max-width: 520px) {
      display: block;
    }
    /* -------------------------------------------------------------- */
    /* Carousel */
    /* -------------------------------------------------------------- */
    :global(.carousel-slider) {
      overflow: visible;
    }
    /* -------------------------------------------------------------- */
    /* Slider Wrapper */
    /* -------------------------------------------------------------- */
    :global(.slider-wrapper) {
      width: calc(100% - 1px);
    }
    /* -------------------------------------------------------------- */
    /* Slide - Prevent Hhidden slide button from being focusable */
    /* -------------------------------------------------------------- */
    :global(.slide) {
      a {
        opacity: 0;
        visibility: hidden;
        @include fast-transition(
          $props: 'background-color, opacity, visibility'
        );
      }
    }
    :global(.slide.selected) {
      a {
        opacity: 1;
        visibility: visible;
      }
    }
    /* -------------------------------------------------------------- */
    /* Prev / Next Buttons */
    /* -------------------------------------------------------------- */
    :global(.control-disabled) {
      visibility: hidden;
    }
    /* -------------------------------------------------------------- */
    /* Carousel Root */
    /* -------------------------------------------------------------- */
    :global(.carousel-root) {
      padding-bottom: 36px;
      @media (max-width: 520px) {
        padding-bottom: 31px;
      }
    }
    /* -------------------------------------------------------------- */
    /* Carousel - Dots List */
    /* -------------------------------------------------------------- */
    :global(.control-dots) {
      bottom: -36px;
      height: 16px;
      margin: 0;
      @media (max-width: 520px) {
        bottom: -31px;
      }
    }
    /* -------------------------------------------------------------- */
    /* Carousel - Dots Item */
    /* -------------------------------------------------------------- */
    :global(.dot[class]) {
      background: $mono2;
      box-shadow: none;
      height: 16px;
      margin: 0 6px;
      opacity: 0.5;
      width: 16px;
    }
    /* -------------------------------------------------------------- */
    /* Carousel - Dots Item - Selected */
    /* -------------------------------------------------------------- */
    :global(.dot.selected) {
      background: $mono3;
    }
  }
}
