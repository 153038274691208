@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Content Type List */
/* ---------------------------------------------------------------- */
.contentTypeList {
  padding-bottom: 36px;
  padding-top: 36px;
  @media (max-width: 1280px) {
    padding-bottom: 32px;
    padding-top: 32px;
  }
  @media (max-width: 1024px) {
    padding-bottom: 24px;
    padding-top: 24px;
  }
  @media (max-width: 767px) {
    padding-bottom: 32px;
    padding-top: 32px;
  }
  @media (max-width: 375px) {
    padding-bottom: 24px;
    padding-top: 24px;
  }
  &:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
  }
  /* ---------------------------------------------------------------- */
  /* Title */
  /* ---------------------------------------------------------------- */
  h2 {
    color: $brandBlackGrape;
    font-family: $secondary;
    font-size: 32px;
    font-weight: $semibold;
    line-height: 40px;
    margin: -8px 0;
    padding-bottom: 36px;
    padding-right: 24px;
    @media (max-width: 1664px) {
      font-size: 29px;
      line-height: 36px;
      margin: -7px 0 -8px;
    }
    @media (max-width: 1536px) {
      font-size: 26px;
      line-height: 33px;
      margin: -6px 0 -7px;
    }
    @media (max-width: 1408px) {
      font-size: 23px;
      line-height: 29px;
      margin: -5px 0 -7px;
    }
    @media (max-width: 1280px) {
      font-size: 20px;
      line-height: 26px;
      margin: -5px 0 -6px;
      padding-bottom: 32px;
    }
    @media (max-width: 1024px) {
      padding-bottom: 24px;
    }
    @media (max-width: 375px) {
      font-size: 19px;
      line-height: 25px;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Content Type Card Container */
  /* ---------------------------------------------------------------- */
  > ul {
    /* ---------------------------------------------------------------- */
    /* Item */
    /* ---------------------------------------------------------------- */
    > li {
      display: grid;
      grid-auto-rows: 1fr;
      grid-row-gap: 24px;
      width: 100%;
      @media (max-width: 1280px) {
        grid-row-gap: 20px;
      }
      @media (max-width: 1024px) {
        grid-row-gap: 16px;
      }
      @media (max-width: 896px) and (min-width: 768px), (max-width: 576px) {
        grid-auto-rows: auto;
        grid-row-gap: 12px;
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* CTA Container */
  /* ---------------------------------------------------------------- */
  > div:last-child {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
    position: relative;
    @media (max-width: 896px) and (min-width: 768px), (max-width: 576px) {
      margin-top: 16px;
    }
  }
}
