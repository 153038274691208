@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
.key-message {
  background-color: $productItilLight;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 9px;
  box-sizing: border-box;
  display: block;
  height: auto;
  margin: 56px 0;
  padding: 0 120px 120px 120px;
  position: relative;
  width: 100%;
  @media (max-width: 1536px) {
    margin: 48px 0;
  }
  @media (max-width: 1280px) {
    margin: 40px 0;
    padding: 0 100px 100px 100px;
  }
  @media (max-width: 1024px) {
    margin: 32px 0;
    padding-bottom: 80px;
    padding-left: 60px;
    padding-right: 60px;
  }
  @media (max-width: 767px) {
    padding-left: 20px;
    padding-right: 20px;
    text-align: center;
  }
  @media (max-width: 375px) {
    margin: 24px 0;
  }
  .tag {
    background-color: $productItil;
    border-bottom-left-radius: 9px;
    border-bottom-right-radius: 9px;
    color: $white;
    display: inline-block;
    font-size: 17px;
    font-weight: $bold;
    letter-spacing: 0.125em;
    margin-bottom: 72px;
    padding: 40px 60px;
    position: relative;
    text-transform: uppercase;
    width: auto;
    @media (max-width: 767px) {
      font-size: 15px;
      margin-bottom: 60px;
      padding: 30px;
    }
  }
  p {
    font-family: $secondary;
    font-size: 28px;
    line-height: 36px;
    margin-bottom: 24px;
    @media (max-width: 1536px) {
      font-size: 24px;
      line-height: 32px;
      margin-bottom: 20px;
    }
    @media (max-width: 1280px) {
      font-size: 22px;
      line-height: 30px;
      margin-bottom: 18px;
    }
    @media (max-width: 375px) {
      font-size: 20px;
      line-height: 28px;
      margin-bottom: 16px;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
}
