@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* -------------------------------------------------------------- */
/* Header */
/* -------------------------------------------------------------- */
.menu {
  align-items: center;
  box-sizing: border-box;
  display: flex;
  height: 100%;
  width: 100%;
  @media (max-width: 767px) {
    background: $pageBackground;
    box-sizing: border-box;
    flex-direction: column;
    height: calc(100% - 72px);
    left: 0;
    min-width: 320px;
    padding: 0 24px;
    position: absolute;
    top: 72px;
    transform: translate3d(-100%, 0, 0);
    visibility: hidden;
    width: 100%;
    will-change: transform opacity;
    z-index: 98;
    @include transition(
      $mob: true,
      $props: 'transform, opacity',
      $timing: 'cubic-bezier(0.23, 1, 0.32, 1)',
      $duration: '0.2s'
    );
  }
  @media (max-width: 375px) {
    padding: 0 16px;
  }
  /* -------------------------------------------------------------- */
  /* Menu List */
  /* -------------------------------------------------------------- */
  > ul {
    display: flex;
    height: 100%;
    margin: 0 auto;
    padding: 0;
    @media (max-width: 767px) {
      flex-direction: column;
      flex-grow: 1;
      height: auto;
      margin: 0;
      width: 100%;
    }
  }
  /* -------------------------------------------------------------- */
  /* Menu Item */
  /* -------------------------------------------------------------- */
  > ul > li {
    align-items: center;
    display: flex;
    height: 100%;
    list-style: none;
    margin: 0 4px;
    overflow: hidden;
    position: relative;
    @media (max-width: 767px) {
      align-items: flex-start;
      flex-direction: column;
      flex-shrink: 0;
      height: auto;
      margin: 0;
      min-height: 64px;
    }
    &:last-child {
      margin-right: 0;
      @media (max-width: 767px) {
        margin-bottom: 32px;
      }
    }
  }
  /* -------------------------------------------------------------- */
  /* Menu Link */
  /* -------------------------------------------------------------- */
  a,
  button {
    align-items: center;
    box-sizing: border-box;
    color: $brandBlackGrape;
    display: flex;
    font-size: 17px;
    font-weight: inherit;
    height: 100%;
    padding: 0 31px;
    text-decoration: none;
    @include fast-transition($props: 'background-color');
    @include no-user-select;
    @media (max-width: 1664px) {
      padding: 0 27px;
    }
    @media (max-width: 1536px) {
      padding: 0 23px;
    }
    @media (max-width: 1408px) {
      padding: 0 19px;
    }
    @media (max-width: 1280px) {
      font-size: 16px;
      padding: 0 15px;
    }
    @media (max-width: 1152px) {
      padding: 0 11px;
    }
    @media (max-width: 1024px) {
      padding: 0 7px;
    }
    @media (max-width: 767px) {
      cursor: pointer;
      font-family: $primary;
      padding: 0;
      touch-action: manipulation;
      width: 100%;
      @include fast-transition($props: 'font-variation-settings');
    }
    /* -------------------------------------------------------------- */
    /* Active Page Line */
    /* -------------------------------------------------------------- */
    &::after {
      background-color: $brandAquaLight;
      bottom: 0;
      content: '';
      height: 3px;
      left: -10px;
      position: absolute;
      right: 0;
      width: 0;
      @include fast-transition($props: 'background-color, width');
      @media (max-width: 767px) {
        left: 0;
      }
    }
    /* -------------------------------------------------------------- */
    /* Mobile Border */
    /* -------------------------------------------------------------- */
    &::before {
      @media (max-width: 767px) {
        background-color: $grey;
        bottom: 0;
        content: '';
        height: 1px;
        left: 0;
        position: absolute;
        right: 0;
        width: 100%;
      }
    }
    /* -------------------------------------------------------------- */
    /* Mobile Icon */
    /* -------------------------------------------------------------- */
    svg {
      @media (max-width: 767px) {
        height: 24px;
        position: absolute;
        width: 24px;
      }
      > path:last-child,
      > g:last-child > path:last-child {
        @media (max-width: 767px) {
          fill: rgba($brandBlackGrape, 0.7);
        }
      }
    }
    /* -------------------------------------------------------------- */
    /* Text Container */
    /* -------------------------------------------------------------- */
    span {
      color: transparent;
      display: block;
      position: relative;
      @media (max-width: 767px) {
        color: $brandBlackGrape;
      }
      /* -------------------------------------------------------------- */
      /* Text */
      /* -------------------------------------------------------------- */
      &:first-child::after {
        color: $brandBlackGrape;
        content: attr(data-name);
        display: block;
        left: 50%;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        @include fast-transition($props: 'font-variation-settings');
        @include variable-fonts($weight: 400, $width: 100);
        @media (max-width: 767px) {
          display: none;
        }
      }
    }
    /* -------------------------------------------------------------- */
    /* Active Page */
    /* -------------------------------------------------------------- */
    &[aria-current='page'] {
      @media (max-width: 767px) {
        @include variable-fonts($weight: 600, $width: 100);
      }
      &:focus::after {
        background-color: $brandAqua;
      }
      span::after {
        @media (min-width: 768px) {
          @include variable-fonts($weight: 600, $width: 100);
        }
      }
      &::after {
        width: calc(100% + 20px);
        @media (max-width: 767px) {
          width: 100%;
        }
      }
    }
    /* -------------------------------------------------------------- */
    /* Focus */
    /* -------------------------------------------------------------- */
    &:focus {
      text-underline-offset: 1px;
      span {
        @media (max-width: 767px) {
          text-decoration: underline;
        }
        &::after {
          @media (min-width: 768px) {
            text-decoration: underline;
            @include variable-fonts($weight: 700, $width: 75);
          }
        }
      }
      &::after {
        background-color: $brandBerry;
        width: calc(100% + 20px);
        @media (max-width: 767px) {
          width: 100%;
        }
      }
    }
    /* -------------------------------------------------------------- */
    /* Desktop Hover / Active */
    /* -------------------------------------------------------------- */
    :global(.platform-desktop) & {
      &:hover {
        span::after {
          @media (min-width: 768px) {
            @include variable-fonts($weight: 700, $width: 75);
          }
        }
        &::after {
          width: calc(100% + 20px);
          @media (max-width: 767px) {
            width: 100%;
          }
        }
      }
      &:active {
        @media (min-width: 768px) {
          background-color: $brandAquaLightPastel;
        }
        span::after {
          @media (min-width: 768px) {
            @include variable-fonts($weight: 700, $width: 75);
          }
        }
        &::after {
          @media (max-width: 767px) {
            background-color: $brandAquaLightPastel;
            width: 100%;
          }
        }
      }
    }
    /* -------------------------------------------------------------- */
    /* Mobile Active */
    /* -------------------------------------------------------------- */
    :global(.platform-mobile) & {
      &:active {
        span::after {
          @media (min-width: 768px) {
            @include variable-fonts($weight: 700, $width: 75);
          }
        }
        &::after {
          width: calc(100% + 20px);
          @media (max-width: 767px) {
            width: 100%;
          }
        }
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Animating Mobile Menu */
  /* ---------------------------------------------------------------- */
  &.animating {
    @media (max-width: 767px) {
      visibility: visible;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Open Mobile Menu */
  /* ---------------------------------------------------------------- */
  &.open {
    @media (max-width: 767px) {
      opacity: 1;
      overflow: auto;
      transform: translate3d(0, 0, 0);
      transition-duration: 0.4s;
      visibility: visible;
    }
  }
}
