@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* -------------------------------------------------------------- */
/* Achievements */
/* -------------------------------------------------------------- */
.achievements {
  display: flex;
  flex-direction: column;
  margin-bottom: 48px;
  @media (max-width: 1408px) {
    margin-bottom: 44px;
  }
  @media (max-width: 1024px) {
    margin-bottom: 40px;
  }
  @media (max-width: 767px) {
    margin-bottom: 32px;
  }
  @media (max-width: 375px) {
    margin-bottom: 24px;
  }
  &:last-child {
    margin-bottom: 0;
  }
  /* -------------------------------------------------------------- */
  /* Title */
  /* -------------------------------------------------------------- */
  h3 {
    align-items: center;
    color: rgba($brandBlackGrape, 50%);
    display: flex;
    font-family: $secondary;
    font-size: 18px;
    font-weight: $semibold;
    letter-spacing: 0.16px;
    line-height: 22px;
    margin-bottom: -5px;
    margin-top: -4px;
    text-transform: uppercase;
    @media (max-width: 1536px) {
      font-size: 17px;
      line-height: 21px;
    }
    @media (max-width: 1280px) {
      font-size: 16px;
      line-height: 20px;
      margin-top: -3px;
    }
    @media (max-width: 1024px) {
      font-size: 15px;
      line-height: 19px;
    }
    @media (max-width: 767px) {
      font-size: 16px;
      line-height: 20px;
    }
  }
  /* -------------------------------------------------------------- */
  /* Achievements Container */
  /* -------------------------------------------------------------- */
  ul {
    margin-top: 24px;
    @media (max-width: 1536px) {
      margin-top: 20px;
    }
    @media (max-width: 1280px) {
      margin-top: 16px;
    }
  }
  /* -------------------------------------------------------------- */
  /* Link Container */
  /* -------------------------------------------------------------- */
  > div:last-child {
    display: flex;
    justify-content: flex-end;
    margin-top: 28px;
    @media (max-width: 1536px) {
      margin-top: 24px;
    }
    @media (max-width: 1280px) {
      margin-top: 20px;
    }
    a {
      min-width: 175px;
    }
  }
}
