@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
.loaderModal {
  align-items: center;
  background-color: rgba($color: $black, $alpha: 0.5);
  bottom: 0;
  display: flex;
  justify-content: center;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 9999;
  /* ---------------------------------------------------------------- */
  /* Loading */
  /* ---------------------------------------------------------------- */
  .spinner {
    background-color: $brandBlackGrape;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 56px 56px;
    border-radius: 50%;
    display: block;
    height: 64px;
    top: 0;
    width: 64px;
    @media (max-width: 767px) {
      background-size: 40px 40px;
      height: 48px;
      width: 48px;
    }
  }
}
