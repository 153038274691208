@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Modal Info */
/* ---------------------------------------------------------------- */
.modalInfo {
  /* ---------------------------------------------------------------- */
  /* Scrollable Content */
  /* ---------------------------------------------------------------- */
  > div {
    /* ---------------------------------------------------------------- */
    /* Modal Content */
    /* ---------------------------------------------------------------- */
    .modalContent {
      border: 1px solid $productProPath;
      border-radius: 25px;
      box-sizing: border-box;
      margin: 0 auto;
      max-width: 850px;
      padding: 70px 72px;
      position: relative;
      width: 100%;
      @media (max-width: 767px) {
        padding: 40px 42px;
      }
      @media (max-width: 520px) {
        padding: 32px 16px;
      }
      .certificate {
        padding-bottom: 52px;
        div:first-child {
          align-items: center;
          display: flex;
          justify-content: space-between;
          width: 100%;
          @media (max-width: 520px) {
            flex-direction: column;
          }
          > svg {
            height: 50px;
            width: auto;
            @media (max-width: 520px) {
              order: 2;
            }
            path,
            rect {
              fill: $productProPath;
            }
          }
          .date {
            color: $mono4;
            font-size: 18px;
            line-height: 24px;
            margin-left: 20px;
            @media (max-width: 520px) {
              font-size: 16px;
              line-height: 22px;
              margin-bottom: 26px;
              order: 1;
            }
          }
        }
        div:last-child {
          h3 {
            color: $brandBlackGrape;
            font-family: $secondary;
            font-size: 32px;
            font-weight: $light;
            letter-spacing: 8px;
            line-height: 40px;
            margin-top: 22px;
            text-transform: uppercase;
            @media (max-width: 1152px) {
              font-size: 20px;
              line-height: 24px;
            }
            @media (max-width: 520px) {
              font-size: 24px;
              line-height: 28px;
              text-align: center;
            }
            @media (max-width: 375px) {
              font-size: 20px;
              line-height: 24px;
            }
          }
        }
      }
      .claimInfo {
        @media (max-width: 520px) {
          text-align: center;
        }
        h2 {
          border-bottom: 1px solid $grey;
          color: $brandBlackGrape;
          font-family: $secondary;
          font-size: 32px;
          font-weight: $semibold;
          line-height: 40px;
          margin-bottom: 26px;
          padding-bottom: 20px;
          @media (max-width: 1152px) {
            font-size: 24px;
            line-height: 28px;
          }
          @media (max-width: 375px) {
            font-size: 20px;
            line-height: 24px;
          }
        }
        .candidateNumber {
          color: $mono4;
          display: block;
          font-size: 18px;
          line-height: 26px;
          margin-bottom: 4px;
          @media (max-width: 767px) {
            font-size: 16px;
            line-height: 24px;
          }
        }
        ul {
          li {
            color: $mono4;
            display: block;
            font-size: 18px;
            line-height: 26px;
            margin-bottom: 4px;
            @media (max-width: 767px) {
              font-size: 16px;
              line-height: 24px;
            }
            span {
              font-size: 16px;
              line-height: 24px;
              @media (max-width: 767px) {
                font-size: 14px;
                line-height: 22px;
              }
            }
          }
        }
        strong {
          font-weight: $semibold;
        }
      }
    }
  }
}
