@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* -------------------------------------------------------------- */
/* Badges */
/* -------------------------------------------------------------- */
.badges {
  background-color: $white;
  border: 1px solid $grey;
  border-radius: 4px;
  margin-bottom: 48px;
  position: relative;
  @media (max-width: 1408px) {
    margin-bottom: 44px;
  }
  @media (max-width: 1024px) {
    margin-bottom: 40px;
  }
  @media (max-width: 767px) {
    margin-bottom: 32px;
  }
  @media (max-width: 375px) {
    margin-bottom: 24px;
  }
  @media (max-width: 1152px) and (min-width: 768px), (max-width: 360px) {
    background-color: transparent;
    border: none;
  }
  &:last-child {
    margin-bottom: 0;
  }
  /* -------------------------------------------------------------- */
  /* Header */
  /* -------------------------------------------------------------- */
  > header {
    /* -------------------------------------------------------------- */
    /* Button */
    /* -------------------------------------------------------------- */
    > button {
      border-bottom: solid 1px $grey;
      cursor: pointer;
      list-style: none;
      padding: 28px 32px;
      text-align: left;
      transition-duration: 0.1s;
      transition-property: border-color;
      width: 100%;
      @include no-user-select;
      @media (max-width: 1536px) {
        padding: 24px 28px;
      }
      @media (max-width: 1280px) {
        padding: 20px 24px;
      }
      @media (max-width: 1152px) and (min-width: 768px), (max-width: 360px) {
        border: none;
        padding: 0;
      }
      /* -------------------------------------------------------------- */
      /* Focus Ring */
      /* -------------------------------------------------------------- */
      h3 > span::before {
        border: 2px dashed transparent;
        content: '';
        display: block;
        height: calc(100% + 4px);
        left: -4px;
        position: absolute;
        top: -4px;
        width: calc(100% + 4px);
        @include fast-transition($props: 'border-color');
        @media (max-width: 1536px) {
          height: calc(100% + 2px);
        }
        @media (max-width: 1280px) {
          height: 100%;
        }
      }
      /* -------------------------------------------------------------- */
      /* Focus */
      /* -------------------------------------------------------------- */
      &:focus h3 > span::before {
        border-color: $brandBerry;
      }
      /* -------------------------------------------------------------- */
      /* Chevron Container */
      /* -------------------------------------------------------------- */
      h3 > span {
        height: 14px;
        margin-left: auto;
        position: relative;
        width: 16px;
      }
      /* -------------------------------------------------------------- */
      /* Chevron */
      /* -------------------------------------------------------------- */
      svg {
        height: 8px;
        position: relative;
        right: -4px;
        top: -3px;
        transform: rotate(0);
        width: 14px;
        @include medium-transition($mob: true, $props: 'transform');
        * {
          fill: $mono5;
        }
      }
    }
    /* -------------------------------------------------------------- */
    /* Header - Title */
    /* -------------------------------------------------------------- */
    h3 {
      align-items: center;
      color: rgba($brandBlackGrape, 50%);
      display: flex;
      font-family: $secondary;
      font-size: 18px;
      font-weight: $semibold;
      letter-spacing: 0.16px;
      line-height: 22px;
      margin-bottom: 13px;
      margin-top: -4px;
      text-transform: uppercase;
      @media (max-width: 1536px) {
        font-size: 17px;
        line-height: 21px;
        margin-bottom: 10px;
      }
      @media (max-width: 1280px) {
        font-size: 16px;
        line-height: 20px;
        margin-bottom: 6px;
      }
      @media (max-width: 1024px) {
        font-size: 15px;
        line-height: 19px;
        margin-bottom: 7px;
      }
      @media (max-width: 767px) {
        font-size: 16px;
        line-height: 20px;
        margin-bottom: 6px;
      }
    }
    /* -------------------------------------------------------------- */
    /* Header - Text */
    /* -------------------------------------------------------------- */
    p {
      font-family: $primary;
      font-size: 17px;
      font-weight: $regular;
      line-height: 25px;
      margin-bottom: -6px;
      @media (max-width: 1536px) {
        font-size: 16px;
        line-height: 24px;
      }
      @media (max-width: 1280px) {
        font-size: 15px;
        line-height: 23px;
      }
      @media (max-width: 1024px) {
        font-size: 14px;
        line-height: 21px;
        margin-bottom: -5px;
      }
    }
    /* -------------------------------------------------------------- */
    /* Header - No Candidate Number's */
    /* -------------------------------------------------------------- */
    > div {
      padding: 28px 32px;
      @media (max-width: 1536px) {
        padding: 24px 28px;
      }
      @media (max-width: 1280px) {
        padding: 20px 24px;
      }
      @media (max-width: 1152px) and (min-width: 768px), (max-width: 360px) {
        padding: 0;
      }
    }
  }
  /* -------------------------------------------------------------- */
  /* Content */
  /* -------------------------------------------------------------- */
  > div[aria-hidden] {
    opacity: 1;
    padding: 0;
    transform: scaleY(1);
    transform-origin: 0 0;
    visibility: visible;
    will-change: height, opacity, transform;
    @include medium-transition(
      $firefox: false,
      $mob: true,
      $props: 'opacity, height, transform'
    );
  }
  /* -------------------------------------------------------------- */
  /* List */
  /* -------------------------------------------------------------- */
  ul {
    color: inherit;
    counter-reset: badge-counter;
    padding: 28px 32px;
    position: relative;
    user-select: text;
    @media (max-width: 1536px) {
      padding: 24px 28px;
    }
    @media (max-width: 1280px) {
      padding: 20px 24px;
    }
    @media (max-width: 1152px) and (min-width: 768px), (max-width: 360px) {
      padding: 21px 0 0;
    }
    @media (max-width: 940px) and (min-width: 768px), (max-width: 360px) {
      padding: 31px 0 0;
    }
    /* -------------------------------------------------------------- */
    /* Mobile Row 1 Border */
    /* -------------------------------------------------------------- */
    &::before {
      @media (max-width: 1152px) and (min-width: 768px), (max-width: 360px) {
        background-color: $grey;
        content: '';
        height: 1px;
        position: absolute;
        right: 0;
        top: 20px;
        width: 100%;
      }
      @media (max-width: 940px) and (min-width: 768px), (max-width: 360px) {
        top: 30px;
        width: calc(100% - 18px);
      }
    }
  }
  /* -------------------------------------------------------------- */
  /* Item */
  /* -------------------------------------------------------------- */
  li {
    align-items: center;
    counter-increment: badge-counter;
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 28px;
    padding-top: 28px;
    position: relative;
    @media (max-width: 1536px) {
      padding-bottom: 24px;
      padding-top: 24px;
    }
    @media (max-width: 1280px) {
      padding-bottom: 20px;
      padding-top: 20px;
    }
    &:first-child {
      p,
      &::before {
        opacity: 1;
      }
      @media (min-width: 1153px), (max-width: 767px) and (min-width: 361px) {
        padding-top: 0;
      }
    }
    &:last-child {
      padding-bottom: 0;
      @media (max-width: 1152px) and (min-width: 768px), (max-width: 360px) {
        border-bottom: 1px solid $grey;
        padding-bottom: 20px;
        padding-top: 19px;
      }
    }
    /* -------------------------------------------------------------- */
    /* Achieve Certification Row */
    /* -------------------------------------------------------------- */
    &:nth-child(2) {
      flex-direction: row;
      > div {
        margin-top: 0;
      }
    }
    /* -------------------------------------------------------------- */
    /* Item Number */
    /* -------------------------------------------------------------- */
    &::before {
      background-color: $brandGreyLight;
      border-radius: 99em;
      color: rgba($brandBlackGrape, 50%);
      content: counter(badge-counter);
      display: block;
      flex-shrink: 0;
      font-family: $secondary;
      font-size: initial;
      font-weight: $bold;
      height: 40px;
      line-height: 41px;
      margin-right: 24px;
      opacity: 1;
      text-align: center;
      width: 40px;
      @media (max-width: 1664px) {
        height: 36px;
        line-height: 37px;
        margin-right: 20px;
        width: 36px;
      }
      @media (max-width: 1536px) {
        height: 32px;
        line-height: 33px;
        margin-right: 16px;
        width: 32px;
      }
      @media (max-width: 1280px) {
        height: 28px;
        line-height: 29px;
        margin-right: 12px;
        width: 28px;
      }
      @media (max-width: 940px) and (min-width: 768px), (max-width: 360px) {
        background-color: transparent;
        font-size: 19px;
        height: auto;
        line-height: 13px;
        position: absolute;
        top: -7px;
        width: auto;
        z-index: 8;
      }
    }
    > svg,
    > div > svg {
      height: 40px;
      margin-left: 16px;
      width: 40px;
      @media (max-width: 1664px) {
        height: 36px;
        width: 36px;
      }
      @media (max-width: 1536px) {
        height: 32px;
        width: 32px;
      }
      @media (max-width: 1280px) {
        height: 28px;
        width: 28px;
      }
    }
    /* -------------------------------------------------------------- */
    /* Border */
    /* -------------------------------------------------------------- */
    &::after {
      background-color: $grey;
      bottom: 0;
      content: '';
      height: 1px;
      position: absolute;
      right: 0;
      width: 100%;
      @media (max-width: 940px) and (min-width: 768px), (max-width: 360px) {
        width: calc(100% - 18px);
      }
    }
    &:last-child::after {
      content: none;
    }
    /* -------------------------------------------------------------- */
    /* Inner Container */
    /* -------------------------------------------------------------- */
    > div {
      align-items: center;
      display: flex;
      flex: 1;
      &.claimingBadges {
        p {
          > a[class][href] {
            background-color: transparent;
            color: rgba($brandBlackGrape, 0.5);
            display: block;
            font-size: 13px;
            font-weight: $semibold;
            height: auto;
            line-height: 18px;
            margin: 0;
            padding: 0;
            width: auto;
            :global(.platform-desktop) & {
              &:hover {
                color: $productProPathDark;
              }
              &:active {
                color: darken($productProPathDark, 10%);
              }
            }
            :global(.platform-mobile) & {
              &:active {
                color: $productProPathDark;
              }
            }
            &:focus {
              text-decoration: underline;
            }
          }
        }
        svg > path {
          fill: $brandAqua;
        }
      }
      .claimBadgeCta {
        position: relative;
        /* ---------------------------------------------------------- */
        /* Loader */
        /* ---------------------------------------------------------- */
        > span[class] {
          background-size: 28px 28px;
          height: 32px;
          margin-top: 0;
          position: absolute;
          right: 16px;
          top: 12px;
          width: 32px;
          z-index: 2;
          @media (max-width: 1664px) {
            right: 12px;
          }
        }
      }
    }
    /* -------------------------------------------------------------- */
    /* Text */
    /* -------------------------------------------------------------- */
    p {
      font-size: 20px;
      line-height: 28px;
      margin-right: auto;
      opacity: 0.3;
      @media (max-width: 1664px) {
        font-size: 19px;
        line-height: 27px;
      }
      @media (max-width: 1536px) {
        font-size: 18px;
        line-height: 26px;
      }
      @media (max-width: 1408px) {
        font-size: 17px;
        line-height: 25px;
      }
      @media (max-width: 1280px) {
        font-size: 16px;
        line-height: 24px;
      }
    }
    /* -------------------------------------------------------------- */
    /* Button */
    /* -------------------------------------------------------------- */
    button[class][type],
    a[class][href] {
      height: 56px;
      margin: 0 25px 0 24px;
      @media (max-width: 1664px) {
        margin-left: 20px;
        padding: 0 9px 0 28px;
      }
      @media (max-width: 1536px) {
        margin-left: 16px;
        padding: 0 5px 0 24px;
      }
      @media (max-width: 1280px) {
        margin-left: 12px;
      }
      @media (max-width: 940px) and (min-width: 768px), (max-width: 360px) {
        margin-left: 8px;
      }
      /* -------------------------------------------------------------- */
      /* Button Slice */
      /* -------------------------------------------------------------- */
      svg:last-of-type {
        right: -25px;
        width: 26px;
      }
    }
    /* ---------------------------------------------------------------- */
    /* Show Help Tooltip - Mobile */
    /* ---------------------------------------------------------------- */
    &.showHelp {
      [role='tooltip'] {
        opacity: 1;
        transform: translateY(-100%) scale(1);
      }
    }
  }
  /* -------------------------------------------------------------- */
  /* Closed */
  /* -------------------------------------------------------------- */
  &.closed {
    /* ------------------------------------------------------------ */
    /* Prevent Content Children From Being Focused */
    /* ------------------------------------------------------------ */
    &:not(.animating) > div:nth-child(2) > * {
      visibility: hidden;
    }
    /* ------------------------------------------------------------ */
    /* Content */
    /* ------------------------------------------------------------ */
    > div:nth-child(2) {
      height: 0;
      opacity: 0;
      padding-bottom: 0;
      transform: scaleY(0.6);
    }
    /* ------------------------------------------------------------ */
    /* Button */
    /* ------------------------------------------------------------ */
    > header button {
      border-color: transparent;
      transition-duration: 0.35s;
      /* ------------------------------------------------------------ */
      /* Chevron */
      /* ------------------------------------------------------------ */
      svg {
        transform: rotate(180deg);
      }
    }
  }
  /* -------------------------------------------------------------- */
  /* Candidate Number Success */
  /* -------------------------------------------------------------- */
  &.success,
  &.noExams {
    li:first-child {
      align-items: center;
      p {
        opacity: 0.3;
      }
    }
  }
  /* -------------------------------------------------------------- */
  /* Success */
  /* -------------------------------------------------------------- */
  &.success {
    li:last-child {
      font-weight: $bold;
      &::before {
        background-color: $brandAqua;
        color: $white;
        opacity: 1;
        @media (max-width: 940px) and (min-width: 768px), (max-width: 360px) {
          background-color: transparent;
          color: $brandAqua;
        }
      }
      p {
        opacity: 1;
      }
    }
  }
  /* -------------------------------------------------------------- */
  /* No Exams */
  /* -------------------------------------------------------------- */
  &.noExams {
    li:nth-child(2) {
      font-weight: $bold;
      &::before {
        background-color: $brandAqua;
        color: $white;
        @media (max-width: 940px) and (min-width: 768px), (max-width: 360px) {
          background-color: transparent;
          color: $brandAqua;
        }
      }
      p {
        opacity: 1;
      }
    }
  }
  svg {
    :global(.platform-desktop) & {
      &:hover + [role='tooltip'] {
        opacity: 1;
        transform: translateY(-100%) scale(1);
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Help Tooltip */
  /* ---------------------------------------------------------------- */
  [role='tooltip'] {
    background-color: $white;
    border: 1px solid $mono2;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.16) 0 3px 6px;
    box-sizing: border-box;
    font-size: 17px;
    line-height: 25px;
    opacity: 0;
    padding: 11px 16px;
    position: absolute;
    right: -28px;
    top: 21px;
    transform: translateY(-100%) scale(0);
    transform-origin: calc(100% - 68px) calc(100% + 10px);
    transition-duration: 0.2s;
    transition-property: transform, opacity;
    transition-timing-function: ease-in-out;
    width: 208px;
    z-index: 9;
    @media (max-width: 1536px) {
      font-size: 16px;
      line-height: 24px;
      right: -32px;
      top: 13px;
      width: 200px;
    }
    @media (max-width: 1408px) {
      right: -24px;
      transform-origin: calc(100% - 61px) calc(100% + 10px);
    }
    @media (max-width: 1280px) {
      font-size: 15px;
      line-height: 23px;
      right: -16px;
      top: 9px;
      transform-origin: calc(100% - 48px) calc(100% + 10px);
      width: 188px;
    }
    @media (max-width: 1024px) {
      right: -12px;
      top: 7px;
      transform-origin: calc(100% - 39px) calc(100% + 10px);
    }
    @media (max-width: 375px) {
      font-size: 14px;
      line-height: 22px;
      right: -8px;
      transform-origin: calc(100% - 35px) calc(100% + 10px);
      width: 178px;
    }
    &::after {
      background-image: url(/images/icons/help-chevron.svg);
      background-repeat: no-repeat;
      background-size: cover;
      bottom: -23px;
      content: '';
      display: block;
      height: 30px;
      pointer-events: none;
      position: absolute;
      right: 35px;
      width: 30px;
      @media (max-width: 1408px) {
        right: 27px;
      }
      @media (max-width: 1280px) {
        right: 17px;
      }
      @media (max-width: 1024px) {
        right: 13px;
      }
      @media (max-width: 375px) {
        right: 8px;
      }
    }
  }
}
