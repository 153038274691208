@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Hyperlink */
/* ---------------------------------------------------------------- */
.hyperlink {
  display: inline-block;
  text-decoration: underline;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  @include fast-transition($props: 'color');
  @include no-user-select;
  /* -------------------------------------------------------------- */
  /* Icon */
  /* -------------------------------------------------------------- */
  svg * {
    @include fast-transition($props: 'stroke');
  }
  /* -------------------------------------------------------------- */
  /* Brand Aqua */
  /* -------------------------------------------------------------- */
  &.Aqua {
    color: $brandAqua;
    svg * {
      fill: $brandAqua;
    }
    &:focus {
      color: $brandAquaDark;
      svg * {
        fill: $brandAquaDark;
      }
    }
    :global(.platform-desktop) & {
      &:hover {
        color: $brandAquaLight;
        svg * {
          fill: $brandAquaLight;
        }
      }
      &:active {
        color: $brandAquaDark;
        svg * {
          fill: $brandAquaDark;
        }
      }
    }
    :global(.platform-mobile) & {
      &:active {
        color: $brandAquaLight;
        svg * {
          fill: $brandAquaLight;
        }
      }
    }
  }
  /* -------------------------------------------------------------- */
  /* Brand Black Grape */
  /* -------------------------------------------------------------- */
  &.BlackGrape {
    color: $brandBlackGrape;
    svg * {
      fill: $brandBlackGrape;
    }
    &:focus {
      color: $brandBlackGrapeDark;
      svg * {
        fill: $brandBlackGrapeDark;
      }
    }
    :global(.platform-desktop) & {
      &:hover {
        color: $brandBlackGrapeLight;
        svg * {
          fill: $brandBlackGrapeLight;
        }
      }
      &:active {
        color: $brandBlackGrapeDark;
        svg * {
          fill: $brandBlackGrapeDark;
        }
      }
    }
    :global(.platform-mobile) & {
      &:active {
        color: $brandBlackGrapeLight;
        svg * {
          fill: $brandBlackGrapeLight;
        }
      }
    }
  }
  /* -------------------------------------------------------------- */
  /* Brand Berry */
  /* -------------------------------------------------------------- */
  &.Berry {
    color: $brandBerry;
    svg * {
      fill: $brandBerry;
    }
    &:focus {
      color: $brandBerryDark;
      svg * {
        fill: $brandBerryDark;
      }
    }
    :global(.platform-desktop) & {
      &:hover {
        color: $brandBerryLight;
        svg * {
          fill: $brandBerryLight;
        }
      }
      &:active {
        color: $brandBerryDark;
        svg * {
          fill: $brandBerryDark;
        }
      }
    }
    :global(.platform-mobile) & {
      &:active {
        color: $brandBerryLight;
        svg * {
          fill: $brandBerryLight;
        }
      }
    }
  }
  /* -------------------------------------------------------------- */
  /* White */
  /* -------------------------------------------------------------- */
  &.White {
    color: $white;
    svg * {
      fill: $white;
    }
    &:focus {
      color: $mono2;
      svg * {
        fill: $mono2;
      }
    }
    :global(.platform-desktop) & {
      &:hover {
        color: $mono1;
        svg * {
          fill: $mono1;
        }
      }
      &:active {
        color: $mono2;
        svg * {
          fill: $mono2;
        }
      }
    }
    :global(.platform-mobile) & {
      &:active {
        color: $mono1;
        svg * {
          fill: $mono1;
        }
      }
    }
  }
  /* -------------------------------------------------------------- */
  /* Product ProPath */
  /* -------------------------------------------------------------- */
  &.ProductProPath {
    color: $productProPath;
    svg * {
      fill: $productProPath;
    }
    &:focus {
      color: $productProPathDark;
    }
    :global(.platform-desktop) & {
      &:hover {
        color: $productProPathLight;
      }
      &:active {
        color: $productProPathDark;
      }
    }
    :global(.platform-mobile) & {
      &:active {
        color: $productProPathLight;
      }
    }
  }
  /* -------------------------------------------------------------- */
  /* Product Itil */
  /* -------------------------------------------------------------- */
  &.ProductItil {
    color: $productItil;
    svg * {
      fill: $productItil;
    }
    &:focus {
      color: $productItilDark;
      svg * {
        fill: $productItilDark;
      }
    }
    :global(.platform-desktop) & {
      &:hover {
        color: $productItilLight;
        svg * {
          fill: $productItilLight;
        }
      }
      &:active {
        color: $productItilDark;
        svg * {
          fill: $productItilDark;
        }
      }
    }
    :global(.platform-mobile) & {
      &:active {
        color: $productItilLight;
        svg * {
          fill: $productItilLight;
        }
      }
    }
  }
  /* -------------------------------------------------------------- */
  /* Product Transformation */
  /* -------------------------------------------------------------- */
  &.ProductTransformation {
    color: $productTransformation;
    svg * {
      fill: $productTransformation;
    }
    &:focus {
      color: $productTransformationDark;
      svg * {
        fill: $productTransformationDark;
      }
    }
    :global(.platform-desktop) & {
      &:hover {
        color: $productTransformationLight;
        svg * {
          fill: $productTransformationLight;
        }
      }
      &:active {
        color: $productTransformationDark;
        svg * {
          fill: $productTransformationDark;
        }
      }
    }
    :global(.platform-mobile) & {
      &:active {
        color: $productTransformationLight;
        svg * {
          fill: $productTransformationLight;
        }
      }
    }
  }
  /* -------------------------------------------------------------- */
  /* Product Maturity */
  /* -------------------------------------------------------------- */
  &.ProductMaturity {
    color: $productMaturity;
    svg * {
      fill: $productMaturity;
    }
    &:focus {
      color: $productMaturityDark;
      svg * {
        fill: $productMaturityDark;
      }
    }
    :global(.platform-desktop) & {
      &:hover {
        color: $productMaturityLight;
        svg * {
          fill: $productMaturityLight;
        }
      }
      &:active {
        color: $productMaturityDark;
        svg * {
          fill: $productMaturityDark;
        }
      }
    }
    :global(.platform-mobile) & {
      &:active {
        color: $productMaturityLight;
        svg * {
          fill: $productMaturityLight;
        }
      }
    }
  }
  /* -------------------------------------------------------------- */
  /* Product Resilia */
  /* -------------------------------------------------------------- */
  &.ProductResilia {
    color: $productResilia;
    svg * {
      fill: $productResilia;
    }
    &:focus {
      color: $productResiliaDark;
    }
    :global(.platform-desktop) & {
      &:hover {
        color: $productResiliaLight;
      }
      &:active {
        color: $productResiliaDark;
      }
    }
    :global(.platform-mobile) & {
      &:active {
        color: $productResiliaLight;
      }
    }
  }
  /* -------------------------------------------------------------- */
  /* Disabled */
  /* -------------------------------------------------------------- */
  &.disabled {
    color: $mono2;
    svg * {
      fill: $mono2;
    }
  }
}
