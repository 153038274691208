@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
p.publicationDetails {
  color: rgba($color: $mono5, $alpha: 0.7);
  font-size: 15px;
  line-height: 32px;
  margin: 0;
  .readTime {
    color: $brandBerry;
    margin-left: 16px;
    margin-right: 20px;
  }
}
