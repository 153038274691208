@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
.topPanel {
  display: block;
  margin-bottom: 48px;
  position: relative;
  @media (max-width: 1536px) {
    margin-bottom: 40px;
  }
  @media (max-width: 1280px) {
    margin-bottom: 32px;
  }
  @media (max-width: 375px) {
    margin-bottom: 24px;
  }
  .publication {
    display: flex;
    justify-content: center;
    margin-bottom: 28px;
    @media (max-width: 1536px) {
      margin-bottom: 24px;
    }
    @media (max-width: 1280px) {
      margin-bottom: 20px;
    }
    p,
    time {
      color: rgba($color: $brandBlackGrape, $alpha: 0.7);
      font-size: 17px;
      line-height: 24px;
      margin: -6px 0;
      @media (max-width: 1536px) {
        font-size: 16px;
        line-height: 23px;
        margin: -5px 0 -6px;
      }
      @media (max-width: 1280px) {
        font-size: 15px;
        line-height: 22px;
      }
    }
    p:last-child {
      color: $brandBerry;
      margin-left: 20px;
    }
  }
  .tags {
    align-items: flex-start;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 auto 20px auto;
    max-width: 1028px;
    > li {
      background-color: $white;
      border: 1px solid $productProPath;
      border-radius: 22px;
      display: inline-block;
      font-size: 14px;
      margin: 10px;
      padding: 10px 30px;
      @media (max-width: 1280px) {
        margin: 8px;
        padding: 8px 24px;
      }
      @media (max-width: 375px) {
        font-size: 13px;
        margin: 4px;
        padding: 4px 16px;
      }
    }
  }
  .social {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 52px 0;
    @media (max-width: 1280px) {
      margin: 44px 0;
    }
    @media (max-width: 767px) {
      margin: 36px 0;
    }
    .roundel {
      align-items: center;
      background-color: rgba($color: $brandBlackGrape, $alpha: 0.1);
      border-radius: 99em;
      cursor: pointer;
      display: flex;
      height: 48px;
      justify-content: center;
      width: 48px;
      @media (max-width: 375px) {
        height: 40px;
        width: 40px;
        svg {
          height: 14px;
          width: auto;
        }
      }
    }
    .likes {
      align-items: center;
      display: flex;
      margin-right: 24px;
      @media (max-width: 375px) {
        margin-right: 20px;
      }
      span {
        color: rgba($color: $brandBlackGrape, $alpha: 0.7);
        font-size: 15px;
        font-weight: $semibold;
        margin-left: 12px;
        @media (max-width: 375px) {
          font-size: 14px;
          margin-left: 8px;
        }
      }
    }
  }
  h1 {
    font-family: $secondary;
    font-size: 40px;
    font-weight: $medium;
    line-height: 48px;
    margin-bottom: 36px;
    text-align: center;
    @media (max-width: 1536px) {
      font-size: 36px;
      line-height: 44px;
      margin-bottom: 32px;
    }
    @media (max-width: 1280px) {
      font-size: 32px;
      line-height: 40px;
      margin-bottom: 28px;
    }
    @media (max-width: 375px) {
      font-size: 28px;
      line-height: 36px;
      margin-bottom: 24px;
    }
    span {
      display: block;
      margin: -7px 0 -9px;
      @media (max-width: 1536px) {
        margin: -7px 0 -8px;
      }
      @media (max-width: 1280px) {
        margin: -6px 0 -7px;
      }
      @media (max-width: 375px) {
        margin: -5px 0 -6px;
      }
    }
  }
  .author {
    display: flex;
    justify-content: center;
    position: relative;
    @media (max-width: 540px) {
      flex-wrap: wrap;
      justify-content: flex-start;
    }
    .authorContainer {
      display: flex;
      max-width: 792px;
      width: 100%;
      @media (max-width: 1024px) {
        max-width: 592px;
      }
      @media (max-width: 767px) {
        max-width: 100%;
      }
      @media (max-width: 540px) {
        align-items: center;
      }
      &.siblingVideo {
        @media (max-width: 1024px) {
          max-width: 792px;
        }
      }
      .avatar {
        background-color: rgba($color: $brandBlackGrape, $alpha: 0.1);
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        border-radius: 50%;
        height: 94px;
        margin-right: 10px;
        min-width: 94px;
        @media (max-width: 1024px) {
          height: 74px;
          min-width: 74px;
        }
        @media (max-width: 540px) {
          height: 36px;
          min-width: 36px;
        }
      }
      .authorText {
        @media (max-width: 540px) {
          width: 100%;
        }
        p {
          @media (max-width: 540px) {
            margin-top: 0;
          }
        }
      }
    }
    p {
      color: $brandBlackGrape;
      font-size: 17px;
      line-height: 25px;
      margin-bottom: 29px;
      @media (max-width: 1536px) {
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 28px;
      }
      @media (max-width: 1280px) {
        font-size: 15px;
        line-height: 23px;
        margin-bottom: 27px;
      }
      @media (max-width: 375px) {
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 24px;
        width: 100%;
      }
      span {
        font-weight: $bold;
        margin-left: 10px;
      }
      &:last-child {
        margin-bottom: 0;
        margin-top: 18px;
      }
    }
  }
}
