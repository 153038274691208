@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
.faq {
  padding-bottom: 200px;
  padding-top: 64px;
  width: 100%;
  @media (max-width: 1536px) {
    padding-bottom: 172px;
  }
  @media (max-width: 1408px) {
    padding-bottom: 140px;
  }
  @media (max-width: 1280px) {
    padding-bottom: 116px;
  }
  @media (max-width: 1024px) {
    padding-bottom: 96px;
  }
  @media (max-width: 767px) {
    padding-bottom: 72px;
    padding-top: 42px;
  }
  /* ---------------------------------------------------------------- */
  /* Container */
  /* ---------------------------------------------------------------- */
  > div {
    flex-direction: column;
  }
  /* ---------------------------------------------------------------- */
  /* Title */
  /* ---------------------------------------------------------------- */
  > div > h2 {
    color: $brandBlackGrape;
    font-size: 32px;
    font-weight: $semibold;
    line-height: 42px;
    margin: -10px 0 -9px;
    @media (max-width: 1536px) {
      font-size: 28px;
      line-height: 37px;
      margin: -9px 0 -8px;
    }
    @media (max-width: 1280px) {
      font-size: 24px;
      line-height: 32px;
      margin: -8px 0 -7px;
    }
    @media (max-width: 375px) {
      font-size: 22px;
      line-height: 30px;
      margin-bottom: -6px;
    }
  }
  .form {
    padding-top: 32px;
    &.has-accordion {
      padding-top: 56px;
      @media (max-width: 1280px) {
        padding-top: 40px;
      }
      @media (max-width: 767px) {
        padding-top: 32px;
      }
    }
    h2 {
      color: $brandBlackGrape;
      font-family: $secondary;
      font-size: 32px;
      line-height: 39px;
      padding-bottom: 32px;
      @media (max-width: 1664px) {
        font-size: 30px;
        line-height: 38px;
        padding-bottom: 36px;
      }
      @media (max-width: 1536px) {
        font-size: 28px;
        line-height: 36px;
      }
      @media (max-width: 1408px) {
        font-size: 26px;
        line-height: 34px;
        padding-bottom: 28px;
      }
      @media (max-width: 1280px) {
        padding-bottom: 24px;
      }
      @media (max-width: 767px) {
        font-size: 24px;
        line-height: 32px;
      }
      @media (max-width: 640px) {
        font-size: 22px;
        line-height: 29px;
        padding-bottom: 20px;
      }
      @media (max-width: 576px) {
        font-size: 21px;
        line-height: 28px;
      }
      @media (max-width: 448px) {
        font-size: 21px;
        line-height: 28px;
      }
      @media (max-width: 375px) {
        font-size: 20px;
        line-height: 26px;
        padding-bottom: 16px;
      }
    }
    /* ---------------------------------------------------------------- */
    /* Form */
    /* ---------------------------------------------------------------- */
    form {
      /* ---------------------------------------------------------------- */
      /* Question Row */
      /* ---------------------------------------------------------------- */
      .form-group {
        display: flex;
        margin: 0 -12px;
        @media (max-width: 1024px) {
          flex-direction: column;
          margin: 0;
        }
        /* ---------------------------------------------------------------- */
        /* Question */
        /* ---------------------------------------------------------------- */
        > div {
          padding: 0 12px;
          position: relative;
          width: 100%;
          @media (max-width: 1024px) {
            padding: 0;
          }
        }
        &.form-row-span {
          .group-span-two > div:last-child {
            margin-bottom: 0;
            @media (max-width: 1024px) {
              margin-bottom: 16px;
            }
          }
          > div:last-child {
            margin-bottom: 0;
            > div {
              textarea {
                height: 304px;
                line-height: 32px;
                @media (max-width: 1536px) {
                  height: 272px;
                }
                @media (max-width: 1408px) {
                  height: 264px;
                  line-height: 28px;
                }
                @media (max-width: 1280px) {
                  height: 232px;
                }
              }
            }
          }
        }
      }
      .recaptcha-pol-text {
        font-size: 14px;
        margin-top: 24px;
      }
      /* ---------------------------------------------------------------- */
      /* Button
      /* ---------------------------------------------------------------- */
      > div:last-child {
        @media (max-width: 1024px) {
          flex-direction: row;
        }
        /* ---------------------------------------------------------------- */
        /* Button */
        /* ---------------------------------------------------------------- */
        button {
          @media (max-width: 1024px) {
            align-self: flex-start;
          }
        }
      }
    }
  }
}
