@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Event Date Time */
/* ---------------------------------------------------------------- */
.eventDateTime {
  display: flex;
  flex-wrap: wrap;
  /* ---------------------------------------------------------------- */
  /* Title */
  /* ---------------------------------------------------------------- */
  h4 {
    color: rgba($brandBlackGrape, 0.7);
    flex-basis: 100%;
    font-size: 17px;
    font-weight: $bold;
    line-height: 1;
    margin: -2px 0 12px;
    text-transform: uppercase;
    @media (max-width: 1536px) {
      font-size: 16px;
      margin-bottom: 9px;
    }
    @media (max-width: 1280px) {
      font-size: 15px;
      margin-bottom: 7px;
    }
    @media (max-width: 1024px) {
      font-size: 14px;
    }
    @media (max-width: 896px) and (min-width: 768px), (max-width: 576px) {
      font-size: 15px;
      margin-bottom: 9px;
    }
    @media (max-width: 375px) {
      margin-bottom: 5px;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Date Text + Time Container */
  /* ---------------------------------------------------------------- */
  > span {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -8px -5px;
    @media (max-width: 1536px) {
      margin: 0 -7px -5px;
    }
    @media (max-width: 1280px) {
      margin: 0 -6px -5px;
    }
    @media (max-width: 1024px) {
      margin: 0 -5px -5px;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Date Text + Time */
  /* ---------------------------------------------------------------- */
  time,
  span > span {
    color: $brandBlackGrape;
    font-size: 17px;
    line-height: 23px;
    margin: 0 8px;
    @media (max-width: 1536px) {
      font-size: 16px;
      line-height: 22px;
      margin: 0 7px;
    }
    @media (max-width: 1280px) {
      font-size: 15px;
      line-height: 21px;
      margin: 0 6px;
    }
    @media (max-width: 1024px) {
      font-size: 14px;
      line-height: 20px;
      margin: 0 5px;
    }
    @media (max-width: 896px) and (min-width: 768px), (max-width: 576px) {
      font-size: 16px;
      line-height: 22px;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Date Text  */
  /* ---------------------------------------------------------------- */
  time {
    font-weight: $semibold;
  }
}
