@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Questions */
/* ---------------------------------------------------------------- */
.questions {
  display: block;
  /* ---------------------------------------------------------------- */
  /* Submit Container */
  /* ---------------------------------------------------------------- */
  > div:last-child {
    display: flex;
    justify-content: flex-end;
    margin-top: 48px;
    @media (max-width: 1280px) {
      margin-top: 40px;
    }
    @media (max-width: 767px) {
      margin-top: 32px;
    }
    @media (max-width: 375px) {
      margin-top: 24px;
    }
  }
}
