@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
.documents-download {
  border: 1px solid $mono2;
  margin: 56px 0;
  position: relative;
  @media (max-width: 1536px) {
    margin: 48px 0;
  }
  @media (max-width: 1280px) {
    margin: 40px 0;
  }
  @media (max-width: 1024px) {
    margin: 32px 0;
  }
  @media (max-width: 375px) {
    margin: 24px 0;
  }
  > div {
    background-color: $pageBackground;
    border-bottom: 1px solid $mono2;
    padding: 26px 100px 26px 38px;
    position: relative;
    @media (max-width: 767px) {
      padding: 20px 65px 20px 20px;
    }
    h3 {
      font-family: $secondary;
      font-size: 24px;
      font-weight: $semibold;
      line-height: 32px;
      @media (max-width: 1536px) {
        font-size: 22px;
        line-height: 30px;
      }
      @media (max-width: 1280px) {
        font-size: 20px;
        line-height: 28px;
      }
      @media (max-width: 375px) {
        font-size: 18px;
        line-height: 26px;
      }
    }
    .download-icon {
      align-items: center;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      bottom: 0;
      display: flex;
      height: auto;
      justify-content: flex-end;
      padding-right: 34px;
      position: absolute;
      right: 0;
      top: 0;
      width: 122px;
      @media (max-width: 767px) {
        background-image: none !important;
        padding-right: 16px;
      }
      @media (max-width: 420px) {
        display: none;
      }
      /* ---------------------------------------------------------------- */
      /* Download Icon */
      /* ---------------------------------------------------------------- */
      .downloadIcon {
        height: 32px;
        width: 33px;
        z-index: 1;
        > polygon:last-child {
          animation-duration: 1s;
          animation-iteration-count: infinite;
          animation-timing-function: ease-in-out;
        }
        &.hover {
          polygon:last-child {
            animation-name: arrow-anim;
          }
        }
      }
    }
  }
  > ul {
    position: relative;
    li {
      background-color: $brandGreyLight;
      border-bottom: 1px solid $mono2;
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      padding: 24px 38px;
      @media (max-width: 767px) {
        padding: 15px 20px;
      }
      &:nth-child(even) {
        background-color: rgba($color: $brandGreyLight, $alpha: 0.5);
      }
      &:last-child {
        border-bottom: 0;
      }
      div {
        align-items: center;
        display: flex;
        svg {
          flex-shrink: 0;
          height: 24px;
          width: 24px;
        }
        > span {
          color: $brandBlackGrape;
          font-size: 17px;
          font-weight: $semibold;
          margin-left: 22px;
          word-break: break-word;
          @media (max-width: 1024px) {
            font-size: 15px;
          }
        }
      }
      .download-sml-icon {
        flex-shrink: 0;
        margin-left: 20px;
      }
      &:hover {
        div > span {
          color: $brandBerry;
        }
      }
    }
  }
}
/* prettier-ignore */
@keyframes arrow-anim {
  0% { transform: translateY(0); }
  50% { transform: translateY(100%); }
  50.0000001% { transform: translateY(-100%); }
  100% { transform: translateY(0); }
}
