@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Purchase Summary */
/* ---------------------------------------------------------------- */
.purchaseSummary {
  border: 1px solid $grey;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  padding: 40px 36px;
  position: relative;
  @media (max-width: 1664px) {
    padding: 36px 32px;
  }
  @media (max-width: 1536px) {
    padding: 42px 28px;
  }
  @media (max-width: 1408px) {
    padding: 42px 24px;
  }
  @media (max-width: 1280px) {
    padding: 34px 20px;
  }
  @media (max-width: 1152px) {
    border: none;
    padding: 0;
  }
  @media (max-width: 767px) {
    margin-top: 32px;
    padding: 0;
  }
  @media (max-width: 375px) {
    margin-top: 28px;
  }
  /* ---------------------------------------------------------------- */
  /* Sidebar Title */
  /* ---------------------------------------------------------------- */
  h3 {
    font-size: 32px;
    line-height: 24px;
    @media (max-width: 1536px) {
      font-size: 28px;
      line-height: 21px;
    }
    @media (max-width: 1280px) {
      font-size: 24px;
      line-height: 18px;
    }
    @media (max-width: 1024px) {
      font-size: 20px;
      line-height: 15px;
    }
    @media (max-width: 767px) {
      margin-bottom: 29px;
    }
    &::after {
      background: $grey;
      content: '';
      display: block;
      height: 1px;
      left: -36px;
      margin: 36px 0 40px;
      position: relative;
      width: calc(100% + 72px);
      @media (max-width: 1664px) {
        left: -32px;
        margin: 32px 0 36px;
        width: calc(100% + 64px);
      }
      @media (max-width: 1536px) {
        left: -28px;
        width: calc(100% + 56px);
      }
      @media (max-width: 1408px) {
        left: -24px;
        margin: 28px 0 32px;
        width: calc(100% + 48px);
      }
      @media (max-width: 1280px) {
        left: -20px;
        margin: 24px 0 28px;
        width: calc(100% + 40px);
      }
      @media (max-width: 1152px) {
        left: 0;
        width: 100%;
      }
      @media (max-width: 1024px) {
        margin: 19px 0;
      }
      @media (max-width: 767px) {
        content: none;
      }
    }
    &::before {
      @media (max-width: 767px) {
        background: $grey;
        content: '';
        display: block;
        height: 1px;
        left: 0;
        margin: 0 0 32px;
        position: relative;
        width: 100%;
      }
      @media (max-width: 375px) {
        margin: 0 0 28px;
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Product Name */
  /* ---------------------------------------------------------------- */
  p:first-of-type {
    font-size: 20px;
    font-weight: $bold;
    line-height: 27px;
    margin: -6px 0 22px;
    @media (max-width: 1536px) {
      font-size: 19px;
      line-height: 26px;
      margin-bottom: 18px;
    }
    @media (max-width: 1280px) {
      font-size: 18px;
      line-height: 24px;
      margin-bottom: 14px;
    }
    @media (max-width: 1024px) {
      font-size: 16px;
      line-height: 22px;
      margin-bottom: 12px;
    }
    @media (max-width: 767px) {
      margin-bottom: 18px;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Purchase Features */
  /* ---------------------------------------------------------------- */
  ul {
    &::after {
      background-color: $grey;
      content: '';
      display: block;
      height: 1px;
      margin: 32px 0 18px;
      position: relative;
      width: 100%;
      @media (max-width: 1536px) {
        margin: 28px 0 14px;
      }
      @media (max-width: 1280px) {
        margin: 24px 0 13px;
      }
      @media (max-width: 1024px) {
        margin: 20px 0 12px;
      }
      @media (max-width: 767px) {
        margin: 20px 0 14px;
      }
    }
    li {
      padding-left: 10px;
      position: relative;
      &:first-child {
        margin-top: -6px;
        @media (max-width: 1280px) {
          margin-top: -5px;
        }
      }
      &:last-child {
        margin-bottom: -6px;
        @media (max-width: 1280px) {
          margin-bottom: -5px;
        }
      }
      &::before {
        background-color: $brandBlackGrape;
        border-radius: 50%;
        content: '';
        height: 4px;
        left: 0;
        position: absolute;
        top: 11px;
        width: 4px;
        @media (max-width: 1280px) {
          top: 10px;
        }
        @media (max-width: 1024px) {
          top: 8px;
        }
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Price */
  /* ---------------------------------------------------------------- */
  .credit {
    font-style: italic;
  }
  .discount {
    color: transparent;
    font-style: italic;
  }
  .total {
    color: $brandBerry;
    font-size: 18px;
    font-weight: $bold;
    line-height: 26px;
    margin: 23px 0 -6px;
    @media (max-width: 1536px) {
      font-size: 17px;
      line-height: 25px;
      margin-top: 19px;
    }
    @media (max-width: 1280px) {
      font-size: 16px;
      line-height: 24px;
      margin-top: 17px;
    }
    @media (max-width: 1024px) {
      font-size: 15px;
      line-height: 22px;
      margin-top: 13px;
    }
    @media (max-width: 767px) {
      font-size: 16px;
      line-height: 24px;
      margin-top: 17px;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Price & Features */
  /* ---------------------------------------------------------------- */
  ul li,
  p {
    font-size: 17px;
    line-height: 25px;
    @media (max-width: 1536px) {
      font-size: 16px;
      line-height: 24px;
    }
    @media (max-width: 1280px) {
      font-size: 15px;
      line-height: 22px;
    }
    @media (max-width: 1024px) {
      font-size: 14px;
      line-height: 20px;
    }
    @media (max-width: 767px) {
      font-size: 15px;
      line-height: 22px;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Has Discount */
  /* ---------------------------------------------------------------- */
  &.hasDiscount {
    .discount {
      color: $brandBlackGrape;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Help Icon */
  /* ---------------------------------------------------------------- */
  > div {
    align-items: center;
    column-gap: 10px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    position: relative;
    > p {
      font-size: inherit !important;
      font-weight: inherit !important;
      margin: 0 !important;
    }
    > svg {
      cursor: pointer;
      height: 20px;
      width: 20px;
      z-index: 10;
      @media (max-width: 1280px) {
        height: 19px;
        width: 19px;
      }
      @media (max-width: 1024px) {
        height: 18px;
        width: 18px;
      }
      ~ .field:last-child > input {
        padding-right: 80px;
        @media (max-width: 1536px) {
          padding-right: 72px;
        }
        @media (max-width: 1280px) {
          padding-right: 63px;
        }
        @media (max-width: 1024px) {
          padding-right: 54px;
        }
      }
      &:hover + [role='tooltip'] {
        opacity: 1;
        position: absolute;
        top: -13px;
        transform: translateY(-100%) scale(1);
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Help Tooltip */
  /* ---------------------------------------------------------------- */
  [role='tooltip'] {
    background-color: $white;
    border: 1px solid $mono2;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.16) 0 3px 6px;
    box-sizing: border-box;
    font-size: 17px;
    height: auto;
    left: 48px;
    line-height: 25px;
    opacity: 0;
    padding: 11px 16px;
    position: absolute;
    top: 21px;
    transform: translateY(-100%) scale(0);
    transform-origin: calc(100% - 68px) calc(100% + 10px);
    transition-duration: 0.2s;
    transition-property: transform, opacity;
    transition-timing-function: ease-in-out;
    width: 208px;
    z-index: 9;
    @media (max-width: 1536px) {
      font-size: 16px;
      left: 54px;
      line-height: 24px;
      top: 13px;
      width: 200px;
    }
    @media (max-width: 1408px) {
      left: 47px;
      transform-origin: calc(100% - 61px) calc(100% + 10px);
    }
    @media (max-width: 1280px) {
      font-size: 15px;
      left: 45px;
      line-height: 23px;
      top: 9px;
      transform-origin: calc(100% - 48px) calc(100% + 10px);
      width: 188px;
    }
    @media (max-width: 1024px) {
      left: 35px;
      top: 7px;
      transform-origin: calc(100% - 39px) calc(100% + 10px);
    }
    @media (max-width: 375px) {
      font-size: 14px;
      left: 43px;
      line-height: 22px;
      transform-origin: calc(100% - 35px) calc(100% + 10px);
      width: 178px;
    }
    &::after {
      background-image: url(/images/icons/help-chevron.svg);
      background-repeat: no-repeat;
      background-size: cover;
      bottom: -23px;
      content: '';
      display: block;
      height: 30px;
      position: absolute;
      right: 54px;
      width: 30px;
      @media (max-width: 1408px) {
        right: 47px;
      }
      @media (max-width: 1280px) {
        right: 34px;
      }
      @media (max-width: 1024px) {
        right: 25px;
      }
      @media (max-width: 375px) {
        right: 21px;
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Show Help Tooltip - Mobile */
  /* ---------------------------------------------------------------- */
  &.showHelp {
    [role='tooltip'] {
      opacity: 1;
      transform: translateY(-100%) scale(1);
    }
  }
}
