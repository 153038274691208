@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* -------------------------------------------------------------- */
/* Summary Subscription Skeleton */
/* -------------------------------------------------------------- */
.skeleton {
  display: block;
  margin: -4px 0 0 0;
  overflow: hidden;
  position: relative;
  @media (max-width: 640px) {
    margin-top: 17px;
    text-align: left;
  }
  @media (max-width: 375px) {
    margin-top: 13px;
  }
  &::after {
    @include skeleton-shimmer;
    @include skeleton-shimmer-color($color: $white);
  }
  > p {
    font-size: 17px;
    line-height: 21px;
    margin: 0 0 0 auto;
    text-align: right;
    white-space: nowrap;
    @media (max-width: 1536px) {
      font-size: 16px;
      line-height: 20px;
    }
    @media (max-width: 1280px) {
      font-size: 15px;
      line-height: 19px;
    }
    @media (max-width: 640px) {
      margin-left: 0;
    }
    &::after {
      background-color: $mono1;
      color: transparent;
      content: 'Subscription renews';
      font-size: inherit;
      line-height: inherit;
      @include rounded-skeleton-text;
    }
  }
  > span {
    display: block;
    font-size: 18px;
    font-weight: $semibold;
    letter-spacing: normal;
    line-height: 22px;
    margin: 7px 0 0;
    @media (max-width: 1536px) {
      font-size: 17px;
      line-height: 21px;
      margin: 6px 0 0;
    }
    @media (max-width: 1280px) {
      font-size: 16px;
      line-height: 20px;
      margin: 5px 0 0;
    }
    @media (max-width: 640px) {
      margin: 4px 0 0;
    }
    &::after {
      background-color: $mono1;
      color: transparent;
      content: '27th April 2022';
      font-size: inherit;
      line-height: inherit;
      @include rounded-skeleton-text;
    }
  }
}

@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}
