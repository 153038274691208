@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Password Form */
/* ---------------------------------------------------------------- */
.passwordForm {
  /* ---------------------------------------------------------------- */
  /* Recaptcha */
  /* ---------------------------------------------------------------- */
  small {
    margin-left: 0;
    margin-top: 32px;
    max-width: 100%;
    @media (max-width: 1536px) {
      margin-top: 28px;
    }
    @media (max-width: 1408px) {
      margin-top: 24px;
    }
    @media (max-width: 1280px) {
      margin-top: 20px;
    }
    @media (max-width: 1024px) {
      margin-top: 16px;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Text */
  /* ---------------------------------------------------------------- */
  fieldset > p {
    font-size: 17px;
    line-height: 25px;
    margin: -6px 0;
    @media (max-width: 1536px) {
      font-size: 16px;
      line-height: 24px;
    }
    @media (max-width: 1280px) {
      font-size: 15px;
      line-height: 23px;
    }
  }
}
/* ---------------------------------------------------------------- */
/* Spinner */
/* ---------------------------------------------------------------- */
.spinner {
  background-color: $brandBlackGrape;
  background-image: url('/images/icons/button-loading.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: 56px 56px;
  border-radius: 50%;
  display: block;
  flex-shrink: 0;
  height: 64px;
  margin-right: 12px;
  top: 0;
  width: 64px;
  @media (max-width: 1536px) {
    background-size: 48px 48px;
    height: 56px;
    margin-right: 10px;
    width: 56px;
  }
  @media (max-width: 1280px) {
    background-size: 40px 40px;
    height: 48px;
    margin-right: 8px;
    width: 48px;
  }
  @media (max-width: 520px) {
    margin-right: 12px;
  }
  ~ button {
    @media (max-width: 520px) {
      max-width: calc(50% - 36px) !important;
    }
  }
}
