@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* stylelint-disable max-line-length */
/* ---------------------------------------------------------------- */
/* Content Type List Skeleton */
/* ---------------------------------------------------------------- */
.skeleton {
  overflow: hidden;
  position: relative;
  &:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
  }
  &::after {
    @include skeleton-shimmer;
    @include skeleton-shimmer-color($color: $pageBackground);
  }
  /* ---------------------------------------------------------------- */
  /* Publication */
  /* ---------------------------------------------------------------- */
  .publication {
    p:first-child::after {
      background-color: $mono1;
      color: transparent;
      content: 'September 14, 2021';
      font-size: inherit;
      line-height: inherit;
      @include rounded-skeleton-text;
    }
    p:last-child::after {
      background-color: $mono1;
      color: transparent;
      content: '8 min read';
      font-size: inherit;
      line-height: inherit;
      @include rounded-skeleton-text;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Publication */
  /* ---------------------------------------------------------------- */
  .tags > li {
    background-color: $mono1;
    border: 1px solid $mono1;
    &::after {
      background-color: $mono1;
      color: transparent;
      content: 'P3M3';
      font-size: inherit;
      line-height: inherit;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Title */
  /* ---------------------------------------------------------------- */
  .title {
    font-family: $secondary;
    font-size: 40px;
    font-weight: $medium;
    line-height: 48px;
    margin-bottom: 36px;
    text-align: center;
    @media (max-width: 1536px) {
      font-size: 36px;
      line-height: 44px;
      margin-bottom: 32px;
    }
    @media (max-width: 1280px) {
      font-size: 32px;
      line-height: 40px;
      margin-bottom: 28px;
    }
    @media (max-width: 375px) {
      font-size: 28px;
      line-height: 36px;
      margin-bottom: 24px;
    }
    span {
      display: block;
      margin: -7px 0 -9px;
      @media (max-width: 1536px) {
        margin: -7px 0 -8px;
      }
      @media (max-width: 1280px) {
        margin: -6px 0 -7px;
      }
      @media (max-width: 375px) {
        margin: -5px 0 -6px;
      }
      &::after {
        background-color: $mono1;
        color: transparent;
        content: 'Change management through ITIL Practitioner';
        font-size: inherit;
        line-height: inherit;
        @include rounded-skeleton-text;
      }
    }
  }
  .likesText {
    &::after {
      background-color: $mono1;
      color: transparent;
      content: '4 Likes';
      font-size: inherit;
      line-height: inherit;
      @include rounded-skeleton-text;
    }
  }
  .headerImage {
    background-color: $mono1;
    border-radius: 10px;
    height: 445px;
    margin: 0 auto 42px auto;
    position: relative;
    width: 792px;
    @media (max-width: 1024px) {
      height: 345px;
      width: 592px;
    }
    @media (max-width: 767px) {
      height: 345px;
      width: 100%;
    }
    @media (max-width: 540px) {
      height: 184px;
    }
  }
  .introParagraph {
    font-size: 17px;
    line-height: 25px;
    margin-bottom: 24px;
    @media (max-width: 767px) {
      font-size: 15px;
      line-height: 23px;
    }
    &::after {
      background-color: $mono1;
      color: transparent;
      content: 'Lorem ipsum dolor sit amet, eum enim percipit complectitur ut. Eum an tollit maiorum qualisque. Equidem legimus at nec. Wisi virtute oporteat ne eos. Ea sea case vocent erroribus. Pri modus falli aliquando no, eu cum assum eruditi, quo ut mucius moderatius. Lorem ipsum dolor sit amet, eum enim percipit complectitur ut. Eum an tollit maiorum qualisque. Equidem legimus at nec. Wisi virtute oporteat ne eos. Ea sea case vocent erroribus. Pri modus falli aliquando no, eu cum assum eruditi, quo ut mucius moderatius.';
      font-size: inherit;
      line-height: inherit;
      @include rounded-skeleton-text;
    }
    &:nth-child(2)::after {
      content: 'His at sale ridens volumus, legere forensibus usu eu, impetus nusquam definitionem eos ex. Vix ut persius bonorum. In ceteros constituam quo. Paulo reformidans sit ad, est in quando copiosae. Ut pri sadipscing accommodare, an eligendi perpetua has, sit ad dicta accumsan. Mundi graeco molestie ex sed, eu mea eruditi delectus placerat, an homero facilis vim.';
    }
    &:nth-child(3)::after {
      content: 'Lorem ipsum dolor sit amet, eum enim percipit complectitur ut. Eum an tollit maiorum qualisque. Equidem legimus at nec. Wisi virtute oporteat ne eos. Ea sea case vocent erroribus. Pri modus falli aliquando no, eu cum assum eruditi, quo ut mucius moderatius. Lorem ipsum dolor sit amet, eum enim percipit complectitur ut. Eum an tollit maiorum qualisque. Equidem legimus at nec. Wisi virtute oporteat ne eos. Ea sea case vocent erroribus. Pri modus falli aliquando no, eu cum assum eruditi, quo ut mucius moderatius. Lorem ipsum dolor sit amet, eum enim percipit complectitur ut. Eum an tollit maiorum qualisque. Equidem legimus at nec. Wisi virtute oporteat ne eos. Ea sea case vocent erroribus. Pri modus falli aliquando no, eu cum assum eruditi, quo ut mucius moderatius. Lorem ipsum dolor sit amet, eum enim percipit complectitur ut. Eum an tollit maiorum qualisque. Equidem legimus at nec. Wisi virtute oporteat ne eos. Ea sea case vocent erroribus. Pri modus falli aliquando no, eu cum assum eruditi, quo ut mucius moderatius.';
    }
    &:last-child {
      margin-bottom: 0;
      &::after {
        content: 'His at sale ridens volumus, legere forensibus usu eu, impetus nusquam definitionem eos ex. Vix ut persius bonorum. In ceteros constituam quo. Paulo reformidans sit ad, est in quando copiosae. Ut pri sadipscing accommodare, an eligendi perpetua has, sit ad dicta accumsan. Mundi graeco molestie ex sed, eu mea eruditi delectus placerat, an homero facilis vim.';
      }
    }
  }
  > div:last-of-type {
    margin-bottom: 48px;
    @media (max-width: 767px) {
      margin-bottom: 32px;
    }
    @media (max-width: 375px) {
      margin-bottom: 24px;
    }
  }
}

@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}
