@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* -------------------------------------------------------------- */
/* Badges Skeleton */
/* -------------------------------------------------------------- */
.skeleton {
  border-color: $mono1;
  border-radius: 4px;
  overflow: hidden;
  position: relative;
  @media (max-width: 1152px) and (min-width: 768px), (max-width: 360px) {
    margin-left: -32px;
    margin-right: -32px;
    margin-top: -32px !important;
    padding-left: 32px;
    padding-right: 32px;
    padding-top: 32px;
  }
  @media (max-width: 1024px) and (min-width: 768px), (max-width: 360px) {
    margin-left: -24px;
    margin-right: -24px;
    margin-top: -24px !important;
    padding-left: 24px;
    padding-right: 24px;
    padding-top: 24px;
  }
  @media (max-width: 360px) {
    margin-left: -16px;
    margin-right: -16px;
    padding-left: 16px;
    padding-right: 16px;
  }
  &::after {
    @include skeleton-shimmer;
    @include skeleton-shimmer-color($color: $white);
    @media (max-width: 360px) {
      @include skeleton-shimmer-color($color: $pageBackground);
    }
  }
  /* -------------------------------------------------------------- */
  /* Button */
  /* -------------------------------------------------------------- */
  > div:nth-child(1) {
    border-bottom: solid 1px $mono1;
    box-sizing: border-box;
    cursor: pointer;
    list-style: none;
    padding: 28px 32px;
    text-align: left;
    transition-duration: 0.1s;
    transition-property: border-color;
    width: 100%;
    @media (max-width: 1536px) {
      padding: 24px 28px;
    }
    @media (max-width: 1280px) {
      padding: 20px 24px;
    }
    @media (max-width: 1152px) and (min-width: 768px), (max-width: 360px) {
      border: none;
      padding: 0;
    }
    p::after {
      background-color: $mono1;
      color: transparent;
      font-size: inherit;
      line-height: inherit;
      @include rounded-skeleton-text;
    }
  }
  /* -------------------------------------------------------------- */
  /* Button - Title */
  /* -------------------------------------------------------------- */
  > div:nth-child(1) > p:first-child {
    align-items: center;
    color: rgba($brandBlackGrape, 50%);
    display: flex;
    font-family: $secondary;
    font-size: 18px;
    font-weight: $semibold;
    letter-spacing: 0.16px;
    line-height: 22px;
    margin-bottom: 13px;
    margin-top: -4px;
    text-transform: uppercase;
    @media (max-width: 1536px) {
      font-size: 17px;
      line-height: 21px;
      margin-bottom: 10px;
    }
    @media (max-width: 1280px) {
      font-size: 16px;
      line-height: 20px;
      margin-bottom: 6px;
    }
    @media (max-width: 1024px) {
      font-size: 15px;
      line-height: 19px;
      margin-bottom: 7px;
    }
    @media (max-width: 767px) {
      font-size: 16px;
      line-height: 20px;
      margin-bottom: 6px;
    }
    &::after {
      content: 'Your digital badges';
    }
  }
  /* -------------------------------------------------------------- */
  /* Button - Text */
  /* -------------------------------------------------------------- */
  > div:nth-child(1) > p:last-child {
    font-size: 17px;
    line-height: 25px;
    margin-bottom: -6px;
    @media (max-width: 1536px) {
      font-size: 16px;
      line-height: 24px;
    }
    @media (max-width: 1280px) {
      font-size: 15px;
      line-height: 23px;
    }
    @media (max-width: 1024px) {
      font-size: 14px;
      line-height: 21px;
      margin-bottom: -5px;
    }
    &::after {
      content: 'Let\2019s claim your first digital badge!';
    }
  }
  /* -------------------------------------------------------------- */
  /* Content */
  /* -------------------------------------------------------------- */
  > div:nth-child(2) {
    opacity: 1;
    padding: 0;
    transform: scaleY(1);
    transform-origin: 0 0;
    visibility: visible;
    will-change: height, opacity, transform;
    @include medium-transition(
      $firefox: false,
      $mob: true,
      $props: 'opacity, height, transform'
    );
  }
  /* -------------------------------------------------------------- */
  /* List */
  /* -------------------------------------------------------------- */
  ul {
    /* -------------------------------------------------------------- */
    /* Mobile Row 1 Border */
    /* -------------------------------------------------------------- */
    &::before {
      @media (max-width: 1152px) and (min-width: 768px), (max-width: 360px) {
        background-color: $mono1;
      }
      @media (max-width: 940px) and (min-width: 768px), (max-width: 360px) {
        width: 100%;
      }
    }
  }
  /* -------------------------------------------------------------- */
  /* Item */
  /* -------------------------------------------------------------- */
  li {
    &:last-child {
      @media (max-width: 1152px) and (min-width: 768px), (max-width: 360px) {
        border-bottom: 1px solid $mono1;
      }
    }
    /* -------------------------------------------------------------- */
    /* Item Number */
    /* -------------------------------------------------------------- */
    &::before {
      background-color: $mono1;
      color: transparent;
      flex-shrink: 0;
      opacity: 1 !important;
      @media (max-width: 940px) and (min-width: 768px), (max-width: 360px) {
        content: none;
      }
    }
    /* -------------------------------------------------------------- */
    /* Border */
    /* -------------------------------------------------------------- */
    &::after {
      background-color: $mono1;
      @media (max-width: 940px) and (min-width: 768px), (max-width: 360px) {
        width: 100%;
      }
    }
    /* -------------------------------------------------------------- */
    /* Text */
    /* -------------------------------------------------------------- */
    p {
      opacity: 1 !important;
      &::after {
        background-color: $mono1;
        color: transparent;
        font-size: inherit;
        line-height: inherit;
        @include rounded-skeleton-text;
      }
    }
  }
  li:nth-child(1) p::after {
    content: 'Achieved Certification';
  }
  li:nth-child(2) p::after {
    content: 'Achieve Certification';
  }
  li:nth-child(3) {
    div {
      height: 56px;
    }
    p::after {
      content: 'Claim badge';
    }
  }
}

@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}
