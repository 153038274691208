@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Events Skeleton */
/* ---------------------------------------------------------------- */
.skeleton {
  padding-bottom: 36px;
  padding-top: 36px;
  position: relative;
  @media (max-width: 1280px) {
    padding-bottom: 32px;
    padding-top: 32px;
  }
  @media (max-width: 1024px) {
    padding-bottom: 24px;
    padding-top: 24px;
  }
  @media (max-width: 767px) {
    padding-bottom: 32px;
    padding-top: 32px;
  }
  @media (max-width: 375px) {
    padding-bottom: 24px;
    padding-top: 24px;
  }
  &:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
    @media (max-width: 767px) {
      padding-bottom: 32px;
    }
    @media (max-width: 375px) {
      padding-bottom: 24px;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Background Colour */
  /* ---------------------------------------------------------------- */
  &::before {
    background-color: $brandAquaXLightPastel;
    bottom: 0;
    content: '';
    display: block;
    position: absolute;
    right: -72px;
    top: 0;
    width: 100vw;
    z-index: 0;
    @media (max-width: 1664px) {
      right: -64px;
    }
    @media (max-width: 1536px) {
      right: -56px;
    }
    @media (max-width: 1408px) {
      right: -48px;
    }
    @media (max-width: 1280px) {
      right: -40px;
    }
    @media (max-width: 1152px) {
      right: -32px;
    }
    @media (max-width: 1024px) {
      right: -24px;
    }
    @media (max-width: 375px) {
      left: -16px;
      right: -16px;
    }
  }
  &:last-child::before {
    bottom: -72px;
    @media (max-width: 1664px) {
      bottom: -64px;
    }
    @media (max-width: 1536px) {
      bottom: -56px;
    }
    @media (max-width: 1408px) {
      bottom: -48px;
    }
    @media (max-width: 1280px) {
      bottom: -40px;
    }
    @media (max-width: 1152px) {
      bottom: -32px;
    }
    @media (max-width: 1024px) {
      bottom: -24px;
    }
    @media (max-width: 767px) {
      bottom: 0;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Title */
  /* ---------------------------------------------------------------- */
  > span {
    display: block;
    font-family: $secondary;
    font-size: 32px;
    font-weight: $semibold;
    line-height: 40px;
    margin: -8px 0;
    padding-bottom: 36px;
    padding-right: 24px;
    position: relative;
    @media (max-width: 1664px) {
      font-size: 29px;
      line-height: 36px;
    }
    @media (max-width: 1536px) {
      font-size: 26px;
      line-height: 33px;
      margin: -6px 0 -7px;
      padding-right: 20px;
    }
    @media (max-width: 1408px) {
      font-size: 23px;
      line-height: 29px;
    }
    @media (max-width: 1280px) {
      font-size: 20px;
      line-height: 26px;
      margin: -6px 0;
      padding-bottom: 32px;
      padding-right: 16px;
    }
    @media (max-width: 1024px) {
      padding-bottom: 24px;
    }
    @media (max-width: 896px) {
      padding-right: 0;
    }
    @media (max-width: 767px) {
      padding-bottom: 32px;
    }
    @media (max-width: 375px) {
      font-size: 19px;
      line-height: 25px;
      margin-top: -5px;
      padding-bottom: 24px;
    }
    &::before {
      @include skeleton-shimmer;
      @include skeleton-shimmer-color($color: $brandAquaXLightPastel);
    }
    &::after {
      background-color: $mono2;
      color: transparent;
      content: 'Events';
      font-size: inherit;
      line-height: inherit;
      @include rounded-skeleton-text;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Events Card Container */
  /* ---------------------------------------------------------------- */
  > ul {
    /* ---------------------------------------------------------------- */
    /* Columns */
    /* ---------------------------------------------------------------- */
    > li {
      /* ---------------------------------------------------------------- */
      /* Large Card Container x 1 */
      /* ---------------------------------------------------------------- */
      &:first-child {
        margin-bottom: 48px;
        @media (max-width: 1664px) {
          margin-bottom: 44px;
        }
        @media (max-width: 1536px) {
          margin-bottom: 40px;
        }
        @media (max-width: 1408px) {
          margin-bottom: 36px;
        }
        @media (max-width: 1280px) {
          margin-bottom: 32px;
        }
        &:only-child {
          margin-bottom: 0;
        }
        .headingThree::after {
          content: 'Lorem ipsum dolor sit amet adipiscing elit sed do eiusmod ut labore.';
        }
        p::after {
          /* stylelint-disable-next-line max-line-length */
          content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna sit do aliqua sit do amet.';
        }
        .cta span {
          background-color: $mono2;
          height: 48px;
          width: 198px;
          @include rounded-skeleton-text;
        }
        .skeletonItem {
          position: relative;
          z-index: 0;
          &::after {
            @include skeleton-shimmer;
            @include skeleton-shimmer-color($color: $brandAquaXLightPastel);
          }
        }
      }
      /* ---------------------------------------------------------------- */
      /* Small Card Container x 3 */
      /* ---------------------------------------------------------------- */
      &:last-child {
        display: flex;
        @media (max-width: 896px) and (min-width: 768px), (max-width: 576px) {
          flex-direction: column;
        }
        .headingThree::after {
          content: 'Lorem ipsum dolor elit.';
        }
        p::after {
          /* stylelint-disable-next-line max-line-length */
          content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.';
        }
        .cta span {
          @include rounded-skeleton-text;
          @media (max-width: 896px) and (min-width: 768px), (max-width: 576px) {
            background-color: $mono2;
            height: 48px;
            margin: 0;
            width: 198px;
          }
          &::after {
            background-color: $mono2;
            color: transparent;
            content: 'Read more';
            font-size: inherit;
            line-height: inherit;
            @include rounded-skeleton-text;
            @media (max-width: 896px) and (min-width: 768px),
              (max-width: 576px) {
              content: none;
            }
          }
        }
        .skeletonItem {
          margin-right: 28px;
          position: relative;
          z-index: 1;
          @media (max-width: 1664px) {
            margin-right: 24px;
          }
          @media (max-width: 1536px) {
            margin-right: 20px;
          }
          @media (max-width: 1408px) {
            margin-right: 16px;
          }
          @media (max-width: 1280px) {
            margin-right: 12px;
          }
          @media (max-width: 896px) and (min-width: 768px), (max-width: 576px) {
            margin-right: 0;
          }
          &:only-child {
            .headingThree {
              font-weight: $bold;
            }
          }
          &:last-child {
            margin-right: 0;
          }
          &::after {
            background-image: linear-gradient(
              90deg,
              rgba($brandAquaXLightPastel, 0) 0,
              rgba($brandAquaXLightPastel, 0) 20%,
              rgba($brandAquaXLightPastel, 0) 60%,
              rgba($brandAquaXLightPastel, 0)
            );
            @include skeleton-shimmer;
          }
          @media (max-width: 896px) and (min-width: 768px), (max-width: 576px) {
            &::after {
              background-image: linear-gradient(
                90deg,
                rgba($brandAquaXLightPastel, 0) 0,
                rgba($brandAquaXLightPastel, 0.5) 20%,
                rgba($brandAquaXLightPastel, 0.8) 60%,
                rgba($brandAquaXLightPastel, 0)
              );
            }
          }
          > div {
            overflow: hidden;
            position: relative;
            z-index: 1;
            @media (max-width: 896px) and (min-width: 768px),
              (max-width: 576px) {
              z-index: 0;
            }
            &::after {
              background-image: linear-gradient(
                90deg,
                rgba(#dce6e8, 0) 0,
                rgba(#dce6e8, 0.5) 20%,
                rgba(#dce6e8, 0.8) 60%,
                rgba(#dce6e8, 0)
              );
              @include skeleton-shimmer;
              @media (max-width: 896px) and (min-width: 768px),
                (max-width: 576px) {
                background-image: linear-gradient(
                  90deg,
                  rgba(#dce6e8, 0) 0,
                  rgba(#dce6e8, 0) 20%,
                  rgba(#dce6e8, 0) 60%,
                  rgba(#dce6e8, 0)
                );
              }
            }
          }
          > span {
            overflow: hidden;
            position: relative;
            z-index: 1;
            &::after {
              background-image: linear-gradient(
                90deg,
                rgba($brandAquaXLightPastel, 0) 0,
                rgba($brandAquaXLightPastel, 0.5) 20%,
                rgba($brandAquaXLightPastel, 0.8) 60%,
                rgba($brandAquaXLightPastel, 0)
              );
              @include skeleton-shimmer;
              @media (max-width: 896px) and (min-width: 768px),
                (max-width: 576px) {
                background-image: linear-gradient(
                  90deg,
                  rgba($brandAquaXLightPastel, 0) 0,
                  rgba($brandAquaXLightPastel, 0) 20%,
                  rgba($brandAquaXLightPastel, 0) 60%,
                  rgba($brandAquaXLightPastel, 0)
                );
              }
            }
            @media (max-width: 896px) and (min-width: 768px),
              (max-width: 576px) {
              z-index: 0;
            }
          }
        }
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Mobile CTA Container */
  /* ---------------------------------------------------------------- */
  > div:last-child {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
    position: relative;
    @media (max-width: 896px) and (min-width: 768px), (max-width: 576px) {
      margin-top: 13px;
    }
  }
}
/* ---------------------------------------------------------------- */
/* Events Item Skeleton */
/* ---------------------------------------------------------------- */
.skeletonItem {
  background-color: #dce6e8; //rgba($mono2, 0.4);
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;
  position: relative;
  width: 100%;
  @media (max-width: 896px) and (min-width: 768px), (max-width: 576px) {
    background-color: transparent;
    box-shadow: none;
    flex-direction: column;
  }
  /* ---------------------------------------------------------------- */
  /* Image */
  /* ---------------------------------------------------------------- */
  > span {
    background-color: $mono2;
    display: block;
    flex-shrink: 0;
    height: 0;
    overflow: hidden;
    padding-bottom: 60%;
    position: relative;
    width: 100%;
    @media (max-width: 896px) and (min-width: 768px), (max-width: 576px) {
      min-height: 208px;
      padding-bottom: 0;
    }
    @media (max-width: 375px) {
      min-height: 198px;
    }
    /* ---------------------------------------------------------------- */
    /* 63 Deg Overlay */
    /* ---------------------------------------------------------------- */
    span {
      background-image: url('/images/artwork/post-slice.svg');
      background-repeat: no-repeat;
      background-size: cover;
      bottom: -1px;
      height: auto;
      position: absolute;
      right: -1px;
      top: -1px;
      width: 52.5%;
      z-index: 2;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Content */
  /* ---------------------------------------------------------------- */
  > div {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 20px;
    @media (max-width: 1536px) {
      padding: 18px;
    }
    @media (max-width: 1280px) {
      padding: 16px;
    }
    @media (max-width: 1024px) {
      padding: 12px;
    }
    @media (max-width: 896px) and (min-width: 768px), (max-width: 576px) {
      padding: 20px 0 32px;
    }
  }
  &:last-child > div {
    @media (max-width: 896px) and (min-width: 768px), (max-width: 576px) {
      padding-bottom: 0;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Copy Container */
  /* ---------------------------------------------------------------- */
  > div > div:nth-child(1) {
    margin: 0;
  }
  /* ---------------------------------------------------------------- */
  /* Date Container */
  /* ---------------------------------------------------------------- */
  .eventDateTime {
    margin-top: auto;
    padding-top: 23px;
    @media (max-width: 1536px) {
      padding-top: 20px;
    }
    @media (max-width: 896px) and (min-width: 768px), (max-width: 576px) {
      padding-top: 24px;
    }
    @media (max-width: 375px) {
      padding-top: 20px;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Title */
  /* ---------------------------------------------------------------- */
  .headingThree {
    display: block;
    font-size: 18px;
    font-weight: $bold;
    line-height: 26px;
    margin-bottom: 11px;
    margin-top: -7px;
    position: relative;
    @media (max-width: 1536px) {
      font-size: 17px;
      line-height: 25px;
      margin-bottom: 8px;
      margin-top: -6px;
    }
    @media (max-width: 1280px) {
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 6px;
    }
    @media (max-width: 1024px) {
      font-size: 15px;
      line-height: 23px;
      margin-bottom: 6px;
    }
    @media (max-width: 896px) and (min-width: 768px), (max-width: 576px) {
      font-size: 17px;
      line-height: 25px;
    }
    @media (max-width: 767px) {
      font-size: 16px;
      line-height: 24px;
    }
    @media (max-width: 480px) {
      font-size: 15px;
      line-height: 23px;
    }
    @media (max-width: 375px) {
      font-size: 14px;
      line-height: 21px;
    }
    &:last-child {
      margin-bottom: -7px;
      @media (max-width: 896px) and (min-width: 768px), (max-width: 576px) {
        margin-bottom: -6px;
      }
      @media (max-width: 375px) {
        margin-bottom: -5px;
      }
    }
    &::after {
      background-color: $mono2;
      color: transparent;
      font-size: inherit;
      line-height: inherit;
      @include rounded-skeleton-text;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Excerpt */
  /* ---------------------------------------------------------------- */
  p {
    display: block;
    font-size: 17px;
    line-height: 25px;
    margin-bottom: -6px;
    position: relative;
    @media (max-width: 1536px) {
      font-size: 16px;
      line-height: 24px;
    }
    @media (max-width: 1280px) {
      font-size: 15px;
      line-height: 23px;
    }
    @media (max-width: 1024px) {
      font-size: 14px;
      line-height: 21px;
      margin-bottom: -5px;
    }
    @media (max-width: 896px) and (min-width: 768px), (max-width: 576px) {
      display: none;
    }
    &::after {
      background-color: $mono2;
      color: transparent;
      font-size: inherit;
      line-height: inherit;
      @include rounded-skeleton-text;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Cta Container */
  /* ---------------------------------------------------------------- */
  .cta {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-top: 24px;
    @media (max-width: 1536px) {
      padding-top: 20px;
    }
    @media (max-width: 1280px) {
      padding-top: 20px;
    }
    @media (max-width: 896px) and (min-width: 768px), (max-width: 576px) {
      padding-top: 24px;
    }
    @media (max-width: 375px) {
      padding-top: 20px;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Hyperlink */
  /* ---------------------------------------------------------------- */
  .hyperlink {
    @media (max-width: 1408px) {
      display: table;
      font-family: $secondary;
      font-size: 16px;
      font-weight: $bold;
      line-height: 1;
      margin: -2px 0 -3px;
      min-width: 0;
      text-decoration: none;
    }
    @media (max-width: 1280px) {
      font-size: 15px;
      margin-bottom: -2px;
    }
    @media (max-width: 1024px) {
      font-size: 14px;
    }
    @media (max-width: 896px) and (min-width: 768px), (max-width: 576px) {
      font-size: 17px;
      margin: 0 0 8px;
    }
    @media (max-width: 480px) {
      font-size: 16px;
    }
    @media (max-width: 375px) {
      font-size: 15px;
    }
    &:focus {
      text-decoration: underline;
    }
    > svg {
      height: 12px;
      margin-left: 16px;
      width: 7px;
      @media (max-width: 1536px) {
        margin-left: 12px;
        position: relative;
        top: 1px;
      }
      @media (max-width: 1280px) {
        margin-left: 8px;
      }
      @media (max-width: 896px) and (min-width: 768px), (max-width: 576px) {
        margin-left: 16px;
      }
      @media (max-width: 480px) {
        margin-left: 12px;
      }
      @media (max-width: 375px) {
        margin-left: 8px;
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Button */
  /* ---------------------------------------------------------------- */
  .cta span:not(.hyperlink) {
    min-width: 175px;
    @media (max-width: 896px) and (min-width: 768px), (max-width: 576px) {
      min-width: auto;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Primary */
  /* ---------------------------------------------------------------- */
  &.primary,
  &:only-child {
    background-color: transparent;
    flex-direction: row;
    @media (max-width: 896px) and (min-width: 768px), (max-width: 576px) {
      flex-direction: column;
    }
    /* ---------------------------------------------------------------- */
    /* Image */
    /* ---------------------------------------------------------------- */
    > span {
      height: auto;
      min-height: 320px;
      padding-bottom: 0;
      width: calc(50% - 18px);
      @media (max-width: 1536px) {
        min-height: 280px;
      }
      @media (max-width: 1408px) {
        min-height: 240px;
      }
      @media (max-width: 1280px) {
        min-height: 200px;
        width: calc(50% - 16px);
      }
      @media (max-width: 1152px) {
        min-height: 190px;
      }
      @media (max-width: 1024px) {
        min-height: 180px;
        width: calc(50% - 12px);
      }
      @media (max-width: 896px) and (min-width: 768px), (max-width: 576px) {
        min-height: 208px;
        width: 100%;
      }
      @media (max-width: 375px) {
        min-height: 175px;
      }
    }
    /* ---------------------------------------------------------------- */
    /* Content */
    /* ---------------------------------------------------------------- */
    > div {
      height: auto;
      padding: 0 0 0 36px;
      @media (max-width: 1280px) {
        padding-left: 32px;
      }
      @media (max-width: 1024px) {
        padding-left: 24px;
      }
      @media (max-width: 896px) and (min-width: 768px), (max-width: 576px) {
        padding: 20px 0 0;
      }
    }
    /* ---------------------------------------------------------------- */
    /* Title */
    /* ---------------------------------------------------------------- */
    .headingThree {
      font-family: $secondary;
      font-size: 20px;
      font-weight: $semibold;
      line-height: 28px;
      margin-bottom: 19px;
      margin-top: -2px;
      @media (max-width: 1536px) {
        font-size: 19px;
        line-height: 27px;
        margin-bottom: 13px;
      }
      @media (max-width: 1280px) {
        font-size: 18px;
        line-height: 26px;
        margin-bottom: 11px;
      }
      @media (max-width: 1024px) {
        font-size: 17px;
        line-height: 25px;
        margin-bottom: 7px;
      }
      @media (max-width: 896px) and (min-width: 768px), (max-width: 576px) {
        font-family: $primary;
        font-weight: $bold;
      }
      @media (max-width: 767px) {
        font-size: 16px;
        line-height: 24px;
      }
      @media (max-width: 480px) {
        font-size: 15px;
        line-height: 23px;
      }
      @media (max-width: 375px) {
        font-size: 14px;
        line-height: 21px;
      }
      &:last-child {
        margin-bottom: -7px;
        @media (max-width: 896px) and (min-width: 768px), (max-width: 576px) {
          margin-bottom: -6px;
        }
        @media (max-width: 375px) {
          margin-bottom: -5px;
        }
      }
    }
    /* ---------------------------------------------------------------- */
    /* Date Container */
    /* ---------------------------------------------------------------- */
    .eventDateTime {
      padding-top: 32px;
      @media (max-width: 1536px) {
        padding-top: 28px;
      }
      @media (max-width: 1280px) {
        padding-top: 24px;
      }
      @media (max-width: 1024px) {
        padding-top: 20px;
      }
      @media (max-width: 896px) and (min-width: 768px), (max-width: 576px) {
        padding-top: 24px;
      }
      @media (max-width: 375px) {
        padding-top: 20px;
      }
      /* ---------------------------------------------------------------- */
      /*  Title */
      /* ---------------------------------------------------------------- */
      .headingFour {
        margin-bottom: 16px;
        @media (max-width: 1536px) {
          margin-bottom: 13px;
        }
        @media (max-width: 1280px) {
          margin-bottom: 9px;
        }
        @media (max-width: 375px) {
          margin-bottom: 5px;
        }
      }
    }
    /* ---------------------------------------------------------------- */
    /* Cta Container */
    /* ---------------------------------------------------------------- */
    .cta {
      margin-top: 0;
      padding-top: 32px;
      @media (max-width: 1536px) {
        padding-top: 28px;
      }
      @media (max-width: 1280px) {
        padding-top: 24px;
      }
      @media (max-width: 1024px) {
        padding-top: 20px;
      }
      @media (max-width: 896px) and (min-width: 768px), (max-width: 576px) {
        padding-top: 24px;
      }
      @media (max-width: 375px) {
        padding-top: 20px;
      }
    }
  }
}
/* ---------------------------------------------------------------- */
/* Event Date Time */
/* ---------------------------------------------------------------- */
.eventDateTime {
  display: flex;
  flex-wrap: wrap;
  /* ---------------------------------------------------------------- */
  /* Title */
  /* ---------------------------------------------------------------- */
  .headingFour {
    flex-basis: 100%;
    font-size: 17px;
    font-weight: $bold;
    line-height: 1;
    margin: -2px 0 12px;
    text-transform: uppercase;
    @media (max-width: 1536px) {
      font-size: 16px;
      margin-bottom: 9px;
    }
    @media (max-width: 1280px) {
      font-size: 15px;
      margin-bottom: 7px;
    }
    @media (max-width: 1024px) {
      font-size: 14px;
    }
    @media (max-width: 896px) and (min-width: 768px), (max-width: 576px) {
      font-size: 15px;
      margin-bottom: 9px;
    }
    @media (max-width: 375px) {
      margin-bottom: 5px;
    }
    &::after {
      background-color: $mono2;
      color: transparent;
      content: 'Date & Time:';
      font-size: inherit;
      line-height: inherit;
      @include rounded-skeleton-text;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Date Text + Time Container */
  /* ---------------------------------------------------------------- */
  > span {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -8px -5px;
    @media (max-width: 1536px) {
      margin: 0 -7px -5px;
    }
    @media (max-width: 1280px) {
      margin: 0 -6px -5px;
    }
    @media (max-width: 1024px) {
      margin: 0 -5px -5px;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Date Text + Time */
  /* ---------------------------------------------------------------- */
  span > span {
    color: $brandBlackGrape;
    font-size: 17px;
    line-height: 23px;
    margin: 0 8px;
    @media (max-width: 1536px) {
      font-size: 16px;
      line-height: 22px;
      margin: 0 7px;
    }
    @media (max-width: 1280px) {
      font-size: 15px;
      line-height: 21px;
      margin: 0 6px;
    }
    @media (max-width: 1024px) {
      font-size: 14px;
      line-height: 20px;
      margin: 0 5px;
    }
    @media (max-width: 896px) and (min-width: 768px), (max-width: 576px) {
      font-size: 16px;
      line-height: 22px;
    }
    &::after {
      background-color: $mono2;
      color: transparent;
      font-size: inherit;
      line-height: inherit;
      @include rounded-skeleton-text;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Date Text  */
  /* ---------------------------------------------------------------- */
  span > span:nth-child(1) {
    font-weight: $semibold;
    &::after {
      content: 'Thursday, 10 March';
    }
  }
  span > span:nth-child(2) {
    &::after {
      content: '5:02pm GMT';
    }
  }
}

@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}
