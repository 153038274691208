@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
.authLanding {
  align-items: flex-end;
  background-image: url('/images/artwork/auth-landing-bg.jpg');
  background-position: center;
  background-size: cover;
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  padding: 32px 16px 80px 16px;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 999;
  > svg {
    height: auto;
    width: 346px;
    @media (max-width: 1280px) {
      width: 306px;
    }
    @media (max-width: 1024px) {
      width: 266px;
    }
    @media (max-width: 767px) {
      width: 226px;
    }
    @media (max-width: 375px) {
      width: 186px;
    }
  }
}
