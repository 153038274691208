@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Image With Caption */
/* ---------------------------------------------------------------- */
.imageWithCaption {
  margin: 56px auto;
  max-width: 1024px;
  @media (max-width: 1536px) {
    margin-bottom: 48px;
    margin-top: 48px;
  }
  @media (max-width: 1280px) {
    margin-bottom: 40px;
    margin-top: 40px;
  }
  @media (max-width: 1024px) {
    margin-bottom: 32px;
    margin-top: 32px;
  }
  @media (max-width: 375px) {
    margin-bottom: 24px;
    margin-top: 24px;
  }
  &.has-min-width {
    overflow-x: auto;
    overflow-y: hidden;
    @media (max-width: 767px) {
      margin-left: -24px;
      margin-right: -24px;
      padding-left: 24px;
      padding-right: 24px;
      width: 100%;
    }
    :global(.platform-windows) & {
      &::-webkit-scrollbar {
        -webkit-appearance: none;
        height: 12px;
      }
      &::-webkit-scrollbar-track {
        background-color: $mono1;
        border-radius: 8px;
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
      }
      &::-webkit-scrollbar-thumb {
        background-color: $brandAqua;
        border-radius: 8px;
      }
    }
  }
  .imagewcaptionTitle {
    color: $brandBlackGrape;
    font-family: $secondary;
    font-size: 32px;
    font-weight: $semibold;
    line-height: 33px;
    margin-bottom: 32px;
    @media (max-width: 767px) {
      font-size: 24px;
      margin-bottom: 16px;
    }
  }
  figure {
    font-size: 0;
    height: auto;
    line-height: 0;
    margin: 0;
    position: relative;
    width: 100%;
  }
  img {
    width: 100%;
  }
  /* ---------------------------------------------------------------- */
  /* Caption */
  /* ---------------------------------------------------------------- */
  figcaption {
    background-color: rgba($color: $brandBlackGrape, $alpha: 0.85);
    bottom: 0;
    box-sizing: border-box;
    color: $white;
    font-size: 14px;
    left: 0;
    line-height: 20px;
    padding: 19px 24px 18px;
    position: absolute;
    right: 0;
    width: 100%;
    @media (max-width: 1280px) {
      font-size: 13px;
      line-height: 19px;
      padding: 16px 20px 15px;
    }
    &::after {
      background-image: url('/images/artwork/image-caption-degrees.svg');
      background-position: left bottom;
      background-repeat: no-repeat;
      background-size: cover;
      content: '';
      height: 100%;
      position: absolute;
      right: 0;
      top: 0;
      width: 42%;
      z-index: 1;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Fill Row */
  /* ---------------------------------------------------------------- */
  &.fillRow {
    max-width: 100%;
  }
}
