@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Switch Account */
/* ---------------------------------------------------------------- */
.switchAccount {
  background-color: $white;
  position: relative;
  @include variable-fonts($weight: 400, $width: 100);
  &[class] {
    @media (max-width: 767px) {
      background-color: transparent;
      height: 64px;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Chevron */
  /* ---------------------------------------------------------------- */
  svg:nth-child(3) {
    flex-shrink: 0;
    height: 20px;
    position: absolute;
    right: 24px;
    top: 50%;
    transform: translate(3px, -50%);
    transform-origin: 50% 0;
    width: 20px;
    @include transition(
      $mob: true,
      $props: 'transform',
      $timing: 'cubic-bezier(0.2, 1, 0.35, 1)',
      $duration: '0.4s'
    );
    * {
      fill: $brandBlackGrape;
      @media (max-width: 767px) {
        fill: rgba($brandBlackGrape, 0.7);
      }
    }
    g {
      transform: translate(0, 14px);
      @include transition(
        $mob: true,
        $props: 'transform',
        $timing: 'cubic-bezier(0.2, 1, 0.35, 1)',
        $duration: '0.4s'
      );
    }
    line {
      stroke: $brandBlackGrape;
      stroke-linecap: round;
      stroke-width: 12px;
      transform-origin: center center;
      will-change: transform, stroke;
      @include transition(
        $mob: true,
        $props: 'stroke, transform',
        $timing: 'cubic-bezier(0.2, 1, 0.35, 1)',
        $duration: '0.4s'
      );
      @media (max-width: 767px) {
        stroke: rgba($brandBlackGrape, 0.7);
      }
      &:nth-child(1) {
        transform: rotate(45deg);
      }
      &:nth-child(2) {
        transform: rotate(-45deg);
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* List */
  /* ---------------------------------------------------------------- */
  + ul {
    opacity: 1;
    overflow: hidden;
    transform: scaleY(1);
    transform-origin: 0 0;
    will-change: height, opacity, transform;
    @include medium-transition(
      $firefox: false,
      $mob: true,
      $props: 'height, opacity, transform'
    );
    @media (max-width: 767px) {
      width: 100%;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Open */
  /* ---------------------------------------------------------------- */
  &[aria-expanded='true'] {
    @include variable-fonts($weight: 600, $width: 100);
    svg:nth-child(3) {
      g {
        transform: translate(0, -14px);
        line {
          &:nth-child(1) {
            transform: rotate(-45deg);
          }
          &:nth-child(2) {
            transform: rotate(45deg);
          }
        }
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Closed / Closing */
  /* ---------------------------------------------------------------- */
  &.closed {
    + ul {
      height: 0;
      opacity: 0;
      transform: scaleY(0);
    }
    /* ------------------------------------------------------------ */
    /* Prevent Content Children From Being Focused */
    /* ------------------------------------------------------------ */
    &:not(.animating) + ul {
      visibility: hidden;
    }
  }
}
