@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Menu */
/* ---------------------------------------------------------------- */
.skeleton {
  align-items: center;
  display: flex;
  margin-left: auto;
  min-height: 23px;
  overflow: hidden;
  @media (max-width: 576px) {
    margin-top: 16px;
    width: 100%;
  }
  @media (max-width: 375px) {
    margin-top: 12px;
  }
  /* -------------------------------------------------------------- */
  /* Menu List */
  /* -------------------------------------------------------------- */
  > div {
    position: relative;
    width: 100%;
    @media (max-width: 576px) {
      display: grid;
      grid-auto-flow: column;
      grid-template-rows: auto auto auto;
    }
  }
  /* -------------------------------------------------------------- */
  /* Menu Item */
  /* -------------------------------------------------------------- */
  > div > span {
    font-family: $primary;
    font-size: 17px;
    line-height: 17px;
    margin-right: 72px;
    @media (max-width: 1664px) {
      margin-right: 64px;
    }
    @media (max-width: 1536px) {
      margin-right: 56px;
    }
    @media (max-width: 1408px) {
      margin-right: 48px;
    }
    @media (max-width: 1280px) {
      font-size: 16px;
      line-height: 16px;
      margin-right: 32px;
    }
    @media (max-width: 1024px) {
      margin-right: 24px;
    }
    @media (max-width: 576px) {
      margin: 16px 0;
      width: 100%;
    }
    @media (max-width: 375px) {
      font-size: 15px;
      line-height: 15px;
      margin: 12px 0;
    }
    &::before {
      margin: -5px 0;
      @include skeleton-shimmer;
      @include skeleton-shimmer-color($color: $brandBlackGrape);
    }
    &::after {
      background-color: $brandBlackGrapeLight;
      color: transparent;
      font-size: inherit;
      line-height: inherit;
      text-transform: inherit;
      @include rounded-skeleton-text;
    }
    $link-items: 'Home' 'Resources' 'Badges' 'Help' 'Legal';
    @each $link-item in $link-items {
      $i: index($link-items, $link-item);
      &:nth-of-type(#{$i}) {
        &::after {
          content: '#{$link-item}';
        }
      }
    }
    &:last-child {
      margin-right: 0;
    }
  }
}

@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}
