@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* -------------------------------------------------------------- */
/* Curated Pages Item */
/* -------------------------------------------------------------- */
.curatedPagesItem {
  display: flex;
  height: 100%;
  @media (min-width: 1537px), (max-width: 767px) {
    min-height: 140px;
  }
  @media (max-width: 1536px) and (min-width: 1153px) {
    border-bottom: 1px solid $grey;
    height: auto;
    padding: 20px 0;
  }
  @media (max-width: 1536px) and (min-width: 1153px) {
    padding: 16px 0;
  }
  @media (max-width: 1152px) and (min-width: 768px), (max-width: 520px) {
    cursor: grab;
    flex-direction: column;
  }
  &:first-child {
    @media (max-width: 1536px) and (min-width: 1153px) {
      border-top: 1px solid $grey;
    }
  }
  /* -------------------------------------------------------------- */
  /* Image Container */
  /* -------------------------------------------------------------- */
  .image {
    display: block;
    flex-shrink: 0;
    max-width: 230px;
    position: relative;
    width: 45%;
    @media (max-width: 1536px) and (min-width: 1153px) {
      display: none;
    }
    @media (max-width: 1152px) and (min-width: 768px), (max-width: 520px) {
      height: 0;
      max-width: 100%;
      padding-bottom: 62.5%;
      width: 100%;
    }
    /* -------------------------------------------------------------- */
    /* Image */
    /* -------------------------------------------------------------- */
    div {
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      bottom: 0;
      display: block;
      height: 100%;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
    }
    /* -------------------------------------------------------------- */
    /* SVG Slice */
    /* -------------------------------------------------------------- */
    span {
      background-image: url('/images/artwork/post-slice.svg');
      background-repeat: no-repeat;
      background-size: cover;
      bottom: -1px;
      height: auto;
      position: absolute;
      right: 0;
      top: -1px;
      width: 52.5%;
      z-index: 2;
    }
  }
  /* -------------------------------------------------------------- */
  /* Copy Container */
  /* -------------------------------------------------------------- */
  .content {
    display: flex;
    flex-direction: column;
    @media (min-width: 1536px) {
      padding-left: 24px;
    }
    @media (max-width: 1728px) and (min-width: 1537px) {
      padding-left: 20px;
    }
    @media (max-width: 1664px) and (min-width: 1537px),
      (max-width: 767px) and (min-width: 521px) {
      padding-left: 16px;
    }
    @media (max-width: 1536px) and (min-width: 1153px) {
      align-items: center;
      flex-direction: row;
      width: 100%;
    }
    @media (max-width: 1152px) and (min-width: 768px), (max-width: 520px) {
      height: 100%;
      margin-left: 0;
      margin-top: 16px;
    }
    &:only-child {
      padding: 0;
    }
    /* -------------------------------------------------------------- */
    /* Title / Text Container */
    /* -------------------------------------------------------------- */
    > div {
      @media (max-width: 1536px) and (min-width: 1153px) {
        padding-right: 20px;
      }
      @media (max-width: 1280px) and (min-width: 1153px) {
        padding-right: 16px;
      }
      @media (max-width: 1152px) and (min-width: 768px), (max-width: 520px) {
        text-align: left;
      }
    }
    /* -------------------------------------------------------------- */
    /* Title */
    /* -------------------------------------------------------------- */
    h3 {
      font-size: 18px;
      font-weight: $bold;
      line-height: 24px;
      margin: -5px 0 9px;
      @media (max-width: 1728px) {
        font-size: 17px;
        line-height: 23px;
        margin-bottom: 8px;
      }
      @media (max-width: 1664px) and (min-width: 1537px) {
        font-size: 16px;
        line-height: 22px;
        margin-bottom: 6px;
      }
      @media (max-width: 1536px) and (min-width: 1153px) {
        margin-bottom: 8px;
      }
      @media (max-width: 1280px) {
        font-size: 16px;
        line-height: 22px;
        margin-bottom: 5px;
      }
      @media (max-width: 1152px) and (min-width: 768px), (max-width: 520px) {
        font-size: 16px;
      }
      &:last-child {
        margin-bottom: -4px;
      }
    }
    /* -------------------------------------------------------------- */
    /* Text */
    /* -------------------------------------------------------------- */
    p {
      display: block;
      font-size: 17px;
      line-height: 23px;
      margin: 0 0 15px;
      @media (max-width: 1728px) {
        font-size: 16px;
        line-height: 22px;
        margin: 0 0 13px;
      }
      @media (max-width: 1664px) and (min-width: 1537px) {
        font-size: 15px;
        line-height: 21px;
        margin: 0 0 11px;
      }
      @media (max-width: 1280px) {
        font-size: 15px;
        line-height: 21px;
      }
      @media (max-width: 1536px) and (min-width: 1153px) {
        margin-bottom: -5px;
      }
    }
    /* -------------------------------------------------------------- */
    /* Button */
    /* -------------------------------------------------------------- */
    a {
      align-self: flex-start;
      margin-top: auto;
      @media (max-width: 1536px) and (min-width: 1153px) {
        margin-bottom: auto;
        margin-left: auto;
      }
      svg {
        box-sizing: content-box;
      }
    }
  }
}
