/* -------------------------------------------------------------- */
/* Cookie Banner */
/* -------------------------------------------------------------- */
#ccc {
  #ccc-module.ccc-module--slideout {
    @media (max-width: 319px) {
      left: 0;
      min-width: 320px;
      width: 100%;
    }
  }
  #ccc-icon {
    height: 60px !important;
    width: 60px !important;
  }
  #ccc-title {
    font-family: $secondary;
    font-weight: $semibold;
    line-height: 27px;
  }
  .ccc-intro {
    color: #767983 !important;
    font-family: $secondary;
    font-size: 16px !important;
    font-weight: $semibold;
    line-height: 20px;
    margin: 5px 0 30px;
    padding-right: 30px !important;
  }
  #ccc-button-holder {
    display: flex;
    justify-content: space-between;
  }
  /* -------------------------------------------------------------- */
  /* Buttons */
  /* -------------------------------------------------------------- */
  .ccc-notify-button {
    align-items: center;
    border: none !important;
    border-radius: 40px !important;
    box-sizing: border-box;
    cursor: pointer;
    display: inline-flex;
    height: 48px;
    justify-content: center;
    margin: 0;
    min-width: 140px;
    padding: 0 24px !important;
    position: relative;
    touch-action: manipulation;
    transition-duration: 0.1s;
    transition-property: border-color, background-color;
    transition-timing-function: ease-in-out;
    vertical-align: top;
    @media (max-width: 767px) {
      height: 40px;
      min-width: 120px;
      padding: 0 16px !important;
    }
    span {
      font-family: $primary;
      font-size: 18px;
      font-weight: $semibold;
      line-height: normal;
      pointer-events: none;
      position: relative;
      white-space: nowrap;
      z-index: 1;
      @media (max-width: 767px) {
        font-size: 17px;
      }
    }
  }
  #ccc-content button.ccc-accept-button span {
    color: $brandBlackGrape !important;
  }
  .ccc-accept-button {
    background-color: $brandAqua !important;
    order: 2;
    &:focus {
      background-color: $brandAquaDark !important;
    }
    .platform-desktop & {
      &:hover {
        background-color: $brandAquaLight !important;
      }
      &:active {
        background-color: $brandAquaDark !important;
      }
    }
    .platform-mobile & {
      &:active {
        background-color: $brandAquaLight !important;
      }
    }
  }
  #ccc-content button.ccc-reject-button span {
    color: $white !important;
  }
  .ccc-reject-button {
    background-color: $brandBerry !important;
    order: 1;
    &:focus {
      background-color: $brandBerryDark !important;
    }
    .platform-desktop & {
      &:hover {
        background-color: $brandBerryLight !important;
      }
      &:active {
        background-color: $brandBerryDark !important;
      }
    }
    .platform-mobile & {
      &:active {
        background-color: $brandBerryLight !important;
      }
    }
  }
  #ccc-necessary-title,
  .optional-cookie h3 {
    color: #282d3d !important;
    font-family: $secondary;
    font-weight: $semibold;
    line-height: 24px;
  }
  #ccc-necessary-description,
  .optional-cookie p {
    font-family: $primary;
    line-height: 24px;
  }
  #cc-panel hr {
    background-color: $mono2 !important;
    height: 2px;
  }
  #ccc-optional-categories .checkbox-toggle {
    border: none !important;
    border-radius: 15px !important;
  }
  .checkbox-toggle--slider .checkbox-toggle-toggle {
    height: 13px !important;
    left: 4px !important;
    top: 4px !important;
    width: 13px !important;
  }
  .checkbox-toggle--slider input:checked ~ .checkbox-toggle-toggle {
    background-color: $brandAqua !important;
    left: 23px !important;
  }
  .checkbox-toggle-on,
  .checkbox-toggle-off {
    display: none;
  }
  .checkbox-toggle-label {
    border: 1px solid $mono2;
    border-radius: 15px;
    cursor: pointer;
    height: 21px;
    position: relative;
    width: 40px;
  }
  #ccc-icon svg #triangle path {
    fill: $brandAqua;
  }
}
