@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Payment Method */
/* ---------------------------------------------------------------- */
.paymentMethod {
  align-items: center;
  background-color: $white;
  border-color: $mono2;
  border-radius: 9px;
  border-style: solid;
  border-width: 1px;
  box-sizing: border-box;
  display: flex;
  margin-top: 24px;
  padding: 32px;
  position: relative;
  width: auto;
  @include no-user-select;
  @media (max-width: 1536px) {
    margin-top: 20px;
  }
  @media (max-width: 1280px) {
    margin-top: 16px;
    padding: 28px;
  }
  @media (max-width: 1024px) {
    padding: 24px;
  }
  @media (max-width: 640px) {
    align-items: flex-start;
    border-radius: 4px;
    flex-direction: column;
    justify-content: center;
    margin-top: 32px;
    padding: 24px 24px 25px;
  }
  @media (max-width: 375px) {
    margin-top: 24px;
    min-height: 56px;
    padding: 16px;
  }
  &:last-of-type {
    margin-bottom: 32px;
    @media (max-width: 1536px) {
      margin-bottom: 28px;
    }
    @media (max-width: 1280px) {
      margin-bottom: 24px;
    }
    @media (max-width: 640px) {
      margin-bottom: 32px;
    }
    @media (max-width: 375px) {
      margin-bottom: 24px;
    }
  }
  /* -------------------------------------------------------------- */
  /* Additional */
  /* -------------------------------------------------------------- */
  & + .paymentMethod {
    margin-top: 20px;
    @media (max-width: 1536px) {
      margin-top: 16px;
    }
    @media (max-width: 1280px) {
      margin-top: 12px;
    }
    @media (max-width: 375px) {
      margin-top: 8px;
    }
  }
  /* -------------------------------------------------------------- */
  /* Top */
  /* -------------------------------------------------------------- */
  > div:first-child {
    align-items: center;
    display: flex;
    margin: -5px 0 -6px;
    position: relative;
    @media (max-width: 1280px) {
      margin: -5px 0;
    }
    @media (max-width: 640px) {
      margin-bottom: 0;
      width: 100%;
    }
    /* -------------------------------------------------------------- */
    /* Icon */
    /* -------------------------------------------------------------- */
    > svg {
      flex-shrink: 0;
      height: 36px;
      left: 0;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 36px;
      @media (max-width: 375px) {
        height: 32px;
        width: 32px;
      }
    }
    /* -------------------------------------------------------------- */
    /* Card */
    /* -------------------------------------------------------------- */
    > h4 {
      color: $brandBlackGrape;
      flex-grow: 1;
      font-size: 20px;
      letter-spacing: normal;
      line-height: 25px;
      padding: 0 16px 0 51px;
      @include user-select;
      @media (max-width: 1536px) {
        font-size: 19px;
        line-height: 25px;
      }
      @media (max-width: 1280px) {
        font-size: 18px;
        line-height: 24px;
      }
      @media (max-width: 640px) {
        font-size: 17px;
        line-height: 23px;
        padding: 0 44px 0 51px;
      }
      @media (max-width: 460px) {
        font-size: 16px;
        line-height: 22px;
      }
      @media (max-width: 375px) {
        font-size: 15px;
        line-height: 21px;
        padding: 0 40px 0 48px;
      }
      > strong {
        font-weight: $bold;
      }
    }
    /* -------------------------------------------------------------- */
    /* Mobile Accordion Button */
    /* -------------------------------------------------------------- */
    > button {
      background-color: $mono1;
      border-radius: 50%;
      cursor: pointer;
      display: block;
      flex-shrink: 0;
      height: 28px;
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 28px;
      @include no-user-select;
      @media (max-width: 375px) {
        height: 24px;
        width: 24px;
      }
      &:focus::after {
        border-color: $mono3;
      }
      /* ---------------------------------------------------------- */
      /* Focus Ring */
      /* ---------------------------------------------------------- */
      &::after {
        border-color: transparent;
        border-radius: 50%;
        border-style: dashed;
        border-width: 2px;
        box-sizing: border-box;
        content: '';
        height: calc(100% + 12px);
        left: -6px;
        pointer-events: none;
        position: absolute;
        top: -6px;
        width: calc(100% + 12px);
        @include fast-transition($props: 'border-color');
      }
      /* ---------------------------------------------------------- */
      /* SVG */
      /* ---------------------------------------------------------- */
      > svg {
        fill: $brandBlackGrape;
        transform: rotate(-180deg);
        will-change: transform;
        @include fast-transition($mob: true, $props: 'transform');
      }
    }
  }
  /* -------------------------------------------------------------- */
  /* Bottom */
  /* -------------------------------------------------------------- */
  > div:last-child {
    display: flex;
    flex-direction: row;
    margin: -2px 0 -2px auto;
    @media (max-width: 640px) {
      margin: 0 0 -6px;
      opacity: 1;
      overflow: hidden;
      padding-left: 51px;
      transform: scaleY(1);
      will-change: height, opacity, transform;
      @include medium-transition(
        $firefox: false,
        $mob: true,
        $props: 'height, opacity, transform'
      );
    }
    @media (max-width: 375px) {
      padding-left: 48px;
    }
    /* ------------------------------------------------------------ */
    /* Button */
    /* ------------------------------------------------------------ */
    button {
      color: rgba($brandBlackGrape, 0.7);
      cursor: pointer;
      display: block;
      font-family: $primary;
      font-size: 18px;
      font-weight: $bold;
      letter-spacing: normal;
      line-height: 18px;
      @include fast-transition($props: 'color');
      @media (max-width: 1536px) {
        font-size: 17px;
        line-height: 17px;
      }
      @media (max-width: 1280px) {
        font-size: 16px;
        line-height: 16px;
      }
      @media (max-width: 640px) {
        line-height: normal;
        margin: 18px 0 0;
      }
      @media (max-width: 375px) {
        font-size: 15px;
        margin-bottom: 1px;
        margin-top: 10px;
      }
      /* ---------------------------------------------------------- */
      /* Additional */
      /* ---------------------------------------------------------- */
      & + button {
        margin-left: 20px;
        @media (max-width: 375px) {
          margin-left: 16px;
        }
      }
      /* ---------------------------------------------------------- */
      /* Hover / Active */
      /* ---------------------------------------------------------- */
      &:focus {
        color: $brandBlackGrape;
        text-decoration: underline;
      }
      :global(.platform-desktop) & {
        &:hover {
          color: $brandAqua;
        }
        &:active {
          color: $brandBlackGrape;
        }
      }
      :global(.platform-mobile) & {
        &:active {
          color: $brandAqua;
        }
      }
    }
  }
  /* -------------------------------------------------------------- */
  /* Closed */
  /* -------------------------------------------------------------- */
  &.closed {
    > div:first-child > button > svg {
      transform: rotate(0deg);
    }
    > div:last-child {
      height: 0;
      opacity: 0;
      transform: scaleY(0.6);
    }
    /* ------------------------------------------------------------ */
    /* Prevent Content Children From Being Focused */
    /* ------------------------------------------------------------ */
    &:not(.animating) > div:last-child > * {
      visibility: hidden;
    }
  }
  /* -------------------------------------------------------------- */
  /* Default Card */
  /* -------------------------------------------------------------- */
  &.defaultCard {
    border-color: $brandAqua;
    h4 span {
      font-style: italic;
    }
  }
  /* -------------------------------------------------------------- */
  /* Invalid */
  /* -------------------------------------------------------------- */
  &.invalid {
    border-color: red;
  }
}
