@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Featured Resources */
/* ---------------------------------------------------------------- */
.featuredResources {
  padding-bottom: 36px;
  @media (max-width: 1280px) {
    padding-bottom: 32px;
  }
  @media (max-width: 1024px) {
    padding-bottom: 24px;
  }
  @media (max-width: 767px) {
    padding-bottom: 32px;
  }
  @media (max-width: 375px) {
    padding-bottom: 24px;
  }
  &:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
  }
  /* ---------------------------------------------------------------- */
  /* Title Container */
  /* ---------------------------------------------------------------- */
  > div:nth-child(1) {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 36px;
    position: relative;
    @media (max-width: 1280px) {
      margin-bottom: 32px;
    }
    @media (max-width: 1024px) {
      margin-bottom: 24px;
    }
    /* ---------------------------------------------------------------- */
    /* Desktop CTA */
    /* ---------------------------------------------------------------- */
    > a {
      max-width: 204px;
      min-width: 204px;
      width: 100%;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Title */
  /* ---------------------------------------------------------------- */
  h2 {
    color: $brandBlackGrape;
    font-family: $secondary;
    font-size: 32px;
    font-weight: $semibold;
    line-height: 40px;
    margin: -8px 0;
    padding-right: 24px;
    @media (max-width: 1664px) {
      font-size: 29px;
      line-height: 36px;
      margin: -7px 0 -8px;
    }
    @media (max-width: 1536px) {
      font-size: 26px;
      line-height: 33px;
      margin: -6px 0 -7px;
      padding-right: 20px;
    }
    @media (max-width: 1408px) {
      font-size: 23px;
      line-height: 29px;
      margin: -5px 0 -7px;
    }
    @media (max-width: 1280px) {
      font-size: 20px;
      line-height: 26px;
      margin: -5px 0 -6px;
      padding-right: 16px;
    }
    @media (max-width: 896px) {
      padding-right: 0;
    }
    @media (max-width: 375px) {
      font-size: 19px;
      line-height: 25px;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Featured Resources Card Container */
  /* ---------------------------------------------------------------- */
  > ul {
    display: flex;
    position: relative;
    @media (max-width: 896px) and (min-width: 768px), (max-width: 576px) {
      flex-direction: column;
    }
    /* ---------------------------------------------------------------- */
    /* Columns */
    /* ---------------------------------------------------------------- */
    > li {
      display: flex;
      flex-direction: column;
      width: 50%;
      @media (max-width: 896px) and (min-width: 768px), (max-width: 576px) {
        width: 100%;
      }
      /* ---------------------------------------------------------------- */
      /* Large Card Container x 1 */
      /* ---------------------------------------------------------------- */
      &:first-child {
        display: flex;
        margin-right: 36px;
        @media (max-width: 1280px) {
          margin-right: 32px;
        }
        @media (max-width: 1024px) {
          margin-right: 24px;
        }
        @media (max-width: 896px) and (min-width: 768px), (max-width: 576px) {
          display: block;
          margin-right: 0;
        }
      }
      /* ---------------------------------------------------------------- */
      /* Small Card Container x 3 */
      /* ---------------------------------------------------------------- */
      &:last-child {
        article {
          margin-bottom: 12px;
          @media (max-width: 896px) and (min-width: 768px), (max-width: 576px) {
            margin-bottom: 0;
          }
          &:last-child {
            border: 0;
            margin-bottom: 0;
          }
        }
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Mobile CTA Container */
  /* ---------------------------------------------------------------- */
  > div:last-child {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
    position: relative;
    @media (max-width: 896px) and (min-width: 768px), (max-width: 576px) {
      margin-top: 13px;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Row Layout */
  /* ---------------------------------------------------------------- */
  &.rows {
    /* ---------------------------------------------------------------- */
    /* Featured Resources Card Container */
    /* ---------------------------------------------------------------- */
    > ul {
      @media (min-width: 897px), (max-width: 767px) and (min-width: 577px) {
        flex-direction: column;
      }
      /* ---------------------------------------------------------------- */
      /* Row Container */
      /* ---------------------------------------------------------------- */
      > li {
        @media (min-width: 897px), (max-width: 767px) and (min-width: 577px) {
          width: 100%;
        }
        /* ---------------------------------------------------------------- */
        /* Large Card Container x 1 */
        /* ---------------------------------------------------------------- */
        &:first-child {
          @media (min-width: 897px), (max-width: 767px) and (min-width: 577px) {
            margin-bottom: 16px;
          }
          /* ---------------------------------------------------------------- */
          /* Article */
          /* ---------------------------------------------------------------- */
          article {
            @media (min-width: 897px),
              (max-width: 767px) and (min-width: 577px) {
              flex-direction: row;
            }
            /* ---------------------------------------------------------------- */
            /* Image */
            /* ---------------------------------------------------------------- */
            > span {
              @media (min-width: 897px),
                (max-width: 767px) and (min-width: 577px) {
                height: auto;
                max-height: 100%;
                min-height: 320px;
                width: 50%;
              }
              @media (max-width: 1536px) {
                min-height: 280px;
              }
              @media (max-width: 1408px) {
                min-height: 260px;
              }
              @media (max-width: 1280px) {
                min-height: 240px;
              }
              @media (max-width: 1152px) {
                min-height: 220px;
              }
              @media (max-width: 1024px) {
                min-height: 200px;
              }
              @media (max-width: 896px) and (min-width: 768px),
                (max-width: 576px) {
                height: 208px;
              }
              @media (max-width: 375px) {
                height: 175px;
              }
            }
            > div {
              @media (min-width: 897px),
                (max-width: 767px) and (min-width: 577px) {
                height: auto;
              }
            }
          }
        }
        /* ---------------------------------------------------------------- */
        /* Small Card Container */
        /* ---------------------------------------------------------------- */
        &:last-child {
          @media (min-width: 897px), (max-width: 767px) and (min-width: 577px) {
            display: grid;
            grid-auto-rows: 1fr;
            grid-row-gap: 16px;
          }
          /* ---------------------------------------------------------------- */
          /* Row */
          /* ---------------------------------------------------------------- */
          article {
            @media (min-width: 897px),
              (max-width: 767px) and (min-width: 577px) {
              margin: 0;
            }
          }
        }
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Row Layout - Grid (If Over 3 Resources) */
  /* ---------------------------------------------------------------- */
  &.grid {
    /* ---------------------------------------------------------------- */
    /* Small Card Container */
    /* ---------------------------------------------------------------- */
    > ul > li:last-child {
      @media (min-width: 897px), (max-width: 767px) and (min-width: 577px) {
        grid-column-gap: 16px;
        grid-template-columns: repeat(2, 1fr);
      }
    }
  }
}
