@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Profile */
/* ---------------------------------------------------------------- */
.profile {
  padding: 72px 0;
  width: 100%;
  @media (max-width: 1664px) {
    padding: 64px 0;
  }
  @media (max-width: 1536px) {
    padding: 56px 0;
  }
  @media (max-width: 1408px) {
    padding: 48px 0;
  }
  @media (max-width: 1280px) {
    padding: 32px 0;
  }
  @media (max-width: 1024px) {
    padding: 24px 0;
  }
  @media (max-width: 767px) {
    padding: 32px 0;
  }
  @media (max-width: 375px) {
    padding: 24px 0;
  }
  /* ---------------------------------------------------------------- */
  /* Profile Title */
  /* ---------------------------------------------------------------- */
  > div:first-child > h1 {
    color: rgba($color: $brandBlackGrape, $alpha: 0.5);
    font-family: $secondary;
    font-size: 20px;
    font-weight: $semibold;
    line-height: 24px;
    margin: -5px 0;
    text-transform: uppercase;
    @media (max-width: 1536px) {
      font-size: 19px;
      line-height: 23px;
    }
    @media (max-width: 1280px) {
      font-size: 18px;
      line-height: 22px;
      margin-top: -4px;
    }
    @media (max-width: 375px) {
      font-size: 17px;
      line-height: 21px;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Account Management Section */
  /* ---------------------------------------------------------------- */
  .accountManagement {
    /* ---------------------------------------------------------------- */
    /* Title */
    /* ---------------------------------------------------------------- */
    > h2 {
      color: $brandBlackGrape;
      font-size: 32px;
      font-weight: $semibold;
      line-height: 42px;
      margin: -10px 0 -9px;
      @media (max-width: 1536px) {
        font-size: 28px;
        line-height: 37px;
        margin: -9px 0 -8px;
      }
      @media (max-width: 1280px) {
        font-size: 24px;
        line-height: 32px;
        margin: -8px 0 -7px;
      }
      @media (max-width: 375px) {
        font-size: 22px;
        line-height: 30px;
        margin-bottom: -6px;
      }
    }
  }
}
