@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* -------------------------------------------------------------- */
/* Badges Skeleton */
/* -------------------------------------------------------------- */
.skeleton {
  margin-top: -2px;
  overflow: hidden;
  position: relative;
  @media (max-width: 1280px) {
    margin-top: -5px;
  }
  @media (max-width: 1024px) {
    margin-top: -4px;
  }
  > div {
    /* -------------------------------------------------------------- */
    /* Title */
    /* -------------------------------------------------------------- */
    > p {
      font-size: 20px;
      font-weight: $semibold;
      line-height: 24px;
      // margin: -4px 0;
      margin: 2px 0 -4px 0;
      padding-bottom: 28px;
      position: relative;
      @media (max-width: 1536px) {
        font-size: 19px;
        line-height: 23px;
      }
      @media (max-width: 1280px) {
        font-size: 18px;
        line-height: 22px;
        padding-bottom: 24px;
      }
      @media (max-width: 1024px) {
        font-size: 17px;
        line-height: 21px;
        padding-bottom: 20px;
      }
      @media (max-width: 767px) {
        font-size: 18px;
        line-height: 22px;
        padding-bottom: 24px;
      }
      @media (max-width: 375px) {
        padding-bottom: 20px;
      }
      &::before {
        @include skeleton-shimmer;
        @include skeleton-shimmer-color($color: $pageBackground);
      }
      &::after {
        background-color: $mono1;
        color: transparent;
        content: 'Certificates';
        font-size: inherit;
        line-height: inherit;
        @include rounded-skeleton-text;
      }
      /* -------------------------------------------------------------- */
      /* Badge Category */
      /* -------------------------------------------------------------- */
      + p {
        font-family: $secondary;
        font-size: 18px;
        font-weight: $semibold;
        letter-spacing: 0.16px;
        line-height: 22px;
        margin: -4px 0 -5px;
        padding-bottom: 24px;
        text-transform: uppercase;
        @media (max-width: 1536px) {
          font-size: 17px;
          line-height: 21px;
        }
        @media (max-width: 1280px) {
          font-size: 16px;
          line-height: 20px;
          padding-bottom: 20px;
        }
        @media (max-width: 1024px) {
          font-size: 15px;
          line-height: 19px;
          padding-bottom: 16px;
        }
        @media (max-width: 767px) {
          font-size: 16px;
          line-height: 20px;
          padding-bottom: 20px;
        }
        @media (max-width: 375px) {
          padding-bottom: 16px;
        }
      }
    }
    /* -------------------------------------------------------------- */
    /* Badge */
    /* -------------------------------------------------------------- */
    ul > li {
      overflow: hidden;
      position: relative;
      > div {
        &::after {
          @include skeleton-shimmer;
          @include skeleton-shimmer-color($color: $white);
        }
        h4 {
          > span:first-child {
            &::after {
              background-color: $mono1;
              color: transparent;
              content: 'PRINCE2 Practioner';
              font-size: inherit;
              line-height: inherit;
              @include rounded-skeleton-text;
            }
          }
          > .icon {
            background-color: $mono1;
            border-radius: 50%;
            flex-shrink: 0;
            font-size: 0;
            height: 24px;
            line-height: 0;
            margin-left: auto;
            padding-bottom: 0;
            padding-left: 0;
            width: 24px;
            @media (max-width: 1536px) {
              padding-left: 0;
            }
            @media (max-width: 1280px) {
              padding-left: 0;
            }
            @media (max-width: 1024px) and (min-width: 768px),
              (max-width: 375px) {
              height: 23px;
              width: 24px;
            }
          }
        }
        > div > p {
          font-size: 17px;
          line-height: 21px;
          margin: -4px 0;
          @media (max-width: 1536px) {
            font-size: 16px;
            line-height: 20px;
          }
          @media (max-width: 1280px) {
            font-size: 15px;
            line-height: 19px;
          }
          @media (max-width: 1024px) and (min-width: 768px),
            (max-width: 375px) {
            font-size: 14px;
            line-height: 18px;
          }
          &::after {
            background-color: $mono1;
            color: transparent;
            content: 'Find out more';
            font-size: inherit;
            line-height: inherit;
            @include rounded-skeleton-text;
          }
        }
      }
    }
  }
}

@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}
