@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Input */
/* ---------------------------------------------------------------- */
.input {
  position: relative;
  /* ---------------------------------------------------------------- */
  /* Input - Input */
  /* ---------------------------------------------------------------- */
  input {
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    appearance: none;
    border-color: $mono2;
    border-radius: 9px;
    border-style: solid;
    border-width: 1px;
    box-sizing: border-box;
    caret-color: $brandBlackGrape;
    color: $brandBlackGrape;
    display: inline-block;
    filter: none;
    font-weight: normal;
    height: 80px;
    letter-spacing: normal;
    line-height: normal;
    outline: none;
    padding: 0 30px;
    resize: none;
    vertical-align: middle;
    width: 100%;
    @include no-user-select;
    @include fast-transition($props: 'color, border-color, background-color');
    @media (max-width: 1536px) {
      height: 72px;
      padding: 0 26px;
    }
    @media (max-width: 1280px) {
      height: 64px;
      padding: 0 22px;
    }
    @media (max-width: 1024px) {
      height: 56px;
      padding: 0 18px;
    }
    &:invalid {
      box-shadow: none;
    }
    &:-webkit-autofill {
      transition: background-color 10000s ease-in-out 0s;
    }
    &:-moz-autofill {
      background: $white;
    }
    &:-moz-autofill-preview {
      background: $white;
    }
    &::-ms-clear {
      display: none;
      height: 0;
      width: 0;
    }
    &::-webkit-contacts-auto-fill-button {
      display: none !important;
      pointer-events: none;
      position: absolute;
      right: 0;
      visibility: hidden;
    }
    &::-webkit-credentials-auto-fill-button {
      display: none;
      visibility: hidden;
    }
    &::-webkit-caps-lock-indicator {
      display: none;
      visibility: hidden;
    }
    &::-ms-reveal {
      display: none;
      height: 0;
      width: 0;
    }
    &::-webkit-clear-button {
      display: none;
    }
    &::-webkit-search-cancel-button {
      display: none;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Icon */
  /* ---------------------------------------------------------------- */
  > svg {
    flex-shrink: 0;
    height: 30px;
    left: 32px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 30px;
    @media (max-width: 1536px) {
      height: 27px;
      left: 28px;
      width: 27px;
    }
    @media (max-width: 1280px) {
      height: 24px;
      left: 24px;
      width: 24px;
    }
    @media (max-width: 1024px) {
      height: 21px;
      left: 20px;
      width: 21px;
    }
    @media (max-width: 767px) {
      height: 18px;
      width: 18px;
    }
    * {
      @include fast-transition($props: 'fill, stroke');
    }
  }
  /* ---------------------------------------------------------------- */
  /* Primary Style */
  /* ---------------------------------------------------------------- */
  &.primary {
    input {
      background-color: $white;
      font-family: $primary;
      font-size: 20px;
      padding-top: 25px;
      @media (max-width: 1536px) {
        font-size: 19px;
        padding-top: 22px;
      }
      @media (max-width: 1280px) {
        font-size: 18px;
        padding-top: 20px;
      }
      @media (max-width: 1024px) {
        font-size: 17px;
        padding-top: 19px;
      }
      @media (max-width: 767px) {
        font-size: 16px;
      }
      &:-webkit-autofill {
        background: $white;
        -webkit-box-shadow: 0 0 0 1000px $white inset !important;
      }
      /* ---------------------------------------------------------------- */
      /* Hide Placeholder */
      /* ---------------------------------------------------------------- */
      &::-webkit-input-placeholder {
        opacity: 0 !important;
      }
      &:-moz-placeholder {
        opacity: 0 !important;
      }
      &::-moz-placeholder {
        opacity: 0 !important;
      }
      &:-ms-input-placeholder {
        opacity: 0 !important;
      }
      &::placeholder {
        opacity: 0 !important;
      }
      /* ---------------------------------------------------------------- */
      /* Focus */
      /* ---------------------------------------------------------------- */
      &:focus {
        border-color: $brandBlackGrape;
        @include user-select;
      }
      /* ---------------------------------------------------------------- */
      /* Hover */
      /* ---------------------------------------------------------------- */
      :global(.platform-desktop) & {
        &:hover {
          border-color: $mono3;
          /* ---------------------------------------------------------------- */
          /* Hover Focus */
          /* ---------------------------------------------------------------- */
          &:focus {
            border-color: $brandBlackGrape;
          }
        }
      }
      /* ---------------------------------------------------------------- */
      /* Read Only */
      /* ---------------------------------------------------------------- */
      &:read-only {
        background-color: $mono1;
        color: $mono4;
        pointer-events: none;
        &::selection {
          @include no-user-select;
        }
        ~ label {
          pointer-events: none;
        }
      }
    }
    /* ---------------------------------------------------------------- */
    /* Icon */
    /* ---------------------------------------------------------------- */
    > svg {
      left: 32px;
      @media (max-width: 1536px) {
        left: 28px;
      }
      @media (max-width: 1280px) {
        left: 24px;
      }
      @media (max-width: 1024px) {
        left: 20px;
      }
      + input {
        padding-left: 78px;
        @media (max-width: 1536px) {
          padding-left: 70px;
        }
        @media (max-width: 1280px) {
          padding-left: 62px;
        }
        @media (max-width: 1024px) {
          padding-left: 54px;
        }
        @media (max-width: 767px) {
          padding-left: 50px;
        }
      }
      ~ label {
        left: 79px;
        @media (max-width: 1536px) {
          left: 71px;
        }
        @media (max-width: 1280px) {
          left: 63px;
        }
        @media (max-width: 1024px) {
          left: 55px;
        }
        @media (max-width: 767px) {
          left: 51px;
        }
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Secondary Style */
  /* ---------------------------------------------------------------- */
  &.secondary {
    > svg {
      left: 0;
      + input {
        padding-left: 48px;
        @media (max-width: 1536px) {
          padding-left: 43px;
        }
        @media (max-width: 1280px) {
          padding-left: 38px;
        }
        @media (max-width: 1024px) {
          padding-left: 33px;
        }
        @media (max-width: 767px) {
          padding-left: 32px;
        }
      }
    }
    input {
      background-color: transparent;
      border-color: transparent transparent $mono2 transparent;
      font-family: $secondary;
      font-size: 32px;
      padding-right: 0;
      @media (max-width: 1536px) {
        font-size: 29px;
      }
      @media (max-width: 1280px) {
        font-size: 26px;
      }
      @media (max-width: 1024px) {
        font-size: 23px;
      }
      @media (max-width: 767px) {
        font-size: 20px;
      }
      &:-webkit-autofill {
        background: transparent;
        -webkit-box-shadow: 0 0 0 1000px transparent inset !important;
      }
      /* ---------------------------------------------------------------- */
      /* Placeholder */
      /* ---------------------------------------------------------------- */
      &::-webkit-input-placeholder {
        color: $mono4 !important;
        text-transform: inherit !important;
        @include no-user-select;
      }
      &:-moz-placeholder {
        color: $mono4 !important;
        opacity: 1 !important;
        text-transform: inherit !important;
        @include no-user-select;
      }
      &::-moz-placeholder {
        color: $mono4 !important;
        opacity: 1 !important;
        text-transform: inherit !important;
        @include no-user-select;
      }
      &:-ms-input-placeholder {
        color: $mono4 !important;
        text-transform: inherit !important;
        @include no-user-select;
      }
      &::placeholder {
        color: $mono4 !important;
        opacity: 1 !important;
        text-transform: inherit !important;
        @include no-user-select;
      }
      /* ---------------------------------------------------------------- */
      /* Focus */
      /* ---------------------------------------------------------------- */
      &:focus {
        border-bottom-color: $brandBlackGrape;
        @include user-select;
      }
      /* ---------------------------------------------------------------- */
      /* Hover */
      /* ---------------------------------------------------------------- */
      :global(.platform-desktop) & {
        &:hover {
          border-bottom-color: $mono3;
          /* ---------------------------------------------------------------- */
          /* Hover Focus */
          /* ---------------------------------------------------------------- */
          &:focus {
            border-bottom-color: $brandBlackGrape;
          }
        }
      }
      /* ---------------------------------------------------------------- */
      /* Read Only */
      /* ---------------------------------------------------------------- */
      &:read-only {
        background-color: $mono1;
        color: $mono4;
        pointer-events: none;
        ~ label {
          pointer-events: none;
        }
        &:focus {
          border-color: $mono2;
        }
        &::selection {
          @include no-user-select;
        }
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Invalid */
  /* ---------------------------------------------------------------- */
  &.invalid {
    input {
      border-color: red;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Disabled */
  /* ---------------------------------------------------------------- */
  &.disabled {
    input {
      background-color: $pageBackground;
      border-color: $mono2;
      color: $mono2;
      pointer-events: none;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Small */
  /* ---------------------------------------------------------------- */
  &.small {
    &.primary {
      input {
        font-size: 19px;
        height: 72px;
        padding: 22px 26px 0;
        @media (max-width: 1536px) {
          font-size: 18px;
          height: 64px;
          padding: 20px 22px 0;
        }
        @media (max-width: 1280px) {
          font-size: 17px;
          height: 56px;
          padding: 19px 18px 0;
        }
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Extra Small */
  /* ---------------------------------------------------------------- */
  &.extra-small {
    &.primary {
      input {
        font-size: 17px;
        height: 56px;
        padding: 19px 18px 0;
      }
    }
  }
}
