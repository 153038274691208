@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Recaptcha Message */
/* ---------------------------------------------------------------- */
.recaptchaMessage {
  display: table;
  a {
    color: $brandBerry;
    color: $brandBerry;
    @include fast-transition($props: 'color');
    &:focus {
      color: $brandBerryDark;
      text-decoration: underline;
      text-decoration-color: $brandBerryDark;
    }
    :global(.platform-desktop) & {
      &:hover {
        color: $brandBerryDark;
      }
      &:active {
        color: $productTransformation;
      }
    }
    :global(.platform-mobile) & {
      &:active {
        color: $brandBerryDark;
      }
    }
  }
}
