@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Email User Exists */
/* ---------------------------------------------------------------- */
.emailUserExists {
  display: flex;
  flex-direction: column;
  /* ---------------------------------------------------------------- */
  /* Help Tooltip */
  /* ---------------------------------------------------------------- */
  > [role='tooltip'] {
    order: 1;
  }
  /* ---------------------------------------------------------------- */
  /* Input Wrapper */
  /* ---------------------------------------------------------------- */
  > div {
    order: 2;
  }
  /* ---------------------------------------------------------------- */
  /* Error */
  /* ---------------------------------------------------------------- */
  > p {
    order: 3;
  }
  /* ---------------------------------------------------------------- */
  /* Button */
  /* ---------------------------------------------------------------- */
  > button {
    align-items: baseline;
    align-self: flex-start;
    color: $brandBlackGrape;
    cursor: pointer;
    display: flex;
    font-size: 17px;
    font-weight: $bold;
    line-height: 24px;
    margin-top: 12px;
    order: 4;
    touch-action: manipulation;
    @include fast-transition($props: 'color');
    @media (max-width: 1536px) {
      font-size: 16px;
      line-height: 23px;
      margin-top: 11px;
    }
    @media (max-width: 1408px) {
      margin-top: 10px;
    }
    @media (max-width: 1280px) {
      font-size: 15px;
      line-height: 20px;
      margin-top: 9px;
    }
    @media (max-width: 1024px) {
      margin-top: 8px;
    }
    &:focus:not(:active) {
      text-decoration: underline;
    }
    :global(.platform-desktop) & {
      &:hover,
      &:focus {
        color: darken($brandBlackGrape, 10%);
        svg {
          > path {
            fill: darken($brandBlackGrape, 10%);
          }
          transform: translate3d(2px, 0, 0);
        }
      }
      &:active {
        color: darken($brandBlackGrape, 15%);
        svg {
          > path {
            fill: darken($brandBlackGrape, 15%);
          }
          transform: translate3d(4px, 0, 0);
        }
      }
    }
    :global(.platform-mobile) & {
      &:active {
        color: darken($brandBlackGrape, 10%);
      }
    }
    /* ---------------------------------------------------------------- */
    /* Link icon */
    /* ---------------------------------------------------------------- */
    svg {
      height: 10px;
      margin-left: 5px;
      @include fast-transition($props: 'transform, fill');
    }
  }
}
