@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Label */
/* ---------------------------------------------------------------- */
.label {
  color: $mono4;
  cursor: pointer;
  display: inline-block;
  font-size: 20px;
  line-height: 28px;
  @include no-user-select;
  @include fast-transition($props: 'color');
  @media (max-width: 1536px) {
    font-size: 19px;
    line-height: 27px;
  }
  @media (max-width: 1280px) {
    font-size: 18px;
    line-height: 26px;
  }
  @media (max-width: 1024px) {
    font-size: 17px;
    line-height: 25px;
  }
  @media (max-width: 767px) {
    font-size: 16px;
    line-height: 24px;
  }
  /* ---------------------------------------------------------------- */
  /* Focus */
  /* ---------------------------------------------------------------- */
  &:focus {
    color: $brandBlackGrape;
    outline: none;
    text-decoration: underline;
  }
  /* ---------------------------------------------------------------- */
  /* Hover */
  /* ---------------------------------------------------------------- */
  :global(.platform-desktop) & {
    &:hover {
      color: $mono5;
      /* ---------------------------------------------------------------- */
      /* Hover Focus */
      /* ---------------------------------------------------------------- */
      &:focus {
        color: $brandBlackGrape;
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Link */
  /* ---------------------------------------------------------------- */
  a {
    color: inherit;
    text-decoration: underline;
    text-decoration-color: inherit;
    @include fast-transition($props: 'color');
    &:focus {
      color: $brandBerry;
      text-decoration: none;
      text-decoration-color: $brandBerry;
    }
    :global(.platform-desktop) & {
      &:hover {
        color: $brandBerry;
      }
      &:active {
        color: $brandBerryDark;
      }
    }
    :global(.platform-mobile) & {
      &:active {
        color: $brandBerry;
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Sticky Label */
  /* ---------------------------------------------------------------- */
  &.stickyLabel {
    left: 31px;
    line-height: 20px;
    pointer-events: none;
    position: absolute;
    top: 0;
    transform: translate3d(0, 30px, 0);
    @include fast-transition($props: 'transform, font-size');
    @media (max-width: 1536px) {
      left: 27px;
      transform: translate3d(0, 26px, 0);
    }
    @media (max-width: 1280px) {
      left: 23px;
      transform: translate3d(0, 22px, 0);
    }
    @media (max-width: 1024px) {
      left: 19px;
      transform: translate3d(0, 18px, 0);
    }
  }
  /* ---------------------------------------------------------------- */
  /* Small */
  /* ---------------------------------------------------------------- */
  &.small {
    &.stickyLabel {
      left: 27px;
      transform: translate3d(0, 26px, 0);
      @media (max-width: 1536px) {
        left: 23px;
        transform: translate3d(0, 22px, 0);
      }
      @media (max-width: 1280px) {
        left: 19px;
        transform: translate3d(0, 18px, 0);
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Extra Small */
  /* ---------------------------------------------------------------- */
  &.extra-small {
    &.stickyLabel {
      left: 19px;
      transform: translate3d(0, 18px, 0);
    }
  }
}
/* ---------------------------------------------------------------- */
/* Show Sticky Label */
/* ---------------------------------------------------------------- */
.isSticky.stickyLabel,
*:focus + .stickyLabel {
  font-size: 17px;
  transform: translate3d(0, 17px, 0);
  @media (max-width: 1536px) {
    font-size: 16px;
    transform: translate3d(0, 14px, 0);
  }
  @media (max-width: 1280px) {
    font-size: 15px;
    transform: translate3d(0, 11px, 0);
  }
  @media (max-width: 1024px) {
    font-size: 14px;
    transform: translate3d(0, 7px, 0);
  }
  @media (max-width: 767px) {
    font-size: 13px;
  }
}
/* ---------------------------------------------------------------- */
/* Small Show Sticky Label */
/* ---------------------------------------------------------------- */
.small.isSticky.stickyLabel,
*:focus + .small.stickyLabel {
  font-size: 16px;
  transform: translate3d(0, 14px, 0);
  @media (max-width: 1536px) {
    font-size: 15px;
    transform: translate3d(0, 11px, 0);
  }
  @media (max-width: 1280px) {
    font-size: 14px;
    transform: translate3d(0, 7px, 0);
  }
  @media (max-width: 1024px) {
    font-size: 13px;
  }
}
/* ---------------------------------------------------------------- */
/* Extra Small Show Sticky Label */
/* ---------------------------------------------------------------- */
.extra-small.isSticky.stickyLabel,
*:focus + .extra-small.stickyLabel {
  font-size: 14px;
  transform: translate3d(0, 7px, 0);
  @media (max-width: 1024px) {
    font-size: 13px;
  }
}
