@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Text w/ Subheading */
/* ---------------------------------------------------------------- */
.textWithSubheading {
  margin: 56px 0;
  @media (max-width: 1536px) {
    margin: 48px 0;
  }
  @media (max-width: 1280px) {
    margin: 40px 0;
  }
  @media (max-width: 1024px) {
    margin: 32px 0;
  }
  @media (max-width: 375px) {
    margin: 24px 0;
  }
  &:last-child {
    margin-bottom: 0;
  }
  .title {
    color: $brandBlackGrape;
    font-size: 32px;
    font-weight: $semibold;
    line-height: 40px;
    margin-bottom: 36px;
    @media (max-width: 1536px) {
      font-size: 28px;
      line-height: 36px;
      margin-bottom: 32px;
    }
    @media (max-width: 1280px) {
      font-size: 24px;
      line-height: 32px;
      margin-bottom: 28px;
    }
    @media (max-width: 375px) {
      font-size: 22px;
      line-height: 30px;
      margin-bottom: 26px;
    }
    span {
      display: block;
      margin: -7px 0 -9px;
      @media (max-width: 1536px) {
        margin: -7px 0;
      }
      @media (max-width: 375px) {
        margin: -6px 0 -7px;
      }
    }
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: $brandBlackGrape;
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 24px;
  }
  p,
  li {
    color: $brandBlackGrape;
    font-size: 17px;
    line-height: 25px;
    margin-bottom: 29px;
    @media (max-width: 1536px) {
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 28px;
    }
    @media (max-width: 1280px) {
      font-size: 15px;
      line-height: 23px;
      margin-bottom: 27px;
    }
    @media (max-width: 375px) {
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 24px;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
  img {
    display: block;
    margin: 0 auto;
    max-width: 1028px;
    width: 100%;
  }
  /* ---------------------------------------------------------------- */
  /* Strong */
  /* ---------------------------------------------------------------- */
  strong {
    font-weight: $bold;
  }
  /* ---------------------------------------------------------------- */
  /* Lists */
  /* ---------------------------------------------------------------- */
  ul {
    margin: 36px 0;
    @media (max-width: 1280px) {
      margin: 28px 0;
    }
    li {
      border-bottom: 1px solid $greyLight;
      margin-bottom: 18px;
      padding: 0 0 18px 40px;
      position: relative;
      @media (max-width: 1280px) {
        margin-bottom: 14px;
        padding: 0 0 14px 40px;
      }
      @media (max-width: 767px) {
        padding: 0 0 14px 32px;
      }
      &:last-child {
        border: none;
        padding-bottom: 0;
      }
      &::before {
        background-color: $productItil;
        content: '';
        display: block;
        height: 27px;
        left: 12px;
        margin-right: 8px;
        position: absolute;
        top: 4px;
        transform: rotate(28deg) skewY(-28deg);
        width: 4px;
        @media (max-width: 767px) {
          height: 21px;
          transform: rotate(24deg) skewY(-24deg);
          width: 2px;
        }
      }
    }
  }
  ol {
    counter-reset: ol-counter;
    display: block;
    padding-bottom: 30px;
    li {
      border-bottom: 1px solid $greyLight;
      counter-increment: ol-counter;
      display: flex;
      font-weight: $bold;
      list-style: none;
      padding: 27px 0 10px 15px;
      position: relative;
      @media (max-width: 767px) {
        font-size: 17px;
      }
      &:first-child {
        padding-top: 0;
      }
      &::before {
        content: counter(ol-counter) '.';
        display: table-cell;
        padding-right: 15px;
        text-align: right;
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Table */
  /* ---------------------------------------------------------------- */
  table {
    border-collapse: collapse;
    border-spacing: 0;
    margin: 30px auto 30px auto;
    max-width: 1028px;
    min-width: 600px;
    width: 100%;
  }
  /* ---------------------------------------------------------------- */
  /* Header Cell */
  /* ---------------------------------------------------------------- */
  th {
    border-bottom: 3px solid $productItil;
    border-left: 32px solid $pageBackground;
    border-right: 32px solid $pageBackground;
    padding: 0 24px 36px;
    vertical-align: top;
    @media (max-width: 1536px) {
      border-left-width: 24px;
      border-right-width: 24px;
      font-size: 23px;
      line-height: 32px;
      padding: 0 16px 28px;
    }
    @media (max-width: 1408px) {
      padding: 0 16px 24px;
    }
    @media (max-width: 1280px) {
      border-left-width: 16px;
      border-right-width: 16px;
      font-size: 22px;
      line-height: 31px;
      padding: 0 8px 20px;
    }
    @media (max-width: 1024px) {
      font-size: 21px;
      line-height: 30px;
    }
    @media (max-width: 896px) {
      font-size: 20px;
      line-height: 29px;
    }
    @media (max-width: 375px) {
      font-size: 19px;
      line-height: 28px;
      padding: 0 8px 16px;
    }
    &:first-child {
      border-left-width: 0;
    }
    &:last-child {
      border-right-width: 0;
    }
    p {
      font-size: 20px;
      font-style: normal;
      font-weight: $bold;
      line-height: 27px;
      margin: -8px 0 -7px;
      @media (max-width: 1024px) {
        margin: -7px 0;
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Data Cell */
  /* ---------------------------------------------------------------- */
  td {
    border-bottom: 1px solid $productItil;
    font-size: 17px;
    line-height: 25px;
    padding: 40px 24px;
    vertical-align: top;
    @media (max-width: 1536px) {
      font-size: 16px;
      line-height: 24px;
      padding: 32px 16px;
    }
    @media (max-width: 1408px) {
      padding: 28px 16px;
    }
    @media (max-width: 1280px) {
      font-size: 15px;
      line-height: 23px;
      padding: 24px 8px;
    }
    @media (max-width: 375px) {
      padding: 20px 8px;
    }
  }
  td p {
    margin: -6px 0;
  }
  /* ---------------------------------------------------------------- */
  /* Row */
  /* ---------------------------------------------------------------- */
  tr {
    &:first-child td {
      padding-top: 48px;
      @media (max-width: 1536px) {
        padding-top: 40px;
      }
      @media (max-width: 1408px) {
        padding-top: 36px;
      }
      @media (max-width: 1280px) {
        padding-top: 32px;
      }
      @media (max-width: 375px) {
        padding-top: 28px;
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Table */
  :global(.table) {
    overflow: auto;
    @media (max-width: 767px) {
      margin-left: -24px;
      margin-right: -24px;
      padding: 0 24px;
    }
    @media (max-width: 375px) {
      margin-left: -16px;
      margin-right: -16px;
      padding: 0 16px;
    }
    :global(.platform-windows) & {
      &::-webkit-scrollbar {
        -webkit-appearance: none;
        height: 12px;
      }
      &::-webkit-scrollbar-track {
        background-color: $mono1;
        border-radius: 8px;
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
      }
      &::-webkit-scrollbar-thumb {
        background-color: $brandAqua;
        border-radius: 8px;
      }
    }
  }
}
