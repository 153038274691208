@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* ACPs */
/* ---------------------------------------------------------------- */
.acps {
  /* ---------------------------------------------------------------- */
  /* All Text */
  /* ---------------------------------------------------------------- */
  p:first-child {
    margin-top: -5px;
    @media (max-width: 375px) {
      margin-top: -6px;
    }
  }
  p {
    color: $brandBlackGrape;
    font-size: 17px;
    line-height: 25px;
    @media (max-width: 1536px) {
      font-size: 16px;
      line-height: 24px;
    }
    @media (max-width: 1280px) {
      font-size: 15px;
      line-height: 23px;
    }
    @media (max-width: 375px) {
      font-size: 14px;
      line-height: 20px;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Title */
  /* ---------------------------------------------------------------- */
  h4 {
    display: block;
    font-size: 20px;
    font-weight: $bold;
    line-height: 28px;
    margin: -6px 0 8px;
    @media (max-width: 1536px) {
      font-size: 19px;
      line-height: 27px;
    }
    @media (max-width: 1280px) {
      font-size: 18px;
      line-height: 26px;
    }
    @media (max-width: 640px) {
      font-size: 17px;
      line-height: 25px;
    }
    @media (max-width: 375px) {
      font-size: 16px;
      line-height: 24px;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Tier */
  /* ---------------------------------------------------------------- */
  > p {
    display: block;
    margin: 0 0 -6px;
    padding: 0 0 20px;
    @media (max-width: 375px) {
      padding: 0 0 12px;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Products / Maturity Models */
  /* ---------------------------------------------------------------- */
  > div {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin: 0 -19px -20px;
    @media (max-width: 1536px) {
      margin: 0 -17px -20px;
    }
    @media (max-width: 1280px) {
      margin: 0 -15px -20px;
    }
    @media (max-width: 767px) {
      flex-direction: column;
    }
    @media (max-width: 375px) {
      margin-bottom: -12px;
    }
    /* ---------------------------------------------------------------- */
    /* List Container */
    /* ---------------------------------------------------------------- */
    > div {
      align-items: center;
      display: flex;
      margin: 0 19px 20px;
      @media (max-width: 1536px) {
        margin: 0 17px 20px;
      }
      @media (max-width: 1280px) {
        margin: 0 15px 20px;
      }
      @media (max-width: 767px) {
        align-items: flex-start;
        flex-direction: column;
      }
      @media (max-width: 375px) {
        margin-bottom: 12px;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
    /* ---------------------------------------------------------------- */
    /* Title */
    /* ---------------------------------------------------------------- */
    p {
      line-height: 36px;
      margin: 0 11px 0 0;
      white-space: nowrap;
      @media (max-width: 767px) {
        margin: 0;
      }
    }
    /* ---------------------------------------------------------------- */
    /* List */
    /* ---------------------------------------------------------------- */
    ul {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -6px -12px;
    }
    /* ---------------------------------------------------------------- */
    /* Name */
    /* ---------------------------------------------------------------- */
    li {
      align-items: center;
      background-color: $white;
      border: 1px solid $brandBlackGrape;
      border-radius: 26px;
      box-sizing: border-box;
      display: inline-flex;
      font-size: 14px;
      height: 36px;
      line-height: 19px;
      margin: 0 6px 12px;
      padding: 0 24px;
      white-space: nowrap;
      @include no-user-select;
      @media (max-width: 1280px) {
        padding: 0 22px;
      }
      @media (max-width: 375px) {
        font-size: 13px;
        padding: 0 18px;
      }
    }
  }
}
