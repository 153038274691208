@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* -------------------------------------------------------------- */
/* Add Candidate Skeleton */
/* -------------------------------------------------------------- */
.skeleton {
  margin-top: -4px;
  overflow: hidden;
  position: relative;
  &::after {
    @include skeleton-shimmer;
    @include skeleton-shimmer-color($color: $white);
    @media (max-width: 767px) {
      @include skeleton-shimmer-color($color: $pageBackground);
    }
  }
  /* -------------------------------------------------------------- */
  /* Title */
  /* -------------------------------------------------------------- */
  > p {
    align-items: center;
    color: rgba($brandBlackGrape, 50%);
    display: flex;
    font-family: $secondary;
    font-size: 18px;
    font-weight: $semibold;
    letter-spacing: 0.16px;
    line-height: 22px;
    margin-bottom: -5px;
    text-transform: uppercase;
    @media (max-width: 1536px) {
      font-size: 17px;
      line-height: 21px;
    }
    @media (max-width: 1280px) {
      font-size: 16px;
      line-height: 20px;
    }
    @media (max-width: 1024px) {
      font-size: 15px;
      line-height: 19px;
    }
    @media (max-width: 767px) {
      font-size: 16px;
      line-height: 20px;
    }
    &::after {
      background-color: $mono1;
      color: transparent;
      content: 'GOT A NEW CANDIDATE NUMBER';
      font-size: inherit;
      line-height: inherit;
      @include rounded-skeleton-text;
    }
  }
  /* -------------------------------------------------------------- */
  /* Input */
  /* -------------------------------------------------------------- */
  > div {
    span {
      background-color: $mono1;
      display: flex;
      height: 56px;
      width: 100%;
      @include rounded-skeleton-text($radius: '9px');
    }
  }
}

@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}
