@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Accordion */
/* ---------------------------------------------------------------- */
.accordion {
  margin: 48px 0 0;
  @media (max-width: 1536px) {
    margin: 40px 0 0;
  }
  @media (max-width: 1280px) {
    margin: 32px 0 0;
  }
  @media (max-width: 375px) {
    margin: 24px 0 0;
  }
}
