@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* -------------------------------------------------------------- */
/* Skeleton */
/* -------------------------------------------------------------- */
.skeleton {
  position: relative;
  /* -------------------------------------------------------------- */
  /* Title */
  /* -------------------------------------------------------------- */
  > span {
    display: block;
    font-size: 26px;
    line-height: 34px;
    margin: 0 0 20px;
    overflow: hidden;
    position: relative;
    @media (max-width: 1536px) {
      font-size: 24px;
      line-height: 32px;
    }
    @media (max-width: 1280px) {
      font-size: 22px;
      line-height: 30px;
      margin: 0 0 16px;
    }
    @media (max-width: 767px) {
      font-size: 21px;
      line-height: 29px;
      margin: 0 0 20px;
    }
    @media (max-width: 576px) {
      margin: 0 0 16px;
    }
    @media (max-width: 448px) {
      font-size: 20px;
      line-height: 28px;
    }
    @media (max-width: 375px) {
      margin: 0 0 12px;
    }
    &::before {
      @include skeleton-shimmer;
      @include skeleton-shimmer-color($color: $pageBackground);
    }
    &::after {
      background-color: $mono1;
      color: transparent;
      content: 'Lorem ipsum dolor sit amet,';
      font-size: inherit;
      line-height: inherit;
      @include rounded-skeleton-text;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Description */
  /* ---------------------------------------------------------------- */
  > p {
    font-size: 17px;
    line-height: 25px;
    margin-bottom: 24px;
    overflow: hidden;
    position: relative;
    @media (max-width: 1536px) {
      font-size: 16px;
      line-height: 24px;
    }
    @media (max-width: 1280px) {
      font-size: 15px;
      line-height: 23px;
    }
    @media (max-width: 767px) {
      margin-bottom: 16px;
    }
    &::before {
      @include skeleton-shimmer;
      @include skeleton-shimmer-color($color: $pageBackground);
    }
    &::after {
      background-color: $mono1;
      color: transparent;
      /* stylelint-disable-next-line max-line-length */
      content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore';
      font-size: inherit;
      line-height: inherit;
      @include rounded-skeleton-text;
    }
    &:nth-child(3)::after {
      /* stylelint-disable-next-line max-line-length */
      content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore. Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore. Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore. Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore. Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore. Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore. Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore. Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore. Lorem ipsum dolor sit amet';
    }
    &:last-child::after {
      /* stylelint-disable-next-line max-line-length */
      content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore. Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore. Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore. Lorem ipsum dolor sit amet';
    }
  }
}

@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}
