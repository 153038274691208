@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Content Type List Skeleton */
/* ---------------------------------------------------------------- */
.skeleton {
  overflow: hidden;
  padding-bottom: 36px;
  position: relative;
  @media (max-width: 1280px) {
    padding-bottom: 32px;
  }
  @media (max-width: 1024px) {
    padding-bottom: 24px;
  }
  @media (max-width: 767px) {
    padding-bottom: 32px;
  }
  @media (max-width: 375px) {
    padding-bottom: 24px;
  }
  &:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
  }
  &::after {
    @include skeleton-shimmer;
    @include skeleton-shimmer-color($color: $pageBackground);
  }
  svg {
    height: 100%;
    width: 100%;
    * {
      fill: $mono1;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Title */
  /* ---------------------------------------------------------------- */
  > span {
    display: block;
    font-family: $secondary;
    font-size: 32px;
    font-weight: $semibold;
    line-height: 40px;
    margin: -8px 0;
    padding-bottom: 36px;
    padding-right: 24px;
    @media (max-width: 1664px) {
      font-size: 29px;
      line-height: 36px;
      margin: -7px 0 -8px;
    }
    @media (max-width: 1536px) {
      font-size: 26px;
      line-height: 33px;
      margin: -6px 0 -7px;
    }
    @media (max-width: 1408px) {
      font-size: 23px;
      line-height: 29px;
      margin: -5px 0 -7px;
    }
    @media (max-width: 1280px) {
      font-size: 20px;
      line-height: 26px;
      margin: -5px 0 -6px;
      padding-bottom: 32px;
    }
    @media (max-width: 1024px) {
      padding-bottom: 24px;
    }
    @media (max-width: 375px) {
      font-size: 19px;
      line-height: 25px;
    }
    &::after {
      background-color: $mono1;
      color: transparent;
      content: 'Tutorials';
      font-size: inherit;
      line-height: inherit;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Content Type Card Container */
  /* ---------------------------------------------------------------- */
  > ul {
    position: relative;
    z-index: 1;
    /* ---------------------------------------------------------------- */
    /* Item */
    /* ---------------------------------------------------------------- */
    > li {
      display: grid;
      grid-auto-rows: 1fr;
      grid-row-gap: 24px;
      width: 100%;
      @media (max-width: 1280px) {
        grid-row-gap: 20px;
      }
      @media (max-width: 1024px) {
        grid-row-gap: 16px;
      }
      @media (max-width: 896px) and (min-width: 768px), (max-width: 576px) {
        grid-auto-rows: auto;
        grid-row-gap: 12px;
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* CTA Container */
  /* ---------------------------------------------------------------- */
  > div:last-child {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
    position: relative;
    @media (max-width: 896px) and (min-width: 768px), (max-width: 576px) {
      margin-top: 16px;
    }
    > span {
      background-color: $mono1;
      height: 48px;
      width: 198px;
    }
  }
}
/* ---------------------------------------------------------------- */
/* Content Type Item Skeleton */
/* ---------------------------------------------------------------- */
.skeletonItem {
  background-color: $white;
  display: flex;
  overflow: hidden;
  position: relative;
  width: 100%;
  @media (max-width: 896px) and (min-width: 768px), (max-width: 576px) {
    background-color: transparent;
    box-shadow: none;
    flex-direction: column;
  }
  &::after {
    @include skeleton-shimmer;
    @include skeleton-shimmer-color($color: $white);
    @media (max-width: 896px) and (min-width: 768px), (max-width: 576px) {
      background-image: linear-gradient(
        90deg,
        rgba($pageBackground, 0) 0,
        rgba($pageBackground, 0.5) 20%,
        rgba($pageBackground, 0.8) 60%,
        rgba($pageBackground, 0)
      );
    }
  }
  /* ---------------------------------------------------------------- */
  /* Image */
  /* ---------------------------------------------------------------- */
  > span {
    background-color: $mono1;
    display: block;
    flex-shrink: 0;
    min-height: 194px;
    overflow: hidden;
    position: relative;
    width: 28%;
    @media (max-width: 1664px) {
      width: 32%;
    }
    @media (max-width: 1536px) {
      width: 36%;
    }
    @media (max-width: 1408px) {
      width: 40%;
    }
    @media (max-width: 1280px) {
      width: 45%;
    }
    @media (max-width: 896px) and (min-width: 768px), (max-width: 576px) {
      height: 208px;
      min-height: 0;
      width: 100%;
    }
    @media (max-width: 375px) {
      height: 175px;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Content */
  /* ---------------------------------------------------------------- */
  &:last-child > div {
    @media (max-width: 896px) and (min-width: 768px), (max-width: 576px) {
      padding-bottom: 0;
    }
  }
  > div {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 32px;
    width: 100%;
    @media (max-width: 1536px) {
      padding: 28px;
    }
    @media (max-width: 1280px) {
      padding: 24px;
    }
    @media (max-width: 1024px) {
      padding: 20px;
    }
    @media (max-width: 896px) and (min-width: 768px), (max-width: 576px) {
      padding: 20px 0 8px;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Header - Publications + Social Container */
  /* ---------------------------------------------------------------- */
  header {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: -9px -8px 0;
    @media (max-width: 1536px) {
      margin: -7px -7px 0;
    }
    @media (max-width: 1280px) {
      margin: -7px -6px 0;
    }
    @media (max-width: 1024px) {
      margin: -6px -4px 0;
    }
    @media (max-width: 896px) and (min-width: 768px), (max-width: 576px) {
      margin-top: -7px;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Publications Text + Social Container */
  /* ---------------------------------------------------------------- */
  .skeletonSocial,
  .publication > span {
    margin: 6px 8px 5px;
    @media (max-width: 1536px) {
      margin: 5px 7px;
    }
    @media (max-width: 1280px) {
      margin: 5px 6px 4px;
    }
    @media (max-width: 1024px) {
      margin: 4px 5px;
    }
  }
  .skeletonSocial {
    @media (max-width: 896px) and (min-width: 768px), (max-width: 576px) {
      margin: 0 0 8px;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Publication */
  /* ---------------------------------------------------------------- */
  .publication {
    color: rgba($color: $mono5, $alpha: 0.7);
    display: flex;
    flex-wrap: wrap;
    font-size: 17px;
    line-height: 25px;
    @media (max-width: 1536px) {
      font-size: 16px;
      line-height: 24px;
    }
    @media (max-width: 1280px) {
      font-size: 15px;
      line-height: 23px;
    }
    @media (max-width: 1024px) {
      font-size: 14px;
      line-height: 22px;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Copy Container */
  /* ---------------------------------------------------------------- */
  .copy {
    margin-top: 21px;
    @media (max-width: 1536px) {
      margin-top: 17px;
    }
    @media (max-width: 1280px) {
      margin-top: 13px;
    }
    @media (max-width: 1024px) {
      margin-top: 14px;
    }
    @media (max-width: 896px) and (min-width: 768px), (max-width: 576px) {
      margin-top: 10px;
    }
    @media (max-width: 767px) {
      margin-top: 15px;
    }
    @media (max-width: 576px) {
      margin-top: 10px;
    }
    @media (max-width: 375px) {
      margin-top: 11px;
    }
  }
  .publication > span {
    height: 17px;
    line-height: 17px;
    white-space: nowrap;
    @media (max-width: 1536px) {
      height: 16px;
      line-height: 16px;
    }
    @media (max-width: 1280px) {
      height: 15px;
      line-height: 15px;
    }
    @media (max-width: 1024px) {
      height: 14px;
      line-height: 14px;
    }
    @media (max-width: 896px) and (min-width: 768px), (max-width: 576px) {
      height: 15px;
      line-height: 15px;
    }
    &:nth-child(1) {
      &::after {
        content: 'July 8, 2021';
      }
    }
    &:nth-child(2) {
      color: $brandBerry;
      &::after {
        content: '18 min read';
      }
    }
    &::after {
      background: $mono1;
      color: transparent;
      font-size: inherit;
      line-height: inherit;
      @include rounded-skeleton-text;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Title */
  /* ---------------------------------------------------------------- */
  .headingThree {
    display: block;
    font-family: $secondary;
    font-size: 20px;
    font-weight: $bold;
    line-height: 28px;
    margin-bottom: 11px;
    margin-top: -6px;
    position: relative;
    @media (max-width: 1536px) {
      font-size: 19px;
      line-height: 27px;
      margin-bottom: 7px;
    }
    @media (max-width: 1280px) {
      font-size: 18px;
      line-height: 26px;
      margin-bottom: 5px;
    }
    @media (max-width: 1024px) {
      font-size: 17px;
      line-height: 25px;
      margin-bottom: 3px;
    }
    @media (max-width: 896px) and (min-width: 768px), (max-width: 576px) {
      font-family: $primary;
      font-weight: $bold;
    }
    @media (max-width: 767px) {
      font-size: 16px;
      line-height: 24px;
    }
    @media (max-width: 480px) {
      font-size: 15px;
      line-height: 23px;
    }
    @media (max-width: 375px) {
      font-size: 14px;
      line-height: 21px;
    }
    &:last-child {
      margin-bottom: -7px;
    }
    &::after {
      background-color: $mono1;
      color: transparent;
      content: 'Lorem ipsum dolor sit amet adipiscing elit.';
      font-size: inherit;
      line-height: inherit;
      @include rounded-skeleton-text;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Excerpt */
  /* ---------------------------------------------------------------- */
  .copy > p {
    color: $brandBlackGrape;
    display: block;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: -6px;
    position: relative;
    @media (max-width: 1536px) {
      font-size: 16px;
      line-height: 24px;
    }
    @media (max-width: 1280px) {
      font-size: 15px;
      line-height: 23px;
    }
    @media (max-width: 1024px) {
      font-size: 14px;
      line-height: 21px;
      margin-bottom: -5px;
    }
    @media (max-width: 896px) and (min-width: 768px), (max-width: 576px) {
      display: none;
    }
    &::after {
      background-color: $mono1;
      color: transparent;
      /* stylelint-disable-next-line max-line-length */
      content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod ut labore. Lorem ipsum dolor sit amet, consectetur tempor incididunt ut labore.';
      font-size: inherit;
      line-height: inherit;
      @include rounded-skeleton-text;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Footer - Cta Container */
  /* ---------------------------------------------------------------- */
  &:last-child footer {
    @media (max-width: 896px) and (min-width: 768px), (max-width: 576px) {
      margin-bottom: -8px;
    }
    @media (max-width: 350px) {
      margin-bottom: -12px;
    }
  }
  footer {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: auto 0 0;
    padding-top: 28px;
    @media (max-width: 1536px) {
      padding-top: 24px;
    }
    @media (max-width: 1280px) {
      padding-top: 20px;
    }
    @media (max-width: 896px) and (min-width: 768px), (max-width: 576px) {
      margin-left: -16px;
      margin-right: -16px;
      padding-top: 21px;
    }
    @media (max-width: 375px) {
      padding-top: 19px;
    }
    @media (max-width: 350px) {
      align-items: flex-start;
      flex-direction: column;
      padding-top: 20px;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Hyperlink */
  /* ---------------------------------------------------------------- */
  .hyperlink {
    display: table;
    font-family: $secondary;
    font-size: 17px;
    font-weight: $bold;
    line-height: 20px;
    margin: -3px 0 -4px;
    min-width: 0;
    text-decoration: none;
    @media (max-width: 1536px) {
      font-size: 16px;
      line-height: 19px;
    }
    @media (max-width: 1280px) {
      font-size: 15px;
      line-height: 18px;
    }
    @media (max-width: 1024px) {
      font-size: 14px;
      line-height: 17px;
    }
    @media (max-width: 896px) and (min-width: 768px), (max-width: 576px) {
      font-size: 17px;
      line-height: 20px;
      margin: 0 0 8px;
    }
    @media (max-width: 480px) {
      font-size: 16px;
      line-height: 19px;
    }
    @media (max-width: 375px) {
      font-size: 15px;
      line-height: 18px;
    }
    &::after {
      background-color: $mono1;
      color: transparent;
      content: 'Read more';
      font-size: inherit;
      line-height: inherit;
      @include rounded-skeleton-text;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Social Mobile + Hyperlink */
  /* ---------------------------------------------------------------- */
  .skeletonSocial,
  .hyperlink {
    @media (max-width: 896px) and (min-width: 768px), (max-width: 576px) {
      margin-left: 16px;
      margin-right: 16px;
    }
  }
}
/* ---------------------------------------------------------------- */
/* Social Skeleton */
/* ---------------------------------------------------------------- */
.skeletonSocial {
  align-items: center;
  display: flex;
  font-weight: $semibold;
  height: 17px;
  margin: 0;
  white-space: nowrap;
  @media (max-width: 1536px) {
    height: 16px;
  }
  @media (max-width: 1280px) {
    height: 15px;
  }
  @media (max-width: 1024px) {
    height: 14px;
  }
  @media (max-width: 896px) and (min-width: 768px), (max-width: 576px) {
    height: 17px;
    margin-right: 20px;
  }
  @media (max-width: 480px) {
    height: 16px;
  }
  @media (max-width: 375px) {
    height: 15px;
  }
  /* ---------------------------------------------------------------- */
  /* Bookmark */
  /* ---------------------------------------------------------------- */
  .bookmark {
    display: flex;
    margin-right: 12px;
    @media (max-width: 1280px) {
      margin-right: 10px;
    }
    @media (max-width: 1024px) {
      margin-right: 8px;
    }
    @media (max-width: 896px) and (min-width: 768px), (max-width: 576px) {
      margin-right: 16px;
    }
    @media (max-width: 480px) {
      margin-right: 14px;
    }
    @media (max-width: 375px) {
      margin-right: 12px;
    }
    /* ---------------------------------------------------------------- */
    /* Button + Icon */
    /* ---------------------------------------------------------------- */
    span {
      background-color: $mono1;
      clip-path: polygon(100% 0%, 100% 100%, 50% 70%, 0 100%, 0 0);
      height: 17px;
      transform: translateZ(0);
      width: 13px;
      @media (max-width: 1536px) {
        height: 16px;
        width: 13px;
      }
      @media (max-width: 1280px) {
        height: 15px;
        width: 12px;
      }
      @media (max-width: 1024px) {
        height: 14px;
        width: 11px;
      }
      @media (max-width: 896px) and (min-width: 768px), (max-width: 576px) {
        height: 17px;
        width: 13px;
      }
      @media (max-width: 480px) {
        height: 16px;
        width: 13px;
      }
      @media (max-width: 375px) {
        height: 15px;
        width: 12px;
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Likes */
  /* ---------------------------------------------------------------- */
  .likes {
    align-items: center;
    display: flex;
    font-size: 17px;
    font-weight: $semibold;
    @media (max-width: 1536px) {
      font-size: 16px;
    }
    @media (max-width: 1280px) {
      font-size: 15px;
    }
    @media (max-width: 1024px) {
      font-size: 14px;
    }
    @media (max-width: 896px) and (min-width: 768px), (max-width: 576px) {
      font-size: 17px;
    }
    @media (max-width: 480px) {
      font-size: 16px;
    }
    @media (max-width: 375px) {
      font-size: 15px;
    }
    /* ---------------------------------------------------------------- */
    /* Button + Icon */
    /* ---------------------------------------------------------------- */
    span:nth-child(1) {
      backface-visibility: hidden;
      background-color: transparent;
      height: 17px;
      margin-right: 8px;
      transform: translateZ(0);
      width: 19px;
      @media (max-width: 1536px) {
        height: 16px;
        width: 18px;
      }
      @media (max-width: 1280px) {
        height: 15px;
        margin-right: 7px;
        width: 17px;
      }
      @media (max-width: 1024px) {
        height: 14px;
        width: 16px;
      }
      @media (max-width: 896px) and (min-width: 768px), (max-width: 576px) {
        height: 17px;
        margin-right: 12px;
        width: 19px;
      }
      @media (max-width: 480px) {
        height: 16px;
        margin-right: 10px;
        width: 18px;
      }
      @media (max-width: 375px) {
        height: 15px;
        margin-right: 8px;
        width: 17px;
      }
      &:only-child {
        margin-right: 0;
      }
    }
    /* ---------------------------------------------------------------- */
    /* Text */
    /* ---------------------------------------------------------------- */
    > span:nth-child(2) {
      &::after {
        background-color: $mono1;
        color: transparent;
        content: '4 Likes';
        font-size: inherit;
        line-height: 17px;
        @include rounded-skeleton-text;
        @media (max-width: 1536px) {
          line-height: 16px;
        }
      }
    }
  }
}

@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}
