@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Programme */
/* ---------------------------------------------------------------- */
.programme {
  padding-bottom: 36px;
  @media (max-width: 1280px) {
    padding-bottom: 32px;
  }
  @media (max-width: 1024px) {
    padding-bottom: 24px;
  }
  @media (max-width: 767px) {
    padding-bottom: 32px;
  }
  @media (max-width: 375px) {
    padding-bottom: 24px;
  }
  &:last-child {
    padding-bottom: 0;
  }
  /* ---------------------------------------------------------------- */
  /* Title */
  /* ---------------------------------------------------------------- */
  > h2 {
    align-items: center;
    color: $brandBlackGrape;
    display: flex;
    flex-wrap: wrap;
    font-family: $secondary;
    font-size: 32px;
    font-weight: $semibold;
    line-height: 39px;
    position: relative;
    @include medium-transition($mob: true, $props: 'margin-bottom');
    @media (max-width: 1536px) {
      font-size: 30px;
      line-height: 36px;
    }
    @media (max-width: 1280px) {
      font-size: 27px;
      line-height: 33px;
    }
    @media (max-width: 1024px) {
      font-size: 24px;
      line-height: 30px;
    }
    @media (max-width: 896px) {
      font-size: 20px;
      line-height: 26px;
    }
    @media (max-width: 767px) {
      font-size: 17px;
      line-height: 21px;
    }
    /* ---------------------------------------------------------------- */
    /* Logo */
    /* ---------------------------------------------------------------- */
    > svg {
      flex-shrink: 0;
      height: 40px;
      width: 47px;
      @media (max-width: 1536px) {
        height: 36px;
        width: 42px;
      }
      @media (max-width: 1280px) {
        height: 32px;
        width: 38px;
      }
      @media (max-width: 767px) {
        height: 26px;
        width: 31px;
      }
    }
    /* ---------------------------------------------------------------- */
    /* Text Wrapper */
    /* ---------------------------------------------------------------- */
    > span {
      box-sizing: border-box;
      display: block;
      margin: -7px 0 -8px;
      padding: 0 24px 0 22px;
      width: calc(100% - 87px);
      @media (max-width: 1536px) {
        margin: -6px 0 -8px;
        padding: 0 20px 0 18px;
        width: calc(100% - 78px);
      }
      @media (max-width: 1280px) {
        margin: -6px 0 -7px;
        padding: 0 16px 0 14px;
        width: calc(100% - 70px);
      }
      @media (max-width: 1024px) {
        margin: -5px 0 -7px;
      }
      @media (max-width: 896px) {
        margin-bottom: -6px;
      }
      @media (max-width: 767px) {
        padding: 0 16px 0 12px;
        width: calc(100% - 63px);
      }
      @media (max-width: 375px) {
        padding: 0 12px 0 10px;
      }
    }
    /* -------------------------------------------------------------- */
    /* Button */
    /* -------------------------------------------------------------- */
    > button {
      background-color: $mono1;
      border-radius: 50%;
      cursor: pointer;
      display: inline-block;
      flex-shrink: 0;
      height: 40px;
      position: relative;
      right: 0;
      touch-action: manipulation;
      width: 40px;
      @media (max-width: 1536px) {
        height: 36px;
        width: 36px;
      }
      @media (max-width: 1280px) {
        height: 32px;
        width: 32px;
      }
      /* Focus Ring */
      &:focus::before {
        border-color: $mono3;
      }
      /* Focus Ring */
      &::before {
        border-color: transparent;
        border-radius: 50%;
        border-style: dashed;
        border-width: 2px;
        box-sizing: border-box;
        content: '';
        height: calc(100% + 12px);
        left: -6px;
        pointer-events: none;
        position: absolute;
        top: -6px;
        width: calc(100% + 12px);
        @include fast-transition($props: 'border-color');
        @media (max-width: 1280px) {
          height: calc(100% + 10px);
          left: -5px;
          top: -5px;
          width: calc(100% + 10px);
        }
      }
      /* Plus / Minus Icons */
      span {
        box-sizing: border-box;
        display: block;
        height: 16px;
        left: 12px;
        margin-top: -8px;
        position: absolute;
        top: 50%;
        transform: rotate(0deg);
        width: 16px;
        will-change: transform;
        @include fast-transition($mob: true, $props: 'transform');
        @media (max-width: 1536px) {
          left: 10px;
          margin-top: -7px;
        }
        @media (max-width: 1280px) {
          height: 14px;
          left: 9px;
          margin-top: -6px;
          width: 14px;
        }
        &::before,
        &::after {
          border-bottom: solid 2px $mono3;
          bottom: 7px;
          box-sizing: border-box;
          content: '';
          display: block;
          height: 0;
          position: absolute;
          width: 16px;
          will-change: width, transform;
          @include fast-transition($mob: true, $props: 'width, transform');
          @media (max-width: 1280px) {
            bottom: 6px;
            width: 14px;
          }
        }
        &::before {
          transform: rotate(90deg);
          width: 0;
        }
      }
    }
    /* ---------------------------------------------------------------- */
    /* Border */
    /* ---------------------------------------------------------------- */
    &::after {
      background-color: rgba($brandBlackGrape, 0.2);
      content: '';
      display: block;
      height: 1px;
      margin-top: 24px;
      position: relative;
      width: 100%;
      @media (max-width: 1536px) {
        margin-top: 20px;
      }
      @media (max-width: 1280px) {
        margin-top: 16px;
      }
    }
  }
  /* -------------------------------------------------------------- */
  /* Content */
  /* -------------------------------------------------------------- */
  > div:nth-child(2) {
    opacity: 1;
    padding: 0;
    transform: scaleY(1);
    transform-origin: 0 0;
    visibility: visible;
    will-change: height, opacity, transform;
    @include medium-transition(
      $firefox: false,
      $mob: true,
      $props: 'opacity, height, transform'
    );
    > div {
      padding: 36px 0 0;
      @media (max-width: 1280px) {
        padding: 32px 0 0;
      }
      @media (max-width: 1024px) {
        padding: 24px 0 0;
      }
      @media (max-width: 767px) {
        padding: 32px 0 0;
      }
      @media (max-width: 375px) {
        padding: 24px 0 0;
      }
    }
  }
  /* -------------------------------------------------------------- */
  /* Closed */
  /* -------------------------------------------------------------- */
  &.closed {
    /* ------------------------------------------------------------ */
    /* Prevent Content Children From Being Focused */
    /* ------------------------------------------------------------ */
    &:not(.animating) > div:nth-child(2) > * {
      visibility: hidden;
    }
    /* ------------------------------------------------------------ */
    /* Content */
    /* ------------------------------------------------------------ */
    > div:nth-child(2) {
      height: 0;
      opacity: 0;
      padding-bottom: 0;
      transform: scaleY(0.6);
    }
    /* ------------------------------------------------------------ */
    /* Button */
    /* ------------------------------------------------------------ */
    > h2 > button {
      span {
        transform: rotate(180deg);
        &::before {
          width: 16px;
          @media (max-width: 1280px) {
            width: 14px;
          }
        }
        &::after,
        &::before {
          @media (max-width: 1280px) {
            bottom: 5px;
          }
        }
      }
    }
  }
  &.CPDProgrammeType-0 {
    h2 svg path {
      fill: $productItil;
    }
  }
  &.CPDProgrammeType-1 {
    h2 svg path {
      fill: $productProPath;
    }
  }
  &.CPDProgrammeType-2 {
    h2 svg path {
      fill: #f35289;
    }
  }
  &.CPDProgrammeType-3 {
    h2 svg path {
      fill: #6d0c31;
    }
  }
}
