@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* -------------------------------------------------------------- */
/* Key Templates Skeleton */
/* -------------------------------------------------------------- */
.skeleton {
  display: flex;
  flex-direction: column;
  margin-bottom: 48px;
  overflow: hidden;
  position: relative;
  @media (max-width: 1408px) {
    margin-bottom: 44px;
  }
  @media (max-width: 1024px) {
    margin-bottom: 40px;
  }
  @media (max-width: 767px) {
    margin-bottom: 32px;
  }
  @media (max-width: 375px) {
    margin-bottom: 24px;
  }
  &:last-child {
    margin-bottom: 0;
  }
  &::after {
    @include skeleton-shimmer;
    @include skeleton-shimmer-color($color: $white);
    @media (max-width: 767px) {
      @include skeleton-shimmer-color($color: $pageBackground);
    }
  }
  /* -------------------------------------------------------------- */
  /* Title */
  /* -------------------------------------------------------------- */
  > span {
    align-items: center;
    display: flex;
    font-family: $secondary;
    font-size: 18px;
    font-weight: $semibold;
    line-height: 22px;
    margin-bottom: -5px;
    margin-top: 0;
    text-transform: uppercase;
    @media (max-width: 1536px) {
      font-size: 17px;
      line-height: 21px;
    }
    @media (max-width: 1280px) {
      font-size: 16px;
      line-height: 20px;
    }
    @media (max-width: 1024px) {
      font-size: 15px;
      line-height: 19px;
    }
    @media (max-width: 767px) {
      font-size: 16px;
      line-height: 20px;
    }
    &::after {
      background-color: $mono1;
      color: transparent;
      content: 'Key Templates';
      font-size: inherit;
      line-height: inherit;
      text-transform: inherit;
      @include rounded-skeleton-text;
    }
  }
  /* -------------------------------------------------------------- */
  /* Key Template Container */
  /* -------------------------------------------------------------- */
  ul {
    background-color: $white;
    border: 1px solid $mono1;
    border-radius: 4px;
    margin-top: 24px;
    overflow: hidden;
    position: relative;
    z-index: 1;
    @media (max-width: 1536px) {
      margin-top: 20px;
    }
    @media (max-width: 1280px) {
      margin-top: 16px;
    }
    @media (max-width: 1152px) and (min-width: 768px), (max-width: 360px) {
      background-color: transparent;
      border: none;
      border-radius: 0;
    }
    &::after {
      @include skeleton-shimmer;
      @include skeleton-shimmer-color($color: $white);
      @media (max-width: 360px) {
        @include skeleton-shimmer-color($color: $pageBackground);
      }
    }
    li {
      p::after {
        content: 'ITIL4 Framework Template';
      }
      > div:nth-of-type(1) > span::after {
        content: 'Template';
      }
      &:nth-child(2) {
        p::after {
          content: 'ITIL4 Different Practice';
        }
        > div:nth-of-type(1) > span::after {
          content: 'Practice';
        }
      }
    }
  }
}
/* -------------------------------------------------------------- */
/* Key Templates Item Skeleton */
/* -------------------------------------------------------------- */
.skeletonItem {
  border-bottom: solid 1px $mono1;
  display: flex;
  padding: 28px 32px;
  position: relative;
  @media (max-width: 1536px) {
    padding: 24px 28px;
  }
  @media (max-width: 1280px) {
    padding: 20px 24px;
  }
  @media (max-width: 1152px) and (min-width: 768px), (max-width: 360px) {
    padding: 20px 0;
  }
  &:last-child {
    border: none;
    @media (max-width: 1152px) and (min-width: 768px), (max-width: 360px) {
      border-bottom: solid 1px $mono1;
    }
  }
  @media (max-width: 1152px) and (min-width: 768px), (max-width: 360px) {
    &:first-child {
      border-top: solid 1px $mono1;
    }
  }
  /* -------------------------------------------------------------- */
  /* Icon */
  /* -------------------------------------------------------------- */
  > span {
    background-color: $mono1;
    flex-shrink: 0;
    height: 36px;
    margin-right: 16px;
    width: 36px;
    @include rounded-skeleton-text($radius: '10px');
    @media (max-width: 1536px) {
      height: 32px;
      margin-right: 12px;
      width: 32px;
    }
    @media (max-width: 1280px) {
      height: 28px;
      width: 28px;
    }
    @media (max-width: 1024px) and (min-width: 768px), (max-width: 360px) {
      display: none;
    }
  }
  /* -------------------------------------------------------------- */
  /* Content Container */
  /* -------------------------------------------------------------- */
  > div:nth-of-type(1) {
    flex: 1;
    font-size: 18px;
    line-height: 22px;
    margin: -4px 0;
    @media (max-width: 1536px) {
      font-size: 17px;
      line-height: 21px;
    }
    @media (max-width: 1280px) {
      font-size: 16px;
      line-height: 20px;
    }
    @media (max-width: 1024px) {
      font-size: 15px;
      line-height: 19px;
    }
    *::after {
      @include rounded-skeleton-text;
    }
  }
  /* -------------------------------------------------------------- */
  /* Text */
  /* -------------------------------------------------------------- */
  p {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    font-size: 18px;
    font-weight: $semibold;
    line-height: 22px;
    margin: 0 0 7px;
    @media (max-width: 1536px) {
      font-size: 17px;
      line-height: 21px;
      margin: 0 0 4px;
    }
    @media (max-width: 1280px) {
      font-size: 16px;
      line-height: 20px;
    }
    @media (max-width: 1024px) {
      font-size: 15px;
      line-height: 19px;
    }
    &::after {
      background-color: $mono1;
      color: transparent;
      font-size: inherit;
      line-height: inherit;
    }
  }
  /* -------------------------------------------------------------- */
  /* Category Text */
  /* -------------------------------------------------------------- */
  > div:nth-of-type(1) > span {
    display: flex;
    flex-wrap: wrap;
    font-size: 18px;
    line-height: 22px;
    @media (max-width: 1536px) {
      font-size: 17px;
      line-height: 21px;
    }
    @media (max-width: 1280px) {
      font-size: 16px;
      line-height: 20px;
    }
    @media (max-width: 1024px) {
      font-size: 15px;
      line-height: 19px;
    }
    &::after {
      background-color: $mono1;
      color: transparent;
      font-size: inherit;
      line-height: inherit;
    }
  }
  /* -------------------------------------------------------------- */
  /* Cta Container */
  /* -------------------------------------------------------------- */
  > div:nth-of-type(2) {
    display: flex;
    /* -------------------------------------------------------------- */
    /* Link */
    /* -------------------------------------------------------------- */
    > span {
      background-color: $mono1;
      height: 48px;
      margin-left: 24px;
      width: 88px;
      @include rounded-skeleton-text;
    }
  }
}

@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}
