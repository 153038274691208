@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Footer */
/* ---------------------------------------------------------------- */
.footer {
  background-color: $brandBlackGrape;
  display: block;
  height: auto;
  margin: auto 0 0 0;
  overflow: hidden;
  padding: 72px 0 72px 0;
  position: relative;
  width: 100%;
  @media (max-width: 1664px) {
    padding: 64px 0 64px 0;
  }
  @media (max-width: 1536px) {
    padding: 56px 0 56px 0;
  }
  @media (max-width: 1408px) {
    padding: 48px 0 48px 0;
  }
  @media (max-width: 1280px) {
    padding: 32px 0 32px 0;
  }
  @media (max-width: 767px) {
    padding: 32px 0 32px 0;
  }
  @media (max-width: 375px) {
    padding: 24px 0 24px 0;
  }
  :global(.container) {
    position: relative;
    z-index: 3;
  }
  /* ---------------------------------------------------------------- */
  /* 63 Deg Overlay */
  /* ---------------------------------------------------------------- */
  > span {
    animation: fade-slice 0.35s ease-in-out;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    left: 0;
    max-width: 376px;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 2;
    @media (max-width: 767px) {
      height: 334px;
      max-width: 170px;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Container */
  /* ---------------------------------------------------------------- */
  > div > div {
    /* ---------------------------------------------------------------- */
    /* Top Footer Section */
    /* ---------------------------------------------------------------- */
    &:first-child {
      align-items: center;
      display: flex;
      flex-direction: row;
      position: relative;
      @media (max-width: 576px) {
        align-items: flex-start;
        flex-direction: column;
      }
      /* ---------------------------------------------------------------- */
      /* Site Logo */
      /* ---------------------------------------------------------------- */
      > a[href] {
        z-index: 1;
        &:focus {
          outline: 2px dashed $white;
          outline-offset: 4px;
        }
        svg {
          path:first-of-type,
          path:last-of-type {
            fill: $white;
          }
          path {
            fill: rgb(83 87 100);
          }
        }
      }
    }
    /* ---------------------------------------------------------------- */
    /* Bottom Footer Section  */
    /* ---------------------------------------------------------------- */
    &:last-child {
      align-items: center;
      display: flex;
      justify-content: space-between;
      margin-top: 72px;
      position: relative;
      @media (max-width: 1664px) {
        margin-top: 64px;
      }
      @media (max-width: 1536px) {
        margin-top: 56px;
      }
      @media (max-width: 1408px) {
        margin-top: 48px;
      }
      @media (max-width: 1280px) {
        margin-top: 32px;
      }
      @media (max-width: 1024px) {
        margin-top: 24px;
      }
      @media (max-width: 895px) {
        align-items: flex-start;
        flex-direction: column;
        margin-top: 36px;
      }
      @media (max-width: 767px) {
        margin-top: 48px;
      }
      @media (max-width: 520px) {
        margin-top: 24px;
      }
    }
  }
}

@keyframes fade-slice {
  from {
    opacity: 0;
  }
}
