@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Programme */
/* ---------------------------------------------------------------- */
.badge {
  background-color: $white;
  border: 1px solid transparent;
  border-radius: 4px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: 32px 24px 24px 24px;
  position: relative;
  width: 100%;
  @media (max-width: 1536px) {
    font-size: 16px;
    line-height: 20px;
    padding: 28px 20px 20px 20px;
  }
  @media (max-width: 1280px) {
    font-size: 15px;
    line-height: 19px;
    padding: 20px;
  }
  @media (max-width: 1024px) and (min-width: 768px), (max-width: 375px) {
    font-size: 14px;
    line-height: 18px;
    padding: 16px;
  }
  /* ---------------------------------------------------------------- */
  /* Title */
  /* ---------------------------------------------------------------- */
  h4 {
    color: $brandBlackGrape;
    display: flex;
    flex-wrap: wrap;
    font-size: 18px;
    font-weight: $semibold;
    height: 100%;
    line-height: 22px;
    @media (max-width: 1536px) {
      font-size: 17px;
      line-height: 21px;
    }
    @media (max-width: 1280px) {
      font-size: 16px;
      line-height: 20px;
    }
    @media (max-width: 1024px) and (min-width: 768px), (max-width: 375px) {
      font-size: 15px;
      line-height: 19px;
    }
    /* ---------------------------------------------------------------- */
    /* Text */
    /* ---------------------------------------------------------------- */
    span {
      display: block;
      margin: -4px 0;
      width: calc(100% - 42px);
      @media (max-width: 1024px) and (min-width: 768px), (max-width: 375px) {
        margin-top: -3px;
      }
    }
    /* ---------------------------------------------------------------- */
    /* Text / Icon */
    /* ---------------------------------------------------------------- */
    span,
    svg {
      margin-bottom: 20px;
      @media (max-width: 1536px) {
        margin-bottom: 16px;
      }
      @media (max-width: 1280px) {
        margin-bottom: 20px;
      }
      @media (max-width: 1024px) and (min-width: 768px), (max-width: 375px) {
        margin-bottom: 16px;
      }
    }
    /* ---------------------------------------------------------------- */
    /* Border */
    /* ---------------------------------------------------------------- */
    &::after {
      background-color: $grey;
      content: '';
      display: block;
      height: 1px;
      margin: auto 0 20px;
      width: 100%;
      @media (max-width: 1536px) {
        margin-bottom: 16px;
      }
      @media (max-width: 1280px) {
        margin-bottom: 20px;
      }
      @media (max-width: 1024px) and (min-width: 768px), (max-width: 375px) {
        margin-bottom: 16px;
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Badge / Trophy Icon */
  /* ---------------------------------------------------------------- */
  svg {
    flex-shrink: 0;
    height: 26px;
    margin-left: auto;
    padding-left: 16px;
    @media (max-width: 1536px) {
      height: 25px;
      padding-left: 12px;
    }
    @media (max-width: 1280px) {
      height: 24px;
      padding-left: 8px;
    }
    @media (max-width: 1024px) and (min-width: 768px), (max-width: 375px) {
      height: 23px;
    }
    &[data-icon='badge'],
    &[data-icon='badge-timer'] {
      width: 26px;
      @media (max-width: 1536px) {
        width: 25px;
      }
      @media (max-width: 1280px) {
        width: 24px;
      }
      @media (max-width: 1024px) and (min-width: 768px), (max-width: 375px) {
        width: 23px;
      }
    }
    &[data-icon='trophy'] {
      width: 25px;
      @media (max-width: 1536px) {
        width: 24px;
      }
      @media (max-width: 1280px) {
        width: 23px;
      }
      @media (max-width: 1024px) and (min-width: 768px), (max-width: 375px) {
        width: 22px;
      }
    }
    &[data-icon='badge-timer'] {
      cursor: pointer;
      :global(.platform-desktop) & {
        &:hover + [role='tooltip'] {
          opacity: 1;
          transform: translateY(-100%) scale(1);
        }
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Link / Modal Button / Expiry Date Container */
  /* ---------------------------------------------------------------- */
  > div {
    align-items: center;
    display: flex;
    margin-top: auto;
  }
  /* ---------------------------------------------------------------- */
  /* Link / Modal Button */
  /* ---------------------------------------------------------------- */
  a,
  button,
  > div > span {
    font-size: 17px;
    line-height: 21px;
    margin: -4px 0;
    @media (max-width: 1536px) {
      font-size: 16px;
      line-height: 20px;
    }
    @media (max-width: 1280px) {
      font-size: 15px;
      line-height: 19px;
    }
    @media (max-width: 1024px) and (min-width: 768px), (max-width: 375px) {
      font-size: 14px;
      line-height: 18px;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Link / Modal Button */
  /* ---------------------------------------------------------------- */
  a,
  button {
    font-family: $primary;
    font-weight: $semibold;
    @include fast-transition($props: 'color');
    @include no-user-select;
  }
  /* ---------------------------------------------------------------- */
  /* Link */
  /* ---------------------------------------------------------------- */
  a {
    color: rgba($brandBlackGrape, 0.7);
    &:focus {
      text-decoration: underline;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Modal Button - Shows On Claimed */
  /* ---------------------------------------------------------------- */
  button {
    color: $white;
    cursor: pointer;
    margin-left: 20px;
    text-decoration: underline;
    touch-action: manipulation;
    @media (max-width: 1536px) {
      margin-left: 16px;
    }
    @media (max-width: 1280px) {
      margin-left: 12px;
    }
    &:focus {
      text-decoration: none;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Expiry Date - Shows On Claimed */
  /* ---------------------------------------------------------------- */
  > div > span {
    color: $white;
    margin-left: auto;
    padding-left: 20px;
    @media (max-width: 1536px) {
      padding-left: 16px;
    }
    @media (max-width: 1280px) {
      padding-left: 12px;
    }
  }
  /* ---------------------------------------------------------------- */
  /* ProPath */
  /* ---------------------------------------------------------------- */
  &.ProPath:not(.claimed) {
    a {
      &:focus {
        color: $productProPath;
      }
      :global(.platform-desktop) & {
        &:hover {
          color: $productProPath;
        }
        &:active {
          color: $productProPathDark;
        }
      }
      :global(.platform-mobile) & {
        &:active {
          color: $productProPath;
        }
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* ITIL */
  /* ---------------------------------------------------------------- */
  &.ITIL-4:not(.claimed) {
    a {
      &:focus {
        color: $productItil;
      }
      :global(.platform-desktop) & {
        &:hover {
          color: $productItil;
        }
        &:active {
          color: $productItilDark;
        }
      }
      :global(.platform-mobile) & {
        &:active {
          color: $productItil;
        }
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Exam Passed - Achieved Status = 1 */
  /* ---------------------------------------------------------------- */
  &.examPassed {
    > div > span {
      font-family: $primary;
      font-weight: 600;
      margin-left: 0;
      padding-left: 0;
    }
    &.ProPath {
      border-color: $productProPath;
      > h4 > svg > path {
        fill: $productProPath;
      }
      > div > span {
        color: $productProPath;
      }
    }
    &.ITIL-4 {
      border-color: $productItil;
      > div > span {
        color: $productItil;
      }
      > h4 > svg > path {
        fill: $productItil;
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Claim Badge - Achieved Status = 2 */
  /* ---------------------------------------------------------------- */
  &.canClaim {
    &.isSubmitting {
      button > span {
        color: grey;
        cursor: default;
      }
      /* ---------------------------------------------------------------- */
      /* Loading */
      /* ---------------------------------------------------------------- */
      .spinner {
        background-image: url('/images/icons/button-loading.svg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: 18px 34px;
        border-radius: 50%;
        display: block;
        flex-shrink: 0;
        height: 18px;
        margin-left: 8px;
        top: 0;
        width: 18px;
        + * {
          pointer-events: none;
        }
      }
    }
    button {
      margin-left: 0;
      text-decoration: none;
    }
    > div > span {
      font-family: $primary;
      font-weight: 600;
      margin-left: 0;
      padding-left: 0;
    }
    &.ProPath {
      border-color: $productProPath;
      button {
        color: $productProPath;
      }
      > div > span {
        color: $productProPath;
      }
      svg path {
        fill: $productProPath;
      }
      a {
        color: $productProPath;
        &:focus {
          color: $productProPathDark;
        }
        :global(.platform-desktop) & {
          &:hover {
            color: $productProPathDark;
          }
          &:active {
            color: darken($productProPathDark, 10%);
          }
        }
        :global(.platform-mobile) & {
          &:active {
            color: $productProPathDark;
          }
        }
      }
    }
    &.ITIL-4 {
      border-color: $productItil;
      button {
        color: $productItil;
      }
      > h4 > svg > path {
        fill: $productItil;
      }
      > div > span {
        color: $productItil;
      }
      svg path {
        fill: $productItil;
      }
      a {
        color: $productItil;
        &:focus {
          color: $productItilDark;
        }
        :global(.platform-desktop) & {
          &:hover {
            color: $productItilDark;
          }
          &:active {
            color: darken($productItilDark, 10%);
          }
        }
        :global(.platform-mobile) & {
          &:active {
            color: $productItilDark;
          }
        }
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Claimed - Achieved Status = 4 */
  /* ---------------------------------------------------------------- */
  &.claimed {
    h4::after {
      background-color: $white;
    }
    h4,
    a {
      color: $white;
    }
    a,
    button {
      &:focus {
        color: darken($white, 5%);
      }
      :global(.platform-desktop) & {
        &:hover {
          color: darken($white, 5%);
        }
        &:active {
          color: darken($white, 10%);
        }
      }
      :global(.platform-mobile) & {
        &:active {
          color: darken($white, 5%);
        }
      }
    }
    svg path {
      fill: $white;
    }
    &.ProPath {
      background-color: $productProPath;
    }
    &.ITIL-4 {
      background-color: $productItil;
    }
  }
  &.isClaiming {
    > div {
      justify-content: space-between;
      > .credleyLink {
        color: rgba($brandBlackGrape, 0.5);
        pointer-events: none; // Delete this after PeopleCert+ Transition
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Help Tooltip */
  /* ---------------------------------------------------------------- */
  [role='tooltip'] {
    background-color: $white;
    border: 1px solid $mono2;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.16) 0 3px 6px;
    box-sizing: border-box;
    font-size: 17px;
    line-height: 25px;
    opacity: 0;
    padding: 11px 16px;
    position: absolute;
    right: -12px;
    top: 21px;
    transform: translateY(-100%) scale(0);
    transform-origin: calc(100% - 68px) calc(100% + 10px);
    transition-duration: 0.2s;
    transition-property: transform, opacity;
    transition-timing-function: ease-in-out;
    width: 268px;
    z-index: 9;
    @media (max-width: 1536px) {
      font-size: 16px;
      line-height: 24px;
      right: -18px;
      top: 17px;
    }
    @media (max-width: 1408px) {
      right: -9px;
      transform-origin: calc(100% - 61px) calc(100% + 10px);
    }
    @media (max-width: 1280px) {
      font-size: 15px;
      line-height: 23px;
      right: 0;
      top: 9px;
      transform-origin: calc(100% - 48px) calc(100% + 10px);
    }
    @media (max-width: 1024px) {
      right: -1px;
      top: 6px;
      transform-origin: calc(100% - 39px) calc(100% + 10px);
    }
    @media (max-width: 767px) {
      right: 3px;
    }
    @media (max-width: 375px) {
      font-size: 14px;
      line-height: 22px;
      right: 4px;
      transform-origin: calc(100% - 35px) calc(100% + 10px);
    }
    &::after {
      background-image: url(/images/icons/help-chevron.svg);
      background-repeat: no-repeat;
      background-size: cover;
      bottom: -23px;
      content: '';
      display: block;
      height: 30px;
      pointer-events: none;
      position: absolute;
      right: 35px;
      width: 30px;
      @media (max-width: 1408px) {
        right: 27px;
      }
      @media (max-width: 1280px) {
        right: 17px;
      }
      @media (max-width: 1024px) {
        right: 13px;
      }
      @media (max-width: 375px) {
        right: 8px;
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Show Help Tooltip - Mobile */
  /* ---------------------------------------------------------------- */
  &.showHelp {
    [role='tooltip'] {
      opacity: 1;
      transform: translateY(-100%) scale(1);
    }
  }
}

@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}
