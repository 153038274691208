@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* -------------------------------------------------------------- */
/* Accordion Skeleton */
/* -------------------------------------------------------------- */
.skeleton {
  margin: 48px 0 0;
  overflow: hidden;
  position: relative;
  @media (max-width: 1536px) {
    margin: 40px 0 0;
  }
  @media (max-width: 1280px) {
    margin: 32px 0 0;
  }
  @media (max-width: 375px) {
    margin: 24px 0 0;
  }
  &::after {
    @include skeleton-shimmer;
    @include skeleton-shimmer-color($color: $pageBackground);
  }
  > div {
    > div > div {
      /* -------------------------------------------------------------- */
      /* Accordion Title */
      /* -------------------------------------------------------------- */
      > p {
        color: $brandBlackGrape;
        font-size: 20px;
        font-weight: $semibold;
        line-height: 27px;
        margin: -5px 0 -6px;
        padding-right: 52px;
        text-transform: none;
        @media (max-width: 1536px) {
          font-size: 19px;
          line-height: 26px;
          padding-right: 48px;
        }
        @media (max-width: 1280px) {
          font-size: 18px;
          line-height: 25px;
          padding-right: 44px;
        }
        @media (max-width: 767px) {
          font-size: 17px;
          line-height: 24px;
          margin-bottom: -5px;
          margin-top: -5px;
          width: 100%;
        }
        &::after {
          background-color: $mono1;
          color: transparent;
          content: 'Are there any restrictions on qualifications/credits that can be counted towards';
          font-size: inherit;
          line-height: inherit;
          @include rounded-skeleton-text;
        }
      }
      /* -------------------------------------------------------------- */
      /* Accordion Button Icon */
      /* -------------------------------------------------------------- */
      span {
        background-color: $mono1;
        border-radius: 50%;
        display: block;
        flex-shrink: 0;
        height: 40px;
        position: absolute;
        right: 0;
        top: 50%;
        touch-action: manipulation;
        transform: translateY(-50%);
        width: 40px;
        @media (max-width: 1536px) {
          height: 36px;
          width: 36px;
        }
        @media (max-width: 1280px) {
          height: 32px;
          width: 32px;
        }
      }
    }
    &:nth-child(2) > div > div p::after {
      content: 'Who can join MyAxelos';
    }
    &:nth-child(3) > div > div p::after {
      content: 'How do I obtain a new candidate number';
    }
    &:nth-child(4) > div > div p::after {
      content: 'How do I know when I have achieved a new certificate';
    }
    &:nth-child(5) > div > div p::after {
      content: 'Do I need a people cert account?';
    }
  }
}

@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}
