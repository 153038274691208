@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* -------------------------------------------------------------- */
/* Publications Skeleton */
/* -------------------------------------------------------------- */
.skeleton {
  margin-top: -4px;
  overflow: hidden;
  position: relative;
  @media (max-width: 1280px) {
    margin-top: -3px;
  }
  /* -------------------------------------------------------------- */
  /* Title */
  /* -------------------------------------------------------------- */
  > p {
    align-items: center;
    color: rgba($brandBlackGrape, 50%);
    display: flex;
    font-family: $secondary;
    font-size: 18px;
    font-weight: $semibold;
    letter-spacing: 0.16px;
    line-height: 22px;
    margin-bottom: -5px;
    text-transform: uppercase;
    @media (max-width: 1536px) {
      font-size: 17px;
      line-height: 21px;
    }
    @media (max-width: 1280px) {
      font-size: 16px;
      line-height: 20px;
    }
    @media (max-width: 1024px) {
      font-size: 15px;
      line-height: 19px;
    }
    @media (max-width: 767px) {
      font-size: 16px;
      line-height: 20px;
    }
    &::before {
      @include skeleton-shimmer;
      @include skeleton-shimmer-color($color: $white);
      @media (max-width: 767px) {
        @include skeleton-shimmer-color($color: $pageBackground);
      }
    }
    &::after {
      background-color: $mono1;
      color: transparent;
      content: 'PUBLICATIONS';
      font-size: inherit;
      line-height: inherit;
      text-transform: inherit;
      @include rounded-skeleton-text;
    }
  }
  /* -------------------------------------------------------------- */
  /* List */
  /* -------------------------------------------------------------- */
  > ul > li {
    > div {
      &::after {
        @include skeleton-shimmer;
        @include skeleton-shimmer-color($color: $white);
        @media (max-width: 767px) {
          @include skeleton-shimmer-color($color: $pageBackground);
        }
      }
      > div:first-child {
        /* -------------------------------------------------------------- */
        /* Image */
        /* -------------------------------------------------------------- */
        .image {
          background-color: $mono1;
          height: 133px;
          left: 32px;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          width: 100px;
          @media (max-width: 1536px) {
            height: 125px;
            left: 0;
            width: 96px;
          }
          @media (max-width: 1280px) {
            height: 122px;
            width: 92px;
          }
          @media (max-width: 1024px) and (min-width: 768px),
            (max-width: 360px) {
            height: 117px;
            position: relative;
            top: auto;
            transform: none;
            width: 88px;
          }
        }
        /* -------------------------------------------------------------- */
        /* Title */
        /* -------------------------------------------------------------- */
        > p {
          color: $brandBlackGrape;
          font-family: $primary;
          font-size: 18px;
          font-weight: $bold;
          line-height: 22px;
          margin-bottom: 15px;
          @media (max-width: 1536px) {
            font-size: 17px;
            line-height: 21px;
            margin-bottom: 12px;
          }
          @media (max-width: 1280px) {
            font-size: 16px;
            line-height: 20px;
          }
          @media (max-width: 1024px) {
            font-size: 15px;
            line-height: 19px;
          }
          @media (max-width: 1024px) and (min-width: 768px),
            (max-width: 360px) {
            box-sizing: border-box;
            margin-bottom: -4px;
            padding-left: 16px;
            width: calc(100% - 92px);
          }
          &:last-child {
            margin-bottom: -4px;
          }
          &::after {
            background-color: $mono1;
            color: transparent;
            content: 'PUBLICATION Title';
            font-size: inherit;
            line-height: inherit;
            text-transform: inherit;
            @include rounded-skeleton-text;
          }
        }
        /* -------------------------------------------------------------- */
        /* Description */
        /* -------------------------------------------------------------- */
        > span {
          font-size: 17px;
          line-height: 21px;
          margin: 0 0 -4px;
          @media (max-width: 1536px) {
            font-size: 16px;
            line-height: 20px;
          }
          @media (max-width: 1280px) {
            font-size: 15px;
            line-height: 19px;
          }
          @media (max-width: 1024px) {
            font-size: 14px;
            line-height: 18px;
          }
          @media (max-width: 1024px) and (min-width: 768px),
            (max-width: 360px) {
            margin-top: -4px;
            padding-top: 16px;
          }
          &::after {
            background-color: $mono1;
            color: transparent;
            content: 'This is a line of copy about the book. This is a line of copy about';
            font-size: inherit;
            line-height: inherit;
            text-transform: inherit;
            @include rounded-skeleton-text;
          }
        }
      }
      > div:last-child {
        > div {
          /* -------------------------------------------------------------- */
          /* Price Title */
          /* -------------------------------------------------------------- */
          > p {
            color: rgba($brandBlackGrape, 0.7);
            font-family: $primary;
            font-size: 17px;
            font-weight: $bold;
            line-height: 21px;
            margin: -4px 0 8px;
            padding: 0;
            @media (max-width: 1536px) {
              font-size: 16px;
              line-height: 20px;
            }
            @media (max-width: 1280px) {
              font-size: 15px;
              line-height: 19px;
            }
            @media (max-width: 1024px) {
              font-size: 14px;
              line-height: 18px;
            }
            @media (max-width: 832px) and (min-width: 768px),
              (max-width: 350px) {
              line-height: 19px;
              margin: 0;
            }
            &::after {
              background-color: $mono1;
              color: transparent;
              content: 'price:';
              font-size: inherit;
              line-height: inherit;
              text-transform: inherit;
              @include rounded-skeleton-text;
            }
          }
          /* -------------------------------------------------------------- */
          /* Price */
          /* -------------------------------------------------------------- */
          > span {
            display: block;
            font-size: 20px;
            font-weight: $semibold;
            line-height: 24px;
            margin-bottom: -5px;
            @media (max-width: 1536px) {
              font-size: 19px;
              line-height: 23px;
            }
            @media (max-width: 1280px) {
              font-size: 18px;
              line-height: 22px;
            }
            @media (max-width: 1024px) {
              font-size: 17px;
              line-height: 21px;
            }
            @media (max-width: 832px) and (min-width: 768px),
              (max-width: 350px) {
              margin: 0;
              padding-left: 12px;
            }
            @media (max-width: 767px) {
              font-size: 16px;
              line-height: 20px;
            }
            @media (max-width: 480px) {
              font-size: 15px;
              line-height: 19px;
            }
            &::after {
              background-color: $mono1;
              color: transparent;
              content: '£36';
              font-size: inherit;
              line-height: inherit;
              text-transform: inherit;
              @include rounded-skeleton-text;
            }
          }
        }
        > span {
          background-color: $mono1;
          height: 44px;
          margin-left: auto;
          max-width: 120px;
          width: 100%;
          @include rounded-skeleton-text;
          @media (max-width: 1664px) {
            padding: 0 5px 0 24px;
          }
          @media (max-width: 1536px) {
            height: 42px;
          }
          @media (max-width: 1280px) {
            height: 41px;
          }
          @media (max-width: 1024px) {
            height: 39px;
          }
          @media (max-width: 832px) and (min-width: 768px), (max-width: 350px) {
            align-self: flex-start;
            margin-left: 0;
            margin-top: 16px;
          }
          @media (max-width: 767px) {
            height: 38px;
          }
          @media (max-width: 375px) {
            height: 37px;
          }
          &::after {
            color: transparent;
            content: 'Shop Now';
            font-size: inherit;
            line-height: inherit;
            text-transform: inherit;
          }
        }
      }
    }
  }
}

@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}
