@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Logo Container */
/* ---------------------------------------------------------------- */
.logo {
  flex-shrink: 0;
  height: 34px;
  width: 125px;
  @media (max-width: 1280px) {
    height: 32px;
    width: 119px;
  }
  /* ---------------------------------------------------------------- */
  /* Logo Container = Link */
  /* ---------------------------------------------------------------- */
  @at-root {
    a#{&} {
      cursor: pointer;
      touch-action: manipulation;
      &:focus {
        outline: 2px dashed $brandBerry;
        outline-offset: 4px;
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* SVG */
  /* ---------------------------------------------------------------- */
  svg {
    height: 100%;
    width: 100%;
  }
  /* ---------------------------------------------------------------- */
  /* Organization */
  /* ---------------------------------------------------------------- */
  &.organization {
    height: 44px;
    width: 161px;
    @media (max-width: 1280px) {
      height: 40px;
      width: 146px;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Consultant */
  /* ---------------------------------------------------------------- */
  &.consultant {
    height: 44px;
    width: 192px;
    @media (max-width: 1280px) {
      height: 40px;
      width: 175px;
    }
  }
}
