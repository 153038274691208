@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* -------------------------------------------------------------- */
/* Achievements Item */
/* -------------------------------------------------------------- */
.cpdCertificateItem {
  background-color: $white;
  border: 1px solid $grey;
  border-radius: 4px;
  padding: 28px 32px;
  position: relative;
  @media (max-width: 1536px) {
    padding: 24px 28px;
  }
  @media (max-width: 1280px) {
    padding: 20px 24px;
  }
  @media (max-width: 1152px) and (min-width: 768px), (max-width: 360px) {
    background-color: transparent;
    border: none;
    border-radius: 0;
    padding: 0;
  }
  &:not(:last-of-type) {
    margin: 0 0 16px;
    @media (max-width: 1152px) and (min-width: 768px), (max-width: 360px) {
      margin: 0 0 20px;
    }
  }
  &::after {
    @media (max-width: 1152px) and (min-width: 768px), (max-width: 360px) {
      background-color: $grey;
      bottom: 0;
      content: '';
      display: block;
      height: 1px;
      left: 0;
      margin-top: 20px;
      position: relative;
      width: 100%;
    }
  }
  @media (max-width: 1152px) and (min-width: 768px), (max-width: 360px) {
    &:first-child::before {
      background-color: $grey;
      content: '';
      display: block;
      height: 1px;
      left: 0;
      margin-bottom: 20px;
      position: relative;
      top: 0;
      width: 100%;
    }
  }
  /* -------------------------------------------------------------- */
  /* Border */
  /* -------------------------------------------------------------- */
  hr {
    background-color: $grey;
    border: 0;
    height: 1px;
    // margin: 28px 0;
    margin: 10px 0 28px 0;
    width: 100%;
    @media (max-width: 1536px) {
      // margin: 24px 0;
      margin: 10px 0 24px 0;
    }
    @media (max-width: 1280px) {
      // margin: 20px 0;
      margin: 10px 0 20px 0;
    }
    @media (max-width: 1152px) and (min-width: 768px), (max-width: 360px) {
      display: none;
    }
  }
  /* -------------------------------------------------------------- */
  /* Designation Container + Progress Container */
  /* -------------------------------------------------------------- */
  > div {
    align-items: center;
    display: flex;
    @media (max-width: 1536px) and (min-width: 1409px), (max-width: 576px) {
      font-size: 16px;
    }
  }
  /* -------------------------------------------------------------- */
  /* Designation Container  */
  /* -------------------------------------------------------------- */
  > div:nth-of-type(1) {
    @media (max-width: 1152px) and (min-width: 768px), (max-width: 360px) {
      margin-bottom: 20px;
    }
  }
  /* -------------------------------------------------------------- */
  /* Badge Icon */
  /* -------------------------------------------------------------- */
  div:nth-of-type(1) svg {
    flex-shrink: 0;
    height: 36px;
    margin-right: 16px;
    width: 36px;
    @media (max-width: 1536px) {
      height: 32px;
      margin-right: 12px;
      width: 32px;
    }
    @media (max-width: 1280px) {
      height: 28px;
      margin-right: 8px;
      width: 28px;
    }
    @media (max-width: 1024px) {
      height: 24px;
      width: 24px;
    }
  }
  /* -------------------------------------------------------------- */
  /* Badge Text */
  /* -------------------------------------------------------------- */
  p {
    font-size: 18px;
    font-weight: $semibold;
    line-height: 22px;
    margin: -4px 0;
    @media (max-width: 1536px) {
      font-size: 17px;
      line-height: 21px;
    }
    @media (max-width: 1280px) {
      font-size: 16px;
      line-height: 20px;
    }
    @media (max-width: 1024px) {
      font-size: 15px;
      line-height: 19px;
    }
  }
  /* -------------------------------------------------------------- */
  /* Progress Container  */
  /* -------------------------------------------------------------- */
  > div:nth-of-type(2) {
    margin: -6px 0;
    position: relative;
    @media (max-width: 1024px) {
      margin-bottom: -5px;
    }
    @media (max-width: 896px) and (min-width: 768px), (max-width: 360px) {
      align-items: flex-start;
      display: flex;
      flex-direction: column;
    }
  }
  /* -------------------------------------------------------------- */
  /* Progress Text / Progress State Text */
  /* -------------------------------------------------------------- */
  span,
  em {
    font-size: 17px;
    font-weight: $semibold;
    line-height: 25px;
    @media (max-width: 1536px) {
      font-size: 16px;
      line-height: 24px;
    }
    @media (max-width: 1280px) {
      font-size: 15px;
      line-height: 23px;
    }
    @media (max-width: 1024px) {
      font-size: 14px;
      line-height: 21px;
    }
  }
  /* -------------------------------------------------------------- */
  /* Progress Text */
  /* -------------------------------------------------------------- */
  > div > span {
    flex: 1;
    padding-right: 16px;
    @media (max-width: 896px) and (min-width: 768px), (max-width: 360px) {
      margin-bottom: 5px;
    }
  }
  /* -------------------------------------------------------------- */
  /* Progress State Text */
  /* -------------------------------------------------------------- */
  em {
    align-items: center;
    color: rgba($brandBlackGrape, 70%);
    display: flex;
    font-style: normal;
    padding-right: 12px;
    @media (max-width: 896px) and (min-width: 768px), (max-width: 360px) {
      padding: 0 0 0 22px;
    }
  }
  /* -------------------------------------------------------------- */
  /* Trophy Icon */
  /* -------------------------------------------------------------- */
  > div:nth-of-type(2) svg {
    color: rgba($brandBlackGrape, 10%);
    flex-shrink: 0;
    height: 20px;
    width: 19px;
    @media (max-width: 1536px) {
      height: 19px;
      width: 18px;
    }
    @media (max-width: 1280px) {
      height: 18px;
      width: 17px;
    }
    @media (max-width: 1024px) {
      height: 17px;
      width: 16px;
    }
    @media (max-width: 896px) and (min-width: 768px), (max-width: 360px) {
      bottom: 2px;
      height: 16px;
      position: absolute;
      width: 15px;
    }
  }
  /* -------------------------------------------------------------- */
  /* Complete */
  /* -------------------------------------------------------------- */
  &:not(.complete) + .complete {
    @media (max-width: 1152px) and (min-width: 768px), (max-width: 360px) {
      margin-top: -21px;
    }
  }
  &.complete {
    background-color: $productItil;
    border: none;
    color: $white;
    @media (max-width: 1152px) and (min-width: 768px), (max-width: 360px) {
      margin-left: -32px;
      margin-right: -32px;
      padding: 20px 32px;
    }
    @media (max-width: 1024px) and (min-width: 768px), (max-width: 360px) {
      margin-left: -24px;
      margin-right: -24px;
      padding: 20px 24px;
    }
    @media (max-width: 1152px) and (min-width: 768px), (max-width: 360px) {
      &:first-child::before {
        content: none;
      }
    }
    &::after {
      content: none;
    }
    em {
      color: $white;
    }
    hr {
      background-color: rgba($white, 20%);
    }
  }
  /* -------------------------------------------------------------- */
  /* ProPath */
  /* -------------------------------------------------------------- */
  &.proPath {
    background-color: $productProPath;
  }

  & .expiryDate {
    font-size: 14px;
    margin: 20px 0 5px 0;
  }

  & .expiryDateGrey {
    font-size: 14px;
    margin: 20px 0 5px 0;
    color: #838690
  }

  & .learnMore {
    color: #CE2D4F
  }

  & .success {
    color: #64D19E;
    margin-top: 10px;
    margin-left: 5px;
  }

  & .failure {
    color: #FF9981;
    margin-top: 10px;
    margin-left: 5px;
  }

  & .flex {
    display: flex;
  }
}
