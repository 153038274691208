@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* AddFile */
/* ---------------------------------------------------------------- */
.addFile {
  display: flex;

  .fileItem {
    display: inline-block;
    margin-left: 30px;
    margin-right: 30px;
    margin-top: 10px;
    justify-content: center;
    border-radius: 8px;
    background-color: #282d3d;
    color: white;
    width: 150px;
    height: 150px;

    .checkIcon {
      width: 30px;
      margin-left: 65px;
      margin-top: 10px;
      margin-bottom: 20px;
      z-index: 1000;
    }

    .closeIcon {
      width: 25px;
      display: block;
      z-index: 1000;
      position: relative;
      left: 133px;
      top: -10px;
      color: white;
      border-radius: 12.5px;
    }
    .fileNameText {
      text-align: center;
    }
  }

  .buttonArea {
    margin-top: 10px;
    border-radius: 8px;
    width: 150px;
    min-width: 150px;
    height: 150px;
    background-color: #ebebed;

    .displayOpt {
      display: none;
    }
    > p {
      font-size: 16px;
      text-align: center;
    }

    .uploadIcon {
      width: 25%;
      padding-top: 20px;
      display: block;
      margin-right: auto;
      margin-left: auto;
    }

    .buttonText {
      margin-top: 18px;
    }
  }
}

.noEvents {
  pointer-events: none;
}

.carouselArea {
  display: inline-block;
  max-width: 100%;
  min-width: 1%;
  // width: max-content;
  // height: 150px !important;
}

:global(.react-multi-carousel-list) {
  // margin-right: 30px;
}

:global(ul.react-multi-carousel-track) {
  width: 100%;
}

:global(.react-multiple-carousel__arrow--left) {
  top: 50%;
  transform: translateY(-50%);
  left: 10px;
  background: #f1f1f1;
  border: 1px solid;
  &::before {
    content: '\2190';
    color: #282d3d;
  }
}

:global(.react-multiple-carousel__arrow) {
  &:hover {
    &::before {
      color: #fff;
    }
  }
}

:global(.react-multiple-carousel__arrow--right) {
  top: 50%;
  transform: translateY(-50%);
  right: 10px;
  background: #f1f1f1;
  border: 1px solid;
  &::before {
    content: '\2192';
    color: #282d3d;
  }
}
:global(p.fileNameText) {
  width: 150px;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  word-break: break-all;
}

:global(li.react-multi-carousel-item) {
  width: auto !important;
  margin: 10px;
  flex: none !important;

  > span.fileItem {
    margin: 0;

    p {
      width: 150px;
      overflow: hidden;
      -o-text-overflow: ellipsis;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }
}

// @at-root {
//   .react-multi-carousel-list {
//     border: 1px solid red;
//   }
// }
