@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
.related-content {
  display: block;
  margin: 56px 0;
  position: relative;
  @media (max-width: 1536px) {
    margin: 48px 0;
  }
  @media (max-width: 1280px) {
    margin: 40px 0;
  }
  @media (max-width: 1024px) {
    margin: 32px 0;
  }
  @media (max-width: 375px) {
    margin: 24px 0;
  }
  h2 {
    color: $brandBlackGrape;
    font-size: 32px;
    font-weight: $semibold;
    line-height: 40px;
    margin-bottom: 36px;
    @media (max-width: 1536px) {
      font-size: 28px;
      line-height: 36px;
      margin-bottom: 32px;
    }
    @media (max-width: 1280px) {
      font-size: 24px;
      line-height: 32px;
      margin-bottom: 28px;
    }
    @media (max-width: 375px) {
      font-size: 22px;
      line-height: 30px;
      margin-bottom: 26px;
    }
    span {
      display: block;
      margin: -7px 0 -9px;
      @media (max-width: 1536px) {
        margin: -7px 0;
      }
      @media (max-width: 375px) {
        margin: -6px 0 -7px;
      }
    }
  }
  .description {
    color: $brandBlackGrape;
    font-size: 17px;
    line-height: 25px;
    margin-bottom: 29px;
    @media (max-width: 1536px) {
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 28px;
    }
    @media (max-width: 1280px) {
      font-size: 15px;
      line-height: 23px;
      margin-bottom: 27px;
    }
    @media (max-width: 375px) {
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 24px;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
  ul {
    display: flex;
    margin: 30px -15px 0 -15px;
    width: auto;
    @media (max-width: 1024px) {
      flex-wrap: wrap;
    }
    @media (max-width: 767px) {
      margin-left: 0;
      margin-right: 0;
      width: 100%;
    }
    li {
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      padding: 0 15px;
      width: 33.333%;
      @media (max-width: 1024px) {
        margin-bottom: 32px;
        width: 50%;
      }
      @media (max-width: 767px) {
        padding-left: 0;
        padding-right: 0;
        width: 100%;
      }
      &:last-child {
        @media (max-width: 1024px) {
          margin-bottom: 0;
        }
      }
      .image {
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        height: 208px;
        width: 100%;
      }
      .content {
        box-shadow: 0 0 10px rgba($color: #000, $alpha: 0.1);
        display: flex;
        flex: 1;
        flex-direction: column;
        padding: 20px 20px 20px 20px;
        @media (max-width: 767px) {
          background-color: $pageBackground;
          box-shadow: none;
          padding-left: 0;
          padding-right: 0;
        }
        h3 {
          color: $brandBlackGrape;
          font-size: 20px;
          font-weight: $bold;
          line-height: 28px;
          margin-bottom: 20px;
          @media (max-width: 600px) {
            font-size: 17px;
            line-height: 25px;
          }
        }
        p {
          color: $brandBlackGrape;
          font-size: 17px;
          margin-bottom: 30px;
          @media (max-width: 767px) {
            display: none;
          }
        }
        a {
          margin-top: auto;
          max-width: 174px;
        }
      }
    }
  }
}

.post-card-list {
  display: flex;
}

.post-card-container {
  display: block;
}
