@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
.newCandidateId {
  background-color: $white;
  border: 1px solid $brandGreyLight;
  border-radius: 4px;
  display: block;
  margin-bottom: 72px;
  padding: 36px;
  position: relative;
  @media (max-width: 1280px) {
    margin-bottom: 64px;
    padding: 32px;
  }
  @media (max-width: 1024px) {
    margin-bottom: 48px;
    padding: 24px;
  }
  @media (max-width: 767px) {
    margin-bottom: 64px;
  }
  @media (max-width: 440px) {
    border-radius: 0;
    box-sizing: border-box;
    left: -24px;
    width: calc(100% + 48px);
  }
  @media (max-width: 375px) {
    left: -16px;
    margin-bottom: 48px;
    padding: 20px 16px;
    width: calc(100% + 32px);
  }
  &:last-child {
    margin-bottom: 0;
  }
  /* ------------------------------------------------------------ */
  /* Title */
  /* ------------------------------------------------------------ */
  h2 {
    color: $brandBlackGrape;
    font-family: $secondary;
    font-size: 20px;
    line-height: 27px;
    margin: -6px 0 -7px;
    text-align: center;
    @media (max-width: 1536px) {
      font-size: 19px;
      line-height: 26px;
    }
    @media (max-width: 1280px) {
      font-size: 18px;
      line-height: 25px;
    }
    @media (max-width: 1024px) {
      font-size: 17px;
      line-height: 24px;
      margin-bottom: -6px;
    }
    @media (max-width: 820px) and (min-width: 768px), (max-width: 540px) {
      margin-left: auto;
      margin-right: auto;
      max-width: 235px;
    }
  }
}
/* ------------------------------------------------------------ */
/* New Candidate Number Form */
/* ------------------------------------------------------------ */
.newCandidateIdForm {
  display: flex;
  margin: 32px auto 0;
  max-width: 510px;
  position: relative;
  @media (max-width: 1536px) {
    margin-top: 28px;
  }
  @media (max-width: 1280px) {
    margin-top: 24px;
  }
  /* ------------------------------------------------------------ */
  /* Label */
  /* ------------------------------------------------------------ */
  label {
    @include no-user-select;
  }
  /* ---------------------------------------------------------- */
  /* Input */
  /* ---------------------------------------------------------- */
  > div {
    margin-bottom: 0;
    width: 100%;
    /* ---------------------------------------------------------- */
    /* Help Icon */
    /* ---------------------------------------------------------- */
    > svg {
      right: 120px;
      top: 30px;
      transform: none;
      @media (max-width: 1536px) {
        right: 113px;
        top: 26px;
      }
      @media (max-width: 1280px) {
        right: 106px;
        top: 23px;
      }
      @media (max-width: 1024px) {
        right: 98px;
        top: 19px;
      }
    }
    /* ---------------------------------------------------------- */
    /* Tooltip */
    /* ---------------------------------------------------------- */
    [role='tooltip'] {
      right: 61px;
      width: 320px;
      @media (max-width: 1536px) {
        right: 54px;
      }
      @media (max-width: 1408px) {
        right: 61px;
      }
      @media (max-width: 1280px) {
        right: 67px;
      }
      @media (max-width: 440px) {
        width: 240px;
      }
      @media (max-width: 375px) {
        right: 71px;
      }
      @media (max-width: 350px) {
        right: 37px;
        transform-origin: calc(100% - 69px) calc(100% + 10px);
      }
      /* ---------------------------------------------------------- */
      /* Chevron */
      /* ---------------------------------------------------------- */
      &::after {
        @media (max-width: 350px) {
          right: 55px;
        }
      }
    }
  }
  /* ---------------------------------------------------------- */
  /* Input */
  /* ---------------------------------------------------------- */
  input[id][type] {
    padding-right: 155px;
    @media (max-width: 1536px) {
      padding-right: 148px;
    }
    @media (max-width: 1280px) {
      padding-right: 140px;
    }
    @media (max-width: 1024px) {
      padding-right: 131px;
    }
  }
  /* ---------------------------------------------------------- */
  /* Submit */
  /* ---------------------------------------------------------- */
  button[class][type] {
    height: 46px;
    margin-right: 21px;
    position: absolute;
    right: 30px;
    top: 17px;
    width: 53px;
    @media (max-width: 1536px) {
      height: 44px;
      margin-right: 20px;
      right: 26px;
      top: 14px;
      width: 51px;
    }
    @media (max-width: 1280px) {
      height: 42px;
      margin-right: 19px;
      right: 22px;
      top: 11px;
      width: 49px;
    }
    @media (max-width: 1024px) {
      height: 40px;
      right: 18px;
      top: 8px;
      width: 45px;
    }
    /* ---------------------------------------------------------- */
    /* Slice SVG */
    /* ---------------------------------------------------------- */
    svg:last-child {
      right: -20px;
      width: 21px;
      @media (max-width: 1536px) {
        right: -19px;
        width: 20px;
      }
      @media (max-width: 1280px) {
        right: -18px;
        width: 19px;
      }
    }
  }
  /* ---------------------------------------------------------- */
  /* Loader */
  /* ---------------------------------------------------------- */
  > span[class] {
    background-size: 32px 32px;
    height: 36px;
    margin-top: -18px;
    position: absolute;
    right: 41px;
    top: 50%;
    width: 36px;
    z-index: 2;
    @media (max-width: 1536px) {
      background-size: 30px 30px;
      height: 34px;
      margin-top: -17px;
      right: 39px;
      width: 34px;
    }
    @media (max-width: 1280px) {
      background-size: 28px 28px;
      height: 32px;
      margin-top: -16px;
      right: 35px;
      width: 32px;
    }
    @media (max-width: 1024px) {
      background-size: 26px 26px;
      height: 30px;
      margin-top: -15px;
      right: 31px;
      width: 30px;
    }
  }
}
/* ---------------------------------------------------------------- */
/* Error */
/* ---------------------------------------------------------------- */
.errorResponse {
  align-self: flex-start;
  color: red;
  font-size: 17px;
  line-height: 24px;
  margin: 7px auto -6px;
  max-width: 510px;
  text-align: left;
  @media (max-width: 1536px) {
    font-size: 16px;
    line-height: 23px;
    margin-top: 6px;
  }
  @media (max-width: 1408px) {
    margin-top: 5px;
  }
  @media (max-width: 1280px) {
    font-size: 15px;
    line-height: 20px;
    margin-bottom: -4px;
    margin-top: 4px;
  }
  /* ---------------------------------------------------------------- */
  /* Link */
  /* ---------------------------------------------------------------- */
  a {
    color: red;
    font-weight: $semibold;
    margin: 0;
    padding: 0;
    text-decoration: underline;
    white-space: pre;
    @include fast-transition($props: 'color');
    &:focus {
      color: darken(red, 20%);
      text-decoration: none;
    }
    :global(.platform-desktop) & {
      &:hover {
        color: darken(red, 10%);
      }
      &:active {
        color: darken(red, 20%);
      }
    }
    :global(.platform-mobile) & {
      &:active {
        color: darken(red, 10%);
      }
    }
  }
}
