@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
.contentTypes {
  margin-bottom: 24px;
  position: relative;
  @media (max-width: 767px) {
    flex-direction: column;
  }
  &:last-child {
    margin-bottom: 0;
  }
  article {
    .publication {
      display: flex;
      justify-content: space-between;
      margin-bottom: 8px;
      @media (max-width: 1536px) {
        flex-wrap: wrap;
      }
    }
    .cardCta {
      margin-top: auto;
      @media (max-width: 1152px) {
        align-items: center;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
      }
      a.textLink {
        align-items: center;
        color: $brandBerry;
        display: inline-flex;
        font-family: $secondary;
        font-size: 17px;
        font-weight: $bold;
        line-height: 20px;
        @media (max-width: 1024px) {
          font-size: 16px;
        }
        @media (max-width: 767px) {
          font-size: 17px;
        }
        @media (max-width: 400px) {
          font-size: 15px;
        }
        > svg {
          height: 14px;
          margin-left: 16px;
          width: 8px;
          @media (max-width: 400px) {
            margin-left: 8px;
          }
          path {
            fill: $brandBerry;
          }
        }
        &:hover,
        &:active {
          color: $brandBerryDark;
          svg > path {
            fill: $brandBerryDark;
          }
        }
      }
    }
  }
}
