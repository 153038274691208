@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* -------------------------------------------------------------- */
/* Maturity Models Skeleton */
/* -------------------------------------------------------------- */
.skeleton {
  margin-bottom: 48px;
  @media (max-width: 1408px) {
    margin-bottom: 44px;
  }
  @media (max-width: 1024px) {
    margin-bottom: 40px;
  }
  @media (max-width: 767px) {
    margin-bottom: 32px;
  }
  @media (max-width: 375px) {
    margin-bottom: 24px;
  }
}
/* -------------------------------------------------------------- */
/* Maturity Model Item */
/* -------------------------------------------------------------- */
.skeletonItem {
  border: 1px solid transparent;
  border-radius: 4px;
  color: $white;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 28px 32px;
  position: relative;
  @media (max-width: 1536px) {
    padding: 24px 28px;
  }
  @media (max-width: 1280px) {
    padding: 20px 24px;
  }
  @media (max-width: 1152px) and (min-width: 768px), (max-width: 360px) {
    border: none;
    border-radius: 0;
    margin: 0 -32px;
  }
  @media (max-width: 1024px) and (min-width: 768px), (max-width: 360px) {
    margin: 0 -24px;
  }
  &:first-child {
    margin-bottom: 24px;
    @media (max-width: 1536px) {
      margin-bottom: 20px;
    }
    @media (max-width: 1280px) {
      margin-bottom: 16px;
    }
  }
  /* -------------------------------------------------------------- */
  /* Title */
  /* -------------------------------------------------------------- */
  > span {
    color: $white;
    font-family: $secondary;
    font-size: 18px;
    font-weight: $semibold;
    line-height: 26px;
    margin-bottom: 14px;
    margin-top: -6px;
    text-transform: uppercase;
    @media (max-width: 1536px) {
      font-size: 17px;
      line-height: 25px;
      margin-bottom: 10px;
    }
    @media (max-width: 1280px) {
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 10px;
      margin-top: -5px;
    }
    @media (max-width: 1024px) {
      font-size: 15px;
      line-height: 23px;
    }
    &::after {
      color: transparent;
      content: 'axelos maturity model application';
      font-size: inherit;
      line-height: inherit;
      text-transform: inherit;
    }
  }
  /* -------------------------------------------------------------- */
  /* Text */
  /* -------------------------------------------------------------- */
  p {
    font-size: 20px;
    line-height: 30px;
    margin: 0 0 auto;
    @media (max-width: 1536px) {
      font-size: 19px;
      line-height: 28px;
      margin-top: -6px;
    }
    @media (max-width: 1280px) {
      font-size: 18px;
      line-height: 27px;
    }
    @media (max-width: 1024px) {
      font-size: 17px;
      line-height: 25px;
    }
    &::after {
      color: transparent;
      /* stylelint-disable-next-line max-line-length */
      content: 'Carry out assessments easily, view your consultancy’s activity, request certifications and view reports.';
      font-size: inherit;
      line-height: inherit;
    }
  }
  /* -------------------------------------------------------------- */
  /* Logo / Button Container */
  /* -------------------------------------------------------------- */
  > div {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: -8px;
    padding-top: 41px;
    @media (max-width: 1664px) {
      padding-top: 37px;
    }
    @media (max-width: 1536px) and (min-width: 768px), (max-width: 480px) {
      align-items: flex-start;
      flex-direction: column;
      padding-top: 34px;
    }
    @media (max-width: 1408px) {
      padding-top: 30px;
    }
    @media (max-width: 1280px) {
      padding-top: 26px;
    }
    @media (max-width: 1024px) {
      padding-top: 22px;
    }
  }
  /* -------------------------------------------------------------- */
  /* Link */
  /* -------------------------------------------------------------- */
  > div > div > span {
    display: block;
    height: 48px;
    width: 188px;
  }
  /* -------------------------------------------------------------- */
  /* Logo / Link Container */
  /* -------------------------------------------------------------- */
  > div > span,
  > div > div {
    display: block;
    margin: 8px;
  }
  /* -------------------------------------------------------------- */
  /* P3M3 */
  /* -------------------------------------------------------------- */
  &.p3m3 {
    background-color: $productProPath;
    border-color: $productProPathLight;
    > div > div > span,
    > div > span,
    > span::after,
    > p::after {
      background-color: $productProPathLight;
      @include rounded-skeleton-text;
    }
    /* -------------------------------------------------------------- */
    /* Logo */
    /* -------------------------------------------------------------- */
    > div > span {
      height: 49px;
      width: 188px;
      @media (max-width: 1664px) {
        height: 40px;
        width: 153px;
      }
    }
    &::after {
      @include skeleton-shimmer;
      @include skeleton-shimmer-color($color: $productProPath);
    }
  }
  /* -------------------------------------------------------------- */
  /* ITIL */
  /* -------------------------------------------------------------- */
  &.itil {
    background-color: $productItil;
    border-color: $productItilLight;
    > div > div > span,
    > div > span,
    > span::after,
    > p::after {
      background-color: $productItilLight;
      @include rounded-skeleton-text;
    }
    /* -------------------------------------------------------------- */
    /* Logo */
    /* -------------------------------------------------------------- */
    > div > span {
      height: 49px;
      width: 147px;
      @media (max-width: 1664px) {
        height: 40px;
        width: 118px;
      }
    }
    &::after {
      @include skeleton-shimmer;
      @include skeleton-shimmer-color($color: $productItil);
    }
  }
}

@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}
