@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* -------------------------------------------------------------- */
/* New Candidate Skeleton */
/* -------------------------------------------------------------- */
.skeleton {
  overflow: hidden;
  position: relative;
  &::after {
    @include skeleton-shimmer;
    @include skeleton-shimmer-color($color: $white);
  }
  /* -------------------------------------------------------------- */
  /* title */
  /* -------------------------------------------------------------- */
  > span {
    display: block;
    margin: -6px 0 -7px;
    @media (max-width: 1024px) {
      margin-bottom: -6px;
    }
    @media (max-width: 820px) and (min-width: 768px), (max-width: 540px) {
      margin-left: auto;
      margin-right: auto;
      max-width: 235px;
    }
    > p {
      color: $brandBlackGrape;
      font-family: $secondary;
      font-size: 20px;
      line-height: 27px;
      text-align: center;
      @media (max-width: 1536px) {
        font-size: 19px;
        line-height: 26px;
      }
      @media (max-width: 1280px) {
        font-size: 18px;
        line-height: 25px;
      }
      @media (max-width: 1024px) {
        font-size: 17px;
        line-height: 24px;
      }
      &::after {
        background-color: $mono1;
        color: transparent;
        content: 'Start your path!';
        font-size: inherit;
        line-height: inherit;
        @include rounded-skeleton-text;
      }
      + p::after {
        content: 'Add a Candidate Number to claim your first badge.';
      }
    }
  }
  /* -------------------------------------------------------------- */
  /* Input */
  /* -------------------------------------------------------------- */
  > div {
    display: flex;
    margin: 32px auto 0;
    max-width: 510px;
    position: relative;
    span {
      background-color: $mono1;
      height: 80px;
      width: 100%;
      @include rounded-skeleton-text($radius: '9px');
      @media (max-width: 1536px) {
        height: 72px;
      }
      @media (max-width: 1280px) {
        height: 64px;
      }
      @media (max-width: 1024px) {
        height: 56px;
      }
    }
  }
}

@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}
