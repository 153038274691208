@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Modals */
/* ---------------------------------------------------------------- */
.modals {
  height: 0;
  width: 0;
  /* ---------------------------------------------------------------- */
  /* SHARED - Root */
  /* ---------------------------------------------------------------- */
  :global(.MuiDialog-root) {
    min-width: 320px;
  }
  /* ---------------------------------------------------------------- */
  /* SHARED - Backdrop */
  /* ---------------------------------------------------------------- */
  :global(.MuiBackdrop-root) {
    -webkit-backdrop-filter: blur(16px) brightness(0.72);
    backdrop-filter: blur(16px) brightness(0.72);
    background-color: rgba(255, 255, 255, 0.75);
  }
  /* ---------------------------------------------------------------- */
  /* Container */
  /* ---------------------------------------------------------------- */
  :global(.MuiDialog-container) {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    overflow: hidden;
  }
  /* ---------------------------------------------------------------- */
  /* Outer Container */
  /* ---------------------------------------------------------------- */
  :global(.MuiPaper-root) {
    align-items: center;
    background-color: transparent;
    border-radius: 0;
    box-shadow: none;
    color: $brandBlackGrape;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    margin: 0;
    max-height: 100%;
    max-width: 100%;
    overflow: hidden;
    width: 100%;
    @media (max-width: 767px) {
      align-items: initial;
      justify-content: flex-start;
      max-height: 100%;
    }
  }
  /* ---------------------------------------------------------------- */
  /* BODY - Outer Container */
  /* ---------------------------------------------------------------- */
  :global(.MuiDialog-paperScrollBody) {
    align-items: center;
    justify-content: flex-start;
    max-height: 100%;
    max-width: 100% !important;
  }
}
/* ---------------------------------------------------------------- */
/* Fullwidth Modal */
/* ---------------------------------------------------------------- */
.fullwidth {
  align-items: center;
  background-color: $brandAqua;
  background-image: url('/images/artwork/modal-background.svg');
  background-repeat: no-repeat;
  background-size: cover;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  height: 100%;
  max-height: 100%;
  max-width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 40px;
  scrollbar-color: $brandBlackGrape $mono1;
  scrollbar-width: thin;
  text-align: center;
  width: 100%;
  @media (max-width: 1536px) {
    padding: 36px;
  }
  @media (max-width: 1408px) {
    padding: 32px;
  }
  @media (max-width: 1280px) {
    padding: 28px;
  }
  @media (max-width: 767px) {
    padding: 24px;
  }
  @media (max-width: 375px) {
    padding: 16px;
  }
  /* ---------------------------------------------------------------- */
  /* Scroll Bar  */
  /* ---------------------------------------------------------------- */
  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 8px;
  }
  &::-webkit-scrollbar-track {
    background-color: $mono1;
    border-radius: 8px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  }
  &::-webkit-scrollbar-thumb {
    background-color: $brandBlackGrape;
    border-radius: 8px;
  }
}
/* ---------------------------------------------------------------- */
/* Narrow Modal */
/* ---------------------------------------------------------------- */
.narrow {
  background-color: $white;
  border: 1px solid rgba($brandBlackGrape, 0.2);
  border-radius: 14px;
  /* stylelint-disable declaration-colon-newline-after,value-list-comma-newline-after */
  box-shadow: 0 11px 15px -7px rgba(0, 0, 0, 0.2),
    0 24px 38px 3px rgba(0, 0, 0, 0.14), 0 9px 46px 8px rgba(0, 0, 0, 0.12);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-height: calc(100% - 48px);
  max-width: 1152px;
  overflow: hidden;
  @media (max-width: 1664px) {
    max-width: 1088px;
  }
  @media (max-width: 1536px) {
    max-width: 1024px;
  }
  @media (max-width: 1408px) {
    max-width: 976px;
  }
  @media (max-width: 1280px) {
    max-width: 928px;
  }
  @media (max-width: 1152px) {
    max-width: 880px;
  }
  @media (max-width: 1024px) {
    max-width: 864px;
  }
  @media (max-width: 912px) {
    max-width: calc(100% - 48px);
  }
  @media (max-width: 767px) {
    background-color: $pageBackground;
    border: none;
    border-radius: 0;
    box-shadow: none;
    height: 100%;
    max-height: 100%;
    max-width: 100%;
  }
  /* ---------------------------------------------------------------- */
  /* Containers */
  /* ---------------------------------------------------------------- */
  > header,
  > div {
    box-sizing: border-box;
    padding: 40px;
    width: 100%;
    @media (max-width: 1536px) {
      padding: 36px;
    }
    @media (max-width: 1408px) {
      padding: 32px;
    }
    @media (max-width: 1280px) {
      padding: 28px;
    }
    @media (max-width: 767px) {
      padding: 24px;
    }
    @media (max-width: 375px) {
      padding: 16px;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Header */
  /* ---------------------------------------------------------------- */
  > header {
    align-items: center;
    display: flex;
    flex-shrink: 0;
    height: auto;
    /* ---------------------------------------------------------------- */
    /* Close Icon */
    /* ---------------------------------------------------------------- */
    > button {
      cursor: pointer;
      height: 20px;
      margin-left: auto;
      position: relative;
      touch-action: manipulation;
      width: 20px;
      &::after {
        border-color: transparent;
        border-radius: 50%;
        border-style: solid;
        border-width: 2px;
        box-sizing: border-box;
        content: '';
        height: calc(100% + 16px);
        left: -8px;
        position: absolute;
        top: -8px;
        width: calc(100% + 16px);
        @include fast-transition($props: 'border-color');
      }
      &:focus::after {
        border-color: $brandBlackGrape;
      }
      > svg {
        height: 20px;
        width: 20px;
        > * {
          fill: $brandBlackGrape;
        }
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Header Title */
  /* ---------------------------------------------------------------- */
  h2 {
    font-family: $secondary;
    font-size: 32px;
    line-height: 40px;
    margin: -8px 0 -9px;
    padding-right: 24px;
    @media (max-width: 1664px) {
      font-size: 30px;
      line-height: 38px;
    }
    @media (max-width: 1536px) {
      font-size: 28px;
      line-height: 36px;
      margin: -8px 0 -8px;
    }
    @media (max-width: 1408px) {
      font-size: 26px;
      line-height: 34px;
      margin: -7px 0 -8px;
    }
    @media (max-width: 767px) {
      font-size: 24px;
      line-height: 32px;
      margin: -7px 0;
    }
    @media (max-width: 640px) {
      font-size: 22px;
      line-height: 29px;
      margin: -6px 0;
    }
    @media (max-width: 576px) {
      font-size: 21px;
      line-height: 28px;
    }
    @media (max-width: 448px) {
      font-size: 21px;
      line-height: 28px;
    }
    @media (max-width: 375px) {
      font-size: 20px;
      line-height: 26px;
      margin: -6px 0;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Scroll Container */
  /* ---------------------------------------------------------------- */
  > div {
    border-top: 1px solid rgba($brandBlackGrape, 0.2);
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    overflow-y: auto;
    scrollbar-color: $brandAqua $mono1;
    scrollbar-width: thin;
    @media (max-width: 767px) {
      height: 100%;
    }
    /* ---------------------------------------------------------------- */
    /* Scroll Bar  */
    /* ---------------------------------------------------------------- */
    &::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 8px;
    }
    &::-webkit-scrollbar-track {
      background-color: $mono1;
      border-radius: 8px;
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    }
    &::-webkit-scrollbar-thumb {
      background-color: $brandAqua;
      border-radius: 8px;
    }
  }
}
/* ---------------------------------------------------------------- */
/* Buttons */
/* ---------------------------------------------------------------- */
.buttons {
  display: flex;
  margin-top: auto;
  padding-top: 40px;
  @media (max-width: 1536px) {
    padding-top: 36px;
  }
  @media (max-width: 1408px) {
    padding-top: 32px;
  }
  @media (max-width: 1280px) {
    padding-top: 28px;
  }
  @media (max-width: 767px) {
    padding-top: 24px;
  }
  @media (max-width: 375px) {
    padding-top: 16px;
  }
  button[class] {
    min-width: 0;
    > span {
      font-family: $primary;
      font-weight: $regular;
    }
  }
}
