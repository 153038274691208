@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ------------------------------------------------------------ */
/* Candidate Number Form */
/* ------------------------------------------------------------ */
.candidateIdForm {
  box-sizing: border-box;
  flex-direction: column;
  padding: 0 32px 28px;
  position: relative;
  width: 100%;
  @media (max-width: 1536px) {
    padding: 0 28px 24px;
  }
  @media (max-width: 1280px) {
    padding: 0 24px 20px;
  }
  @media (max-width: 1152px) and (min-width: 768px), (max-width: 360px) {
    padding: 24px 0 0 0;
  }
  /* ---------------------------------------------------------- */
  /* Label */
  /* ---------------------------------------------------------- */
  label {
    font-size: 16px;
    white-space: nowrap;
    @include no-user-select;
    @media (max-width: 832px) and (min-width: 801px) {
      font-size: 15px;
    }
    @media (max-width: 800px) and (min-width: 768px) {
      font-size: 14px;
    }
  }
  /* ---------------------------------------------------------- */
  /* Input */
  /* ---------------------------------------------------------- */
  input[type][id] {
    padding-right: 40px;
  }
  /* ---------------------------------------------------------- */
  /* Form */
  /* ---------------------------------------------------------- */
  > form {
    display: flex;
    position: relative;
    width: 100%;
    /* ---------------------------------------------------------- */
    /* Question */
    /* ---------------------------------------------------------- */
    > div {
      flex-grow: 1;
      margin-bottom: 0;
      /* ---------------------------------------------------------- */
      /* Help Text & Icon */
      /* ---------------------------------------------------------- */
      > svg {
        right: 12px;
        top: 18px;
        transform: none;
        @media (max-width: 1024px) {
          top: 19px;
        }
        + [role='tooltip'] {
          right: -47px;
          top: 9px;
          width: 380px;
          z-index: 9;
          @media (max-width: 767px) {
            width: 240px;
          }
          @media (max-width: 1408px) {
            right: -40px;
          }
          @media (max-width: 1280px) {
            right: -27px;
          }
          @media (max-width: 1024px) {
            right: -19px;
          }
          @media (max-width: 375px) {
            right: -15px;
          }
        }
      }
      /* ---------------------------------------------------------- */
      /* Error Message */
      /* ---------------------------------------------------------- */
      p:last-child {
        font-size: 14px;
        line-height: 18px;
        margin: 5px 0 -4px;
      }
    }
    /* -------------------------------------------------------------- */
    /* Button */
    /* -------------------------------------------------------------- */
    button[class][type] {
      height: 56px;
      margin: 0 25px 0 24px;
      @media (max-width: 1664px) {
        margin-left: 20px;
        padding: 0 9px 0 28px;
      }
      @media (max-width: 1536px) {
        margin-left: 16px;
        padding: 0 5px 0 24px;
      }
      @media (max-width: 1280px) {
        margin-left: 12px;
      }
      @media (max-width: 940px) and (min-width: 768px), (max-width: 360px) {
        margin-left: 8px;
      }
    }
    /* ---------------------------------------------------------- */
    /* Loader */
    /* ---------------------------------------------------------- */
    > span[class] {
      background-size: 28px 28px;
      height: 32px;
      margin-top: 0;
      position: absolute;
      right: 16px;
      top: 12px;
      width: 32px;
      z-index: 2;
      @media (max-width: 1664px) {
        right: 12px;
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Error */
  /* ---------------------------------------------------------------- */
  .errorResponse {
    align-self: flex-start;
    color: red;
    font-size: 14px;
    line-height: 18px;
    margin: 5px 0 -4px;
    text-align: left;
    /* ---------------------------------------------------------------- */
    /* Link */
    /* ---------------------------------------------------------------- */
    a {
      color: red;
      font-weight: $semibold;
      margin: 0;
      padding: 0;
      text-decoration: underline;
      white-space: pre;
      @include fast-transition($props: 'color');
      &:focus {
        color: darken(red, 20%);
        text-decoration: none;
      }
      :global(.platform-desktop) & {
        &:hover {
          color: darken(red, 10%);
        }
        &:active {
          color: darken(red, 20%);
        }
      }
      :global(.platform-mobile) & {
        &:active {
          color: darken(red, 10%);
        }
      }
    }
  }
}
