@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Payment History Year */
/* ---------------------------------------------------------------- */
.paymentHistoryYear {
  @include no-user-select;
  /* -------------------------------------------------------------- */
  /* Additional */
  /* -------------------------------------------------------------- */
  & + .paymentHistoryYear {
    margin-top: 20px;
    @media (max-width: 1536px) {
      margin-top: 16px;
    }
    @media (max-width: 1280px) {
      margin-top: 12px;
    }
    @media (max-width: 375px) {
      margin-top: 8px;
    }
  }
  /* -------------------------------------------------------------- */
  /* Top */
  /* -------------------------------------------------------------- */
  > div:first-child {
    align-items: center;
    background-color: $white;
    border-color: $mono2;
    border-radius: 9px;
    border-style: solid;
    border-width: 1px;
    box-sizing: border-box;
    display: flex;
    min-height: 80px;
    padding: 0 32px;
    position: relative;
    width: auto;
    @media (max-width: 1280px) {
      min-height: 72px;
      padding: 0 28px;
    }
    @media (max-width: 1024px) {
      min-height: 64px;
      padding: 0 24px;
    }
    @media (max-width: 640px) {
      border-radius: 4px;
      min-height: 63px;
    }
    @media (max-width: 460px) {
      min-height: 62px;
    }
    @media (max-width: 375px) {
      min-height: 56px;
      padding: 0 16px;
    }
    /* ------------------------------------------------------------ */
    /* Year */
    /* ------------------------------------------------------------ */
    > h3 {
      color: $brandBlackGrape;
      font-size: 18px;
      letter-spacing: normal;
      line-height: 18px;
      @include user-select;
      @media (max-width: 1280px) {
        font-size: 17px;
        line-height: 17px;
      }
      @media (max-width: 375px) {
        font-size: 16px;
        line-height: 16px;
      }
      > span {
        color: $mono4;
        display: block;
        font-size: 17px;
        line-height: 17px;
        padding-bottom: 11px;
        @media (max-width: 1280px) {
          font-size: 16px;
          line-height: 16px;
          padding-bottom: 7px;
        }
        @media (max-width: 375px) {
          font-size: 15px;
          line-height: 15px;
          padding-bottom: 3px;
        }
      }
    }
    /* ------------------------------------------------------------ */
    /* Button */
    /* ------------------------------------------------------------ */
    > button {
      background-color: $mono1;
      border-radius: 50%;
      cursor: pointer;
      display: block;
      flex-shrink: 0;
      height: 28px;
      position: absolute;
      right: 32px;
      top: 50%;
      transform: translateY(-50%);
      width: 28px;
      @include no-user-select;
      @media (max-width: 1280px) {
        right: 28px;
      }
      @media (max-width: 1024px) {
        right: 24px;
      }
      @media (max-width: 375px) {
        height: 24px;
        right: 16px;
        width: 24px;
      }
      &:focus::after {
        border-color: $mono3;
      }
      /* ---------------------------------------------------------- */
      /* Focus Ring */
      /* ---------------------------------------------------------- */
      &::after {
        border-color: transparent;
        border-radius: 50%;
        border-style: dashed;
        border-width: 2px;
        box-sizing: border-box;
        content: '';
        height: calc(100% + 12px);
        left: -6px;
        pointer-events: none;
        position: absolute;
        top: -6px;
        width: calc(100% + 12px);
        @include fast-transition($props: 'border-color');
      }
      /* ---------------------------------------------------------- */
      /* SVG */
      /* ---------------------------------------------------------- */
      > svg {
        fill: $brandBlackGrape;
        transform: rotate(-180deg);
        will-change: transform;
        @include fast-transition($mob: true, $props: 'transform');
      }
    }
  }
  /* -------------------------------------------------------------- */
  /* Bottom */
  /* -------------------------------------------------------------- */
  > div:last-child {
    opacity: 1;
    overflow: hidden;
    transform: scaleY(1);
    transform-origin: 0 0;
    will-change: height, opacity, transform;
    @include medium-transition(
      $firefox: false,
      $mob: true,
      $props: 'height, opacity, transform'
    );
  }
  /* -------------------------------------------------------------- */
  /* Closed */
  /* -------------------------------------------------------------- */
  &.closed {
    > div:first-child > button > svg {
      transform: rotate(0deg);
    }
    > div:last-child {
      height: 0;
      opacity: 0;
      transform: scaleY(0.6);
    }
    /* ------------------------------------------------------------ */
    /* Prevent Content Children From Being Focused */
    /* ------------------------------------------------------------ */
    &:not(.animating) > div:last-child > * {
      visibility: hidden;
    }
  }
}
