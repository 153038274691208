@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Subscription */
/* ---------------------------------------------------------------- */
.subscription {
  align-items: stretch;
  background-color: white;
  border-color: $brandAqua;
  border-radius: 9px;
  border-style: solid;
  border-width: 1px;
  box-sizing: border-box;
  display: flex;
  padding: 32px;
  position: relative;
  width: auto;
  @media (max-width: 1280px) {
    padding: 28px;
  }
  @media (max-width: 1024px) {
    padding: 24px;
  }
  @media (max-width: 640px) {
    border-radius: 4px;
    flex-direction: column;
  }
  @media (max-width: 375px) {
    padding: 16px;
  }
  &:nth-child(2) {
    margin-top: 48px;
    @media (max-width: 1536px) {
      margin-top: 40px;
    }
    @media (max-width: 1280px) {
      margin-top: 32px;
    }
    @media (max-width: 375px) {
      margin-top: 24px;
    }
  }
  + .subscription {
    margin-top: 20px;
    @media (max-width: 1536px) {
      margin-top: 16px;
    }
    @media (max-width: 1280px) {
      margin-top: 12px;
    }
    @media (max-width: 375px) {
      margin-top: 8px;
    }
  }
  /* -------------------------------------------------------------- */
  /* Left */
  /* -------------------------------------------------------------- */
  > div:first-child {
    color: $brandBlackGrape;
    display: flex;
    flex-direction: column;
    padding-right: 20px;
    @media (max-width: 1536px) {
      padding-right: 18px;
    }
    @media (max-width: 1280px) {
      padding-right: 16px;
    }
    /* ------------------------------------------------------------ */
    /* Title */
    /* ------------------------------------------------------------ */
    > h4 {
      font-size: 20px;
      font-weight: $bold;
      line-height: 28px;
      margin-top: -6px;
      @media (max-width: 1536px) {
        font-size: 19px;
        line-height: 27px;
      }
      @media (max-width: 1280px) {
        font-size: 18px;
        line-height: 26px;
      }
      @media (max-width: 640px) {
        font-size: 17px;
        line-height: 25px;
      }
      @media (max-width: 375px) {
        font-size: 16px;
        line-height: 24px;
        margin-top: -5px;
      }
    }
    /* ------------------------------------------------------------ */
    /* Subscription */
    /* ------------------------------------------------------------ */
    > p:nth-child(2) {
      display: block;
      font-size: 20px;
      line-height: 27px;
      margin-top: 8px;
      white-space: nowrap;
      @media (max-width: 1536px) {
        font-size: 19px;
        line-height: 26px;
        margin-top: 4px;
      }
      @media (max-width: 1280px) {
        font-size: 18px;
        line-height: 25px;
        margin-top: 2px;
      }
      @media (max-width: 375px) {
        font-size: 17px;
        line-height: 24px;
        margin-top: 0;
      }
    }
    /* ------------------------------------------------------------ */
    /* Price */
    /* ------------------------------------------------------------ */
    > p:nth-child(3) {
      font-family: $secondary;
      font-size: 32px;
      line-height: 39px;
      margin-top: auto;
      padding-top: 18px;
      @media (max-width: 1536px) {
        font-size: 31px;
        line-height: 38px;
        padding-top: 14px;
      }
      @media (max-width: 1280px) {
        font-size: 30px;
        line-height: 37px;
        padding-top: 10px;
      }
      @media (max-width: 375px) {
        font-size: 29px;
        line-height: 38px;
        padding-top: 6px;
      }
      &:last-child {
        margin-bottom: -12px;
      }
      > span {
        font-size: 20px;
        @media (max-width: 1536px) {
          font-size: 19px;
        }
        @media (max-width: 1280px) {
          font-size: 18px;
        }
        @media (max-width: 375px) {
          font-size: 17px;
        }
      }
    }
    /* ------------------------------------------------------------ */
    /* Renewal Date */
    /* ------------------------------------------------------------ */
    > p:nth-child(4) {
      color: $brandBlackGrape;
      font-size: 17px;
      line-height: 25px;
      margin: 5px 0 -6px 0;
      @media (max-width: 1536px) {
        font-size: 16px;
        line-height: 24px;
        margin-top: 2px;
      }
      @media (max-width: 1280px) {
        font-size: 15px;
        line-height: 23px;
        margin-top: -2px;
      }
      @media (max-width: 375px) {
        font-size: 14px;
        line-height: 20px;
        margin-top: -5px;
      }
      > strong {
        font-weight: $bold;
      }
    }
  }
  /* -------------------------------------------------------------- */
  /* Right */
  /* -------------------------------------------------------------- */
  > div:last-child {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-left: auto;
    @media (max-width: 640px) {
      align-items: flex-start;
      margin: 24px 0 0 0;
    }
    @media (max-width: 375px) {
      margin-top: 22px;
    }
    /* ------------------------------------------------------------ */
    /* Upgrade Text */
    /* ------------------------------------------------------------ */
    > p {
      color: $brandBlackGrape;
      display: block;
      font-size: 17px;
      line-height: 25px;
      margin: -6px 0 0 auto;
      padding-bottom: 18px;
      text-align: right;
      @media (max-width: 1536px) {
        font-size: 16px;
        line-height: 24px;
        padding-bottom: 14px;
      }
      @media (max-width: 1280px) {
        font-size: 15px;
        line-height: 23px;
        padding-bottom: 10px;
      }
      @media (max-width: 640px) {
        margin-left: 0;
        text-align: left;
      }
      @media (max-width: 375px) {
        font-size: 14px;
        line-height: 20px;
        padding-bottom: 7px;
      }
      > strong {
        font-weight: $bold;
      }
    }
    /* ------------------------------------------------------------ */
    /* Button */
    /* ------------------------------------------------------------ */
    > button {
      margin: auto 0 0 auto;
      min-width: 222px;
      @media (max-width: 1536px) {
        min-width: 200px;
      }
      @media (max-width: 1280px) {
        min-width: 179px;
      }
      @media (max-width: 767px) {
        min-width: 149px;
      }
      @media (max-width: 640px) {
        margin-left: 0;
        min-width: 232px;
      }
      @media (max-width: 375px) {
        min-width: 100%;
      }
    }
  }
}
