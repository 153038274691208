@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Social Links */
/* ---------------------------------------------------------------- */
.socialLinks {
  display: flex;
  @media (max-width: 1024px) {
    order: 1;
  }
  @media (max-width: 375px) {
    justify-content: space-between;
    width: 100%;
  }
  a {
    align-items: center;
    display: flex;
    margin-right: 45px;
    position: relative;
    @include no-user-select;
    @media (max-width: 1536px) {
      margin-right: 25px;
    }
    @media (max-width: 1152px) {
      margin-right: 15px;
    }
    &:last-child {
      margin-right: 0;
    }
    &::after {
      border-color: transparent;
      border-style: dashed;
      border-width: 2px;
      bottom: 0;
      box-sizing: content-box;
      content: '';
      height: 100%;
      left: 50%;
      padding: 3px;
      position: absolute;
      right: 0;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
    }
    &:focus {
      &::after {
        border-color: $brandBerry;
      }
      svg {
        opacity: 0.9;
      }
    }
    > svg {
      fill: $white;
      height: 24px;
      width: auto;
      @media (max-width: 375px) {
        height: 22px;
      }
    }
  }
}
