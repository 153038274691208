@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Fieldset */
/* ---------------------------------------------------------------- */
.fieldset {
  &:not(:first-of-type) {
    > legend {
      @media (max-width: 1408px) {
        margin-top: 4px;
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Legend */
  /* ---------------------------------------------------------------- */
  > legend {
    float: left;
    margin: 0 0 32px;
    width: 100%;
    @media (max-width: 1536px) {
      margin: 0 0 28px;
    }
    @media (max-width: 1280px) {
      margin: 0 0 24px;
    }
    @media (max-width: 1024px) {
      margin: 0 0 20px;
    }
    + * {
      clear: both;
    }
    /* ---------------------------------------------------------------- */
    /* Title */
    /* ---------------------------------------------------------------- */
    h2 {
      font-size: 20px;
      font-weight: $semibold;
      line-height: 27px;
      margin: -6px 0;
      @media (max-width: 1536px) {
        font-size: 19px;
        line-height: 26px;
      }
      @media (max-width: 1280px) {
        font-size: 18px;
        line-height: 25px;
      }
      @media (max-width: 1024px) {
        font-size: 16px;
        line-height: 24px;
      }
      &.cpdTitle {
        align-items: center;
        display: flex;
        justify-content: space-between;
        padding-right: 0;
        > svg {
          height: 38px;
          width: 38px;
          @media (max-width: 1408px) {
            height: 32px;
            margin-left: 20px;
            width: 32px;
          }
          @media (max-width: 1024px) {
            height: 28px;
            margin-left: 20px;
            width: 28px;
          }
        }
      }
    }
  }
}
