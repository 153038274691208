@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Question Payment Method */
/* ---------------------------------------------------------------- */
.questionPaymentMethod {
  display: block;
  /* -------------------------------------------------------------- */
  /* Error */
  /* -------------------------------------------------------------- */
  > p {
    color: red;
    font-size: 17px;
    line-height: 24px;
    margin: 7px 0 -6px;
    @media (max-width: 1536px) {
      font-size: 16px;
      line-height: 23px;
      margin-top: 6px;
    }
    @media (max-width: 1408px) {
      margin-top: 5px;
    }
    @media (max-width: 1280px) {
      font-size: 15px;
      line-height: 20px;
      margin-bottom: -4px;
      margin-top: 4px;
    }
    @media (max-width: 1024px) {
      margin-top: 3px;
    }
    &.input-extra-small {
      margin-top: 2px;
      padding-left: 19px;
    }
  }
}
