@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Spinner */
/* ---------------------------------------------------------------- */
.spinner {
  align-items: center;
  background-color: $brandBlackGrape;
  border-radius: 50%;
  box-sizing: border-box;
  display: flex;
  flex-shrink: 0;
  height: 64px;
  justify-content: center;
  padding: 4px;
  width: 64px;
  @media (max-width: 1536px) {
    height: 56px;
    width: 56px;
  }
  @media (max-width: 1280px) {
    height: 48px;
    width: 48px;
  }
  /* ---------------------------------------------------------------- */
  /* Icon */
  /* ---------------------------------------------------------------- */
  > svg {
    flex-shrink: 0;
    height: 100%;
    width: 100%;
    /* ---------------------------------------------------------------- */
    /* Group */
    /* ---------------------------------------------------------------- */
    > g[data-name] {
      fill: transparent;
    }
    /* ---------------------------------------------------------------- */
    /* Circle */
    /* ---------------------------------------------------------------- */
    path:nth-child(1)[d] {
      fill: transparent;
    }
  }
  /* ---------------------------------------------------------------- */
  /* No Background */
  /* ---------------------------------------------------------------- */
  &.noBackground {
    background-color: transparent;
    padding: 0;
  }
  /* ---------------------------------------------------------------- */
  /* Aqua */
  /* ---------------------------------------------------------------- */
  &.Aqua {
    > svg path:nth-child(1) {
      stroke: $brandAqua;
    }
    > svg path:nth-child(2) {
      fill: $brandAqua;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Black Grape */
  /* ---------------------------------------------------------------- */
  &.BlackGrape {
    > svg path:nth-child(1) {
      stroke: $brandBlackGrape;
    }
    > svg path:nth-child(2) {
      fill: $brandBlackGrape;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Berry */
  /* ---------------------------------------------------------------- */
  &.Berry {
    > svg path:nth-child(1) {
      stroke: $brandBerry;
    }
    > svg path:nth-child(2) {
      fill: $brandBerry;
    }
  }
  /* ---------------------------------------------------------------- */
  /* White */
  /* ---------------------------------------------------------------- */
  &.White {
    > svg path:nth-child(1) {
      stroke: $white;
    }
    > svg path:nth-child(2) {
      fill: $white;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Mono 1 */
  /* ---------------------------------------------------------------- */
  &.Mono1 {
    > svg path:nth-child(1) {
      stroke: $mono1;
    }
    > svg path:nth-child(2) {
      fill: $mono1;
    }
  }
}
