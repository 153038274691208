@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Bookmarks Container */
/* ---------------------------------------------------------------- */
.bookmarks {
  display: flex;
  flex-direction: column;
  padding: 72px 0;
  width: 100%;
  @media (max-width: 1664px) {
    padding: 64px 0;
  }
  @media (max-width: 1536px) {
    padding: 56px 0;
  }
  @media (max-width: 1408px) {
    padding: 48px 0;
  }
  @media (max-width: 1280px) {
    padding: 32px 0;
  }
  @media (max-width: 1024px) {
    padding: 24px 0;
  }
  @media (max-width: 767px) {
    padding: 32px 0;
  }
  @media (max-width: 375px) {
    padding: 24px 0;
  }
  /* ---------------------------------------------------------------- */
  /* Title */
  /* ---------------------------------------------------------------- */
  h1 {
    font-size: 32px;
    font-weight: $semibold;
    line-height: 42px;
    margin: -8px 0 -9px 0;
    @media (max-width: 1536px) {
      font-size: 28px;
      line-height: 37px;
      margin: -10px 0 -11px 0;
    }
    @media (max-width: 1280px) {
      font-size: 24px;
      line-height: 32px;
    }
    @media (max-width: 375px) {
      font-size: 22px;
      line-height: 30px;
      margin: -11px 0 -12px 0;
    }
  }
  /* -------------------------------------------------------------- */
  /* Show More Button */
  /* -------------------------------------------------------------- */
  .showMoreButton {
    background-color: $mono1;
    border-radius: 11px;
    cursor: pointer;
    display: block;
    font-family: $primary;
    font-size: 16px;
    font-weight: $semibold;
    height: 48px;
    margin: 32px auto 72px auto;
    max-width: 256px;
    width: 100%;
    @media (max-width: 1408px) {
      margin-bottom: 56px;
    }
  }
}
