@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Braintree Field */
/* ---------------------------------------------------------------- */
.braintreeField {
  display: block;
  margin: 0 0 32px;
  position: relative;
  z-index: 1;
  @media (max-width: 1536px) {
    margin: 0 0 28px;
  }
  @media (max-width: 1408px) {
    margin: 0 0 24px;
  }
  @media (max-width: 1280px) {
    margin: 0 0 20px;
  }
  @media (max-width: 1024px) {
    margin: 0 0 16px;
  }
  /* ---------------------------------------------------------------- */
  /* Input Wrapper */
  /* ---------------------------------------------------------------- */
  .field {
    background-color: $white;
    border-color: $mono2;
    border-radius: 9px;
    border-style: solid;
    border-width: 1px;
    box-sizing: border-box;
    position: relative;
    width: 100%;
    @include fast-transition($props: 'border-color, background-color');
    /* ---------------------------------------------------------------- */
    /* Input Inner Wrapper */
    /* ---------------------------------------------------------------- */
    > div:last-of-type {
      height: calc(80px - 25px);
      margin: 25px calc(32px - 1rem) 0;
      overflow: hidden;
      @media (max-width: 1536px) {
        height: calc(72px - 22px);
        margin: 22px calc(28px - 1rem) 0;
      }
      @media (max-width: 1280px) {
        height: 64px;
        height: calc(64px - 20px);
        margin: 20px calc(24px - 1rem) 0;
      }
      @media (max-width: 1024px) {
        height: calc(56px - 19px);
        margin: 19px calc(20px - 1rem) 0;
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Select Wrapper */
  /* ---------------------------------------------------------------- */
  &.selectField {
    svg {
      height: 10px;
      pointer-events: none;
      width: 14px;
      path {
        fill: currentColor;
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Label */
  /* ---------------------------------------------------------------- */
  span {
    color: $mono4;
    cursor: pointer;
    display: inline-block;
    font-size: 20px;
    left: 31px;
    line-height: 20px;
    pointer-events: none;
    position: absolute;
    top: 0;
    transform: translate3d(0, 30px, 0);
    @include no-user-select;
    @include fast-transition($props: 'transform, font-size');
    @media (max-width: 1536px) {
      font-size: 19px;
      left: 27px;
      transform: translate3d(0, 26px, 0);
    }
    @media (max-width: 1280px) {
      font-size: 18px;
      left: 23px;
      transform: translate3d(0, 22px, 0);
    }
    @media (max-width: 1024px) {
      font-size: 17px;
      left: 19px;
      transform: translate3d(0, 18px, 0);
    }
    @media (max-width: 767px) {
      font-size: 16px;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Help Icon */
  /* ---------------------------------------------------------------- */
  > div > svg {
    cursor: pointer;
    height: 20px;
    position: absolute;
    right: 31px;
    top: 50%;
    transform: translateY(-50%);
    width: 20px;
    z-index: 10;
    @media (max-width: 1536px) {
      right: 27px;
    }
    @media (max-width: 1280px) {
      height: 19px;
      right: 23px;
      width: 19px;
    }
    @media (max-width: 1024px) {
      height: 18px;
      right: 19px;
      width: 18px;
    }
    ~ .field:last-child > input {
      padding-right: 80px;
      @media (max-width: 1536px) {
        padding-right: 72px;
      }
      @media (max-width: 1280px) {
        padding-right: 63px;
      }
      @media (max-width: 1024px) {
        padding-right: 54px;
      }
    }
    :global(.platform-desktop) & {
      &:hover + [role='tooltip'] {
        opacity: 1;
        transform: translateY(-100%) scale(1);
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Help Tooltip */
  /* ---------------------------------------------------------------- */
  [role='tooltip'] {
    background-color: $white;
    border: 1px solid $mono2;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.16) 0 3px 6px;
    box-sizing: border-box;
    font-size: 17px;
    height: auto;
    line-height: 25px;
    opacity: 0;
    padding: 11px 16px;
    position: absolute;
    right: -28px;
    top: 21px;
    transform: translateY(-100%) scale(0);
    transform-origin: calc(100% - 68px) calc(100% + 10px);
    transition-duration: 0.2s;
    transition-property: transform, opacity;
    transition-timing-function: ease-in-out;
    width: 208px;
    z-index: 9;
    @media (max-width: 1536px) {
      font-size: 16px;
      line-height: 24px;
      right: -32px;
      top: 13px;
      width: 200px;
    }
    @media (max-width: 1408px) {
      right: -24px;
      transform-origin: calc(100% - 61px) calc(100% + 10px);
    }
    @media (max-width: 1280px) {
      font-size: 15px;
      line-height: 23px;
      right: -16px;
      top: 9px;
      transform-origin: calc(100% - 48px) calc(100% + 10px);
      width: 188px;
    }
    @media (max-width: 1024px) {
      right: -12px;
      top: 7px;
      transform-origin: calc(100% - 39px) calc(100% + 10px);
    }
    @media (max-width: 375px) {
      font-size: 14px;
      line-height: 22px;
      right: -8px;
      transform-origin: calc(100% - 35px) calc(100% + 10px);
      width: 178px;
    }
    &::after {
      background-image: url(/images/icons/help-chevron.svg);
      background-repeat: no-repeat;
      background-size: cover;
      bottom: -23px;
      content: '';
      display: block;
      height: 30px;
      position: absolute;
      right: 54px;
      width: 30px;
      @media (max-width: 1408px) {
        right: 47px;
      }
      @media (max-width: 1280px) {
        right: 34px;
      }
      @media (max-width: 1024px) {
        right: 25px;
      }
      @media (max-width: 375px) {
        right: 21px;
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Show Help Tooltip - Mobile */
  /* ---------------------------------------------------------------- */
  &.showHelp {
    [role='tooltip'] {
      opacity: 1;
      transform: translateY(-100%) scale(1);
    }
  }
  /* ---------------------------------------------------------------- */
  /* Sticky Label */
  /* ---------------------------------------------------------------- */
  &.notEmpty,
  &.focused {
    span {
      font-size: 17px;
      transform: translate3d(0, 17px, 0);
      @media (max-width: 1536px) {
        font-size: 16px;
        transform: translate3d(0, 14px, 0);
      }
      @media (max-width: 1280px) {
        font-size: 15px;
        transform: translate3d(0, 11px, 0);
      }
      @media (max-width: 1024px) {
        font-size: 14px;
        transform: translate3d(0, 7px, 0);
      }
      @media (max-width: 767px) {
        font-size: 13px;
      }
    }
  }
  &.invalid {
    .field {
      border-color: red;
    }
  }
  &.focused {
    .field {
      border-color: $brandBlackGrape;
    }
  }
  &.isSubmitting {
    pointer-events: none;
    .field {
      > div {
        opacity: 0.5;
      }
      svg path {
        fill: $mono4;
      }
      background-color: $mono1;
    }
  }
}
