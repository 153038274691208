@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Overview */
/* ---------------------------------------------------------------- */
.overview {
  margin-bottom: 28px;
  @media (max-width: 1536px) {
    margin-bottom: 24px;
  }
  @media (max-width: 1280px) {
    margin-bottom: 20px;
  }
  &:last-child {
    margin-bottom: 0;
  }
  /* ---------------------------------------------------------------- */
  /* Title */
  /* ---------------------------------------------------------------- */
  h3 {
    align-items: center;
    color: rgba($brandBlackGrape, 50%);
    display: flex;
    font-family: $secondary;
    font-size: 18px;
    font-weight: $semibold;
    letter-spacing: 0.16px;
    line-height: 22px;
    margin-bottom: 13px;
    margin-top: -4px;
    text-transform: uppercase;
    @media (max-width: 1536px) {
      font-size: 17px;
      line-height: 21px;
      margin-bottom: 10px;
    }
    @media (max-width: 1280px) {
      font-size: 16px;
      line-height: 20px;
      margin-bottom: 6px;
    }
    @media (max-width: 1024px) {
      font-size: 15px;
      line-height: 19px;
      margin-bottom: 7px;
    }
    @media (max-width: 767px) {
      font-size: 16px;
      line-height: 20px;
      margin-bottom: 5px;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Text */
  /* ---------------------------------------------------------------- */
  p {
    font-size: 17px;
    line-height: 25px;
    margin-bottom: -6px;
    @media (max-width: 1536px) {
      font-size: 16px;
      line-height: 24px;
    }
    @media (max-width: 1280px) {
      font-size: 15px;
      line-height: 23px;
    }
    @media (max-width: 1024px) and (min-width: 768px) {
      font-size: 14px;
      line-height: 21px;
      margin-bottom: -5px;
    }
    /* ---------------------------------------------------------------- */
    /* Badges Earned */
    /* ---------------------------------------------------------------- */
    span {
      font-weight: $semibold;
    }
  }
}
