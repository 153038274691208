@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* -------------------------------------------------------------- */
/* Skeleton */
/* -------------------------------------------------------------- */
.skeleton {
  overflow: hidden;
  position: relative;
  &::after {
    @include skeleton-shimmer;
    @include skeleton-shimmer-color($color: $pageBackground);
  }
  /* -------------------------------------------------------------- */
  /* Title */
  /* -------------------------------------------------------------- */
  > p {
    font-family: $secondary;
    font-size: 32px;
    line-height: 39px;
    margin-bottom: -7px;
    @media (max-width: 1536px) {
      font-size: 30px;
      line-height: 36px;
      margin-bottom: -6px;
    }
    @media (max-width: 1280px) {
      font-size: 27px;
      line-height: 33px;
    }
    @media (max-width: 1024px) {
      font-size: 24px;
      line-height: 30px;
    }
    &::after {
      background-color: $mono1;
      color: transparent;
      content: 'Lorem ipsum dolor sit amet,';
      font-size: inherit;
      line-height: inherit;
      @include rounded-skeleton-text;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Description */
  /* ---------------------------------------------------------------- */
  > span {
    display: block;
    font-family: $secondary;
    font-size: 20px;
    line-height: 27px;
    margin: 33px 0 40px;
    @media (max-width: 1536px) {
      font-size: 19px;
      line-height: 26px;
      margin: 29px 0 36px;
    }
    @media (max-width: 1408px) {
      margin: 25px 0 32px;
    }
    @media (max-width: 1280px) {
      font-size: 18px;
      line-height: 25px;
      margin: 22px 0 28px;
    }
    @media (max-width: 1024px) {
      font-size: 17px;
      line-height: 24px;
      margin: 18px 0 25px;
    }
    @media (max-width: 767px) {
      font-size: 16px;
      line-height: 23px;
    }
    &::after {
      background-color: $mono1;
      color: transparent;
      /* stylelint-disable-next-line max-line-length */
      content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore';
      font-size: inherit;
      line-height: inherit;
      @include rounded-skeleton-text;
    }
  }
}

@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}
