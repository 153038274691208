@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Back Button */
/* ---------------------------------------------------------------- */
.back {
  align-items: center;
  color: rgba($brandBlackGrape, 50%);
  display: inline-flex;
  font-family: $secondary;
  font-size: 18px;
  font-weight: $semibold;
  margin: -4px 0 18px;
  text-transform: uppercase;
  @include fast-transition($props: color);
  @media (max-width: 1536px) {
    font-size: 17px;
    margin-bottom: 16px;
  }
  @media (max-width: 1280px) {
    font-size: 16px;
    margin-bottom: 14px;
  }
  @media (max-width: 1024px) {
    font-size: 15px;
    margin-bottom: 12px;
  }
  @media (max-width: 767px) {
    display: table;
    margin: -4px 0 14px;
    padding-left: 19px;
    position: relative;
  }
  span {
    order: 2;
  }
  svg {
    height: 11px;
    margin-right: 15px;
    order: 1;
    transform: rotate(180deg);
    width: 6px;
    @media (max-width: 1536px) {
      margin-right: 14px;
    }
    @media (max-width: 1280px) {
      margin-right: 13px;
    }
    @media (max-width: 1024px) {
      margin-right: 12px;
    }
    @media (max-width: 767px) {
      left: 0;
      position: absolute;
      top: 3px;
    }
    * {
      fill: rgba($brandBlackGrape, 50%);
      @include fast-transition($props: fill);
    }
  }
  &:focus {
    color: $brandBerry;
    text-decoration: underline;
    svg * {
      fill: $brandBerry;
    }
  }
  :global(.platform-desktop) & {
    &:hover {
      color: $brandBerry;
      svg * {
        fill: $brandBerry;
      }
    }
    &:active {
      color: $brandBerryDark;
      svg * {
        fill: $brandBerryDark;
      }
    }
  }
  :global(.platform-mobile) & {
    &:active {
      color: $brandBerry;
      svg * {
        fill: $brandBerry;
      }
    }
  }
}
