@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* -------------------------------------------------------------- */
/* Purchase Summary Skeleton */
/* -------------------------------------------------------------- */
.skeleton {
  overflow: hidden;
  position: relative;
  &::after {
    @include skeleton-shimmer;
    @include skeleton-shimmer-color($color: $white);
    @media (max-width: 767px) {
      @include skeleton-shimmer-color($color: $pageBackground);
    }
  }
  .back {
    align-items: center;
    display: inline-flex;
    font-size: 20px;
    margin: 0 0 18px;
    text-transform: uppercase;
    @include fast-transition($props: color);
    @media (max-width: 1536px) {
      font-size: 18px;
      margin-bottom: 16px;
    }
    @media (max-width: 1280px) {
      font-size: 17px;
      margin-bottom: 14px;
    }
    @media (max-width: 1024px) {
      font-size: 16px;
      margin-bottom: 12px;
    }
    @media (max-width: 767px) {
      display: table;
      margin: 0 0 14px;
      position: relative;
    }
    &::after {
      background-color: $mono1;
      color: transparent;
      content: '<> Back to plan page';
      font-size: inherit;
      line-height: inherit;
      @include rounded-skeleton-text;
    }
  }
  h3 {
    position: relative;
    span {
      display: block;
      font-size: 32px;
      line-height: 24px;
      @media (max-width: 1536px) {
        font-size: 28px;
        line-height: 21px;
      }
      @media (max-width: 1280px) {
        font-size: 24px;
        line-height: 18px;
      }
      @media (max-width: 1024px) {
        font-size: 20px;
        line-height: 15px;
      }
      &::after {
        background-color: $mono1;
        color: transparent;
        content: 'Purchase summary';
        font-size: inherit;
        line-height: inherit;
        @include rounded-skeleton-text;
      }
    }
  }
  .productName {
    font-size: 20px;
    font-weight: $bold;
    line-height: 30px;
    margin: -6px 0 22px;
    @media (max-width: 1536px) {
      font-size: 19px;
      line-height: 28px;
      margin-bottom: 18px;
    }
    @media (max-width: 1280px) {
      font-size: 18px;
      line-height: 26px;
      margin-bottom: 14px;
    }
    @media (max-width: 1024px) {
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 12px;
    }
    @media (max-width: 767px) {
      margin-bottom: 18px;
    }
    &::after {
      background-color: $mono1;
      color: transparent;
      content: 'MyAxelos Standard Individual Subscription';
      font-size: inherit;
      line-height: inherit;
      @include rounded-skeleton-text;
    }
  }
  span {
    font-size: 17px;
    line-height: 25px;
    @media (max-width: 1536px) {
      font-size: 16px;
      line-height: 24px;
    }
    @media (max-width: 1280px) {
      font-size: 15px;
      line-height: 22px;
    }
    @media (max-width: 1024px) {
      font-size: 14px;
      line-height: 20px;
    }
    @media (max-width: 767px) {
      font-size: 15px;
      line-height: 22px;
    }
    &::after {
      background-color: $mono1;
      color: transparent;
      font-size: inherit;
      line-height: inherit;
      @include rounded-skeleton-text;
    }
    &.bundle::after {
      content: 'Bundle price: £50.00';
    }
    &:last-child::after {
      content: 'Total: £50.00';
    }
    &.tax::after {
      content: 'Tax/VAT (0%): £0.00';
    }
    &.total {
      margin-bottom: 0;
    }
  }
}

@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}
