@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Notifications */
/* ---------------------------------------------------------------- */
.notifications {
  align-items: center;
  background-color: $mono1;
  border-radius: 10px;
  box-sizing: border-box;
  display: flex;
  margin: 0 0 40px;
  padding: 12px 20px;
  @include medium-transition(
    $mob: true,
    $props: 'height, margin, opacity, padding'
  );
  @media (max-width: 1153px) and (min-width: 768px), (max-width: 724px) {
    align-items: flex-start;
    flex-direction: column;
    padding: 16px 54px 15px 16px;
    position: relative;
  }
  + .notifications {
    margin-top: -24px;
    @media (max-width: 375px) {
      margin-top: -28px;
    }
  }
  > div {
    /* ---------------------------------------------------------------- */
    /* Icon */
    /* ---------------------------------------------------------------- */
    &:nth-of-type(1) {
      align-items: center;
      background-color: $white;
      border-radius: 50%;
      display: flex;
      flex-shrink: 0;
      height: 48px;
      justify-content: center;
      margin: 0 20px 0 0;
      width: 48px;
      @media (max-width: 1536px) {
        height: 44px;
        width: 44px;
      }
      @media (max-width: 1280px) {
        height: 40px;
        margin-right: 16px;
        width: 40px;
      }
      @media (max-width: 1153px) and (min-width: 768px), (max-width: 724px) {
        display: none;
      }
      > svg {
        height: 16px;
        width: 16px;
      }
    }
    /* ---------------------------------------------------------------- */
    /* Action */
    /* ---------------------------------------------------------------- */
    &:nth-of-type(2) {
      display: block;
      font-size: 17px;
      font-weight: $semibold;
      line-height: 21px;
      padding-right: 20px;
      @media (max-width: 1536px) {
        font-size: 16px;
        line-height: 20px;
      }
      @media (max-width: 1280px) {
        font-size: 15px;
        line-height: 19px;
        padding-right: 16px;
      }
      @media (max-width: 1153px) and (min-width: 768px), (max-width: 724px) {
        margin: 0 0 12px;
        padding: 0;
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Date */
  /* ---------------------------------------------------------------- */
  time {
    display: block;
    font-family: $secondary;
    font-size: 17px;
    line-height: 21px;
    margin: 0 20px 0 0;
    white-space: nowrap;
    @media (max-width: 1536px) {
      font-size: 16px;
      line-height: 20px;
    }
    @media (max-width: 1280px) {
      font-size: 15px;
      line-height: 19px;
      margin-right: 16px;
    }
    @media (max-width: 1153px) and (min-width: 768px), (max-width: 724px) {
      margin: 0 0 4px;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Show Modal Button */
  /* ---------------------------------------------------------------- */
  > button {
    margin: 0 0 0 auto;
    @include no-user-select;
    &.button {
      @media (max-width: 1153px) and (min-width: 768px), (max-width: 724px) {
        background-color: transparent;
        cursor: pointer;
        height: auto;
        margin: 0;
        padding: 0;
        touch-action: manipulation;
        transition: none;
        span {
          color: $brandBerry;
          font-family: $secondary;
          font-size: 17px;
          font-weight: $bold;
          @include fast-transition($props: 'color');
        }
        svg {
          height: 12px;
          padding-left: 15px;
          width: 7px;
          path {
            fill: $brandBerry;
            @include fast-transition($props: 'fill');
          }
        }
        &:focus {
          span {
            color: $brandBerryLight;
            text-decoration: underline;
          }
          svg path {
            fill: $brandBerryLight;
          }
        }
        :global(.platform-desktop) & {
          &:hover {
            background-color: transparent;
            span {
              color: $brandBerryDark;
            }
            svg path {
              fill: $brandBerryDark;
            }
          }
          &:active {
            background-color: transparent;
            span {
              color: $brandBerryLight;
            }
            svg path {
              fill: $brandBerryLight;
            }
          }
        }
        :global(.platform-mobile) & {
          &:active {
            background-color: transparent;
            span {
              color: $brandBerryDark;
            }
            svg path {
              fill: $brandBerryDark;
            }
          }
        }
      }
      @media (max-width: 375px) {
        span {
          font-size: 16px;
        }
      }
    }
    /* ---------------------------------------------------------------- */
    /* Close Notifications Button */
    /* ---------------------------------------------------------------- */
    &:nth-of-type(2) {
      cursor: pointer;
      flex-shrink: 0;
      height: 18px;
      margin: 0 0 0 47px;
      position: relative;
      touch-action: manipulation;
      width: 18px;
      @media (max-width: 1536px) {
        margin-left: 39px;
      }
      @media (max-width: 1408px) {
        margin-left: 31px;
      }
      @media (max-width: 1280px) {
        margin-left: 25px;
      }
      @media (max-width: 1153px) and (min-width: 768px), (max-width: 724px) {
        margin: 0;
        position: absolute;
        right: 16px;
        top: 16px;
      }
      /* ---------------------------------------------------------------- */
      /* Focus Ring */
      /* ---------------------------------------------------------------- */
      &::after {
        border: 2px dashed transparent;
        border-radius: 50%;
        content: '';
        display: block;
        height: calc(100% + 10px);
        left: -7px;
        position: absolute;
        top: -7px;
        width: calc(100% + 10px);
        @include fast-transition($props: 'border-color');
      }
      &:focus::after {
        border-color: $brandBlackGrape;
      }
      > svg {
        height: 18px;
        width: 18px;
      }
    }
  }
}
