@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Modal Confirm */
/* ---------------------------------------------------------------- */
.modalConfirm {
  /* ---------------------------------------------------------------- */
  /* Scrollable Content */
  /* ---------------------------------------------------------------- */
  > div {
    /* ---------------------------------------------------------------- */
    /* Text */
    /* ---------------------------------------------------------------- */
    p {
      display: block;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Buttons */
  /* ---------------------------------------------------------------- */
  .buttons {
    align-self: flex-start;
    justify-content: space-between;
    width: 100%;
  }
}
