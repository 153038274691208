@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Social */
/* ---------------------------------------------------------------- */
.social {
  align-items: center;
  color: $brandBlackGrape;
  display: flex;
  font-weight: $semibold;
  height: 17px;
  margin: 0;
  white-space: nowrap;
  @media (max-width: 1536px) {
    height: 16px;
  }
  @media (max-width: 1280px) {
    height: 15px;
  }
  @media (max-width: 1024px) {
    height: 14px;
  }
  @media (max-width: 896px) and (min-width: 768px), (max-width: 576px) {
    height: 17px;
    margin-right: 20px;
  }
  @media (max-width: 480px) {
    height: 16px;
  }
  @media (max-width: 375px) {
    height: 15px;
  }
  /* ---------------------------------------------------------------- */
  /* Buttons */
  /* ---------------------------------------------------------------- */
  button {
    cursor: pointer;
    position: relative;
    touch-action: manipulation;
    /* ---------------------------------------------------------------- */
    /* Focus Ring */
    /* ---------------------------------------------------------------- */
    &::after {
      border-color: transparent;
      border-style: dashed;
      border-width: 2px;
      content: '';
      display: block;
      height: calc(100% + 4px);
      left: -4px;
      position: absolute;
      top: -4px;
      width: calc(100% + 4px);
      @include fast-transition($props: 'border-color');
    }
    /* ---------------------------------------------------------------- */
    /* Focus */
    /* ---------------------------------------------------------------- */
    &:focus::after {
      border-color: $brandBerry;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Icons */
  /* ---------------------------------------------------------------- */
  svg {
    cursor: pointer;
  }
  /* ---------------------------------------------------------------- */
  /* Bookmark */
  /* ---------------------------------------------------------------- */
  .bookmark {
    display: flex;
    margin-right: 12px;
    @media (max-width: 1280px) {
      margin-right: 10px;
    }
    @media (max-width: 1024px) {
      margin-right: 8px;
    }
    @media (max-width: 896px) and (min-width: 768px), (max-width: 576px) {
      margin-right: 16px;
    }
    @media (max-width: 480px) {
      margin-right: 14px;
    }
    @media (max-width: 375px) {
      margin-right: 12px;
    }
    /* ---------------------------------------------------------------- */
    /* Button + Icon */
    /* ---------------------------------------------------------------- */
    button,
    svg {
      height: 17px;
      width: 13px;
      @media (max-width: 1536px) {
        height: 16px;
        width: 13px;
      }
      @media (max-width: 1280px) {
        height: 15px;
        width: 12px;
      }
      @media (max-width: 1024px) {
        height: 14px;
        width: 11px;
      }
      @media (max-width: 896px) and (min-width: 768px), (max-width: 576px) {
        height: 17px;
        width: 13px;
      }
      @media (max-width: 480px) {
        height: 16px;
        width: 13px;
      }
      @media (max-width: 375px) {
        height: 15px;
        width: 12px;
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Likes */
  /* ---------------------------------------------------------------- */
  .likes {
    align-items: center;
    color: $brandBlackGrape;
    display: flex;
    font-size: 17px;
    font-weight: $semibold;
    @media (max-width: 1536px) {
      font-size: 16px;
    }
    @media (max-width: 1280px) {
      font-size: 15px;
    }
    @media (max-width: 1024px) {
      font-size: 14px;
    }
    @media (max-width: 896px) and (min-width: 768px), (max-width: 576px) {
      font-size: 17px;
    }
    @media (max-width: 480px) {
      font-size: 16px;
    }
    @media (max-width: 375px) {
      font-size: 15px;
    }
    /* ---------------------------------------------------------------- */
    /* Button + Icon */
    /* ---------------------------------------------------------------- */
    button,
    svg {
      height: 17px;
      width: 19px;
      @media (max-width: 1536px) {
        height: 16px;
        width: 18px;
      }
      @media (max-width: 1280px) {
        height: 15px;
        width: 17px;
      }
      @media (max-width: 1024px) {
        height: 14px;
        width: 16px;
      }
      @media (max-width: 896px) and (min-width: 768px), (max-width: 576px) {
        height: 17px;
        width: 19px;
      }
      @media (max-width: 480px) {
        height: 16px;
        width: 18px;
      }
      @media (max-width: 375px) {
        height: 15px;
        width: 17px;
      }
    }
    /* ---------------------------------------------------------------- */
    /* Button */
    /* ---------------------------------------------------------------- */
    button {
      margin-right: 8px;
      @media (max-width: 1280px) {
        margin-right: 7px;
      }
      @media (max-width: 1024px) {
        margin-right: 6px;
      }
      @media (max-width: 896px) and (min-width: 768px), (max-width: 576px) {
        margin-right: 12px;
      }
      @media (max-width: 480px) {
        margin-right: 10px;
      }
      @media (max-width: 375px) {
        margin-right: 8px;
      }
      &:only-child {
        margin-right: 0;
      }
    }
    /* ---------------------------------------------------------------- */
    /* Text */
    /* ---------------------------------------------------------------- */
    > span {
      @include no-user-select;
    }
  }
}
