@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* CPDTrackRecord */
/* ---------------------------------------------------------------- */
.CPDTrackRecord {
  display: flex;
  gap: 20px;
  min-height: 200px;
  min-width: 600px;

  & .date {
    min-height: 100px;
    width: 60px;
    text-align: right;
    color: #000;
    font-weight: 600;
  }

  & .status {
    display: flex;
    flex-direction: column;
    position: relative;

    & span:first-of-type {
      border: 4px solid $cpdTrackInitialYear;
      border-radius: 50%;
      font-size: 30px;
      height: 40px;
      width: 40px;
      background: $cpdTrackInitialYear;
      position: relative;
      top: -15px;
      left: auto;
      right: auto;
      bottom: auto;

      &::before {
        content: '\2713';
        padding: 0 8px;
        color: #fff;
      }
    }

    & span {
      width: 4px;
      background: $cpdTrackInitialYear;
      height: 95%;
      position: absolute;
      top: 33px;
      left: 23px;

      &::before {
        content: '';
        height: 150px;
        width: 5px;
        padding: 5px;
      }
    }
  }

  & .years {
    min-width: 400px;
    & h3 {
      margin-bottom: 0;
    }

    & p {
      font-size: 14px;
      color: #000;
      margin-bottom: 30px;
    }

    & div.pointsSummary {
      margin: 20px 0;
      display: flex;
      gap: 30px;
      color: #000;
      font-weight: 800;
      @media (max-width: 767px) {
        gap: 5px;
        flex-direction: column;
      }

      & span {
        min-width: 100px;
      }
      & span.pointsDescription {
        cursor: pointer;
        color: #f50a33;
        font-weight: normal;
        margin-right: 40px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }

      &:last-child {
        margin-bottom: 30px;
      }
      .lastItem {
        margin-left: auto;
        text-align: end;
        @media (max-width: 767px) {
          margin-left: 0px;
        }
      }
    }

    & button {
      margin: 30px 0;
    }
  }

  & .points {
    border: 1px solid pink;
  }

  &:last-child {
    min-height: auto;
    & .status {
      & span:last-of-type {
        display: none;
      }
    }
  }
}

.initialYearState {
  & .status {
    & span:first-of-type {
      background: $white;

      &::before {
        content: '';
      }
    }
    & span {
      background: $mono2;
    }
  }
}

.partialYearState {
  & .status {
    & span:first-of-type {
      background: $white;

      &::before {
        content: '';
      }
    }
    & span {
      background: $mono2;
    }
  }
}

.completeYearState {
  & .date {
    color: $cpdTrackCompleteYear;
  }

  // button {
  //   display: none;
  // }

  & .years {
    & div.pointsSummary:last-of-type {
      margin-bottom: 60px;
    }
  }
}

.incompleteYearState {
  & .status {
    & span:first-of-type {
      border-color: $mono3;
      background: $white;

      &::before {
        content: '\00d7';
        padding: 0 11px;
        color: #878787;
        font-weight: 600;
      }
    }

    & span {
      background: $mono2;
    }
  }

  & .years {
    & h3,
    p {
      color: $mono4;
    }

    & div.pointsSummary {
      & span {
        color: $mono3;
      }
    }

    & div.pointsSummary:last-of-type {
      margin-bottom: 60px;
    }
  }

  button {
    display: none;
  }
}

.futureYearState {
  & .date {
    color: $mono2;
    visibility: hidden;
  }

  & .status {
    & span:first-of-type {
      border-color: $mono2;
      background: $white;

      &::before {
        content: '';
      }
    }

    & span {
      background: $mono2;
    }
  }

  & .years {
    & h3,
    p {
      color: $mono3;
    }
  }
}

.incompleteFutureYearState {
  & .date {
    color: $mono2;
    visibility: hidden;
  }

  & .status {
    & span:first-of-type {
      border-color: $mono2;
      background: $white;

      &::before {
        content: '';
      }
    }

    & span {
      background: $mono2;
    }
  }

  & .years {
    & h3,
    p {
      color: $mono3;
    }
  }
}

.missedExamRenewalState {
  & .date {
    color: $mono2;
    visibility: hidden;
  }

  & .status {
    & span:first-of-type {
      border-color: $mono2;
      background: $white;

      &::before {
        content: '\00d7';
      }
    }

    & span {
      background: $mono2;
    }
  }

  & .years {
    & h3,
    p {
      color: $mono3;
    }
  }
}

.pointsRemainingText {
  color: red;
  font-size: medium;
}
