@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
$white: #fff;
$black: #000;
$grey001: #f9f9f9;
$grey002: #c4c5ca;
$grey003: #767983;
$brandAqua: #6de2e2;
$brandBlackGrape: #282d3d;
$red: #ce2d4f;

body,
html,
#root {
  height: 100%;
}

body {
  min-height: 620px;
  min-width: 320px;
  @media (max-width: 540px) {
    // background-color: $grey001;
  }
}

label {
  margin-bottom: 22px;
  // font-size: 18px !important;
  font-weight: 600;
  // line-height: 24px !important;
  color: black !important;
}

.fakeComponentDiv {
  display: block;
  height: 100%;
  background-color: white;
  background-image: url('/images/artwork/myAxelos_user_consent.webp');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  bottom: 0;
  content: '';
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;
}

$background-color: $grey001;
$input-border: $grey002;
$error: $red;
$borderLight: rgba(40, 45, 61, 0.2);

// Hover States
$brandBlackGrapeDark: #191c26;
$brandBlackGrapeLight: #3c414f;
/* Fonts
------------------------------------------------------------------------------------ */
$primary: 'Montserrat', sans-serif;
$secondary: 'Open Sans', sans-serif;

#loadOverlay {
  background-color: #fff;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 2000;
}

// @use 'variables' as *;
// @use 'mixins' as *;

.consent {
  // background-color: $grey001;
  // border: 1px solid $grey002;
  // border-radius: 14px;
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  // padding: 0 16px;
  position: relative;
  @media (max-width: 540px) {
    align-items: flex-start;
    padding: 0;
  }
  /* Desktop Background
  ----------------------------------------------------------------------------------- */
  // &::before {
  //   background-image: url('/images/artwork/myAxelos_user_consent.webp');
  //   background-position: center;
  //   background-repeat: no-repeat;
  //   background-size: cover;
  //   bottom: 0;
  //   content: '';
  //   left: 0;
  //   position: absolute;
  //   right: 0;
  //   top: 0;
  //   z-index: -1;
  //   @media (max-width: 540px) {
  //     display: none;
  //   }
  // }
  /* Popup
  ----------------------------------------------------------------------------------- */
  .popup {
    background-color: $grey001;
    border: 1px solid $grey002;
    border-radius: 14px;
    color: $black;
    max-width: 1144px;
    width: 100%;
    @media (max-width: 540px) {
      border: 0;
      border-radius: 0;
    }

    /* Popup header
    ----------------------------------------------------------------------------------- */
    h1 {
      align-items: center;
      border-bottom: 1px solid $borderLight;
      color: $brandBlackGrape;
      display: flex;
      font-family: $primary;
      font-size: 32px;
      justify-content: space-between;
      line-height: 40px;
      padding: 24px 40px;
      text-align: left;
      @media (max-width: 1536px) {
        font-size: 28px;
        line-height: 36px;
        padding-left: 36px;
        padding-right: 36px;
      }
      @media (max-width: 1280px) {
        font-size: 24px;
        line-height: 32px;
        padding-left: 32px;
        padding-right: 32px;
      }
      @media (max-width: 1024px) {
        font-size: 20px;
        line-height: 28px;
        padding-left: 28px;
        padding-right: 28px;
      }
      @media (max-width: 767px) {
        font-size: 18px;
        line-height: 26px;
        padding: 24px 24px;
      }
      @media (max-width: 540px) {
        background-color: $white;
        padding: 20px 16px;
      }
      span {
        padding-right: 20px;
        &.mobileTitle {
          display: none;
          @media (max-width: 540px) {
            display: block;
          }
        }
        &.desktopTitle {
          @media (max-width: 540px) {
            display: none;
          }
        }
      }
      /* Close consent button
      ----------------------------------------------------------------------------------- */
      button {
        cursor: pointer;
        font-size: 0;
        line-height: 0;
        outline: none;
        position: relative;
        touch-action: manipulation;
        @include no-user-select;
        &:focus-visible {
          &::before {
            border: 2px dotted $brandBlackGrape;
            border-radius: 50%;
            box-sizing: border-box;
            content: '';
            height: calc(100% + 16px);
            left: -8px;
            position: absolute;
            top: -8px;
            transition-duration: 0.175s;
            transition-property: border-color;
            transition-timing-function: ease-in-out;
            width: calc(100% + 16px);
          }
        }
        > svg {
          height: 20px;
          width: 20px;
          &:hover path,
          &:active path {
            fill: $brandBlackGrapeLight;
          }
          &:focus path {
            fill: $brandBlackGrapeDark;
          }
        }
      }
    }
    .popupBody {
      padding: 24px 40px;
      @media (max-width: 1536px) {
        padding-left: 36px;
        padding-right: 36px;
      }
      @media (max-width: 1280px) {
        padding-left: 32px;
        padding-right: 32px;
      }
      @media (max-width: 1024px) {
        padding-left: 28px;
        padding-right: 28px;
      }
      @media (max-width: 767px) {
        padding: 24px 24px;
      }
      @media (max-width: 540px) {
        padding: 20px 16px;
      }
      .termsText {
        padding-bottom: 48px;
        @media (max-width: 1280px) {
          padding-bottom: 40px;
        }
        @media (max-width: 1024px) {
          padding-bottom: 32px;
        }
        /* Mobile body header
        ----------------------------------------------------------------------------------- */
        h2 {
          display: none;
          font-family: $primary;
          font-size: 24px;
          line-height: 32px;
          margin-bottom: 24px;
          text-align: left;
          @media (max-width: 540px) {
            display: block;
          }
          @media (max-width: 380px) {
            font-size: 20px;
            line-height: 28px;
          }
        }
        /* Body text
        ----------------------------------------------------------------------------------- */
        p {
          color: $brandBlackGrape;
          font-family: $secondary;
          font-size: 18px;
          line-height: 26px;
          text-align: left;
          @media (max-width: 1536px) {
            font-size: 17px;
            line-height: 25px;
          }
          @media (max-width: 1280px) {
            font-size: 16px;
            line-height: 24px;
          }
          @media (max-width: 767px) {
            font-size: 15px;
            line-height: 23px;
          }
          + p {
            margin-top: 24px;
          }
        }
      }
      /* Consent checkboxes
      ----------------------------------------------------------------------------------- */
      .form {
        ul > li {
          align-items: center;
          display: flex;
          margin-bottom: 22px;
          &:last-child {
            margin-bottom: 0;
          }
          input[type='checkbox'] {
            -webkit-appearance: none;
            appearance: none;
            border: 1px solid $grey002;
            border-radius: 5px;
            cursor: pointer;
            height: 25px;
            margin-left: 0;
            margin-right: 10px;
            outline: none;
            width: 25px;
            &:focus-visible {
              background-image: url('/images/artwork/checkbox_focus_no_tick.svg');
              background-repeat: no-repeat;
              border: 0;
            }
            &:checked {
              background-image: url('/images/artwork/check_box_ticked.svg');
              background-repeat: no-repeat;
              border: 0;
              &:focus-visible {
                background-image: url('./checkbox_focus_with_tick.svg');
                background-repeat: no-repeat;
                border: 0;
              }
            }
          }
          label > a {
            color: $brandBlackGrape;
            font-family: $secondary;
            font-size: 18px;
            font-weight: 600;
            line-height: 24px;
            @include no-user-select;
            @media (max-width: 1536px) {
              font-size: 17px;
              line-height: 23px;
            }
            @media (max-width: 1280px) {
              font-size: 16px;
              line-height: 22px;
            }
            @media (max-width: 767px) {
              font-size: 15px;
              line-height: 21px;
            }
            &:hover,
            &:active {
              color: $brandBlackGrapeLight;
            }
            &:focus-visible {
              color: $brandBlackGrapeDark;
              text-decoration: none;
            }
          }
        }
        /* Submit button
        ----------------------------------------------------------------------------------- */
        .buttons {
          margin-top: 56px;
          @media (max-width: 1536px) {
            margin-top: 48px;
          }
          @media (max-width: 1280px) {
            margin-top: 40px;
          }
          @media (max-width: 1024px) {
            margin-top: 32px;
          }
          /* Button */
          #agree {
            background-color: $brandBlackGrape;
            border: 0;
            border-radius: 10px;
            color: $white;
            cursor: pointer;
            font-family: $secondary;
            font-size: 20px;
            height: 64px;
            touch-action: manipulation;
            width: 148px;
            @include fast-transition($props: 'background-color');
            @include no-user-select;
            &:hover,
            &:active {
              background-color: $brandBlackGrapeLight;
            }
            &:focus {
              background-color: $brandBlackGrapeDark;
              text-decoration: underline;
            }
            &:disabled {
              background-color: $grey003;
              cursor: not-allowed;
              &:hover,
              &:active {
                background-color: $grey003;
              }
            }
            @media (max-width: 1280px) {
              font-size: 18px;
              height: 56px;
              line-height: 24px;
              width: 140px;
            }
            @media (max-width: 767px) {
              font-size: 16px;
              height: 48px;
              line-height: 22px;
              width: 132px;
            }
          }
        }
      }
      /* Hide B2C Markup
      ----------------------------------------------------------------------------------- */
      #api {
        display: none;
      }
    }
  }
}

@mixin user-select() {
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
}
@mixin no-user-select() {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
@mixin fast-transition($timing: ease-in-out, $props: 'all') {
  transition-duration: 0.175s;
  transition-property: #{$props};
  transition-timing-function: #{$timing};
}
