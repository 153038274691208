@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* -------------------------------------------------------------- */
/* Legal Text Skeleton */
/* -------------------------------------------------------------- */
.skeleton {
  max-width: 990px;
  overflow: hidden;
  position: relative;
  @media (max-width: 1536px) {
    max-width: 790px;
  }
  @media (max-width: 1152px) {
    max-width: 690px;
  }
  @media (max-width: 895px) {
    margin-top: 32px;
    max-width: 100%;
    order: 2;
  }
  @media (max-width: 375px) {
    margin-top: 24px;
  }
  &::after {
    @include skeleton-shimmer;
    @include skeleton-shimmer-color($color: $brandBlackGrape);
  }
  /* -------------------------------------------------------------- */
  /* Text */
  /* -------------------------------------------------------------- */
  p {
    font-size: 15px;
    line-height: 24px;
    + p {
      margin-top: 10px;
    }
  }
  small {
    &::after {
      background-color: $brandBlackGrapeLight;
      color: transparent;
      content: '© myAXELOS 2021';
      font-size: inherit;
      line-height: inherit;
      text-transform: inherit;
      @include rounded-skeleton-text;
    }
  }
}

@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}
