@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Badges */
/* ---------------------------------------------------------------- */
.badges {
  padding-bottom: 36px;
  @media (max-width: 1280px) {
    padding-bottom: 32px;
  }
  @media (max-width: 1024px) {
    padding-bottom: 24px;
  }
  @media (max-width: 767px) {
    padding-bottom: 32px;
  }
  @media (max-width: 375px) {
    padding-bottom: 24px;
  }
  &:last-child {
    padding-bottom: 0;
  }
  /* ---------------------------------------------------------------- */
  /* Title (Certificates / Designations) */
  /* ---------------------------------------------------------------- */
  > h3 {
    color: $brandBlackGrape;
    font-size: 20px;
    font-weight: $semibold;
    line-height: 24px;
    margin: -4px 0;
    padding-bottom: 28px;
    @media (max-width: 1536px) {
      font-size: 19px;
      line-height: 23px;
    }
    @media (max-width: 1280px) {
      font-size: 18px;
      line-height: 22px;
      margin-top: -5px;
      padding-bottom: 24px;
    }
    @media (max-width: 1024px) {
      font-size: 17px;
      line-height: 21px;
      margin-top: -4px;
      padding-bottom: 20px;
    }
    @media (max-width: 767px) {
      font-size: 18px;
      line-height: 22px;
      padding-bottom: 24px;
    }
    @media (max-width: 375px) {
      padding-bottom: 20px;
    }
    /* ---------------------------------------------------------------- */
    /* Category Title */
    /* ---------------------------------------------------------------- */
    + h4 {
      margin-top: -9px;
      @media (max-width: 1280px) {
        margin-top: -8px;
      }
    }
    /* ---------------------------------------------------------------- */
    /* Badges List */
    /* ---------------------------------------------------------------- */
    + ul {
      margin-top: -9px;
      @media (max-width: 1280px) {
        margin-top: -8px;
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Category Title */
  /* ---------------------------------------------------------------- */
  > h4 {
    color: rgba($brandBlackGrape, 0.5);
    font-family: $secondary;
    font-size: 18px;
    font-weight: $semibold;
    letter-spacing: 0.16px;
    line-height: 22px;
    margin: -4px 0 -5px;
    padding-bottom: 24px;
    text-transform: uppercase;
    @media (max-width: 1536px) {
      font-size: 17px;
      line-height: 21px;
    }
    @media (max-width: 1280px) {
      font-size: 16px;
      line-height: 20px;
      padding-bottom: 20px;
    }
    @media (max-width: 1024px) {
      font-size: 15px;
      line-height: 19px;
      padding-bottom: 16px;
    }
    @media (max-width: 767px) {
      font-size: 16px;
      line-height: 20px;
      padding-bottom: 20px;
    }
    @media (max-width: 375px) {
      padding-bottom: 16px;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Badges List */
  /* ---------------------------------------------------------------- */
  > ul {
    display: grid;
    grid-gap: 16px;
    grid-template-columns: 356px 356px;
    @media (max-width: 1280px) {
      grid-gap: 12px;
      grid-template-columns: calc(50% - 6px) calc(50% - 6px);
    }
    @media (max-width: 896px) and (min-width: 768px), (max-width: 640px) {
      grid-gap: 16px;
      grid-template-columns: 100%;
    }
    /* ---------------------------------------------------------------- */
    /* Category Title */
    /* ---------------------------------------------------------------- */
    + h4 {
      padding-top: 36px;
      @media (max-width: 1280px) {
        padding-top: 32px;
      }
      @media (max-width: 1024px) {
        padding-top: 24px;
      }
      @media (max-width: 767px) {
        padding-top: 32px;
      }
      @media (max-width: 375px) {
        padding-top: 24px;
      }
    }
  }
}
