@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* -------------------------------------------------------------- */
/* Key Templates Item */
/* -------------------------------------------------------------- */
.keyTemplatesItem {
  border-bottom: solid 1px $grey;
  display: flex;
  padding: 28px 32px;
  @media (max-width: 1536px) {
    padding: 24px 28px;
  }
  @media (max-width: 1280px) {
    padding: 20px 24px;
  }
  @media (max-width: 1152px) and (min-width: 768px), (max-width: 360px) {
    padding: 20px 0;
  }
  &:last-child {
    border: none;
    @media (max-width: 1152px) and (min-width: 768px), (max-width: 360px) {
      border-bottom: solid 1px $grey;
    }
  }
  @media (max-width: 1152px) and (min-width: 768px), (max-width: 360px) {
    &:first-child {
      border-top: solid 1px $grey;
    }
  }
  /* -------------------------------------------------------------- */
  /* Icon */
  /* -------------------------------------------------------------- */
  > svg {
    flex-shrink: 0;
    height: 36px;
    margin-right: 16px;
    width: 36px;
    @media (max-width: 1536px) {
      height: 32px;
      margin-right: 12px;
      width: 32px;
    }
    @media (max-width: 1280px) {
      height: 28px;
      width: 28px;
    }
    @media (max-width: 1024px) and (min-width: 768px), (max-width: 360px) {
      display: none;
    }
  }
  /* -------------------------------------------------------------- */
  /* Content Container */
  /* -------------------------------------------------------------- */
  > div:nth-of-type(1) {
    flex: 1;
    font-size: 18px;
    line-height: 22px;
    margin: -4px 0;
    @media (max-width: 1536px) {
      font-size: 17px;
      line-height: 21px;
    }
    @media (max-width: 1280px) {
      font-size: 16px;
      line-height: 20px;
    }
    @media (max-width: 1024px) {
      font-size: 15px;
      line-height: 19px;
    }
  }
  /* -------------------------------------------------------------- */
  /* Text */
  /* -------------------------------------------------------------- */
  p {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    font-size: 18px;
    font-weight: $semibold;
    line-height: 22px;
    margin: 0 0 7px;
    @media (max-width: 1536px) {
      font-size: 17px;
      line-height: 21px;
      margin: 0 0 4px;
    }
    @media (max-width: 1280px) {
      font-size: 16px;
      line-height: 20px;
    }
    @media (max-width: 1024px) {
      font-size: 15px;
      line-height: 19px;
    }
  }
  /* -------------------------------------------------------------- */
  /* Category Text */
  /* -------------------------------------------------------------- */
  > div:nth-of-type(1) > span {
    color: rgba($brandBlackGrape, 50%);
    display: flex;
    flex-wrap: wrap;
    font-size: 18px;
    line-height: 22px;
    @media (max-width: 1536px) {
      font-size: 17px;
      line-height: 21px;
    }
    @media (max-width: 1280px) {
      font-size: 16px;
      line-height: 20px;
    }
    @media (max-width: 1024px) {
      font-size: 15px;
      line-height: 19px;
    }
  }
  /* -------------------------------------------------------------- */
  /* Cta Container */
  /* -------------------------------------------------------------- */
  > div:nth-of-type(2) {
    display: flex;
    /* -------------------------------------------------------------- */
    /* Link */
    /* -------------------------------------------------------------- */
    > a[class][href] {
      margin-left: 24px;
    }
  }
}
