@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* CPDTrack */
/* ---------------------------------------------------------------- */
.CPDTrack {
  // margin-top: 50px;
  margin-bottom: 28px;
  @media (max-width: 1536px) {
    margin-bottom: 24px;
  }
  @media (max-width: 1280px) {
    margin-bottom: 20px;
  }
  &:last-child {
    margin-bottom: 0;
  }
  /* ---------------------------------------------------------------- */
  /* Title */
  /* ---------------------------------------------------------------- */
  h3 {
    color: $otherBestPractices;
    align-items: center;
    display: flex;
    font-family: $secondary;
    font-size: 18px;
    font-weight: $semibold;
    letter-spacing: 0.16px;
    line-height: 22px;
    margin-bottom: 13px;
    margin-top: -4px;
    // text-transform: uppercase;
    @media (max-width: 1536px) {
      font-size: 17px;
      line-height: 21px;
      margin-bottom: 10px;
    }
    @media (max-width: 1280px) {
      font-size: 16px;
      line-height: 20px;
      margin-bottom: 6px;
    }
    @media (max-width: 1024px) {
      font-size: 15px;
      line-height: 19px;
      margin-bottom: 7px;
    }
    @media (max-width: 767px) {
      font-size: 16px;
      line-height: 20px;
      margin-bottom: 5px;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Text */
  /* ---------------------------------------------------------------- */
  p {
    font-size: 17px;
    line-height: 25px;
    margin-bottom: -6px;
    color: $otherBestPractices;
    @media (max-width: 1536px) {
      font-size: 16px;
      line-height: 24px;
    }
    @media (max-width: 1280px) {
      font-size: 15px;
      line-height: 23px;
    }
    @media (max-width: 1024px) and (min-width: 768px) {
      font-size: 14px;
      line-height: 21px;
      margin-bottom: -5px;
    }
    /* ---------------------------------------------------------------- */
    /* Badges Earned */
    /* ---------------------------------------------------------------- */
    span {
      font-weight: $semibold;
    }
  }

  & .expirationDate {
    margin-bottom: 60px;
    h3:first-of-type {
      color: rgba($brandBlackGrape, 50%);
      text-transform: uppercase;
    }
  }
  & .notEligible {
    & p {
      font-size: 16px;
      line-height: 24px;
      color: $otherBestPractices;
    }

    & button {
      margin-top: 30px;
    }
  }

  & .certifications {
    margin-bottom: 60px;

    & div.certification {
      display: flex;
      gap: 20px;
      flex-wrap: wrap;
    }
  }
  & .prince2 > span {
    background-image: url('/images/placeholders/PRINCE2.png');
    background-position: right;
    background-size: cover;
    width: 276px;
    height: 80px;
    position: absolute;
  }
  & .itil > span {
    background-image: url('/images/placeholders/ITIL.png');
    background-position: right;
    background-size: cover;
    width: 276px;
    height: 80px;
    position: absolute;
  }
  & .resilia > span {
    background-image: url('/images/placeholders/RESILIA.png');
    background-position: right;
    background-size: cover;
    width: 276px;
    height: 90px;
    position: absolute;
  }

  & .CPDTrackRecord {
    margin-bottom: 60px;

    & .record {
      border: 1px solid $mono2;
      border-radius: 10px;
      height: auto;
      background: #fff;
      padding: 60px 80px;
      overflow-x: auto;
      @media (max-width: 767px) {
        padding: 30px 10px;
      }
    }
  }

  & .peopleCertPath {
    margin-bottom: 60px;
    & h3 {
      margin-bottom: 30px;
    }
  }

  .agileShift,
  .prince2,
  .resilia,
  .itil {
    margin-top: 10px;
    width: max-content;
    padding: 15px;
    margin-left: 0;
    border-radius: 7px;

    .itemContent {
      .title {
        color: white;
        display: flex;
        margin-bottom: 5px;
        font-size: medium !important;
        .imgSize {
          width: 15px;
          margin-right: 5px;
        }
      }
    }
  }

  .itil,
  .resilia,
  .agileShift {
    .detailText {
      color: white;
      margin: 5px;
    }
  }

  & .prince2 {
    background-color: rgb(46 191 165);
    .detailText {
      color: black;
      margin: 5px;
    }
  }
  & .itil {
    background-color: #8f63e5;
  }

  & .agileShift {
    background-color: #f35289;
  }

  & .resilia {
    background-color: rgb(185 0 92);
  }
}
.noCertificate {
  margin: 0;

  & h3 {
    color: rgba($brandBlackGrape, 50%);
  }

  & p {
    color: #000;
    font-weight: 600;
  }

  & .peopleCertButton {
    margin: 50px 0 30px 0;
  }
}

.noCpdYears {
  margin-bottom: 60px;
  button {
    margin-top: 30px;
  }
}
