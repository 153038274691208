@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Button */
/* ---------------------------------------------------------------- */
.button {
  align-items: center;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-flex;
  flex-shrink: 0;
  position: relative;
  touch-action: manipulation;
  vertical-align: top;
  @include fast-transition($props: 'background-color');
  @include no-user-select;
  /* -------------------------------------------------------------- */
  /* Primary */
  /* -------------------------------------------------------------- */
  &.primary {
    border-radius: 40px 0 0 40px;
    height: 64px;
    justify-content: flex-start;
    margin-right: 29px;
    padding: 0 6px 0 40px;
    @media (max-width: 1536px) {
      height: 56px;
      margin-right: 26px;
      padding: 0 4px 0 32px;
    }
    @media (max-width: 1280px) {
      height: 48px;
      margin-right: 22px;
      padding: 0 2px 0 24px;
    }
    span {
      font-family: $primary;
      font-size: 18px;
      font-weight: $semibold;
      @media (max-width: 1536px) {
        font-size: 17px;
      }
      @media (max-width: 1280px) {
        font-size: 16px;
      }
    }
    .chevron {
      height: 21px;
      padding-left: 25px;
      width: 8px;
      @media (max-width: 1536px) {
        height: 18px;
        padding-left: 19px;
        width: 7px;
      }
      @media (max-width: 1280px) {
        height: 15px;
        padding-left: 15px;
        width: 6px;
      }
      * {
        @include fast-transition($props: 'fill');
      }
      &:first-child {
        padding: 0 10px 0 0;
        @media (max-width: 1536px) {
          padding: 0 10px 0 4px;
        }
        @media (max-width: 1280px) {
          padding: 0 8px 0 4px;
        }
      }
    }
    .slice {
      right: -29px;
      width: 30px;
      @media (max-width: 1536px) {
        right: -26px;
        width: 27px;
      }
      @media (max-width: 1280px) {
        right: -22px;
        width: 23px;
      }
    }
  }
  /* -------------------------------------------------------------- */
  /* Primary - Chevron */
  /* -------------------------------------------------------------- */
  .chevron {
    flex-shrink: 0;
    margin-left: auto;
    position: relative;
    z-index: 1;
  }
  /* -------------------------------------------------------------- */
  /* Primary - Slice (63 Degree) */
  /* -------------------------------------------------------------- */
  .slice {
    bottom: 0;
    flex-shrink: 0;
    height: 100%;
    position: absolute;
    top: 0;
    z-index: 0;
    * {
      @include fast-transition($props: 'fill');
    }
  }
  /* -------------------------------------------------------------- */
  /* Secondary */
  /* -------------------------------------------------------------- */
  &.secondary {
    border-radius: 11px;
    height: 72px;
    justify-content: center;
    padding: 0 56px;
    @media (max-width: 1536px) {
      height: 64px;
      padding: 0 48px;
    }
    @media (max-width: 1280px) {
      height: 56px;
      padding: 0 40px;
    }
    @media (max-width: 767px) {
      height: 48px;
      padding: 0 28px;
    }
    span {
      font-family: $primary;
      font-size: 20px;
      font-weight: $bold;
      @media (max-width: 1536px) {
        font-size: 19px;
      }
      @media (max-width: 1280px) {
        font-size: 18px;
      }
      @media (max-width: 767px) {
        font-size: 17px;
      }
    }
  }
  /* -------------------------------------------------------------- */
  /* Small */
  /* -------------------------------------------------------------- */
  &.small {
    &.primary {
      height: 48px;
      margin-right: 23px;
      padding: 0 13px 0 32px;
      @media (max-width: 1536px) {
        padding: 0 12px 0 30px;
      }
      @media (max-width: 1280px) {
        padding: 0 11px 0 28px;
      }
      .chevron {
        height: 13px;
        padding-left: 20px;
        width: 7px;
        @media (max-width: 1536px) {
          padding-left: 18px;
        }
        @media (max-width: 1280px) {
          padding-left: 16px;
        }
        &:first-child {
          padding: 0 3px 0 0;
        }
        * {
          @include fast-transition($props: 'fill');
        }
      }
      .slice {
        right: -22px;
        width: 23px;
      }
      span {
        font-family: $secondary;
        font-size: 13px;
        font-weight: $bold;
      }
    }
    &.secondary {
      height: 56px;
      padding: 0 40px;
      @media (max-width: 1536px) {
        padding: 0 36px;
      }
      @media (max-width: 1280px) {
        padding: 0 32px;
      }
      @media (max-width: 767px) {
        height: 48px;
        padding: 0 28px;
      }
      .slice {
        right: -22px;
        width: 23px;
      }
      span {
        font-size: 15px;
      }
    }
  }
  /* -------------------------------------------------------------- */
  /* Text */
  /* -------------------------------------------------------------- */
  span {
    line-height: normal;
    pointer-events: none;
    position: relative;
    white-space: nowrap;
    z-index: 1;
    @include fast-transition($props: 'color');
    @at-root :focus#{&} {
      text-decoration: underline;
    }
  }
  /* -------------------------------------------------------------- */
  /* Brand Aqua */
  /* -------------------------------------------------------------- */
  &.Aqua {
    background-color: $brandAqua;
    /* Custom Icon */
    svg * {
      fill: $brandBlackGrape;
    }
    /* Slice */
    .slice path {
      fill: $brandAqua;
    }
    /* Chevron */
    .chevron path {
      fill: $brandBlackGrape;
    }
    &.small .chevron path {
      fill: $brandBlackGrape;
    }
    /* Text */
    span {
      color: $brandBlackGrape;
    }
    &:focus {
      background-color: $brandAquaDark;
      .slice path {
        fill: $brandAquaDark;
      }
    }
    :global(.platform-desktop) & {
      &:hover {
        background-color: $brandAquaLight;
        .slice path {
          fill: $brandAquaLight;
        }
      }
      &:active {
        background-color: $brandAquaDark;
        .slice path {
          fill: $brandAquaDark;
        }
      }
    }
    :global(.platform-mobile) & {
      &:active {
        background-color: $brandAquaLight;
        .slice path {
          fill: $brandAquaLight;
        }
      }
    }
  }
  /* -------------------------------------------------------------- */
  /* Mono 1 */
  /* -------------------------------------------------------------- */
  &.Mono1 {
    background-color: $mono1;
    /* Custom Icon */
    svg * {
      fill: $brandBlackGrape;
    }
    /* Slice */
    .slice path {
      fill: $mono1;
    }
    /* Chevron */
    .chevron path {
      fill: $brandBlackGrape;
    }
    /* Text */
    span {
      color: $brandBlackGrape;
    }
    &:focus {
      background-color: $mono2;
      .slice path {
        fill: $mono2;
      }
    }
    :global(.platform-desktop) & {
      &:hover {
        background-color: #d6d6da;
        .slice path {
          fill: #d6d6da;
        }
      }
      &:active {
        background-color: $mono2;
        .slice path {
          fill: $mono2;
        }
      }
    }
    :global(.platform-active) & {
      &:active {
        background-color: #d6d6da;
        .slice path {
          fill: #d6d6da;
        }
      }
    }
  }
  /* -------------------------------------------------------------- */
  /* White */
  /* -------------------------------------------------------------- */
  &.White {
    background-color: $white;
    /* Custom Icon */
    svg * {
      fill: $brandBlackGrape;
    }
    /* Slice */
    .slice path {
      fill: $white;
    }
    /* Chevron */
    .chevron path {
      fill: $brandBlackGrape;
    }
    /* Text */
    span {
      color: $brandBlackGrape;
    }
    &:focus {
      background-color: $mono2;
      .slice path {
        fill: $mono2;
      }
    }
    :global(.platform-desktop) & {
      &:hover {
        background-color: $mono1;
        .slice path {
          fill: $mono1;
        }
      }
      &:active {
        background-color: $mono2;
        .slice path {
          fill: $mono2;
        }
      }
    }
    :global(.platform-mobile) & {
      &:active {
        background-color: $mono1;
        .slice path {
          fill: $mono1;
        }
      }
    }
  }
  /* -------------------------------------------------------------- */
  /* Brand Black Grape */
  /* -------------------------------------------------------------- */
  &.BlackGrape {
    background-color: $brandBlackGrape;
    /* Custom Icon */
    svg * {
      fill: $white;
    }
    /* Slice */
    .slice path {
      fill: $brandBlackGrape;
    }
    /* Chevron */
    .chevron path {
      fill: $white;
    }
    /* Text */
    span {
      color: $white;
    }
    &:focus {
      background-color: $brandBlackGrapeDark;
      .slice path {
        fill: $brandBlackGrapeDark;
      }
    }
    :global(.platform-desktop) & {
      &:hover {
        background-color: $brandBlackGrapeLight;
        .slice path {
          fill: $brandBlackGrapeLight;
        }
      }
      &:active {
        background-color: $brandBlackGrapeDark;
        .slice path {
          fill: $brandBlackGrapeDark;
        }
      }
    }
    :global(.platform-mobile) & {
      &:active {
        background-color: $brandBlackGrapeLight;
        .slice path {
          fill: $brandBlackGrapeLight;
        }
      }
    }
  }
  /* -------------------------------------------------------------- */
  /* Brand Berry */
  /* -------------------------------------------------------------- */
  &.Berry {
    background-color: $brandBerry;
    /* Custom Icon */
    svg * {
      fill: $white;
    }
    /* Slice */
    .slice path {
      fill: $brandBerry;
    }
    /* Chevron */
    .chevron path {
      fill: $white;
    }
    /* Text */
    span {
      color: $white;
    }
    &:focus {
      background-color: $brandBerryDark;
      .slice path {
        fill: $brandBerryDark;
      }
    }
    :global(.platform-desktop) & {
      &:hover {
        background-color: $brandBerryLight;
        .slice path {
          fill: $brandBerryLight;
        }
      }
      &:active {
        background-color: $brandBerryDark;
        .slice path {
          fill: $brandBerryDark;
        }
      }
    }
    :global(.platform-mobile) & {
      &:active {
        background-color: $brandBerryLight;
        .slice path {
          fill: $brandBerryLight;
        }
      }
    }
  }
  /* -------------------------------------------------------------- */
  /* Disabled */
  /* -------------------------------------------------------------- */
  &.disabled {
    background-color: $mono4;
    cursor: default;
    pointer-events: none;
    /* Custom Icon */
    svg * {
      fill: $mono1;
    }
    /* Slice */
    .slice path {
      fill: $mono4;
    }
    /* Chevron */
    .chevron path {
      fill: $mono1;
    }
    /* Text */
    span {
      color: $mono1;
    }
    &:focus {
      span {
        text-decoration: none;
      }
    }
  }
  /* -------------------------------------------------------------- */
  /* Read Only */
  /* -------------------------------------------------------------- */
  &.readOnly {
    cursor: default;
    pointer-events: none;
  }
  /* -------------------------------------------------------------- */
  /* Is Submitting */
  /* -------------------------------------------------------------- */
  &.isSubmitting {
    cursor: default;
    pointer-events: none;
  }
}
/* ---------------------------------------------------------------- */
/* Loading */
/* ---------------------------------------------------------------- */
.spinner {
  background-color: $brandBlackGrape;
  background-image: url('/images/icons/button-loading.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: 56px 56px;
  border-radius: 50%;
  display: block;
  flex-shrink: 0;
  height: 64px;
  margin-right: 12px;
  top: 0;
  width: 64px;
  @media (max-width: 1536px) {
    background-size: 48px 48px;
    height: 56px;
    margin-right: 10px;
    width: 56px;
  }
  @media (max-width: 1280px) {
    background-size: 40px 40px;
    height: 48px;
    margin-right: 8px;
    width: 48px;
  }
  &.small {
    background-size: 40px 40px;
    height: 48px;
    width: 48px;
  }
  + * {
    pointer-events: none;
  }
}
