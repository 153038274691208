@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* -------------------------------------------------------------- */
/* Bookmarks Skeleton */
/* -------------------------------------------------------------- */
.skeleton {
  /* ---------------------------------------------------------------- */
  /* Title */
  /* ---------------------------------------------------------------- */
  > span {
    display: block;
    font-size: 32px;
    font-weight: $semibold;
    line-height: 42px;
    margin: -8px 0 -9px 0;
    position: relative;
    @media (max-width: 1536px) {
      font-size: 28px;
      line-height: 37px;
      margin: -10px 0 -11px 0;
    }
    @media (max-width: 1280px) {
      font-size: 24px;
      line-height: 32px;
    }
    @media (max-width: 375px) {
      font-size: 22px;
      line-height: 30px;
      margin: -11px 0 -12px 0;
    }
    &::after {
      background-color: $mono1;
      color: transparent;
      content: 'Your Bookmarks';
      font-size: inherit;
      line-height: inherit;
      @include rounded-skeleton-text;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Three Columns Bookmark Item Container */
  /* ---------------------------------------------------------------- */
  .threeColumn {
    ul {
      display: grid;
      grid-gap: 32px;
      grid-template-columns: repeat(3, 1fr);
      margin-top: 32px;
      width: 100%;
      @media (max-width: 1024px) {
        grid-row-gap: 24px;
        margin-top: 24px;
      }
      @media (max-width: 896px) {
        grid-template-columns: auto;
        grid-template-rows: repeat(3, 1fr);
      }
      /* ---------------------------------------------------------------- */
      /* Social Skeleton */
      /* ---------------------------------------------------------------- */
      .skeletonSocial {
        margin-top: 21px;
        @media (max-width: 1536px) {
          margin-top: 17px;
        }
        @media (max-width: 1280px) {
          margin-top: 13px;
        }
        @media (max-width: 1024px) {
          margin-top: 14px;
        }
      }
      /* ---------------------------------------------------------------- */
      /* Bookmark Item */
      /* ---------------------------------------------------------------- */
      .skeletonItem {
        flex-direction: column;
        @media (max-width: 896px) {
          flex-direction: row;
        }
        @media (max-width: 576px) {
          flex-direction: column;
        }
        /* ---------------------------------------------------------------- */
        /* Image */
        /* ---------------------------------------------------------------- */
        > span {
          width: 100%;
          @media (max-width: 896px) {
            width: 36%;
          }
          @media (max-width: 767px) {
            width: 40%;
          }
          @media (max-width: 576px) {
            width: 100%;
          }
        }
        /* ---------------------------------------------------------------- */
        /* Header */
        /* ---------------------------------------------------------------- */
        header {
          /* ---------------------------------------------------------------- */
          /* Date */
          /* ---------------------------------------------------------------- */
          > span {
            display: flex;
            flex-wrap: wrap;
            font-size: 17px;
            height: 17px;
            line-height: 17px;
            margin: 6px 8px 5px;
            white-space: nowrap;
            @media (max-width: 1536px) {
              font-size: 16px;
              line-height: 16px;
              margin: 5px 7px;
            }
            @media (max-width: 1280px) {
              font-size: 15px;
              line-height: 15px;
              margin: 5px 6px 4px;
            }
            @media (max-width: 1024px) {
              font-size: 14px;
              line-height: 14px;
              margin: 4px 5px;
            }
            @media (max-width: 576px) {
              margin: 0 0 8px;
            }
            &::after {
              background-color: $mono1;
              color: transparent;
              content: 'September 14, 2021';
              font-size: inherit;
              height: inherit;
              line-height: inherit;
              @include rounded-skeleton-text;
            }
          }
          /* ---------------------------------------------------------------- */
          /* Heading */
          /* ---------------------------------------------------------------- */
          > div {
            margin-top: 21px;
            @media (max-width: 1536px) {
              margin-top: 17px;
            }
            @media (max-width: 1280px) {
              margin-top: 13px;
            }
            @media (max-width: 1024px) {
              margin-top: 14px;
            }
            @media (max-width: 576px) {
              margin-top: 10px;
            }
            @media (max-width: 767px) {
              margin-top: 15px;
            }
            @media (max-width: 576px) {
              margin-top: 10px;
            }
            @media (max-width: 375px) {
              margin-top: 11px;
            }
            span {
              display: block;
              font-family: $secondary;
              font-size: 20px;
              font-weight: $bold;
              line-height: 28px;
              margin-bottom: 11px;
              margin-top: -6px;
              position: relative;
              @media (max-width: 1536px) {
                font-size: 19px;
                line-height: 27px;
                margin-bottom: 7px;
              }
              @media (max-width: 1280px) {
                font-size: 18px;
                line-height: 26px;
                margin-bottom: 5px;
              }
              @media (max-width: 1024px) {
                font-size: 17px;
                line-height: 25px;
                margin-bottom: 3px;
              }
              @media (max-width: 576px) {
                font-family: $primary;
                font-weight: $bold;
              }
              @media (max-width: 767px) {
                font-size: 16px;
                line-height: 24px;
              }
              @media (max-width: 480px) {
                font-size: 15px;
                line-height: 23px;
              }
              @media (max-width: 375px) {
                font-size: 14px;
                line-height: 21px;
              }
              &:last-child {
                margin: 0 8px -5px 8px;
                @media (max-width: 1536px) {
                  margin: 0 7px -5px 7px;
                }
                @media (max-width: 1280px) {
                  margin: 0 6px -5px 6px;
                }
                @media (max-width: 1024px) {
                  margin: 0 5px -5px 5px;
                }
              }
              &::after {
                background-color: $mono1;
                color: transparent;
                content: 'This could be any title';
                font-size: inherit;
                line-height: inherit;
                @include rounded-skeleton-text;
              }
            }
          }
        }
        /* ---------------------------------------------------------------- */
        /* Footer */
        /* ---------------------------------------------------------------- */
        footer {
          align-items: center;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          margin: auto 0 0;
          padding-top: 28px;
          @media (max-width: 1536px) {
            padding-top: 24px;
          }
          @media (max-width: 1280px) {
            padding-top: 20px;
          }
          @media (max-width: 576px) {
            margin-bottom: -8px;
            margin-left: -16px;
            margin-right: -16px;
            padding-top: 21px;
          }
          @media (max-width: 375px) {
            padding-top: 19px;
          }
          @media (max-width: 350px) {
            align-items: flex-start;
            flex-direction: column;
            margin-bottom: -12px;
            padding-top: 20px;
          }
          /* ---------------------------------------------------------------- */
          /* Button */
          /* ---------------------------------------------------------------- */
          .button {
            background-color: $mono1;
            height: 48px;
            overflow: hidden;
            width: 145px;
            @include rounded-skeleton-text;
            &::after {
              background-color: $mono1;
              color: transparent;
              content: 'Read now \203A';
              font-size: inherit;
              height: inherit;
              line-height: inherit;
            }
          }
        }
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Full Width Bookmark Item Container */
  /* ---------------------------------------------------------------- */
  .fullWidth {
    ul {
      display: grid;
      grid-auto-rows: 1fr;
      grid-row-gap: 32px;
      margin-top: 32px;
      width: 100%;
      @media (max-width: 1024px) {
        grid-gap: 24px;
        margin-top: 24px;
      }
      /* ---------------------------------------------------------------- */
      /* Copy Container */
      /* ---------------------------------------------------------------- */
      .copy {
        @media (max-width: 896px) {
          margin-top: 10px;
        }
        @media (max-width: 375px) {
          margin-top: 11px;
        }
      }
      /* ---------------------------------------------------------------- */
      /* Bookmark Item */
      /* ---------------------------------------------------------------- */
      .skeletonItem {
        overflow: hidden;
        /* ---------------------------------------------------------------- */
        /* Image */
        /* ---------------------------------------------------------------- */
        > span {
          width: 28%;
          @media (max-width: 1280px) {
            width: 32%;
          }
          @media (max-width: 896px) {
            width: 36%;
          }
          @media (max-width: 767px) {
            width: 40%;
          }
          @media (max-width: 576px) {
            height: 208px;
            min-height: 0;
            width: 100%;
          }
          @media (max-width: 375px) {
            height: 175px;
          }
        }
        /* ---------------------------------------------------------------- */
        /* Content */
        /* ---------------------------------------------------------------- */
        &:last-child > div {
          @media (max-width: 896px) {
            padding-bottom: 0;
          }
        }
        > div {
          box-sizing: border-box;
          display: flex;
          flex-direction: column;
          height: 100%;
          padding: 32px;
          width: 100%;
          @media (max-width: 1536px) {
            padding: 28px;
          }
          @media (max-width: 1280px) {
            padding: 24px;
          }
          @media (max-width: 1024px) {
            padding: 20px;
          }
          @media (max-width: 576px) {
            padding: 20px 0 0;
          }
        }
        /* ---------------------------------------------------------------- */
        /* Header - Publications + Social Container */
        /* ---------------------------------------------------------------- */
        header {
          align-items: center;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          margin: -9px -8px 0;
          @media (max-width: 1536px) {
            margin: -7px -7px 0;
          }
          @media (max-width: 1280px) {
            margin: -7px -6px 0;
          }
          @media (max-width: 1024px) {
            margin: -6px -4px 0;
          }
          @media (max-width: 896px) {
            margin-top: -7px;
          }
        }
        /* ---------------------------------------------------------------- */
        /* Publications Text + Social Container */
        /* ---------------------------------------------------------------- */
        .skeletonSocial,
        .publication > span {
          margin: 6px 8px 5px;
          @media (max-width: 1536px) {
            margin: 5px 7px;
          }
          @media (max-width: 1280px) {
            margin: 5px 6px 4px;
          }
          @media (max-width: 1024px) {
            margin: 4px 5px;
          }
        }
        .skeletonSocial {
          @media (max-width: 896px) {
            margin: 0 0 8px;
          }
        }
        /* ---------------------------------------------------------------- */
        /* Publication */
        /* ---------------------------------------------------------------- */
        .publication {
          color: rgba($color: $mono5, $alpha: 0.7);
          display: flex;
          flex-wrap: wrap;
          font-size: 17px;
          line-height: 25px;
          @media (max-width: 1536px) {
            font-size: 16px;
            line-height: 24px;
          }
          @media (max-width: 1280px) {
            font-size: 15px;
            line-height: 23px;
          }
          @media (max-width: 1024px) {
            font-size: 14px;
            line-height: 22px;
          }
        }
        .publication > span {
          height: 17px;
          line-height: 17px;
          white-space: nowrap;
          @media (max-width: 1536px) {
            height: 16px;
            line-height: 16px;
          }
          @media (max-width: 1280px) {
            height: 15px;
            line-height: 15px;
          }
          @media (max-width: 1024px) {
            height: 14px;
            line-height: 14px;
          }
          @media (max-width: 896px) {
            height: 15px;
            line-height: 15px;
          }
          &:nth-child(1) {
            &::after {
              content: 'July 8, 2021';
            }
          }
          &::after {
            background: $mono1;
            color: transparent;
            font-size: inherit;
            line-height: inherit;
            @include rounded-skeleton-text;
          }
        }
        /* ---------------------------------------------------------------- */
        /* Title */
        /* ---------------------------------------------------------------- */
        .headingThree {
          display: block;
          font-family: $secondary;
          font-size: 20px;
          font-weight: $bold;
          line-height: 28px;
          margin-bottom: 11px;
          margin-top: -6px;
          position: relative;
          @media (max-width: 1536px) {
            font-size: 19px;
            line-height: 27px;
            margin-bottom: 7px;
          }
          @media (max-width: 1280px) {
            font-size: 18px;
            line-height: 26px;
            margin-bottom: 5px;
          }
          @media (max-width: 1024px) {
            font-size: 17px;
            line-height: 25px;
            margin-bottom: 3px;
          }
          @media (max-width: 576px) {
            font-family: $primary;
            font-weight: $bold;
          }
          @media (max-width: 767px) {
            font-size: 16px;
            line-height: 24px;
          }
          @media (max-width: 480px) {
            font-size: 15px;
            line-height: 23px;
          }
          @media (max-width: 375px) {
            font-size: 14px;
            line-height: 21px;
          }
          &:last-child {
            margin-bottom: -7px;
          }
          &::after {
            background-color: $mono1;
            color: transparent;
            content: 'Lorem ipsum dolor sit amet adipiscing elit.';
            font-size: inherit;
            line-height: inherit;
            @include rounded-skeleton-text;
          }
        }
        /* ---------------------------------------------------------------- */
        /* Footer - Cta Container */
        /* ---------------------------------------------------------------- */
        &:last-child footer {
          @media (max-width: 896px) {
            margin-bottom: -8px;
          }
          @media (max-width: 350px) {
            margin-bottom: -12px;
          }
        }
        footer {
          align-items: center;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          margin: auto 0 0;
          padding-top: 28px;
          @media (max-width: 1536px) {
            padding-top: 24px;
          }
          @media (max-width: 1280px) {
            padding-top: 20px;
          }
          @media (max-width: 576px) {
            margin-left: -16px;
            margin-right: -16px;
            padding-top: 21px;
          }
          @media (max-width: 375px) {
            padding-top: 19px;
          }
          @media (max-width: 350px) {
            align-items: flex-start;
            flex-direction: column;
            padding-top: 20px;
          }
        }
        /* ---------------------------------------------------------------- */
        /* Hyperlink */
        /* ---------------------------------------------------------------- */
        .hyperlink {
          @media (max-width: 576px) {
            font-size: 17px;
            line-height: 20px;
            margin: 0 0 8px;
          }
          &::after {
            background-color: $mono1;
            color: transparent;
            content: 'Read more \203A';
            font-size: inherit;
            line-height: inherit;
            @include rounded-skeleton-text;
          }
        }
        /* ---------------------------------------------------------------- */
        /* Social Mobile + Hyperlink */
        /* ---------------------------------------------------------------- */
        .skeletonSocial,
        .hyperlink {
          @media (max-width: 576px) {
            margin-left: 16px;
            margin-right: 16px;
          }
        }
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Skeleton item */
  /* ---------------------------------------------------------------- */
  .skeletonItem {
    background-color: $white;
    display: flex;
    position: relative;
    width: 100%;
    @media (max-width: 576px) {
      background-color: transparent;
      flex-direction: column;
    }
    /* ---------------------------------------------------------------- */
    /* Image */
    /* ---------------------------------------------------------------- */
    > span {
      background-color: $mono1;
      display: block;
      flex-shrink: 0;
      min-height: 194px;
      overflow: hidden;
      position: relative;
      width: 28%;
      @media (max-width: 1280px) {
        width: 32%;
      }
      @media (max-width: 896px) {
        width: 36%;
      }
      @media (max-width: 767px) {
        width: 40%;
      }
      @media (max-width: 576px) {
        height: 208px;
        min-height: 0;
        width: 100%;
      }
      @media (max-width: 375px) {
        height: 175px;
      }
    }
    /* ---------------------------------------------------------------- */
    /* Content */
    /* ---------------------------------------------------------------- */
    > div {
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      height: 100%;
      padding: 32px;
      width: 100%;
      @media (max-width: 1536px) {
        padding: 28px;
      }
      @media (max-width: 1280px) {
        padding: 24px;
      }
      @media (max-width: 1024px) {
        padding: 20px;
      }
      @media (max-width: 576px) {
        padding: 20px 0 0;
      }
    }
    /* ---------------------------------------------------------------- */
    /* Header - Publications + Social Container */
    /* ---------------------------------------------------------------- */
    header {
      align-items: center;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin: -9px -8px 0;
      @media (max-width: 1536px) {
        margin: -7px -7px 0;
      }
      @media (max-width: 1280px) {
        margin: -7px -6px 0;
      }
      @media (max-width: 1024px) {
        margin: -6px -4px 0;
      }
      @media (max-width: 576px) {
        margin-top: -7px;
      }
    }
    /* ---------------------------------------------------------------- */
    /* Publications Text + Social Container */
    /* ---------------------------------------------------------------- */
    .social,
    .publication > span {
      margin: 6px 8px 5px;
      @media (max-width: 1536px) {
        margin: 5px 7px;
      }
      @media (max-width: 1280px) {
        margin: 5px 6px 4px;
      }
      @media (max-width: 1024px) {
        margin: 4px 5px;
      }
    }
    /* ---------------------------------------------------------------- */
    /* Social */
    /* ---------------------------------------------------------------- */
    .social {
      @media (max-width: 576px) {
        margin: 0 0 8px;
      }
      @media (max-width: 896px) and (min-width: 768px) {
        height: 14px;
      }
      /* ---------------------------------------------------------------- */
      /* Bookmark */
      /* ---------------------------------------------------------------- */
      div:first-child {
        @media (max-width: 896px) and (min-width: 768px) {
          margin-right: 8px;
        }
        /* ---------------------------------------------------------------- */
        /* Button + Icon */
        /* ---------------------------------------------------------------- */
        button,
        svg {
          @media (max-width: 896px) and (min-width: 768px) {
            height: 14px;
            width: 11px;
          }
        }
      }
      /* ---------------------------------------------------------------- */
      /* Likes */
      /* ---------------------------------------------------------------- */
      div:last-child {
        @media (max-width: 896px) and (min-width: 768px) {
          font-size: 14px;
        }
        /* ---------------------------------------------------------------- */
        /* Button + Icon */
        /* ---------------------------------------------------------------- */
        button,
        svg {
          @media (max-width: 896px) and (min-width: 768px) {
            height: 14px;
            width: 16px;
          }
        }
        /* ---------------------------------------------------------------- */
        /* Button */
        /* ---------------------------------------------------------------- */
        button {
          @media (max-width: 896px) and (min-width: 768px) {
            margin-right: 6px;
          }
        }
      }
    }
    /* ---------------------------------------------------------------- */
    /* Publication */
    /* ---------------------------------------------------------------- */
    .publication {
      display: flex;
      flex-wrap: wrap;
      font-size: 17px;
      line-height: 25px;
      @media (max-width: 1536px) {
        font-size: 16px;
        line-height: 24px;
      }
      @media (max-width: 1280px) {
        font-size: 15px;
        line-height: 23px;
      }
      @media (max-width: 1024px) {
        font-size: 14px;
        line-height: 22px;
      }
    }
    /* ---------------------------------------------------------------- */
    /* Copy Container */
    /* ---------------------------------------------------------------- */
    .copy {
      margin-top: 21px;
      @media (max-width: 1536px) {
        margin-top: 17px;
      }
      @media (max-width: 1280px) {
        margin-top: 13px;
      }
      @media (max-width: 1024px) {
        margin-top: 14px;
      }
      @media (max-width: 576px) {
        margin-top: 10px;
      }
      @media (max-width: 767px) {
        margin-top: 15px;
      }
      @media (max-width: 576px) {
        margin-top: 10px;
      }
      @media (max-width: 375px) {
        margin-top: 11px;
      }
    }
    .publication > span {
      height: 17px;
      line-height: 17px;
      white-space: nowrap;
      @media (max-width: 1536px) {
        height: 16px;
        line-height: 16px;
      }
      @media (max-width: 1280px) {
        height: 15px;
        line-height: 15px;
      }
      @media (max-width: 1024px) {
        height: 14px;
        line-height: 14px;
      }
      @media (max-width: 576px) {
        height: 15px;
        line-height: 15px;
      }
    }
    /* ---------------------------------------------------------------- */
    /* Excerpt */
    /* ---------------------------------------------------------------- */
    .copy > p {
      color: $brandBlackGrape;
      display: block;
      font-size: 16px;
      line-height: 24px;
      margin-bottom: -6px;
      position: relative;
      @media (max-width: 1536px) {
        font-size: 16px;
        line-height: 24px;
      }
      @media (max-width: 1280px) {
        font-size: 15px;
        line-height: 23px;
      }
      @media (max-width: 1024px) {
        font-size: 14px;
        line-height: 21px;
        margin-bottom: -5px;
      }
      @media (max-width: 576px) {
        display: none;
      }
    }
    footer {
      align-items: center;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin: auto 0 0;
      padding-top: 28px;
      @media (max-width: 1536px) {
        padding-top: 24px;
      }
      @media (max-width: 1280px) {
        padding-top: 20px;
      }
      @media (max-width: 576px) {
        margin-bottom: -8px;
        margin-left: -16px;
        margin-right: -16px;
        padding-top: 21px;
      }
      @media (max-width: 375px) {
        padding-top: 19px;
      }
      @media (max-width: 350px) {
        align-items: flex-start;
        flex-direction: column;
        margin-bottom: -12px;
        padding-top: 20px;
      }
      /* ---------------------------------------------------------------- */
      /* No Title or Description */
      /* ---------------------------------------------------------------- */
      &:nth-child(2) {
        padding-top: 0;
        @media (max-width: 576px) {
          padding-top: 14px;
        }
        @media (max-width: 375px) {
          padding-top: 11px;
        }
        @media (max-width: 350px) {
          padding-top: 13px;
        }
        a {
          margin-top: 18px;
          @media (max-width: 1536px) {
            margin-top: 14px;
          }
          @media (max-width: 1280px) {
            margin-top: 11px;
          }
          @media (max-width: 1024px) {
            margin-top: 12px;
          }
          @media (max-width: 576px) {
            margin-top: 0;
          }
          @media (max-width: 767px) and (min-width: 577px) {
            margin-top: 13px;
          }
        }
      }
    }
    /* ---------------------------------------------------------------- */
    /* Hyperlink */
    /* ---------------------------------------------------------------- */
    .hyperlink {
      display: table;
      font-family: $secondary;
      font-size: 17px;
      font-weight: $bold;
      line-height: 20px;
      margin: -3px 0 -4px;
      min-width: 0;
      text-decoration: none;
      @media (max-width: 1536px) {
        font-size: 16px;
        line-height: 19px;
      }
      @media (max-width: 1280px) {
        font-size: 15px;
        line-height: 18px;
      }
      @media (max-width: 1024px) {
        font-size: 14px;
        line-height: 17px;
      }
      @media (max-width: 576px) {
        font-size: 17px;
        line-height: 20px;
        margin: 0 0 8px;
      }
      @media (max-width: 480px) {
        font-size: 16px;
        line-height: 19px;
      }
      @media (max-width: 375px) {
        font-size: 15px;
        line-height: 18px;
      }
      > svg {
        height: 12px;
        margin-left: 16px;
        width: 7px;
        @media (max-width: 1536px) {
          margin-left: 12px;
          position: relative;
          top: 1px;
        }
        @media (max-width: 1280px) {
          margin-left: 8px;
        }
        @media (max-width: 576px) {
          margin-left: 16px;
        }
        @media (max-width: 480px) {
          margin-left: 12px;
        }
        @media (max-width: 375px) {
          margin-left: 8px;
        }
      }
    }
    /* ---------------------------------------------------------------- */
    /* Social Mobile + Hyperlink */
    /* ---------------------------------------------------------------- */
    .social,
    .hyperlink {
      @media (max-width: 576px) {
        margin-left: 16px;
        margin-right: 16px;
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Skeleton Shimmer */
  /* ---------------------------------------------------------------- */
  &::after {
    margin: 2px auto;
    @include skeleton-shimmer;
    @include skeleton-shimmer-color($color: $pageBackground);
  }
  /* ---------------------------------------------------------------- */
  /* Shared */
  /* ---------------------------------------------------------------- */
  .skeletonSocial {
    align-items: center;
    display: flex;
    font-weight: $semibold;
    height: 17px;
    white-space: nowrap;
    @media (max-width: 1536px) {
      height: 16px;
    }
    @media (max-width: 1280px) {
      height: 15px;
    }
    @media (max-width: 1024px) {
      height: 14px;
    }
    @media (max-width: 896px) {
      height: 17px;
      margin-right: 20px;
    }
    @media (max-width: 480px) {
      height: 16px;
    }
    @media (max-width: 375px) {
      height: 15px;
    }
    /* ---------------------------------------------------------------- */
    /* Bookmark */
    /* ---------------------------------------------------------------- */
    .bookmark {
      display: flex;
      margin-right: 12px;
      @media (max-width: 1280px) {
        margin-right: 10px;
      }
      @media (max-width: 1024px) {
        margin-right: 8px;
      }
      @media (max-width: 480px) {
        margin-right: 14px;
      }
      @media (max-width: 375px) {
        margin-right: 12px;
      }
      /* ---------------------------------------------------------------- */
      /* Button + Icon */
      /* ---------------------------------------------------------------- */
      span {
        background-color: $mono1;
        clip-path: polygon(100% 0%, 100% 100%, 50% 70%, 0 100%, 0 0);
        height: 17px;
        transform: translateZ(0);
        width: 13px;
        @media (max-width: 1536px) {
          height: 16px;
          width: 13px;
        }
        @media (max-width: 1280px) {
          height: 15px;
          width: 12px;
        }
        @media (max-width: 1024px) {
          height: 14px;
          width: 11px;
        }
        @media (max-width: 480px) {
          height: 16px;
          width: 13px;
        }
        @media (max-width: 375px) {
          height: 15px;
          width: 12px;
        }
      }
    }
    /* ---------------------------------------------------------------- */
    /* Likes */
    /* ---------------------------------------------------------------- */
    .likes {
      align-items: center;
      display: flex;
      font-size: 17px;
      font-weight: $semibold;
      @media (max-width: 1536px) {
        font-size: 16px;
      }
      @media (max-width: 1280px) {
        font-size: 15px;
      }
      @media (max-width: 1024px) {
        font-size: 14px;
      }
      @media (max-width: 480px) {
        font-size: 16px;
      }
      @media (max-width: 375px) {
        font-size: 15px;
      }
      /* ---------------------------------------------------------------- */
      /* Button + Icon */
      /* ---------------------------------------------------------------- */
      span:nth-child(1) {
        backface-visibility: hidden;
        background-color: transparent;
        height: 17px;
        margin-right: 8px;
        transform: translateZ(0);
        width: 19px;
        @media (max-width: 1536px) {
          height: 16px;
          width: 18px;
        }
        @media (max-width: 1280px) {
          height: 15px;
          margin-right: 7px;
          width: 17px;
        }
        @media (max-width: 1024px) {
          height: 14px;
          width: 16px;
        }
        @media (max-width: 480px) {
          height: 16px;
          margin-right: 10px;
          width: 18px;
        }
        @media (max-width: 375px) {
          height: 15px;
          margin-right: 8px;
          width: 17px;
        }
        &:only-child {
          margin-right: 0;
        }
      }
      /* ---------------------------------------------------------------- */
      /* Text */
      /* ---------------------------------------------------------------- */
      > span:nth-child(2) {
        &::after {
          background-color: $mono1;
          color: transparent;
          content: '4 Likes';
          font-size: inherit;
          line-height: 17px;
          @include rounded-skeleton-text;
          @media (max-width: 1536px) {
            line-height: 16px;
          }
        }
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* SVG Rules */
  /* ---------------------------------------------------------------- */
  svg {
    height: 100%;
    width: 100%;
    * {
      fill: $mono1;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Copy Container */
  /* ---------------------------------------------------------------- */
  .copy {
    margin-top: 21px;
    @media (max-width: 1536px) {
      margin-top: 17px;
    }
    @media (max-width: 1280px) {
      margin-top: 13px;
    }
    @media (max-width: 1024px) {
      margin-top: 14px;
    }
    @media (max-width: 767px) {
      margin-top: 15px;
    }
    @media (max-width: 576px) {
      margin-top: 10px;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Excerpt */
  /* ---------------------------------------------------------------- */
  .copy > p {
    display: block;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: -6px;
    position: relative;
    @media (max-width: 1536px) {
      font-size: 16px;
      line-height: 24px;
    }
    @media (max-width: 1280px) {
      font-size: 15px;
      line-height: 23px;
    }
    @media (max-width: 1024px) {
      font-size: 14px;
      line-height: 21px;
      margin-bottom: -5px;
    }
    &::after {
      background-color: $mono1;
      color: transparent;
      /* stylelint-disable-next-line max-line-length */
      content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt incididuntut sit do aliqua sit do amet.';
      font-size: inherit;
      line-height: inherit;
      @include rounded-skeleton-text;
    }
  }
}

@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}
