@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* -------------------------------------------------------------- */
/* Earned Badges Skeleton */
/* -------------------------------------------------------------- */
.skeleton {
  overflow: hidden;
  position: relative;
  &::after {
    @include skeleton-shimmer;
    @include skeleton-shimmer-color($color: $white);
    @media (max-width: 767px) {
      @include skeleton-shimmer-color($color: $pageBackground);
    }
  }
  > li {
    /* -------------------------------------------------------------- */
    /* Badge Title */
    /* -------------------------------------------------------------- */
    > span {
      &::after {
        background-color: $mono1;
        color: transparent;
        content: 'ProPath Project Expert';
        font-size: inherit;
        line-height: inherit;
        @include rounded-skeleton-text;
      }
    }
    > div {
      /* ---------------------------------------------------------------- */
      /* MyAxelos Logo */
      /* ---------------------------------------------------------------- */
      &:first-child {
        background-color: $mono1;
        border-radius: 50%;
        height: 29px;
        margin-right: 24px;
        width: 34px;
        @media (max-width: 1536px) {
          height: 28px;
          margin-right: 20px;
          width: 33px;
        }
        @media (max-width: 1280px) {
          height: 27px;
          margin-right: 16px;
          width: 32px;
        }
        @media (max-width: 1024px) {
          height: 26px;
          margin-right: 12px;
          width: 31px;
        }
        @media (max-width: 896px) and (min-width: 768px) {
          display: none;
        }
      }
      /* ---------------------------------------------------------------- */
      /* Badge Icon */
      /* ---------------------------------------------------------------- */
      &:last-child {
        background-color: $mono1;
        border-radius: 50%;
        height: 26px;
        margin-left: auto;
        width: 26px;
        @media (max-width: 1536px) {
          height: 25px;
          width: 25px;
        }
        @media (max-width: 1280px) {
          height: 24px;
          width: 24px;
        }
        @media (max-width: 1024px) {
          height: 23px;
          width: 23px;
        }
        @media (max-width: 896px) and (min-width: 768px) {
          height: 22px;
          width: 22px;
        }
      }
    }
  }
}

@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}
