@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Question */
/* ---------------------------------------------------------------- */
.question {
  margin: 0 0 32px;
  position: relative;
  @media (max-width: 1536px) {
    margin: 0 0 28px;
  }
  @media (max-width: 1408px) {
    margin: 0 0 24px;
  }
  @media (max-width: 1280px) {
    margin: 0 0 20px;
  }
  @media (max-width: 1024px) {
    margin: 0 0 16px;
  }
  /* ---------------------------------------------------------------- */
  /* Error */
  /* ---------------------------------------------------------------- */
  > p {
    color: red;
    font-size: 17px;
    line-height: 24px;
    margin: 7px 0 -6px;
    @media (max-width: 1536px) {
      font-size: 16px;
      line-height: 23px;
      margin-top: 6px;
    }
    @media (max-width: 1408px) {
      margin-top: 5px;
    }
    @media (max-width: 1280px) {
      font-size: 15px;
      line-height: 20px;
      margin-bottom: -4px;
      margin-top: 4px;
    }
    @media (max-width: 1024px) {
      margin-top: 3px;
    }
    &.input-extra-small {
      margin-top: 2px;
      // padding-left: 19px;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Question Date Picker */
  /* ---------------------------------------------------------------- */
  &.questionDatepicker {
    /* ---------------------------------------------------------------- */
    /* Label */
    /* ---------------------------------------------------------------- */
    > label {
      display: table;
      left: 50px;
      margin-bottom: 5px;
      margin-top: -6px;
      z-index: 99;
      @media (max-width: 1408px) {
        margin-top: -6px;
      }
      @media (max-width: 1280px) {
        margin-bottom: 6px;
        margin-top: -7px;
      }
    }
    /* ---------------------------------------------------------------- */
    /* MUI Input Container */
    /* ---------------------------------------------------------------- */
    div:nth-child(2) {
      width: 100%;
      /* ---------------------------------------------------------------- */
      /* Error Text */
      /* ---------------------------------------------------------------- */
      p {
        color: red;
        font-size: 17px;
        letter-spacing: normal;
        line-height: 24px;
        margin: 2px 0 -6px;
        padding-left: 19px;
        @media (max-width: 1536px) {
          font-size: 16px;
          line-height: 23px;
          margin-top: 6px;
        }
        @media (max-width: 1408px) {
          margin-top: 5px;
        }
        @media (max-width: 1280px) {
          font-size: 15px;
          line-height: 20px;
          margin-bottom: -4px;
          margin-top: 4px;
        }
        @media (max-width: 1024px) {
          margin-top: 3px;
        }
      }
      /* ---------------------------------------------------------------- */
      /* Input Container */
      /* ---------------------------------------------------------------- */
      > div {
        /* ---------------------------------------------------------------- */
        /* Input */
        /* ---------------------------------------------------------------- */
        input {
          -webkit-appearance: none;
          -moz-appearance: none;
          -o-appearance: none;
          appearance: none;
          background-color: $white;
          border-color: $mono2;
          border-radius: 9px;
          border-style: solid;
          border-width: 1px;
          box-sizing: border-box;
          caret-color: $brandBlackGrape;
          color: $brandBlackGrape;
          display: inline-block;
          filter: none;
          font-family: $primary;
          font-size: 20px;
          font-weight: normal;
          height: 80px;
          letter-spacing: normal;
          line-height: normal;
          outline: none;
          padding: 30px 30px 30px 65px;
          resize: none;
          vertical-align: middle;
          width: 100%;
          @include no-user-select;
          @include fast-transition($props: 'border-color, background-color');
          @media (max-width: 1536px) {
            font-size: 19px;
            height: 72px;
            padding: 26px 26px 26px 61px;
          }
          @media (max-width: 1280px) {
            font-size: 18px;
            height: 64px;
            padding: 22px 22px 22px 58px;
          }
          @media (max-width: 1024px) {
            font-size: 17px;
            height: 56px;
            padding: 18px 18px 18px 53px;
          }
          @media (max-width: 767px) {
            font-size: 16px;
          }
          &::placeholder {
            color: $mono4;
            opacity: 1;
          }
        }
        &::before,
        &::after {
          display: none;
        }
        div:last-child {
          position: absolute;
        }
      }
      :global(.Mui-error) {
        input {
          border-color: red;
        }
      }
      :global(.MuiIconButton-root) {
        &:hover,
        &:active {
          background-color: transparent;
        }
      }
    }
    /* ---------------------------------------------------------------- */
    /* Reazd Only */
    /* ---------------------------------------------------------------- */
    &.readOnly {
      pointer-events: none;
      > div:nth-child(2) > div {
        input {
          background-color: $mono1;
          &:focus {
            border-color: $mono2;
          }
          :global(.platform-desktop) & {
            &:hover {
              border-color: $mono2;
              &:focus {
                border-color: $mono2;
              }
            }
          }
        }
      }
    }
    /* ---------------------------------------------------------------- */
    /* Invalid */
    /* ---------------------------------------------------------------- */
    &.invalid {
      div:nth-child(2) > div input {
        border-color: red;
      }
    }
    /* ---------------------------------------------------------------- */
    /* Mobile Date */
    /* ---------------------------------------------------------------- */
    .mobileDate {
      > div > input[id][type] {
        display: block;
        padding-left: 30px;
        padding-right: 25px;
        text-align: left;
        text-transform: uppercase;
        &::placeholder {
          color: $mono4;
          opacity: 1;
        }
        &::-webkit-date-and-time-value {
          text-align: left;
        }
        @media (max-width: 1536px) {
          padding-left: 26px;
          padding-right: 21px;
        }
        @media (max-width: 1280px) {
          padding-left: 22px;
          padding-right: 17px;
        }
        @media (max-width: 1024px) {
          padding-left: 18px;
          padding-right: 13px;
        }
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Help Icon */
  /* ---------------------------------------------------------------- */
  > svg {
    cursor: pointer;
    height: 20px;
    position: absolute;
    right: 27px;
    top: 50px;
    width: 20px;
    z-index: 10;
    @media (max-width: 1280px) {
      height: 19px;
      width: 19px;
    }
    @media (max-width: 1024px) {
      height: 18px;
      width: 18px;
      right: 18px;
      top: 42px;
    }
    ~ .field:last-child > input {
      padding-right: 80px;
      @media (max-width: 1536px) {
        padding-right: 72px;
      }
      @media (max-width: 1280px) {
        padding-right: 63px;
      }
      @media (max-width: 1024px) {
        padding-right: 54px;
      }
    }
    &:hover + [role='tooltip'] {
      opacity: 1;
      position: absolute;
      // top: -13px;
      top: 40px;
      left: 90px;
      transform: translateY(-100%) scale(1);
    }
    &::after {
      right: 30px;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Help Tooltip */
  /* ---------------------------------------------------------------- */
  [role='tooltip'] {
    top: 40px !important;
    left: 90px !important;
    background-color: $white;
    border: 1px solid $mono2;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.16) 0 3px 6px;
    box-sizing: border-box;
    font-size: 17px;
    height: auto;
    // left: 48px;
    line-height: 25px;
    opacity: 0;
    padding: 11px 16px;
    position: absolute;
    // top: 21px;
    transform: translateY(-100%) scale(0);
    transform-origin: calc(100% - 68px) calc(100% + 10px);
    transition-duration: 0.2s;
    transition-property: transform, opacity;
    transition-timing-function: ease-in-out;
    width: 208px;
    z-index: 9;
    @media (max-width: 1536px) {
      font-size: 16px;
      left: 54px;
      line-height: 24px;
      top: 13px;
      width: 200px;
    }
    @media (max-width: 1408px) {
      left: 47px;
      transform-origin: calc(100% - 61px) calc(100% + 10px);
    }
    @media (max-width: 1280px) {
      font-size: 15px;
      left: 45px;
      line-height: 23px;
      top: 9px;
      transform-origin: calc(100% - 48px) calc(100% + 10px);
      width: 188px;
    }
    @media (max-width: 1024px) {
      left: 35px;
      top: 7px;
      transform-origin: calc(100% - 39px) calc(100% + 10px);
      max-width: fit-content;
      max-height: fit-content;
    }
    @media (max-width: 375px) {
      font-size: 14px;
      left: 43px;
      line-height: 22px;
      transform-origin: calc(100% - 35px) calc(100% + 10px);
      width: 178px;
    }
    &::after {
      right: 23px !important;
      background-image: url(/images/icons/help-chevron.svg);
      background-repeat: no-repeat;
      background-size: cover;
      bottom: -23px;
      content: '';
      display: block;
      height: 30px;
      position: absolute;
      // right: 54px;
      width: 30px;
      @media (max-width: 1408px) {
        right: 47px;
      }
      @media (max-width: 1280px) {
        right: 34px;
      }
      @media (max-width: 1024px) {
        right: 25px;
      }
      @media (max-width: 375px) {
        right: 21px;
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Show Help Tooltip - Mobile */
  /* ---------------------------------------------------------------- */
  &.showHelp {
    [role='tooltip'] {
      opacity: 1;
      transform: translateY(-100%) scale(1);
    }
  }
  /* ---------------------------------------------------------------- */
  /* MUI Date Icon Container */
  /* ---------------------------------------------------------------- */
  :global(.MuiInputAdornment-positionEnd) {
    margin-left: 17px;
    @media (max-width: 1536px) {
      margin-left: 13px;
    }
    @media (max-width: 1280px) {
      margin-left: 10px;
    }
    @media (max-width: 1024px) {
      margin-left: 5px;
    }
    svg > path:nth-child(1) {
      fill: $brandBlackGrape;
    }
  }
}
