@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* -------------------------------------------------------------- */
/* Search Bar */
/* -------------------------------------------------------------- */
.searchBar {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: -13px 0 32px;
  position: relative;
  @media (max-width: 1536px) {
    margin: -11px 0 28px;
  }
  @media (max-width: 1280px) {
    margin: -10px 0 24px;
  }
  @media (max-width: 1024px) {
    margin: -8px 0 20px;
  }
  /* -------------------------------------------------------------- */
  /* Input Container */
  /* -------------------------------------------------------------- */
  > div {
    position: relative;
  }
  /* -------------------------------------------------------------- */
  /* Button */
  /* -------------------------------------------------------------- */
  button[class][type] {
    height: 54px;
    margin-left: 24px;
    margin-right: 24px;
    position: relative;
    @media (min-width: 1152px), (max-width: 767px) and (min-width: 641px) {
      min-width: 120px;
      width: 22.5%;
    }
    @media (max-width: 1536px) {
      height: 50px;
      margin-left: 20px;
    }
    @media (max-width: 1536px) {
      margin-right: 22px;
    }
    @media (max-width: 1280px) {
      height: 45px;
      margin-left: 16px;
      margin-right: 20px;
    }
    @media (max-width: 1024px) {
      height: 41px;
      margin-left: 12px;
    }
    @media (max-width: 1024px) {
      margin-right: 18px;
    }
    @media (max-width: 375px) {
      padding: 0 4px 0 21px;
    }
    /* -------------------------------------------------------------- */
    /* Focus Ring - For Text-Less Button */
    /* -------------------------------------------------------------- */
    &::after {
      @media (max-width: 1152px) and (min-width: 768px), (max-width: 640px) {
        border: 2px dashed transparent;
        border-radius: 50%;
        content: '';
        display: block;
        height: 24px;
        left: 50%;
        position: absolute;
        top: 50%;
        transform: translate(-8px, -50%);
        width: 24px;
        @include fast-transition($props: 'border-color');
      }
    }
    &:focus::after {
      @media (max-width: 1152px) and (min-width: 768px), (max-width: 640px) {
        border-color: $white;
      }
    }
    /* -------------------------------------------------------------- */
    /* SVG Slice */
    /* -------------------------------------------------------------- */
    > svg:last-child {
      @media (min-width: 1152px) {
        right: -24px;
        width: 25px;
      }
      @media (max-width: 1536px) {
        right: -22px;
        width: 23px;
      }
      @media (max-width: 1280px) {
        right: -20px;
        width: 21px;
      }
      @media (max-width: 1024px) {
        right: -18px;
        width: 19px;
      }
    }
  }
}
