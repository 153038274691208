@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* -------------------------------------------------------------- */
/* Earned Badges Skeleton */
/* -------------------------------------------------------------- */
.skeleton {
  margin-top: -4px;
  overflow: hidden;
  position: relative;
  @media (max-width: 1280px) {
    margin-top: -3px;
  }
  &::after {
    @include skeleton-shimmer;
    @include skeleton-shimmer-color($color: $white);
    @media (max-width: 767px) {
      @include skeleton-shimmer-color($color: $pageBackground);
    }
  }
  > span {
    align-items: center;
    color: rgba($brandBlackGrape, 50%);
    display: flex;
    font-family: $secondary;
    font-size: 18px;
    font-weight: $semibold;
    letter-spacing: 0.16px;
    line-height: 22px;
    margin-bottom: -5px;
    text-transform: uppercase;
    @media (max-width: 1536px) {
      font-size: 17px;
      line-height: 21px;
    }
    @media (max-width: 1280px) {
      font-size: 16px;
      line-height: 20px;
    }
    @media (max-width: 1024px) {
      font-size: 15px;
      line-height: 19px;
    }
    &::after {
      background-color: $mono1;
      color: transparent;
      content: 'CURATED FOR YOU';
      font-size: inherit;
      line-height: inherit;
      @include rounded-skeleton-text;
    }
  }
  > div > div {
    cursor: default;
    @media (max-width: 1152px) and (min-width: 767px) {
      display: none;
      &:first-child {
        display: block;
      }
    }
    @media (max-width: 520px) {
      display: none;
      &:first-child {
        display: block;
      }
    }
    > div {
      &:first-child {
        background-color: $mono1;
      }
      &:last-child {
        > div {
          p {
            font-size: 18px;
            font-weight: $bold;
            line-height: 24px;
            margin: 0 0 9px;
            @media (max-width: 1728px) {
              font-size: 17px;
              line-height: 23px;
              margin-bottom: 8px;
            }
            @media (max-width: 1664px) and (min-width: 1537px) {
              font-size: 16px;
              line-height: 22px;
              margin-bottom: 6px;
            }
            @media (max-width: 1536px) and (min-width: 1153px) {
              margin-bottom: 8px;
            }
            @media (max-width: 1280px) {
              font-size: 16px;
              line-height: 22px;
              margin-bottom: 5px;
            }
            @media (max-width: 1152px) and (min-width: 768px),
              (max-width: 520px) {
              font-size: 16px;
            }
            &:last-child {
              margin-bottom: -4px;
            }
            &::after {
              background-color: $mono1;
              color: transparent;
              content: 'Individual Curated Page';
              font-size: inherit;
              line-height: inherit;
              @include rounded-skeleton-text;
            }
          }
          > span {
            display: block;
            font-size: 17px;
            line-height: 23px;
            margin: 0 0 15px;
            @media (max-width: 1728px) {
              font-size: 16px;
              line-height: 22px;
              margin: 0 0 13px;
            }
            @media (max-width: 1664px) and (min-width: 1537px) {
              font-size: 15px;
              line-height: 21px;
              margin: 0 0 11px;
            }
            @media (max-width: 1280px) {
              font-size: 15px;
              line-height: 21px;
            }
            @media (max-width: 1536px) and (min-width: 1153px) {
              margin-bottom: -5px;
            }
            &::after {
              background-color: $mono1;
              color: transparent;
              content: 'A collection of useful PRINCE2 materials for consultants.';
              font-size: inherit;
              line-height: inherit;
              @include rounded-skeleton-text;
            }
          }
          &:last-child {
            align-self: flex-start;
            background-color: $mono1;
            flex-shrink: 0;
            height: 48px;
            margin-top: auto;
            padding: 0;
            width: 55px;
            @include rounded-skeleton-text;
            @media (max-width: 1536px) and (min-width: 1153px) {
              margin-bottom: auto;
              margin-left: auto;
            }
            @media (max-width: 1536px) {
              width: 52px;
            }
            @media (max-width: 1280px) {
              width: 49px;
            }
          }
        }
      }
    }
  }
}

@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}
