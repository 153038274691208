@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Earned Badges */
/* ---------------------------------------------------------------- */
.earnedBadges {
  display: flex;
  flex-direction: column;
  margin-bottom: 48px;
  @media (max-width: 1408px) {
    margin-bottom: 44px;
  }
  @media (max-width: 1024px) {
    margin-bottom: 40px;
  }
  @media (max-width: 767px) {
    margin-bottom: 32px;
  }
  @media (max-width: 375px) {
    margin-bottom: 24px;
  }
  &:last-child {
    margin-bottom: 0;
  }
}
