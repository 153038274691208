@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Filter */
/* ---------------------------------------------------------------- */
.filtersFilter {
  background-color: $mono1;
  width: 100%;
  &:last-child {
    margin-right: 0;
  }
  /* ---------------------------------------------------------------- */
  /* Button */
  /* ---------------------------------------------------------------- */
  > button {
    align-items: center;
    background-color: $mono1;
    border-radius: 0;
    color: $brandBlackGrape;
    cursor: pointer;
    display: flex;
    font-family: $primary;
    font-size: 17px;
    font-weight: $semibold;
    height: 100%;
    justify-content: space-between;
    line-height: 21px;
    padding: 24px;
    width: 100%;
    @include fast-transition($props: 'background-color');
    @include no-user-select;
    @media (max-width: 1536px) {
      font-size: 16px;
      line-height: 20px;
      padding: 20px;
    }
    @media (max-width: 1236px) {
      font-size: 15px;
      line-height: 19px;
      padding: 16px;
    }
    @media (max-width: 896px) and (min-width: 768px), (max-width: 350px) {
      font-size: 14px;
      line-height: 18px;
    }
    > svg {
      height: 15px;
      width: 10px;
      @media (max-width: 1236px) {
        height: 13px;
        width: 9px;
      }
      * {
        fill: $brandBlackGrape;
      }
    }
    &.expanded {
      background-color: $greyDark;
      > svg {
        transform: rotate(90deg);
      }
    }
    &:focus {
      background-color: $greyDark;
      text-decoration: underline;
    }
    :global(.platform-desktop) & {
      &:hover {
        background-color: $greyDark;
      }
    }
  }
}
