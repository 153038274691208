@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Email Settings Item */
/* ---------------------------------------------------------------- */
.emailSettingsItem {
  cursor: pointer;
  display: flex;
  margin-bottom: -5px;
  margin-top: 40px;
  -webkit-tap-highlight-color: transparent;
  @include no-user-select;
  @media (max-width: 1536px) {
    margin-top: 32px;
  }
  @media (max-width: 1280px) {
    margin-top: 24px;
  }
  @media (max-width: 640px) {
    flex-direction: column;
    margin-bottom: 0;
    margin-top: 32px;
  }
  @media (max-width: 375px) {
    margin-top: 24px;
  }
  &:first-of-type {
    margin-top: 48px;
    @media (max-width: 1536px) {
      margin-top: 40px;
    }
    @media (max-width: 1280px) {
      margin-top: 32px;
    }
    @media (max-width: 375px) {
      margin-top: 24px;
    }
  }
  &:focus {
    outline: 0;
    text-decoration: underline;
  }
  /* -------------------------------------------------------------- */
  /* Title / Text */
  /* -------------------------------------------------------------- */
  > span:first-child {
    flex-grow: 1;
    margin-right: 20px;
    @media (max-width: 1280px) {
      margin-right: 16px;
    }
    @media (max-width: 640px) {
      margin: 0 0 14px;
    }
    @media (max-width: 375px) {
      margin: 0 0 10px;
    }
    /* ------------------------------------------------------------ */
    /* Title */
    /* ------------------------------------------------------------ */
    > strong {
      color: $brandBlackGrape;
      display: block;
      font-size: 20px;
      font-weight: $bold;
      line-height: 28px;
      margin: -6px 0;
      width: 100%;
      @include user-select;
      @media (max-width: 1536px) {
        font-size: 19px;
        line-height: 27px;
      }
      @media (max-width: 1280px) {
        font-size: 18px;
        line-height: 26px;
      }
      @media (max-width: 640px) {
        font-size: 17px;
        line-height: 25px;
      }
      @media (max-width: 375px) {
        font-size: 16px;
        line-height: 24px;
      }
    }
    /* ------------------------------------------------------------ */
    /* Text */
    /* ------------------------------------------------------------ */
    > span {
      display: block;
      font-size: 17px;
      line-height: 25px;
      margin: 13px 0 0;
      width: 100%;
      @include user-select;
      @media (max-width: 1536px) {
        font-size: 16px;
        line-height: 24px;
      }
      @media (max-width: 1280px) {
        font-size: 15px;
        line-height: 23px;
        margin: 11px 0 0;
      }
      @media (max-width: 375px) {
        font-size: 14px;
        line-height: 22px;
        margin: 7px 0 0;
      }
    }
  }
  /* -------------------------------------------------------------- */
  /* Button */
  /* -------------------------------------------------------------- */
  > span:last-child {
    display: block;
    height: 40px;
    position: relative;
    width: 75px;
    @media (max-width: 1280px) {
      height: 36px;
      width: 65px;
    }
    /* ------------------------------------------------------------ */
    /* Input */
    /* ------------------------------------------------------------ */
    > input {
      cursor: pointer;
      height: 100%;
      margin: 0;
      opacity: 0;
      position: absolute;
      -webkit-tap-highlight-color: transparent;
      visibility: hidden;
      width: 100%;
      z-index: 1;
      &[disabled] + span {
        cursor: not-allowed;
        filter: grayscale(0.5);
        opacity: 0.5;
      }
    }
    /* ------------------------------------------------------------ */
    /* Button */
    /* ------------------------------------------------------------ */
    > span {
      background-color: $brandBerry;
      border-radius: 20px;
      box-sizing: border-box;
      cursor: pointer;
      display: block;
      height: 40px;
      padding: 5px;
      position: relative;
      width: 75px;
      @include fast-transition($props: 'background-color');
      @media (max-width: 1280px) {
        height: 36px;
        width: 65px;
      }
      /* ---------------------------------------------------------- */
      /* Slider */
      /* ---------------------------------------------------------- */
      &::after {
        background-color: white;
        border-radius: 20px;
        content: '';
        cursor: pointer;
        display: block;
        height: 30px;
        width: 30px;
        @include fast-transition($props: 'transform');
        @media (max-width: 1280px) {
          height: 26px;
          width: 26px;
        }
      }
    }
    /* ------------------------------------------------------------ */
    /* Checked */
    /* ------------------------------------------------------------ */
    > input:checked + span {
      background-color: $productProPath;
      &::after {
        transform: translateX(35px);
        @media (max-width: 1280px) {
          transform: translateX(29px);
        }
      }
    }
  }
}
/* ------------------------------------------------------------ */
/* Status message */
/* ------------------------------------------------------------ */
.statusMessage {
  display: block;
  margin-top: 12px;
  min-height: 22px;
  @include user-select;
  @media (max-width: 1536px) {
    font-size: 16px;
    line-height: 24px;
    min-height: 24px;
  }
  @media (max-width: 1280px) {
    font-size: 15px;
    line-height: 23px;
    margin: 11px 0 0;
    min-height: 23px;
  }
  @media (max-width: 375px) {
    font-size: 14px;
    line-height: 22px;
    margin: 7px 0 0;
  }
  &.statusIsError {
    color: $brandBerry;
  }
}
