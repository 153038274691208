@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Checkbox */
/* ---------------------------------------------------------------- */
.checkbox {
  /* ---------------------------------------------------------------- */
  /* Label */
  /* ---------------------------------------------------------------- */
  label {
    color: $brandBlackGrape;
    cursor: default;
  }
}
