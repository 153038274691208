@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Subscriptions */
/* ---------------------------------------------------------------- */
.subscriptions {
  display: block;
  /* ---------------------------------------------------------------- */
  /* Text */
  /* ---------------------------------------------------------------- */
  > p {
    color: $brandBlackGrape;
    font-size: 17px;
    line-height: 25px;
    margin: -6px 0;
    @media (max-width: 1536px) {
      font-size: 16px;
      line-height: 24px;
    }
    @media (max-width: 1280px) {
      font-size: 15px;
      line-height: 23px;
    }
    @media (max-width: 375px) {
      font-size: 14px;
      line-height: 22px;
    }
    strong {
      font-weight: $semibold;
    }
  }
  & > div.cancelSubscription {
    margin-top: 36px;
    text-align: right;
  }
}
