@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ------------------------------------------------------------ */
/* Add Candidate */
/* ------------------------------------------------------------ */
.addCandidate {
  margin-bottom: 48px;
  @media (max-width: 1408px) {
    margin-bottom: 44px;
  }
  @media (max-width: 1024px) {
    margin-bottom: 40px;
  }
  @media (max-width: 767px) {
    margin-bottom: 32px;
  }
  @media (max-width: 375px) {
    margin-bottom: 24px;
  }
  &:last-child {
    margin-bottom: 0;
  }
  /* ------------------------------------------------------------ */
  /* Title */
  /* ------------------------------------------------------------ */
  > h3 {
    align-items: center;
    color: rgba($brandBlackGrape, 50%);
    display: flex;
    font-family: $secondary;
    font-size: 18px;
    font-weight: $semibold;
    letter-spacing: 0.16px;
    line-height: 22px;
    margin-bottom: -5px;
    margin-top: -4px;
    text-transform: uppercase;
    @media (max-width: 1536px) {
      font-size: 17px;
      line-height: 21px;
    }
    @media (max-width: 1280px) {
      font-size: 16px;
      line-height: 20px;
    }
    @media (max-width: 1024px) {
      font-size: 15px;
      line-height: 19px;
    }
    @media (max-width: 767px) {
      font-size: 16px;
      line-height: 20px;
    }
  }
}
/* ------------------------------------------------------------ */
/* Add Candidate Form */
/* ------------------------------------------------------------ */
.addCandidateForm {
  display: flex;
  margin-top: 28px;
  position: relative;
  width: 100%;
  @media (max-width: 1536px) {
    margin-top: 24px;
  }
  @media (max-width: 1280px) {
    margin-top: 20px;
  }
  /* ---------------------------------------------------------- */
  /* Label */
  /* ---------------------------------------------------------- */
  label {
    font-size: 16px;
    white-space: nowrap;
    @include no-user-select;
    @media (max-width: 832px) and (min-width: 801px) {
      font-size: 15px;
    }
    @media (max-width: 800px) and (min-width: 768px) {
      font-size: 14px;
    }
    @media (max-width: 940px) and (min-width: 768px) {
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 120px;
    }
  }
  /* ---------------------------------------------------------- */
  /* Input */
  /* ---------------------------------------------------------- */
  input[type][id] {
    padding-right: 48px;
  }
  /* ---------------------------------------------------------- */
  /* Question */
  /* ---------------------------------------------------------- */
  > div {
    flex-grow: 1;
    margin-bottom: 0;
    /* ---------------------------------------------------------- */
    /* Help Text & Icon */
    /* ---------------------------------------------------------- */
    > svg {
      right: 12px;
      top: 18px;
      transform: none;
      @media (max-width: 1024px) {
        top: 19px;
      }
      + [role='tooltip'] {
        right: -47px;
        top: 9px;
        width: 380px;
        z-index: 9;
        @media (max-width: 767px) {
          width: 240px;
        }
        @media (max-width: 1408px) {
          right: -40px;
        }
        @media (max-width: 1280px) {
          right: -27px;
        }
        @media (max-width: 1024px) {
          right: -19px;
        }
        @media (max-width: 375px) {
          right: -15px;
        }
      }
    }
    /* ---------------------------------------------------------- */
    /* Error Message */
    /* ---------------------------------------------------------- */
    p:last-child {
      font-size: 14px;
      line-height: 18px;
      margin: 5px 0 -4px;
    }
  }
  /* -------------------------------------------------------------- */
  /* Button */
  /* -------------------------------------------------------------- */
  button[class][type] {
    height: 56px;
    margin: 0 25px 0 24px;
    @media (max-width: 1664px) {
      margin-left: 20px;
      padding: 0 9px 0 28px;
    }
    @media (max-width: 1536px) {
      margin-left: 16px;
      padding: 0 5px 0 24px;
    }
    @media (max-width: 1280px) {
      margin-left: 12px;
    }
    @media (max-width: 940px) and (min-width: 768px), (max-width: 360px) {
      margin-left: 8px;
    }
    /* -------------------------------------------------------------- */
    /* Button Slice */
    /* -------------------------------------------------------------- */
    svg:last-of-type {
      right: -25px;
      width: 26px;
    }
  }
  /* ---------------------------------------------------------- */
  /* Loader */
  /* ---------------------------------------------------------- */
  > span[class] {
    background-size: 28px 28px;
    height: 32px;
    margin-top: 0;
    position: absolute;
    right: 16px;
    top: 12px;
    width: 32px;
    z-index: 2;
    @media (max-width: 1664px) {
      right: 12px;
    }
  }
}
/* ---------------------------------------------------------------- */
/* Error */
/* ---------------------------------------------------------------- */
.errorResponse {
  align-self: flex-start;
  color: red;
  font-size: 14px;
  line-height: 18px;
  margin: 5px 0 -4px;
  text-align: left;
  /* ---------------------------------------------------------------- */
  /* Link */
  /* ---------------------------------------------------------------- */
  a {
    color: red;
    font-weight: $semibold;
    margin: 0;
    padding: 0;
    text-decoration: underline;
    white-space: pre;
    @include fast-transition($props: 'color');
    &:focus {
      color: darken(red, 20%);
      text-decoration: none;
    }
    :global(.platform-desktop) & {
      &:hover {
        color: darken(red, 10%);
      }
      &:active {
        color: darken(red, 20%);
      }
    }
    :global(.platform-mobile) & {
      &:active {
        color: darken(red, 10%);
      }
    }
  }
}
