@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Modal Info */
/* ---------------------------------------------------------------- */
.modalInfo {
  min-width: 424px;
  @media (max-width: 767px) {
    min-width: 100%;
  }
  /* ---------------------------------------------------------------- */
  /* Scrollable Content */
  /* ---------------------------------------------------------------- */
  > div {
    /* ---------------------------------------------------------------- */
    /* Text */
    /* ---------------------------------------------------------------- */
    p {
      display: block;
      /* ---------------------------------------------------------------- */
      /* link */
      /* ---------------------------------------------------------------- */
      a {
        color: $brandBerry;
        &:hover,
        &:active {
          color: $brandBerryDark;
        }
        &:focus {
          color: $brandBerryDark;
          text-decoration: underline;
        }
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Buttons */
  /* ---------------------------------------------------------------- */
  .buttons {
    align-self: flex-start;
  }
}
