@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Buttons */
/* ---------------------------------------------------------------- */
.debug {
  /* ---------------------------------------------------------------- */
  /* Section */
  /* ---------------------------------------------------------------- */
  > fieldset {
    background-color: $mono1;
    border: 1px solid $mono2;
    box-sizing: border-box;
    margin: 24px 0;
    padding: 24px;
  }
}
