@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Question */
/* ---------------------------------------------------------------- */
.question {
  margin: 0 0 32px;
  position: relative;
  @media (max-width: 1536px) {
    margin: 0 0 28px;
  }
  @media (max-width: 1408px) {
    margin: 0 0 24px;
  }
  @media (max-width: 1280px) {
    margin: 0 0 20px;
  }
  @media (max-width: 1024px) {
    margin: 0 0 16px;
  }
  /* ---------------------------------------------------------------- */
  /* Error */
  /* ---------------------------------------------------------------- */
  > p {
    color: red;
    font-size: 17px;
    line-height: 24px;
    margin: 7px 0 -6px;
    @media (max-width: 1536px) {
      font-size: 16px;
      line-height: 23px;
      margin-top: 6px;
    }
    @media (max-width: 1408px) {
      margin-top: 5px;
    }
    @media (max-width: 1280px) {
      font-size: 15px;
      line-height: 20px;
      margin-bottom: -4px;
      margin-top: 4px;
    }
    @media (max-width: 1024px) {
      margin-top: 3px;
    }
    &.input-extra-small {
      margin-top: 2px;
      padding-left: 19px;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Help Icon */
  /* ---------------------------------------------------------------- */
  > svg {
    cursor: pointer;
    height: 20px;
    position: absolute;
    right: 31px;
    top: 30px;
    width: 20px;
    z-index: 10;
    @media (max-width: 1536px) {
      right: 27px;
      top: 26px;
    }
    @media (max-width: 1280px) {
      height: 19px;
      right: 23px;
      top: 22px;
      width: 19px;
    }
    @media (max-width: 1024px) {
      height: 18px;
      right: 19px;
      top: 19px;
      width: 18px;
    }
    ~ div:last-child > input {
      padding-right: 80px;
      @media (max-width: 1536px) {
        padding-right: 72px;
      }
      @media (max-width: 1280px) {
        padding-right: 63px;
      }
      @media (max-width: 1024px) {
        padding-right: 54px;
      }
    }
    :global(.platform-desktop) & {
      &:hover + [role='tooltip'] {
        opacity: 1;
        transform: translateY(-100%) scale(1);
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Help Tooltip */
  /* ---------------------------------------------------------------- */
  [role='tooltip'] {
    // > p {
    //   max-height: 250px;
    //   overflow-y: auto;
    //   overflow-x: hidden;
    //   word-break: break-word;
    // }
    background-color: $white;
    border: 1px solid $mono2;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.16) 0 3px 6px;
    box-sizing: border-box;
    font-size: 17px;
    line-height: 25px;
    opacity: 0;
    padding: 11px 16px;
    position: absolute;
    right: -28px;
    top: 21px;
    transform: translateY(-100%) scale(0);
    transform-origin: calc(100% - 68px) calc(100% + 10px);
    transition-duration: 0.2s;
    transition-property: transform, opacity;
    transition-timing-function: ease-in-out;
    width: 208px;
    z-index: 10;
    @media (max-width: 1536px) {
      font-size: 16px;
      line-height: 24px;
      right: -32px;
      top: 13px;
      width: 200px;
    }
    @media (max-width: 1408px) {
      right: -24px;
      transform-origin: calc(100% - 61px) calc(100% + 10px);
    }
    @media (max-width: 1280px) {
      font-size: 15px;
      line-height: 23px;
      right: -16px;
      top: 9px;
      transform-origin: calc(100% - 48px) calc(100% + 10px);
      width: 188px;
    }
    @media (max-width: 1024px) {
      right: -12px;
      top: 7px;
      transform-origin: calc(100% - 39px) calc(100% + 10px);
    }
    @media (max-width: 375px) {
      font-size: 14px;
      line-height: 22px;
      right: -8px;
      transform-origin: calc(100% - 35px) calc(100% + 10px);
      width: 178px;
    }
    &::after {
      background-image: url(/images/icons/help-chevron.svg);
      background-repeat: no-repeat;
      background-size: cover;
      bottom: -23px;
      content: '';
      display: block;
      height: 30px;
      position: absolute;
      right: 54px;
      width: 30px;
      @media (max-width: 1408px) {
        right: 47px;
      }
      @media (max-width: 1280px) {
        right: 34px;
      }
      @media (max-width: 1024px) {
        right: 25px;
      }
      @media (max-width: 375px) {
        right: 21px;
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Show Help Tooltip - Mobile */
  /* ---------------------------------------------------------------- */
  &.showHelp {
    [role='tooltip'] {
      opacity: 1;
      transform: translateY(-100%) scale(1);
    }
  }
}