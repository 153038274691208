@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Braintree Form */
/* ---------------------------------------------------------------- */
.braintreeForm {
  & .cardTooltip {
    display: flex;
    flex-wrap: wrap;
    grid-gap: 10px;
    & > svg {
      height: 24px;
      right: 60px;
      top: 29px;
      transform: none;
      width: 24px;
      z-index: 8;
      @media (max-width: 1536px) {
        height: 22px;
        top: 26px;
        width: 22px;
      }
      @media (max-width: 1280px) {
        height: 20px;
        right: 55px;
        top: 23px;
        width: 20px;
      }
      @media (max-width: 1024px) {
        height: 18px;
        right: 50px;
        top: 20px;
        width: 18px;
      }
    }
  }
}
