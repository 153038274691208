@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
.video-with-caption {
  margin: 56px auto;
  max-width: 1020px;
  @media (max-width: 1536px) {
    margin: 48px auto;
  }
  @media (max-width: 1280px) {
    margin: 40px auto;
  }
  @media (max-width: 1024px) {
    margin: 32px auto;
  }
  @media (max-width: 375px) {
    margin: 24px auto;
  }
  figure {
    font-size: 0;
    line-height: 0;
    margin: 0 0 0 auto;
    position: relative;
    width: 100%;
  }
  /* ---------------------------------------------------------------- */
  /* iFrame */
  /* ---------------------------------------------------------------- */
  .iframeContainer {
    height: 0;
    padding-bottom: 56.25%;
    position: relative;
  }
  iframe {
    bottom: 0;
    height: 100%;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
  }
  figcaption {
    background-color: $brandBlackGrape;
    bottom: 0;
    box-sizing: border-box;
    color: $white;
    font-size: 14px;
    left: 0;
    line-height: 20px;
    padding: 19px 24px 18px;
    position: relative;
    right: 0;
    @media (max-width: 1280px) {
      font-size: 13px;
      line-height: 19px;
      padding: 16px 20px 15px;
    }
  }
}
