@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
h3.heading {
  color: $brandBlackGrape;
  display: block;
  font-family: $secondary;
  font-size: 20px;
  font-weight: $bold;
  line-height: 28px;
  margin-bottom: 16px;
  position: relative;
  @media (max-width: 1024px) {
    font-size: 18px;
  }
  @media (max-width: 767px) {
    font-size: 17px;
  }
}
