@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Congratulations Page */
/* ---------------------------------------------------------------- */
.congratulations {
  align-items: center;
  background-color: $brandBlackGrape;
  display: flex;
  height: 100%;
  padding: 84px;
  position: relative;
  @media (max-width: 1536px) {
    padding: 72px;
  }
  @media (max-width: 1408px) {
    padding: 60px;
  }
  @media (max-width: 1280px) {
    padding: 48px;
  }
  @media (max-width: 576px) {
    padding: 48px 40px;
  }
  @media (max-width: 448px) {
    padding: 48px 32px;
  }
  @media (max-width: 375px) {
    padding: 40px 24px;
  }
  /* ---------------------------------------------------------------- */
  /* Overlay */
  /* ---------------------------------------------------------------- */
  &::before {
    background-color: rgba($brandBlackGrape, 0.8);
    bottom: 0;
    content: '';
    display: block;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 0;
  }
  /* ---------------------------------------------------------------- */
  /* Decorative Slice */
  /* ---------------------------------------------------------------- */
  &::after {
    background-image: url('/images/artwork/congratulations-slice.svg');
    background-position-x: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    bottom: 0;
    content: '';
    display: block;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
    @media (max-width: 1280px) {
      background-position-x: 25%;
    }
    @media (max-width: 767px) {
      background-position-x: 35%;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Container */
  /* ---------------------------------------------------------------- */
  > div {
    align-items: center;
    flex-direction: column;
    height: 100%;
    min-width: 0;
    padding: 0;
    position: relative;
    z-index: 2;
    > div {
      display: flex;
      flex-direction: column;
      position: relative;
      width: 100%;
      &:first-child,
      &:last-child {
        align-items: center;
        flex-grow: 1;
      }
    }
    .greeting {
      align-items: center;
      text-align: center;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Title */
  /* ---------------------------------------------------------------- */
  h1 {
    align-items: center;
    color: $white;
    display: flex;
    font-family: $secondary;
    font-size: 60px;
    line-height: 64px;
    margin-bottom: 48px;
    @media (max-width: 1664px) {
      margin-bottom: 44px;
    }
    @media (max-width: 1536px) {
      font-size: 54px;
      line-height: 59px;
      margin-bottom: 40px;
    }
    @media (max-width: 1408px) {
      font-size: 48px;
      line-height: 53px;
      margin-bottom: 36px;
    }
    @media (max-width: 1280px) {
      font-size: 44px;
      line-height: 50px;
      margin-bottom: 32px;
    }
    @media (max-width: 767px) {
      flex-direction: column;
    }
    @media (max-width: 576px) {
      font-size: 40px;
      line-height: 45px;
      margin-bottom: 28px;
    }
    @media (max-width: 448px) {
      font-size: 36px;
      line-height: 41px;
      margin-bottom: 24px;
    }
    @media (max-width: 375px) {
      font-size: 32px;
      line-height: 37px;
      margin-bottom: 20px;
    }
    &:last-child {
      margin-bottom: 0;
    }
    span {
      display: table;
      margin: -10px 0 -12px;
      @media (max-width: 1280px) {
        margin: -10px 0 -9px;
      }
      @media (max-width: 767px) {
        margin: -9px 0;
      }
      @media (max-width: 576px) {
        margin: -8px 0;
      }
      @media (max-width: 375px) {
        margin: -7px 0;
      }
      &:not(:only-child) {
        text-align: right;
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Logo */
  /* ---------------------------------------------------------------- */
  .logo {
    display: inline-block;
    flex-shrink: 0;
    height: 98px;
    margin: 0 0 -3px 17px;
    width: 360px;
    @media (max-width: 1536px) {
      height: 85px;
      margin-left: 16px;
      width: 312px;
    }
    @media (max-width: 1408px) {
      height: 72px;
      margin-left: 15px;
      width: 264px;
    }
    @media (max-width: 1280px) {
      height: 64px;
      margin-left: 13px;
      width: 235px;
    }
    @media (max-width: 767px) {
      height: 64px;
      margin: 18px 0 0;
      width: 235px;
    }
    @media (max-width: 576px) {
      height: 58px;
      margin: 20px 0 0;
      width: 214px;
    }
    @media (max-width: 448px) {
      height: 51px;
      margin: 16px 0 0;
      width: 187px;
    }
    @media (max-width: 375px) {
      height: 44px;
      margin: 12px 0 0;
      width: 162px;
    }
    path:nth-child(2) {
      fill: $white;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Text */
  /* ---------------------------------------------------------------- */
  p {
    color: $white;
  }
  /* ---------------------------------------------------------------- */
  /* Main Text */
  /* ---------------------------------------------------------------- */
  .message {
    font-size: 28px;
    line-height: 38px;
    margin: -9px 0 -8px;
    max-width: 1024px;
    @media (max-width: 1536px) {
      font-size: 26px;
      line-height: 35px;
      margin: -7px 0 -8px;
    }
    @media (max-width: 1408px) {
      font-size: 24px;
      line-height: 33px;
      margin: -7px 0;
      max-width: 960px;
    }
    @media (max-width: 1280px) {
      font-size: 23px;
      line-height: 31px;
      max-width: 767px;
    }
    @media (max-width: 767px) {
      font-size: 22px;
      line-height: 30px;
      margin: -9px 0 -6px;
    }
    @media (max-width: 576px) {
      font-size: 21px;
      line-height: 29px;
      margin: -8px 0 -5px;
    }
    @media (max-width: 448px) {
      font-size: 20px;
      line-height: 28px;
      margin: -8px 0 -7px;
    }
    @media (max-width: 375px) {
      font-size: 19px;
      line-height: 26px;
      margin: -7px 0 -6px;
    }
    span:first-of-type {
      display: block;
    }
    .plan {
      color: $brandAqua;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Button */
  /* ---------------------------------------------------------------- */
  .link {
    margin-top: 48px;
    @media (max-width: 1664px) {
      margin-top: 44px;
    }
    @media (max-width: 1536px) {
      margin-top: 40px;
    }
    @media (max-width: 1408px) {
      margin-top: 36px;
    }
    @media (max-width: 1280px) {
      margin-top: 32px;
    }
    @media (max-width: 576px) {
      margin-top: 28px;
    }
    @media (max-width: 480px) {
      margin-top: 24px;
    }
    @media (max-width: 375px) {
      margin-top: 20px;
    }
  }
  /* ---------------------------------------------------------------- */
  /* No email container  */
  /* ---------------------------------------------------------------- */
  .noEmail {
    flex-direction: row;
    margin: 0 auto;
    padding-top: 56px;
    text-align: center;
    width: auto;
    @media (max-width: 1536px) {
      padding-top: 48px;
    }
    @media (max-width: 1408px) {
      padding-top: 40px;
    }
    @media (max-width: 1280px) {
      padding-top: 32px;
    }
    @media (max-width: 767px) {
      flex-direction: column;
      padding-top: 72px;
    }
    @media (max-width: 576px) {
      padding-top: 64px;
    }
    @media (max-width: 448px) {
      padding-top: 56px;
    }
    @media (max-width: 375px) {
      padding-top: 48px;
    }
    /* ---------------------------------------------------------------- */
    /* Text */
    /* ---------------------------------------------------------------- */
    p {
      align-self: flex-end;
      font-size: 20px;
      line-height: 27px;
      margin: -6px 0;
      @media (max-width: 1536px) {
        font-size: 19px;
        line-height: 26px;
      }
      @media (max-width: 1408px) {
        font-size: 18px;
      }
      @media (max-width: 1280px) {
        font-size: 17px;
        line-height: 25px;
      }
      @media (max-width: 1216px) {
        max-width: 461px;
      }
      @media (max-width: 816px) {
        font-size: 16px;
        line-height: 24px;
        max-width: 434px;
      }
      @media (max-width: 767px) {
        font-size: 17px;
        line-height: 25px;
        margin: -7px 0 -6px;
        max-width: 461px;
        padding: 0;
      }
      @media (max-width: 576px) {
        font-size: 16px;
        line-height: 24px;
        max-width: 305px;
      }
      @media (max-width: 375px) {
        font-size: 15px;
        line-height: 23px;
        max-width: 295px;
      }
      span {
        font-weight: $bold;
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Hide Footer */
  /* ---------------------------------------------------------------- */
  + footer {
    display: none;
  }
}
