@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Email Settings */
/* ---------------------------------------------------------------- */
.emailSettings {
  /* -------------------------------------------------------------- */
  /* Text */
  /* -------------------------------------------------------------- */
  > p {
    font-size: 17px;
    line-height: 25px;
    margin: -6px 0 -7px;
    @media (max-width: 1536px) {
      font-size: 16px;
      line-height: 24px;
    }
    @media (max-width: 1280px) {
      font-size: 15px;
      line-height: 23px;
    }
    @media (max-width: 375px) {
      font-size: 14px;
      line-height: 20px;
    }
  }
  /* -------------------------------------------------------------- */
  /* Button Row */
  /* -------------------------------------------------------------- */
  > div:last-child {
    display: flex;
    justify-content: flex-end;
    margin-top: 48px;
    @media (max-width: 1280px) {
      margin-top: 40px;
    }
    @media (max-width: 767px) {
      margin-top: 32px;
    }
    @media (max-width: 640px) {
      margin-top: 24px;
    }
  }
}
