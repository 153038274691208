@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Earned Badges Item */
/* ---------------------------------------------------------------- */
.earnedBadgesItem {
  align-items: center;
  border-bottom: 1px solid $grey;
  display: flex;
  padding: 28px 0;
  @media (max-width: 1536px) {
    padding: 24px 0;
  }
  @media (max-width: 1280px) {
    padding: 20px 0;
  }
  &:first-child {
    border-top: solid 1px $grey;
  }
  &:last-child {
    margin-bottom: 0;
  }
  /* ---------------------------------------------------------------- */
  /* Text */
  /* ---------------------------------------------------------------- */
  span {
    font-size: 20px;
    font-weight: $semibold;
    line-height: 24px;
    margin: -5px 24px -5px 0;
    @media (max-width: 1536px) {
      font-size: 19px;
      line-height: 23px;
      margin-right: 20px;
    }
    @media (max-width: 1280px) {
      font-size: 18px;
      line-height: 22px;
      margin-right: 16px;
    }
    @media (max-width: 1024px) {
      font-size: 17px;
      line-height: 21px;
      margin-right: 12px;
    }
  }
  /* ---------------------------------------------------------------- */
  /* MyAxelos Logo + Badge Icon */
  /* ---------------------------------------------------------------- */
  svg {
    flex-shrink: 0;
  }
  /* ---------------------------------------------------------------- */
  /* MyAxelos Logo */
  /* ---------------------------------------------------------------- */
  svg:first-child {
    height: 29px;
    margin-right: 24px;
    width: 34px;
    @media (max-width: 1536px) {
      height: 28px;
      margin-right: 20px;
      width: 33px;
    }
    @media (max-width: 1280px) {
      height: 27px;
      margin-right: 16px;
      width: 32px;
    }
    @media (max-width: 1024px) {
      height: 26px;
      margin-right: 12px;
      width: 31px;
    }
    @media (max-width: 896px) and (min-width: 768px) {
      display: none;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Badge Icon */
  /* ---------------------------------------------------------------- */
  svg:last-child {
    height: 26px;
    margin-left: auto;
    width: 26px;
    @media (max-width: 1536px) {
      height: 25px;
      width: 25px;
    }
    @media (max-width: 1280px) {
      height: 24px;
      width: 24px;
    }
    @media (max-width: 1024px) {
      height: 23px;
      width: 23px;
    }
    @media (max-width: 896px) and (min-width: 768px) {
      height: 22px;
      width: 22px;
    }
  }
  /* ---------------------------------------------------------------- */
  /* ProPath */
  /* ---------------------------------------------------------------- */
  &.propath {
    svg path {
      fill: $productProPath;
    }
  }
  /* ---------------------------------------------------------------- */
  /* ITIL */
  /* ---------------------------------------------------------------- */
  &.itil {
    svg path {
      fill: $productItil;
    }
  }
}
