body {
  margin: 0;
  padding: 0;
}

* {
  font-variation-settings: inherit;
}

a {
  text-decoration: none;
  &:focus {
    outline: none;
  }
}

blockquote {
  margin: 0;
}

details summary::-webkit-details-marker {
  display: none;
}

button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: transparent;
  border: none;
  color: inherit;
  display: block;
  font-family: inherit;
  font-weight: inherit;
  margin: 0;
  overflow: visible;
  padding: 0;
  &:focus {
    outline: none;
  }
  &:active {
    color: inherit;
  }
  &::-moz-focus-inner {
    border: 0;
  }
}

*[role='button'] {
  &:focus {
    outline: none;
  }
}

legend {
  margin: 0;
  padding: 0;
}

fieldset {
  border: 0;
  margin: 0;
  min-width: 0;
  padding: 0;
}

input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

ol,
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  font-weight: normal;
  margin: 0;
}

iframe {
  border: none;
}

strong {
  font-weight: normal;
}

table,
tbody,
tfoot,
thead,
tr,
th,
td {
  border: 0;
  font: inherit;
  font-size: 100%;
  margin: 0;
  padding: 0;
  vertical-align: baseline;
}
