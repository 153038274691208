@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Menu */
/* ---------------------------------------------------------------- */
.menu {
  margin-left: auto;
  @media (max-width: 576px) {
    margin-top: 16px;
    width: 100%;
  }
  @media (max-width: 375px) {
    margin-top: 12px;
  }
  /* -------------------------------------------------------------- */
  /* Menu List */
  /* -------------------------------------------------------------- */
  ul {
    display: flex;
    @media (max-width: 576px) {
      display: grid;
      grid-auto-flow: column;
      grid-template-rows: auto auto auto;
    }
  }
  /* -------------------------------------------------------------- */
  /* Menu Item */
  /* -------------------------------------------------------------- */
  ul li {
    font-size: 17px;
    line-height: 17px;
    margin-right: 72px;
    @media (max-width: 1664px) {
      margin-right: 64px;
    }
    @media (max-width: 1536px) {
      margin-right: 56px;
    }
    @media (max-width: 1408px) {
      margin-right: 48px;
    }
    @media (max-width: 1280px) {
      font-size: 16px;
      line-height: 16px;
      margin-right: 32px;
    }
    @media (max-width: 1024px) {
      margin-right: 24px;
    }
    @media (max-width: 576px) {
      margin: 0 -16px;
      width: 100%;
    }
    @media (max-width: 375px) {
      font-size: 15px;
      line-height: 15px;
      margin: 0 -12px;
    }
    &:last-child {
      margin-right: 0;
    }
  }
  /* -------------------------------------------------------------- */
  /* Menu Link */
  /* -------------------------------------------------------------- */
  a {
    align-self: flex-start;
    color: $brandAqua;
    display: block;
    font-family: $primary;
    font-size: inherit;
    font-weight: $medium;
    text-decoration-color: transparent;
    text-underline-offset: 2px;
    @include fast-transition($props: 'color, text-decoration-color');
    @include no-user-select;
    @media (max-width: 576px) {
      padding: 16px;
    }
    @media (max-width: 375px) {
      padding: 12px;
    }
    /* -------------------------------------------------------------- */
    /* Active Page */
    /* -------------------------------------------------------------- */
    &[aria-current] {
      color: $white;
      text-decoration: underline;
      text-decoration-color: $brandAqua;
      text-decoration-thickness: 2px;
      &:focus {
        color: darken($white, 20%);
      }
    }
    /* -------------------------------------------------------------- */
    /* Focus & Active */
    /* -------------------------------------------------------------- */
    &:not([aria-current]) {
      &:focus {
        color: $white;
        text-decoration: underline;
        text-decoration-color: $brandBerry;
        text-decoration-thickness: 2px;
      }
      &:active {
        color: darken($white, 20%);
      }
    }
    /* -------------------------------------------------------------- */
    /* Desktop Hover */
    /* -------------------------------------------------------------- */
    :global(.platform-desktop) & {
      &[aria-current] {
        &:hover {
          color: darken($white, 20%);
        }
      }
      &:not([aria-current]) {
        &:hover {
          color: $white;
        }
      }
    }
  }
}
