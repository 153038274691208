@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
.ThreeColumnCards {
  display: flex;
  margin-top: 30px;
  @media (max-width: 1142px) {
    flex-direction: column;
  }
  article {
    flex-direction: column;
    margin-bottom: 0;
    margin-right: 24px;
    position: relative;
    width: 33.333%;
    @media (max-width: 1142px) {
      flex-direction: row;
      margin-bottom: 24px;
      margin-right: 0;
      width: 100%;
    }
    @media (max-width: 767px) {
      flex-direction: column;
      margin-bottom: 32px;
    }
    &:last-child {
      margin-right: 0;
      @media (max-width: 767px) {
        margin-bottom: 0;
      }
    }
    > div:last-child {
      padding-left: 20px;
      padding-right: 20px;
      @media (max-width: 767px) {
        padding-left: 0;
        padding-right: 0;
      }
      .socialContainer {
        margin-bottom: 10px;
        @media (max-width: 767px) {
          margin-bottom: 0;
        }
        p {
          font-size: 17px;
          line-height: 20px;
        }
      }
      .introTextContainer {
        margin-bottom: 32px;
        p {
          font-size: 17px;
          line-height: 25px;
          @media (max-width: 1024px) {
            font-size: 16px;
          }
          @media (max-width: 767px) {
            display: none;
          }
        }
        a {
          color: inherit;
        }
      }
    }
    .publicationDate {
      color: $mono4;
      line-height: normal;
      margin: 0 0 7px 0;
    }
    .cardCta {
      margin-top: auto;
      @media (max-width: 1152px) {
        align-items: center;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
      }
      @media (max-width: 767px) {
        align-items: center;
        display: flex;
        justify-content: space-between;
      }
      @media (max-width: 340px) {
        .socialContainer {
          p {
            display: flex;
          }
        }
      }
      > a {
        min-width: 175px;
        @media (max-width: 767px) {
          font-size: 17px;
          min-width: auto;
        }
      }
    }
  }
}
