@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Autocomplete */
/* ---------------------------------------------------------------- */
.autocomplete {
  position: relative;
  width: 100%;
  /* ---------------------------------------------------------------- */
  /* Input Wrapper */
  /* ---------------------------------------------------------------- */
  .input {
    position: relative;
    /* ---------------------------------------------------------------- */
    /* Input */
    /* ---------------------------------------------------------------- */
    input {
      -webkit-appearance: none;
      -moz-appearance: none;
      -o-appearance: none;
      appearance: none;
      background-color: transparent;
      border: none;
      border-radius: 0;
      box-sizing: border-box;
      caret-color: $brandBlackGrape;
      color: $brandBlackGrape;
      display: inline-block;
      filter: none;
      font-family: $secondary;
      font-size: 32px;
      font-weight: normal;
      height: 80px;
      letter-spacing: normal;
      line-height: normal;
      outline: none;
      padding: 0 0 0 48px;
      resize: none;
      vertical-align: middle;
      width: 100%;
      @include no-user-select;
      @media (max-width: 1536px) {
        font-size: 29px;
        height: 72px;
        padding: 0 0 0 43px;
      }
      @media (max-width: 1280px) {
        font-size: 26px;
        height: 64px;
        padding: 0 0 0 38px;
      }
      @media (max-width: 1024px) {
        font-size: 23px;
        height: 56px;
        padding: 0 0 0 33px;
      }
      @media (max-width: 767px) {
        font-size: 20px;
        padding-left: 32px;
      }
      @media (max-width: 375px) {
        font-size: 18px;
      }
      &:invalid {
        box-shadow: none;
      }
      &:-webkit-autofill {
        background: transparent;
        -webkit-box-shadow: 0 0 0 1000px transparent inset !important;
        box-shadow: 0 0 0 1000px transparent inset !important;
        transition: background-color 10000s ease-in-out 0s;
      }
      &:-moz-autofill {
        background: $white;
      }
      &:-moz-autofill-preview {
        background: $white;
      }
      &::-ms-clear {
        display: none;
        height: 0;
        width: 0;
      }
      &::-webkit-contacts-auto-fill-button {
        display: none;
        visibility: hidden;
      }
      &::-webkit-credentials-auto-fill-button {
        display: none;
        visibility: hidden;
      }
      &::-webkit-caps-lock-indicator {
        display: none;
        visibility: hidden;
      }
      &::-ms-reveal {
        display: none;
        height: 0;
        width: 0;
      }
      &::-webkit-clear-button {
        display: none;
      }
      &::-webkit-search-cancel-button {
        display: none;
      }
      /* ---------------------------------------------------------------- */
      /* Placeholder */
      /* ---------------------------------------------------------------- */
      &::-webkit-input-placeholder {
        color: $mono4 !important;
        text-transform: inherit !important;
        @include no-user-select;
      }
      &:-moz-placeholder {
        color: $mono4 !important;
        opacity: 1 !important;
        text-transform: inherit !important;
        @include no-user-select;
      }
      &::-moz-placeholder {
        color: $mono4 !important;
        opacity: 1 !important;
        text-transform: inherit !important;
        @include no-user-select;
      }
      &:-ms-input-placeholder {
        color: $mono4 !important;
        text-transform: inherit !important;
        @include no-user-select;
      }
      &::placeholder {
        color: $mono4 !important;
        opacity: 1 !important;
        text-transform: inherit !important;
        @include no-user-select;
      }
      /* ---------------------------------------------------------------- */
      /* Focus */
      /* ---------------------------------------------------------------- */
      &:focus {
        @include user-select;
        + span::after {
          width: 100%;
        }
      }
      /* ---------------------------------------------------------------- */
      /* Hover */
      /* ---------------------------------------------------------------- */
      :global(.platform-desktop) & {
        &:hover {
          + span {
            background-color: $mono3;
          }
          /* ---------------------------------------------------------------- */
          /* Hover Focus */
          /* ---------------------------------------------------------------- */
          &:focus {
            + span::after {
              width: 100%;
            }
          }
        }
      }
    }
    /* ---------------------------------------------------------------- */
    /* Input Border */
    /* ---------------------------------------------------------------- */
    > span {
      align-items: center;
      background-color: $mono2;
      bottom: 0;
      display: flex;
      height: 1px;
      left: 0;
      position: absolute;
      width: 100%;
      @include fast-transition($props: 'background-color');
      &::after {
        background-color: $brandAqua;
        bottom: 0;
        content: '';
        display: block;
        height: 2px;
        left: 50%;
        position: absolute;
        transform: translateX(-50%);
        transition-duration: 0.2s;
        width: 0;
        @include fast-transition(
          $props: 'width',
          $timing: 'ease-out',
          $mob: true
        );
      }
    }
    /* ---------------------------------------------------------------- */
    /* Icon */
    /* ---------------------------------------------------------------- */
    > svg {
      flex-shrink: 0;
      height: 30px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 30px;
      @media (max-width: 1536px) {
        height: 27px;
        width: 27px;
      }
      @media (max-width: 1280px) {
        height: 24px;
        width: 24px;
      }
      @media (max-width: 1024px) {
        height: 21px;
        width: 21px;
      }
      @media (max-width: 767px) {
        height: 18px;
        width: 18px;
      }
      * {
        @include fast-transition($props: 'fill, stroke');
      }
    }
    /* ---------------------------------------------------------------- */
    /* Icon - Chevron */
    /* ---------------------------------------------------------------- */
    .chevron {
      height: 8px;
      margin-top: -4px;
      pointer-events: none;
      right: 0;
      transform: rotate(0);
      width: 14px;
      * {
        fill: $brandBlackGrape;
      }
    }
    /* ---------------------------------------------------------------- */
    /* Icon - Clear */
    /* ---------------------------------------------------------------- */
    .clear {
      cursor: pointer;
      height: 14px;
      right: 34px;
      width: 14px;
      @media (max-width: 1536px) {
        right: 30px;
      }
      @media (max-width: 1280px) {
        right: 29px;
      }
      @media (max-width: 1024px) {
        right: 27px;
      }
      @media (max-width: 767px) {
        right: 29px;
      }
      :global(.platform-desktop) & {
        &:hover {
          * {
            fill: $brandBlackGrape;
          }
        }
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Listbox */
  /* ---------------------------------------------------------------- */
  ul {
    background-color: $white;
    border: 1px solid $mono1;
    box-shadow: 0 8px 12px rgba($brandBlackGrape, 0.1);
    box-sizing: border-box;
    left: 0;
    position: absolute;
    right: 0;
    top: calc(100% + 12px);
    z-index: 999;
  }
  /* ---------------------------------------------------------------- */
  /* Item */
  /* ---------------------------------------------------------------- */
  li {
    align-items: center;
    background-color: $white;
    border-bottom: 1px solid $mono1;
    color: $brandBlackGrape;
    cursor: pointer;
    display: flex;
    font-family: $secondary;
    font-size: 20px;
    font-weight: $semibold;
    line-height: 24px;
    padding: 20px 24px;
    @include fast-transition($props: 'color, background-color');
    &:last-child {
      border: none;
    }
    @media (max-width: 1536px) {
      font-size: 19px;
      line-height: 22px;
      padding: 17px 22px;
    }
    @media (max-width: 1536px) {
      font-size: 18px;
      line-height: 22px;
      padding: 13px 20px;
    }
    &[data-focus='true'] {
      background-color: $greyXLight;
    }
    :global(.platform-desktop) & {
      &:hover {
        background-color: $greyXLight;
      }
      &:active {
        background-color: $mono1;
      }
    }
    :global(.platform-mobile) & {
      &:active {
        background-color: $greyXLight;
      }
    }
    /* ---------------------------------------------------------------- */
    /* Selected */
    /* ---------------------------------------------------------------- */
    &[aria-selected='true'] {
      background-color: $brandAquaPastel;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Show Listbox */
  /* ---------------------------------------------------------------- */
  &.showList {
    .chevron {
      transform: rotate(180deg);
    }
  }
  /* ---------------------------------------------------------------- */
  /* Has Value */
  /* ---------------------------------------------------------------- */
  &.hasValue {
    .input {
      input {
        padding-right: 65px;
        @media (max-width: 1536px) {
          padding-right: 59px;
        }
        @media (max-width: 1280px) {
          padding-right: 57px;
        }
        @media (max-width: 1024px) {
          padding-right: 53px;
        }
        @media (max-width: 767px) {
          padding-right: 57px;
        }
        + span {
          background-color: $brandBlackGrape;
        }
      }
    }
  }
}
