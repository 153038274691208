@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Signup reminder banner */
/* ---------------------------------------------------------------- */
.signupReminder {
  align-items: center;
  align-items: flex-start;
  background-color: $mono1;
  border-radius: 10px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  margin: 40px 0 0;
  padding: 12px 20px;
  @media (max-width: 1153px) and (min-width: 768px), (max-width: 724px) {
    padding: 16px 54px 15px 16px;
  }
  > div {
    /* ---------------------------------------------------------------- */
    /* CTA Button */
    /* ---------------------------------------------------------------- */
    &:nth-of-type(1) {
      font-size: 17px;
      margin-bottom: 15px;
      @media (max-width: 600px) {
        font-size: 15px;
      }
    }
  }
}
