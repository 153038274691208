@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Account Info */
/* ---------------------------------------------------------------- */
.accountInfo[class] {
  display: flex;
  flex-direction: column;
  height: 56px;
  justify-content: center;
  max-width: 264px;
  padding-left: 56px;
  padding-right: 46px;
  position: relative;
  @media (max-width: 1280px) {
    max-width: 248px;
  }
  @media (max-width: 896px) and (min-width: 768px) {
    flex-shrink: 0;
    max-width: 108px;
  }
  @media (max-width: 767px) {
    box-sizing: border-box;
    height: auto;
    margin-top: auto;
    max-width: 100%;
    min-height: 64px;
    padding-left: 60px;
    padding-right: 0;
  }
  /* -------------------------------------------------------------- */
  /* Mobile Border */
  /* -------------------------------------------------------------- */
  &::before {
    @media (max-width: 767px) {
      background-color: $grey;
      bottom: 0;
      content: '';
      height: 1px;
      left: 0;
      position: absolute;
      right: 0;
      width: 100%;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Button */
  /* ---------------------------------------------------------------- */
  @at-root {
    button#{&} {
      background-color: transparent;
      border: 1px solid $grey;
      border-radius: 100px;
      cursor: pointer;
      touch-action: manipulation;
      @include fast-transition($props: 'background-color');
      > * {
        z-index: 1;
      }
      /* ---------------------------------------------------------------- */
      /* Focus Ring */
      /* ---------------------------------------------------------------- */
      &::after {
        border: 2px dashed transparent;
        border-radius: 100px;
        bottom: -1px;
        content: '';
        display: block;
        left: -1px;
        position: absolute;
        right: -1px;
        top: -1px;
        z-index: 0;
        @include fast-transition($props: 'border-color');
      }
      /* ---------------------------------------------------------------- */
      /* Interactive States */
      /* ---------------------------------------------------------------- */
      &:focus {
        background-color: $greyXXLight;
        &::after {
          border-color: $brandBerry;
        }
        span {
          text-decoration: underline;
        }
      }
      :global(.platform-desktop) & {
        &:hover {
          background-color: $greyXXLight;
        }
        &:active {
          background-color: $grey;
        }
      }
      :global(.platform-mobile) & {
        &:active {
          background-color: $greyXXLight;
        }
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Profile Image */
  /* ---------------------------------------------------------------- */
  img {
    border-radius: 50%;
    height: 40px;
    left: 8px;
    object-fit: cover;
    position: absolute;
    top: 8px;
    width: 40px;
    @include no-user-select;
    @media (max-width: 767px) {
      left: 0;
      top: 12px;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Name & Company */
  /* ---------------------------------------------------------------- */
  span {
    display: block;
    font-size: 12px;
    line-height: 17px;
    overflow: hidden;
    position: relative;
    text-align: left;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
    @include no-user-select;
    @media (max-width: 896px) and (min-width: 768px) {
      display: none;
    }
    @media (max-width: 767px) {
      font-size: 13px;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Name */
  /* ---------------------------------------------------------------- */
  span:nth-of-type(1) {
    font-family: $secondary;
    font-weight: $bold;
    &:only-of-type {
      margin-bottom: -1px;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Company */
  /* ---------------------------------------------------------------- */
  span:nth-of-type(2) {
    font-family: $primary;
  }
  /* ---------------------------------------------------------------- */
  /* Chevron */
  /* ---------------------------------------------------------------- */
  svg {
    flex-shrink: 0;
    height: 20px;
    position: absolute;
    right: 24px;
    top: 50%;
    transform: translate(3px, -50%);
    transform-origin: 50% 0;
    width: 20px;
    @include transition(
      $mob: true,
      $props: 'transform',
      $timing: 'cubic-bezier(0.2, 1, 0.35, 1)',
      $duration: '0.4s'
    );
    * {
      fill: $brandBlackGrape;
    }
    g {
      transform: translate(0, 14px);
      @include transition(
        $mob: true,
        $props: 'transform',
        $timing: 'cubic-bezier(0.2, 1, 0.35, 1)',
        $duration: '0.4s'
      );
    }
    line {
      stroke: $brandBlackGrape;
      stroke-linecap: round;
      stroke-width: 12px;
      transform-origin: center center;
      will-change: transform, stroke;
      @include transition(
        $mob: true,
        $props: 'stroke, transform',
        $timing: 'cubic-bezier(0.2, 1, 0.35, 1)',
        $duration: '0.4s'
      );
      &:nth-child(1) {
        transform: rotate(45deg);
      }
      &:nth-child(2) {
        transform: rotate(-45deg);
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Open */
  /* ---------------------------------------------------------------- */
  &[aria-expanded='true'] {
    svg {
      g {
        transform: translate(0, -14px);
        line {
          &:nth-child(1) {
            transform: rotate(-45deg);
          }
          &:nth-child(2) {
            transform: rotate(45deg);
          }
        }
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Mobile Account Item */
  /* ---------------------------------------------------------------- */
  ~ li {
    button,
    a {
      span {
        padding-left: 34px;
      }
    }
  }
}
/* ---------------------------------------------------------------- */
/* Account */
/* ---------------------------------------------------------------- */
.account {
  animation-duration: 0.4s;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.2, 1, 0.35, 1);
  background: white;
  border: 1px solid $mono2;
  border-radius: 9px;
  box-shadow: 0 5px 8px rgba($mono4, 0.5);
  box-sizing: border-box;
  position: absolute;
  right: 72px;
  top: 101px;
  transform-origin: calc(100% - 32px) -16px;
  width: 304px;
  will-change: opacity, transform;
  z-index: 100;
  @media (max-width: 1664px) {
    right: 64px;
  }
  @media (max-width: 1536px) {
    right: 56px;
  }
  @media (max-width: 1408px) {
    right: 48px;
  }
  @media (max-width: 1280px) {
    right: 40px;
    top: 93px;
  }
  @media (max-width: 1152px) {
    right: 32px;
  }
  @media (max-width: 1024px) {
    right: 24px;
  }
  @media (max-width: 375px) {
    right: 16px;
  }
  /* ---------------------------------------------------------------- */
  /* Chevron */
  /* ---------------------------------------------------------------- */
  > svg {
    height: 26px;
    position: absolute;
    right: 10px;
    top: -26px;
  }
  /* ---------------------------------------------------------------- */
  /* List */
  /* ---------------------------------------------------------------- */
  > ul {
    background-color: $brandGreyLight;
    border-radius: 9px;
    overflow: hidden;
    /* ---------------------------------------------------------------- */
    /* Switch Accounts Item */
    /* ---------------------------------------------------------------- */
    > li {
      border-bottom: 1px solid $mono2;
      &:last-child {
        border-bottom: none;
      }
      /* ---------------------------------------------------------------- */
      /* Button / Link */
      /* ---------------------------------------------------------------- */
      > button,
      > a {
        background-color: $white;
      }
      /* ---------------------------------------------------------------- */
      /* Text */
      /* ---------------------------------------------------------------- */
      > button > span,
      > a > span {
        padding-left: 37px;
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* List Item */
  /* ---------------------------------------------------------------- */
  li {
    position: relative;
    /* ---------------------------------------------------------------- */
    /* Buttons */
    /* ---------------------------------------------------------------- */
    > button,
    > a {
      align-items: center;
      color: $brandBlackGrape;
      cursor: pointer;
      display: inline-flex;
      font-family: $primary;
      font-size: 15px;
      font-weight: inherit;
      height: 57px;
      padding-left: 23px;
      touch-action: manipulation;
      width: 100%;
      @include fast-transition(
        $props: 'background-color, font-variation-settings'
      );
      @include no-user-select;
      /* ---------------------------------------------------------------- */
      /* Icon */
      /* ---------------------------------------------------------------- */
      svg:nth-child(2) {
        height: 20px;
        position: absolute;
        width: 20px;
      }
      /* -------------------------------------------------------------- */
      /* Active Page */
      /* -------------------------------------------------------------- */
      &[aria-current='page'] {
        @include variable-fonts($weight: 700);
        svg:nth-child(2) {
          height: 24px;
          margin-left: -2px;
          width: 24px;
        }
      }
      /* ---------------------------------------------------------------- */
      /* Interactive States */
      /* ---------------------------------------------------------------- */
      &:focus {
        background-color: $greyXXLight;
        span {
          text-decoration: underline;
        }
      }
      :global(.platform-desktop) & {
        &:hover {
          background-color: $greyXXLight;
        }
        &:active {
          background-color: $grey;
        }
      }
      :global(.platform-mobile) & {
        &:active {
          background-color: $greyXXLight;
        }
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Open */
  /* ---------------------------------------------------------------- */
  &.open {
    animation-name: fadeIn;
    :global(html:not(.browser-firefox)) & {
      animation-name: fadeInScaleUp;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Closed / Closing */
  /* ---------------------------------------------------------------- */
  &.closed {
    animation-name: fadeOut;
    :global(html:not(.browser-firefox)) & {
      animation-name: fadeOutScaleDown;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Hide from screen readers / tab order */
  /* ---------------------------------------------------------------- */
  &.hide {
    display: none;
  }
}

@keyframes fadeInScaleUp {
  0% {
    opacity: 0;
    transform: scale(0.25);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
@keyframes fadeOutScaleDown {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(0.25);
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
