@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
.privacyPolicy {
  padding: 72px 0;
  text-align: justify;
  @media (max-width: 1664px) {
    padding: 64px 0;
  }
  @media (max-width: 1536px) {
    padding: 56px 0;
  }
  @media (max-width: 1408px) {
    padding: 48px 0;
  }
  @media (max-width: 1280px) {
    padding: 32px 0;
  }
  @media (max-width: 1024px) {
    padding: 24px 0;
  }
  @media (max-width: 767px) {
    padding: 32px 0;
  }
  @media (max-width: 375px) {
    padding: 24px 0;
  }
}
