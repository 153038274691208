@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Content */
/* ---------------------------------------------------------------- */
.content {
  box-sizing: border-box;
  padding: 36px 72px 72px 0;
  width: 66.666%;
  @media (max-width: 1664px) {
    padding: 36px 64px 64px 0;
  }
  @media (max-width: 1536px) {
    padding: 36px 56px 56px 0;
  }
  @media (max-width: 1408px) {
    padding: 36px 48px 48px 0;
    width: 66.666%;
  }
  @media (max-width: 1280px) {
    padding: 32px 40px 32px 0;
  }
  @media (max-width: 1152px) {
    padding: 32px 32px 32px 0;
  }
  @media (max-width: 1024px) {
    padding: 24px 24px 24px 0;
  }
  @media (max-width: 767px) {
    padding: 32px 0;
    width: 100%;
  }
  @media (max-width: 375px) {
    padding: 24px 0;
  }
  /* ---------------------------------------------------------------- */
  /* Top Padding */
  /* ---------------------------------------------------------------- */
  &.verticalPaddingLarge {
    padding-top: 72px;
    @media (max-width: 1664px) {
      padding-top: 64px;
    }
    @media (max-width: 1536px) {
      padding-top: 56px;
    }
    @media (max-width: 1408px) {
      padding-top: 48px;
    }
    @media (max-width: 1280px) {
      padding-bottom: 40px;
      padding-top: 40px;
    }
    @media (max-width: 1152px) {
      padding-bottom: 40px;
      padding-top: 40px;
    }
    @media (max-width: 1024px) {
      padding-bottom: 40px;
      padding-top: 40px;
    }
    @media (max-width: 767px) {
      padding-bottom: 40px;
      padding-top: 40px;
    }
    @media (max-width: 375px) {
      padding-bottom: 32px;
      padding-top: 32px;
    }
  }
}
