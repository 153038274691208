@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Select */
/* ---------------------------------------------------------------- */
.select {
  position: relative;
  /* ---------------------------------------------------------------- */
  /* Input - Input */
  /* ---------------------------------------------------------------- */
  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    appearance: none;
    background-color: $white;
    border-color: $mono2;
    border-radius: 11px;
    border-style: solid;
    border-width: 1px;
    box-sizing: border-box;
    caret-color: $brandBlackGrape;
    color: $brandBlackGrape;
    display: inline-block;
    filter: none;
    font-family: $primary;
    font-size: 20px;
    font-weight: normal;
    height: 80px;
    letter-spacing: normal;
    line-height: normal;
    outline: none;
    padding: 0 72px 0 30px;
    resize: none;
    vertical-align: middle;
    width: 100%;
    @include no-user-select;
    @include fast-transition($props: 'color, border-color, background-color');
    @media (max-width: 1536px) {
      font-size: 19px;
      height: 72px;
      padding: 0 66px 0 26px;
    }
    @media (max-width: 1280px) {
      font-size: 18px;
      height: 64px;
      padding: 0 56px 0 22px;
    }
    @media (max-width: 1024px) {
      font-size: 17px;
      height: 56px;
      padding: 0 48px 0 18px;
    }
    @media (max-width: 767px) {
      font-size: 16px;
    }
    &:invalid {
      box-shadow: none;
    }
    &:-webkit-autofill {
      transition: background-color 10000s ease-in-out 0s;
    }
    &:-moz-autofill {
      background: $white;
    }
    &:-moz-autofill-preview {
      background: $white;
    }
    &::-ms-clear {
      display: none;
      height: 0;
      width: 0;
    }
    &::-webkit-contacts-auto-fill-button {
      display: none;
      visibility: hidden;
    }
    &::-webkit-credentials-auto-fill-button {
      display: none;
      visibility: hidden;
    }
    &::-webkit-caps-lock-indicator {
      display: none;
      visibility: hidden;
    }
    &::-ms-reveal {
      display: none;
      height: 0;
      width: 0;
    }
    &::-webkit-clear-button {
      display: none;
    }
    &::-webkit-search-cancel-button {
      display: none;
    }
    /* ---------------------------------------------------------------- */
    /* Focus */
    /* ---------------------------------------------------------------- */
    &:focus {
      border-color: $brandBlackGrape;
      @include user-select;
    }
    /* ---------------------------------------------------------------- */
    /* Hover */
    /* ---------------------------------------------------------------- */
    :global(.platform-desktop) & {
      &:hover {
        border-color: $mono3;
        /* ---------------------------------------------------------------- */
        /* Hover Focus */
        /* ---------------------------------------------------------------- */
        &:focus {
          border-color: $brandBlackGrape;
        }
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Chevron */
  /* ---------------------------------------------------------------- */
  > svg {
    flex-shrink: 0;
    height: 10px;
    pointer-events: none;
    position: absolute;
    right: 32px;
    top: 50%;
    transform: translateY(-50%);
    width: 14px;
    @media (max-width: 1536px) {
      right: 28px;
    }
    @media (max-width: 1280px) {
      right: 24px;
    }
    @media (max-width: 1024px) {
      right: 20px;
    }
    * {
      fill: $mono4;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Read Only */
  /* ---------------------------------------------------------------- */
  &.readOnly {
    select {
      background-color: $mono1;
      color: $mono4;
      pointer-events: none;
      &:focus {
        &::selection {
          @include no-user-select;
        }
      }
      ~ label {
        pointer-events: none;
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Placeholder */
  /* ---------------------------------------------------------------- */
  &.placeholder {
    select {
      color: $mono4;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Invalid */
  /* ---------------------------------------------------------------- */
  &.invalid {
    select {
      border-color: red;
      /* ---------------------------------------------------------------- */
      /* Focus */
      /* ---------------------------------------------------------------- */
      &:focus {
        border-color: $brandBlackGrape;
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Disabled */
  /* ---------------------------------------------------------------- */
  &.disabled {
    select {
      background-color: $pageBackground;
      border-color: $mono2;
      color: $mono2;
      opacity: 1;
      pointer-events: none;
    }
  }
}
