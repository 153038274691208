@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
.textWithSubheading {
  &.topBorder {
    border-top: 1px solid black;
  }
  &.bottomBorder {
    border-bottom: 1px solid black;
  }
}

.post-card-list {
  display: flex;
  .post-card-container {
    display: flex;
    flex-direction: column;
    width: 365px;
  }
}
