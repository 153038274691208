@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Braintree Wrapper */
/* ---------------------------------------------------------------- */
.braintreeWrapper {
  align-items: flex-start;
  display: grid;
  grid-column-gap: 32px;
  position: relative;
  @media (min-width: 896px), (max-width: 767px) and (min-width: 577px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (max-width: 1536px) {
    grid-column-gap: 28px;
  }
  @media (max-width: 1408px) {
    grid-column-gap: 24px;
  }
  @media (max-width: 1280px) {
    grid-column-gap: 20px;
  }
  @media (max-width: 1024px) {
    grid-column-gap: 16px;
  }
  > * {
    @media (min-width: 896px), (max-width: 767px) and (min-width: 577px) {
      grid-column: 1 / span 3;
    }
    &:focus {
      outline: none;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Iframe */
  /* ---------------------------------------------------------------- */
  iframe {
    position: relative;
    &:focus {
      outline: none;
      *:focus {
        outline: none;
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Safari Bug Fix
  /* ---------------------------------------------------------------- */
  fieldset[class] {
    display: grid;
    // display: flex and flex-direction: column was causing the fieldsets to
    // ignore align-items: flex-start on container
  }
  /* ---------------------------------------------------------------- */
  /* Payment Details */
  /* ---------------------------------------------------------------- */
  fieldset:nth-of-type(1) {
    /* ---------------------------------------------------------------- */
    /* Credit Card Number */
    /* ---------------------------------------------------------------- */
    > div:nth-of-type(2) {
      > div > div[role='tooltip'] {
        margin-right: auto;
      }
      > div > div {
        margin-right: 116px;
        @media (max-width: 1536px) {
          margin-right: 104px;
        }
        @media (max-width: 1280px) {
          margin-right: 92px;
        }
        @media (max-width: 1024px) {
          margin-right: 80px;
        }
      }
      > svg {
        height: 24px;
        right: 60px;
        top: 29px;
        transform: none;
        width: 24px;
        z-index: 8;
        @media (max-width: 1536px) {
          height: 22px;
          top: 26px;
          width: 22px;
        }
        @media (max-width: 1280px) {
          height: 20px;
          right: 55px;
          top: 23px;
          width: 20px;
        }
        @media (max-width: 1024px) {
          height: 18px;
          right: 50px;
          top: 20px;
          width: 18px;
        }
      }
      > svg + svg {
        right: 90px;
        @media (max-width: 1536px) {
          right: 90px;
        }
        @media (max-width: 1280px) {
          right: 85px;
        }
        @media (max-width: 1024px) {
          right: 75px;
        }
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Expiration Date */
  /* ---------------------------------------------------------------- */
  fieldset:nth-of-type(2) {
    @media (min-width: 896px), (max-width: 767px) and (min-width: 577px) {
      align-items: flex-start;
      display: grid;
      grid-column: 1 / span 2;
      grid-column-gap: inherit;
      grid-template-columns: repeat(2, 1fr);
    }
    > div {
      @media (min-width: 896px), (max-width: 767px) and (min-width: 577px) {
        grid-row: 2;
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* CVV */
  /* ---------------------------------------------------------------- */
  fieldset:nth-of-type(3) {
    @media (min-width: 896px), (max-width: 767px) and (min-width: 577px) {
      grid-column: 3 / span 1;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Billing Address */
  /* ---------------------------------------------------------------- */
  fieldset:nth-of-type(4) {
    @media (min-width: 896px), (max-width: 767px) and (min-width: 577px) {
      display: grid;
      grid-column: 1 / span 3;
      grid-column-gap: inherit;
      grid-template-columns: 1fr 1fr;
    }
    /* ---------------------------------------------------------------- */
    /* Legend + Questions */
    /* ---------------------------------------------------------------- */
    > * {
      @media (min-width: 896px), (max-width: 767px) and (min-width: 577px) {
        grid-column: 1 / span 2;
      }
    }
    /* ---------------------------------------------------------------- */
    /* First Name + City */
    /* ---------------------------------------------------------------- */
    > div:nth-of-type(1),
    > div:nth-of-type(5) {
      @media (min-width: 896px), (max-width: 767px) and (min-width: 577px) {
        grid-column: 1;
      }
    }
    /* ---------------------------------------------------------------- */
    /* Last Name + Zip / Postcode */
    /* ---------------------------------------------------------------- */
    > div:nth-of-type(2),
    > div:nth-of-type(6) {
      @media (min-width: 896px), (max-width: 767px) and (min-width: 577px) {
        grid-column: 2;
      }
    }
    /* ---------------------------------------------------------------- */
    /* Country select */
    /* ---------------------------------------------------------------- */
    > div:nth-of-type(7) {
      svg path {
        fill: currentColor;
      }
    }
  }
}
