@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Account Settings Item */
/* ---------------------------------------------------------------- */
.accountSettingsItem {
  display: table-row;
  @media (max-width: 767px) {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
  }
  @media (max-width: 375px) {
    margin-top: 13px;
  }
  &:first-child {
    margin-top: 0;
  }
  /* -------------------------------------------------------------- */
  /* Additional */
  /* -------------------------------------------------------------- */
  & + .accountSettingsItem > span {
    padding-top: 27px;
    @media (max-width: 1536px) {
      padding-top: 24px;
    }
    @media (max-width: 1280px) {
      padding-top: 21px;
    }
    @media (max-width: 767px) {
      padding-top: 0;
    }
  }
  /* -------------------------------------------------------------- */
  /* Cells */
  /* -------------------------------------------------------------- */
  > span {
    color: $brandBlackGrape;
    display: table-cell;
    font-size: 17px;
    line-height: 25px;
    @media (max-width: 1536px) {
      font-size: 16px;
      line-height: 24px;
    }
    @media (max-width: 1280px) {
      font-size: 15px;
      line-height: 23px;
    }
    @media (max-width: 375px) {
      font-size: 14px;
      line-height: 20px;
    }
    /* ------------------------------------------------------------ */
    /* Title */
    /* ------------------------------------------------------------ */
    &:nth-child(1) {
      width: 128px;
      @media (max-width: 1536px) {
        width: 119px;
      }
      @media (max-width: 1280px) {
        width: 111px;
      }
      @media (max-width: 767px) {
        width: auto;
      }
    }
    /* ------------------------------------------------------------ */
    /* Text */
    /* ------------------------------------------------------------ */
    &:nth-child(2) {
      color: rgba($brandBlackGrape, 0.7);
      font-weight: $bold;
      width: auto;
      word-break: break-word;
      @media (max-width: 767px) {
        margin-top: 5px;
      }
      @media (max-width: 375px) {
        margin-top: 1px;
      }
    }
    /* ------------------------------------------------------------ */
    /* Button */
    /* ------------------------------------------------------------ */
    &:nth-child(3) {
      text-align: right;
      width: 257px;
      @media (max-width: 1536px) {
        width: 242px;
      }
      @media (max-width: 1280px) {
        width: 227px;
      }
      @media (max-width: 767px) {
        margin-top: 12px;
        text-align: left;
        width: auto;
      }
      @media (max-width: 375px) {
        margin-top: 8px;
      }
      /* ---------------------------------------------------------- */
      /* Button */
      /* ---------------------------------------------------------- */
      > button {
        color: $brandBlackGrape;
        cursor: pointer;
        display: inline-block;
        font-family: $primary;
        font-size: 18px;
        font-weight: $bold;
        line-height: 26px;
        white-space: nowrap;
        @include fast-transition($props: 'color');
        @include no-user-select;
        @media (max-width: 1536px) {
          font-size: 17px;
          line-height: 25px;
        }
        @media (max-width: 1280px) {
          font-size: 16px;
          line-height: 24px;
        }
        @media (max-width: 375px) {
          font-size: 15px;
          line-height: 23px;
        }
        &:focus {
          color: $mono5;
          text-decoration: underline;
          svg * {
            fill: $mono5;
          }
        }
        :global(.platform-desktop) & {
          &:hover {
            color: $brandAqua;
            svg * {
              fill: $brandAqua;
            }
          }
          &:active {
            color: $mono5;
            svg * {
              fill: $mono5;
            }
          }
        }
        :global(.platform-mobile) & {
          &:active {
            color: $brandAqua;
            svg * {
              fill: $brandAqua;
            }
          }
        }
        > svg {
          display: inline-block;
          fill: $brandBlackGrape;
          height: 10px;
          margin-left: 15px;
          width: 6px;
          @media (max-width: 375px) {
            margin-left: 11px;
          }
          * {
            @include fast-transition($props: 'fill');
          }
        }
      }
    }
  }
}
