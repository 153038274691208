@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
p.excerpt {
  color: $brandBlackGrape;
  display: block;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 20px;
  position: relative;
  @media (max-width: 767px) {
    display: none;
  }
}
