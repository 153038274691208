@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
.card {
  background-color: $white;
  box-shadow: 0 0 10px rgba($black, 10%);
  display: flex;
  margin-bottom: 24px;
  position: relative;
  &:last-child {
    margin-bottom: 0;
  }
  @media (max-width: 767px) {
    background-color: transparent;
    box-shadow: none;
    flex-direction: column;
  }
  &.eventCard {
    background-color: rgba($color: $white, $alpha: 0.4);
    box-shadow: none;
    @media (max-width: 767px) {
      background-color: transparent;
    }
  }
  &.featuredEventCard {
    background-color: transparent;
    box-shadow: none;
  }
  .cardImage {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: block;
    min-height: 194px;
    min-width: 320px;
    overflow: hidden;
    position: relative;
    @media (max-width: 1536px) {
      min-width: 256px;
    }
    @media (max-width: 1408px) {
      min-width: 320px;
    }
    @media (max-width: 1024px) {
      min-width: 256px;
    }
    @media (max-width: 767px) {
      height: 208px;
      min-width: 220px;
      width: 100%;
    }
    &.eventCardImage {
      min-width: auto;
      width: 100%;
      @media (max-width: 1600px) {
        min-width: 256px;
        width: auto;
      }
    }
    &.featuredEventImage {
      height: 322px;
      width: 50%;
      @media (max-width: 767px) {
        height: 208px;
        width: 100%;
      }
    }
    &.large {
      height: 320px;
      @media (max-width: 767px) {
        height: 208px;
      }
    }
    // 63 deg overlay
    span {
      background-repeat: no-repeat;
      background-size: cover;
      bottom: -1px;
      height: auto;
      position: absolute;
      right: -1px;
      top: -1px;
      width: 52.5%;
      z-index: 2;
    }
    a {
      bottom: 0;
      color: inherit;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
    }
  }
  .cardContent {
    padding: 24px 32px;
    @media (max-width: 1024px) {
      padding: 16px 24px;
    }
    @media (max-width: 767px) {
      padding-left: 0;
      padding-right: 0;
    }
    &.fullHeight {
      display: flex;
      flex: 1;
      flex-direction: column;
    }
    &.resourceCard {
      padding: 36px 32px;
      @media (max-width: 767px) {
        padding: 24px 0;
      }
    }
    &.eventCardContent {
      padding: 24px;
      @media (max-width: 767px) {
        padding: 24px 0 0 0;
      }
    }
    &.featuredEventContent {
      padding-bottom: 0;
      padding-top: 0;
      @media (max-width: 767px) {
        padding: 24px 0 0 0;
      }
    }
  }
  .placeholderImage {
    background-image: url('/images/placeholders/resource-hub-list.svg');
    background-position: right;
    background-size: cover;
  }
}
