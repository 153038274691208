@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
a.textLink {
  align-items: center;
  color: $brandBerry;
  display: inline-flex;
  font-family: $secondary;
  font-size: 17px;
  font-weight: $bold;
  line-height: 20px;
  @media (max-width: 1024px) {
    font-size: 16px;
  }
  @media (max-width: 767px) {
    font-size: 17px;
  }
  @media (max-width: 400px) {
    font-size: 15px;
  }
  > svg {
    height: 14px;
    margin-left: 16px;
    width: 8px;
    @media (max-width: 400px) {
      margin-left: 8px;
    }
    path {
      fill: $brandBerry;
    }
  }
  &:hover,
  &:active {
    color: $brandBerryDark;
    svg > path {
      fill: $brandBerryDark;
    }
  }
}
