@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Qualifications Item */
/* ---------------------------------------------------------------- */
.qualificationsItem {
  align-items: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  position: relative;
  @media (max-width: 1152px) and (min-width: 768px), (max-width: 576px) {
    align-items: flex-start;
    flex-direction: row;
  }
  &:first-child > div::before,
  &:last-child > div::after {
    content: none;
  }
  &:last-child > div::after {
    @media (max-width: 1152px) and (min-width: 768px), (max-width: 576px) {
      background-color: $pageBackground;
      bottom: 0;
      content: '';
      display: block;
      height: 100%;
      left: 14px;
      position: absolute;
      top: 29px;
      transform: none;
      width: 4px;
      z-index: 1;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Trophy Icon */
  /* ---------------------------------------------------------------- */
  &:last-child > div > div > svg {
    height: 21px;
    @media (max-width: 1536px) {
      height: 19px;
    }
    @media (max-width: 1280px) {
      height: 17px;
    }
    @media (max-width: 1152px) and (min-width: 768px), (max-width: 576px) {
      height: 19px;
    }
    @media (max-width: 375px) {
      height: 18px;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Badges Container */
  /* ---------------------------------------------------------------- */
  > div {
    display: flex;
    justify-content: center;
    margin-bottom: 8px;
    position: relative;
    width: 100%;
    @media (max-width: 1536px) {
      margin-bottom: 10px;
    }
    @media (max-width: 1280px) {
      margin-bottom: 7px;
    }
    @media (max-width: 1152px) and (min-width: 768px), (max-width: 576px) {
      height: 100%;
      justify-content: flex-start;
      margin: 0;
      width: auto;
    }
    /* ---------------------------------------------------------------- */
    /* Badges Left Line */
    /* ---------------------------------------------------------------- */
    &::before {
      background-color: $brandGreyLight;
      content: '';
      height: 4px;
      left: -10px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 50%;
      @media (max-width: 1152px) and (min-width: 768px), (max-width: 576px) {
        display: none;
      }
    }
    /* ---------------------------------------------------------------- */
    /* Badges Right Line */
    /* ---------------------------------------------------------------- */
    &::after {
      background-color: $brandGreyLight;
      content: '';
      height: 4px;
      position: absolute;
      right: -10px;
      top: 50%;
      transform: translate(0, -50%);
      width: 50%;
      @media (max-width: 1152px) and (min-width: 768px), (max-width: 576px) {
        display: none;
      }
    }
    /* ---------------------------------------------------------------- */
    /* Badge */
    /* ---------------------------------------------------------------- */
    > div {
      align-items: center;
      background-color: $brandGreyLight;
      border: 4px solid $white;
      border-radius: 50%;
      display: flex;
      flex-shrink: 0;
      height: 40px;
      justify-content: center;
      width: 40px;
      z-index: 2;
      @media (max-width: 1536px) {
        height: 36px;
        width: 36px;
      }
      @media (max-width: 1280px) {
        height: 32px;
        width: 32px;
      }
      @media (max-width: 375px) {
        height: 30px;
        width: 30px;
      }
      @media (max-width: 1152px) and (min-width: 768px), (max-width: 576px) {
        background-color: $white;
        border: none;
      }
      /* ---------------------------------------------------------------- */
      /* Badge Icon */
      /* ---------------------------------------------------------------- */
      > svg {
        flex-shrink: 0;
        height: 24px;
        width: 24px;
        @media (max-width: 1536px) {
          height: 21px;
          width: 21px;
        }
        @media (max-width: 1280px) {
          height: 18px;
          width: 18px;
        }
        @media (max-width: 375px) {
          height: 17px;
          width: 17px;
        }
        path {
          fill: $brandBlackGrape;
        }
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Text */
  /* ---------------------------------------------------------------- */
  > span {
    color: $brandBlackGrape;
    display: flex;
    flex-direction: column;
    font-size: 17px;
    font-weight: $semibold;
    line-height: 22px;
    margin: -5px 0;
    text-align: center;
    @media (max-width: 1664px) {
      font-size: 16px;
      line-height: 21px;
    }
    @media (max-width: 1536px) {
      font-size: 15px;
      line-height: 19px;
      margin: -4px 0;
    }
    @media (max-width: 1280px) {
      font-size: 14px;
      line-height: 18px;
    }
    @media (max-width: 1152px) and (min-width: 768px), (max-width: 576px) {
      display: block;
      font-size: 16px;
      line-height: 21px;
      margin-left: 15px;
      margin-top: 6px;
      text-align: left;
    }
    @media (max-width: 375px) {
      font-size: 15px;
      line-height: 19px;
      margin-bottom: -4px;
      margin-left: 11px;
    }
    .requiresFoundation {
      color: rgba($brandBlackGrape, 0.5);
      font-size: 12px;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Achieved */
  /* ---------------------------------------------------------------- */
  &.achieved {
    > div > div {
      > svg path {
        fill: $white;
      }
    }
    > span {
      color: rgba($brandBlackGrape, 0.5);
      font-weight: $regular;
    }
    /* ---------------------------------------------------------------- */
    /* ProPath */
    /* ---------------------------------------------------------------- */
    &.ProPath {
      > div > div {
        background-color: $productProPath;
        svg path {
          fill: $white;
        }
      }
    }
    /* ---------------------------------------------------------------- */
    /* ITIL */
    /* ---------------------------------------------------------------- */
    &.ITIL-4 {
      > div > div {
        background-color: $productItil;
        svg path {
          fill: $white;
        }
      }
    }
  }
}
