@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Events Item */
/* ---------------------------------------------------------------- */
.eventsItem {
  background-color: rgba($white, 0.4);
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
  width: 100%;
  @media (max-width: 896px) and (min-width: 768px), (max-width: 576px) {
    background-color: transparent;
    box-shadow: none;
    flex-direction: column;
  }
  /* ---------------------------------------------------------------- */
  /* Image */
  /* ---------------------------------------------------------------- */
  > span {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: block;
    flex-shrink: 0;
    height: 0;
    overflow: hidden;
    padding-bottom: 60%;
    position: relative;
    width: 100%;
    @media (max-width: 896px) and (min-width: 768px), (max-width: 576px) {
      min-height: 208px;
      padding-bottom: 0;
    }
    @media (max-width: 375px) {
      min-height: 175px;
    }
    /* ---------------------------------------------------------------- */
    /* 63 Deg Overlay */
    /* ---------------------------------------------------------------- */
    span {
      background-image: url('/images/artwork/post-slice.svg');
      background-repeat: no-repeat;
      background-size: cover;
      bottom: -1px;
      height: auto;
      position: absolute;
      right: -1px;
      top: -1px;
      width: 52.5%;
      z-index: 2;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Content */
  /* ---------------------------------------------------------------- */
  > div {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 20px;
    @media (max-width: 1536px) {
      padding: 18px;
    }
    @media (max-width: 1280px) {
      padding: 16px;
    }
    @media (max-width: 1024px) {
      padding: 12px;
    }
    @media (max-width: 896px) and (min-width: 768px), (max-width: 576px) {
      padding: 20px 0 32px;
    }
  }
  &:last-child > div {
    @media (max-width: 896px) and (min-width: 768px), (max-width: 576px) {
      padding-bottom: 0;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Copy Container */
  /* ---------------------------------------------------------------- */
  > div > div:nth-child(1) {
    margin: 0;
  }
  /* ---------------------------------------------------------------- */
  /* Date Container */
  /* ---------------------------------------------------------------- */
  .eventDateTime {
    margin-top: auto;
    padding-top: 23px;
    @media (max-width: 1536px) {
      padding-top: 20px;
    }
    @media (max-width: 896px) and (min-width: 768px), (max-width: 576px) {
      padding-top: 24px;
    }
    @media (max-width: 375px) {
      padding-top: 20px;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Title */
  /* ---------------------------------------------------------------- */
  h3 {
    color: $brandBlackGrape;
    display: block;
    font-size: 18px;
    font-weight: $bold;
    line-height: 26px;
    margin-bottom: 11px;
    margin-top: -7px;
    position: relative;
    @media (max-width: 1536px) {
      font-size: 17px;
      line-height: 25px;
      margin-bottom: 8px;
      margin-top: -6px;
    }
    @media (max-width: 1280px) {
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 6px;
    }
    @media (max-width: 1024px) {
      font-size: 15px;
      line-height: 23px;
      margin-bottom: 6px;
    }
    @media (max-width: 896px) and (min-width: 768px), (max-width: 576px) {
      font-size: 17px;
      line-height: 25px;
    }
    @media (max-width: 767px) {
      font-size: 16px;
      line-height: 24px;
    }
    @media (max-width: 480px) {
      font-size: 15px;
      line-height: 23px;
    }
    @media (max-width: 375px) {
      font-size: 14px;
      line-height: 21px;
    }
    &:last-child {
      margin-bottom: -7px;
      @media (max-width: 896px) and (min-width: 768px), (max-width: 576px) {
        margin-bottom: -6px;
      }
      @media (max-width: 375px) {
        margin-bottom: -5px;
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Excerpt */
  /* ---------------------------------------------------------------- */
  p {
    color: $brandBlackGrape;
    display: block;
    font-size: 17px;
    line-height: 25px;
    margin-bottom: -6px;
    position: relative;
    @media (max-width: 1536px) {
      font-size: 16px;
      line-height: 24px;
    }
    @media (max-width: 1280px) {
      font-size: 15px;
      line-height: 23px;
    }
    @media (max-width: 1024px) {
      font-size: 14px;
      line-height: 21px;
      margin-bottom: -5px;
    }
    @media (max-width: 896px) and (min-width: 768px), (max-width: 576px) {
      display: none;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Cta Container */
  /* ---------------------------------------------------------------- */
  .cta {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-top: 24px;
    @media (max-width: 1536px) {
      padding-top: 20px;
    }
    @media (max-width: 1280px) {
      padding-top: 20px;
    }
    @media (max-width: 896px) and (min-width: 768px), (max-width: 576px) {
      padding-top: 24px;
    }
    @media (max-width: 375px) {
      padding-top: 20px;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Hyperlink */
  /* ---------------------------------------------------------------- */
  .hyperlink {
    display: table;
    font-family: $secondary;
    font-size: 17px;
    font-weight: $bold;
    line-height: 20px;
    margin: -3px 0 -4px;
    min-width: 0;
    text-decoration: none;
    @media (max-width: 1536px) {
      font-size: 16px;
      line-height: 19px;
    }
    @media (max-width: 1280px) {
      font-size: 15px;
      line-height: 18px;
    }
    @media (max-width: 1024px) {
      font-size: 14px;
      line-height: 17px;
    }
    @media (max-width: 896px) and (min-width: 768px), (max-width: 576px) {
      font-size: 17px;
      line-height: 20px;
      margin: 0 0 -5px;
    }
    @media (max-width: 480px) {
      font-size: 16px;
      line-height: 19px;
    }
    @media (max-width: 375px) {
      font-size: 15px;
      line-height: 18px;
    }
    &:focus {
      text-decoration: underline;
    }
    > svg {
      height: 12px;
      margin-left: 16px;
      width: 7px;
      @media (max-width: 1536px) {
        margin-left: 12px;
        position: relative;
        top: 1px;
      }
      @media (max-width: 1280px) {
        margin-left: 8px;
      }
      @media (max-width: 896px) and (min-width: 768px), (max-width: 576px) {
        margin-left: 16px;
      }
      @media (max-width: 480px) {
        margin-left: 12px;
      }
      @media (max-width: 375px) {
        margin-left: 8px;
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Button */
  /* ---------------------------------------------------------------- */
  a:not(.hyperlink) {
    min-width: 175px;
    @media (max-width: 896px) and (min-width: 768px), (max-width: 576px) {
      min-width: auto;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Primary */
  /* ---------------------------------------------------------------- */
  &.primary,
  &:only-child {
    background-color: transparent;
    flex-direction: row;
    @media (max-width: 896px) and (min-width: 768px), (max-width: 576px) {
      flex-direction: column;
    }
    /* ---------------------------------------------------------------- */
    /* Image */
    /* ---------------------------------------------------------------- */
    > span {
      height: auto;
      min-height: 320px;
      padding-bottom: 0;
      width: calc(50% - 18px);
      @media (max-width: 1536px) {
        min-height: 280px;
      }
      @media (max-width: 1408px) {
        min-height: 240px;
      }
      @media (max-width: 1280px) {
        min-height: 200px;
        width: calc(50% - 16px);
      }
      @media (max-width: 1152px) {
        min-height: 190px;
      }
      @media (max-width: 1024px) {
        min-height: 180px;
        width: calc(50% - 12px);
      }
      @media (max-width: 896px) and (min-width: 768px), (max-width: 576px) {
        min-height: 208px;
        width: 100%;
      }
      @media (max-width: 375px) {
        min-height: 175px;
      }
    }
    /* ---------------------------------------------------------------- */
    /* Content */
    /* ---------------------------------------------------------------- */
    > div {
      height: auto;
      padding: 0 0 0 36px;
      @media (max-width: 1280px) {
        padding-left: 32px;
      }
      @media (max-width: 1024px) {
        padding-left: 24px;
      }
      @media (max-width: 896px) and (min-width: 768px), (max-width: 576px) {
        padding: 20px 0 0;
      }
    }
    /* ---------------------------------------------------------------- */
    /* Title */
    /* ---------------------------------------------------------------- */
    h3 {
      font-family: $secondary;
      font-size: 20px;
      font-weight: $semibold;
      line-height: 28px;
      margin-bottom: 19px;
      margin-top: -6px;
      @media (max-width: 1536px) {
        font-size: 19px;
        line-height: 27px;
        margin-bottom: 13px;
      }
      @media (max-width: 1280px) {
        font-size: 18px;
        line-height: 26px;
        margin-bottom: 11px;
      }
      @media (max-width: 1024px) {
        font-size: 17px;
        line-height: 25px;
        margin-bottom: 7px;
      }
      @media (max-width: 896px) and (min-width: 768px), (max-width: 576px) {
        font-family: $primary;
        font-weight: $bold;
      }
      @media (max-width: 767px) {
        font-size: 16px;
        line-height: 24px;
      }
      @media (max-width: 480px) {
        font-size: 15px;
        line-height: 23px;
      }
      @media (max-width: 375px) {
        font-size: 14px;
        line-height: 21px;
      }
      &:last-child {
        margin-bottom: -7px;
        @media (max-width: 896px) and (min-width: 768px), (max-width: 576px) {
          margin-bottom: -6px;
        }
        @media (max-width: 375px) {
          margin-bottom: -5px;
        }
      }
    }
    /* ---------------------------------------------------------------- */
    /* Date Container */
    /* ---------------------------------------------------------------- */
    .eventDateTime {
      padding-top: 32px;
      @media (max-width: 1536px) {
        padding-top: 28px;
      }
      @media (max-width: 1280px) {
        padding-top: 24px;
      }
      @media (max-width: 1024px) {
        padding-top: 20px;
      }
      @media (max-width: 896px) and (min-width: 768px), (max-width: 576px) {
        padding-top: 24px;
      }
      @media (max-width: 375px) {
        padding-top: 20px;
      }
      /* ---------------------------------------------------------------- */
      /*  Title */
      /* ---------------------------------------------------------------- */
      h4 {
        margin-bottom: 16px;
        @media (max-width: 1536px) {
          margin-bottom: 13px;
        }
        @media (max-width: 1280px) {
          margin-bottom: 9px;
        }
        @media (max-width: 375px) {
          margin-bottom: 5px;
        }
      }
    }
    /* ---------------------------------------------------------------- */
    /* Cta Container */
    /* ---------------------------------------------------------------- */
    .cta {
      margin-top: 0;
      padding-top: 32px;
      @media (max-width: 1536px) {
        padding-top: 28px;
      }
      @media (max-width: 1280px) {
        padding-top: 24px;
      }
      @media (max-width: 1024px) {
        padding-top: 20px;
      }
      @media (max-width: 896px) and (min-width: 768px), (max-width: 576px) {
        padding-top: 24px;
      }
      @media (max-width: 375px) {
        padding-top: 20px;
      }
    }
  }
}
