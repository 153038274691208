@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Recaptcha Message */
/* ---------------------------------------------------------------- */
.recaptchaMessage {
  margin: -15px 0 -3px;
  @media (max-width: 1536px) {
    margin-top: -12px;
  }
  @media (max-width: 1408px) {
    margin-top: -8px;
  }
  @media (max-width: 1280px) {
    margin-top: -4px;
  }
  @media (max-width: 1024px) {
    margin-top: 0;
  }
}
/* ---------------------------------------------------------------- */
/* Payment Form */
/* ---------------------------------------------------------------- */
.paymentForm {
  position: relative;
}
/* ---------------------------------------------------------------- */
/* Secure Checkout Icon */
/* ---------------------------------------------------------------- */
.secureIcon {
  position: absolute;
  right: 0;
  top: -7px;
  @media (max-width: 1280px) {
    top: -8px;
  }
  @media (max-width: 1024px) {
    top: -9px;
  }
  > svg {
    width: 168px;
    @media (max-width: 1024px) {
      width: 167px;
    }
    @media (max-width: 450px) {
      width: unset;
    }
  }
}
