@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* -------------------------------------------------------------- */
/* No Results Container */
/* -------------------------------------------------------------- */
.noResults {
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 184px 0;
  text-align: center;
  @media (max-width: 1280px) {
    padding: 124px 0;
  }
  @media (max-width: 1024px) {
    padding: 94px 0;
  }
  @media (max-width: 767px) {
    padding: 64px 0;
  }
  /* -------------------------------------------------------------- */
  /* Bookmark Icon */
  /* -------------------------------------------------------------- */
  .bookmarkIcon {
    height: auto;
    margin-bottom: 42px;
    @media (max-width: 1280px) {
      width: 47px;
    }
    @media (max-width: 767px) {
      width: 40px;
    }
  }
  /* -------------------------------------------------------------- */
  /* Page Title */
  /* -------------------------------------------------------------- */
  h2 {
    font-family: $secondary;
    font-size: 32px;
    margin-bottom: 48px;
    max-width: 860px;
    @media (max-width: 1280px) {
      font-size: 24px;
    }
    @media (max-width: 767px) {
      font-size: 20px;
    }
  }
  /* -------------------------------------------------------------- */
  /* CTA */
  /* -------------------------------------------------------------- */
  .link span {
    font-weight: $regular;
  }
}
