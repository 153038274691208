@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Footer */
/* ---------------------------------------------------------------- */
.footer {
  margin-top: auto;
  /* ---------------------------------------------------------------- */
  /* List */
  /* ---------------------------------------------------------------- */
  ul {
    border-top: 1px solid $mono2;
    display: flex;
    margin: 48px 0 -4px;
    padding-top: 28px;
    @media (max-width: 1280px) {
      padding-top: 23px;
    }
    @media (max-width: 1024px) {
      margin-top: 41px;
      padding-top: 18px;
    }
    @media (max-width: 767px) {
      align-items: center;
      flex-direction: column;
      margin-top: 41px;
      padding-top: 34px;
    }
    @media (max-width: 375px) {
      margin: 33px 0 -5px;
      padding-top: 26px;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Item */
  /* ---------------------------------------------------------------- */
  li {
    display: block;
    margin: 0 31px 0 0;
    @media (max-width: 1280px) {
      margin: 0 26px 0 0;
    }
    @media (max-width: 1024px) {
      margin: 0 23px 0 0;
    }
    @media (max-width: 767px) {
      margin: 0 0 18px;
    }
    @media (max-width: 375px) {
      margin: 0 0 14px;
    }
    &:last-child {
      margin: 0;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Link */
  /* ---------------------------------------------------------------- */
  a {
    color: $mono4;
    font-size: 20px;
    line-height: 20px;
    touch-action: manipulation;
    @include fast-transition($props: 'color');
    @media (max-width: 1664px) {
      font-size: 19px;
      line-height: 19px;
    }
    @media (max-width: 1536px) {
      font-size: 18px;
      line-height: 18px;
    }
    @media (max-width: 1408px) {
      font-size: 17px;
      line-height: 17px;
    }
    @media (max-width: 1280px) {
      font-size: 16px;
      line-height: 16px;
    }
    @media (max-width: 1024px) {
      font-size: 15px;
      line-height: 15px;
    }
    &:focus {
      color: $brandBlackGrape;
      text-decoration: underline;
    }
    :global(.platform-desktop) & {
      &:hover {
        color: $brandAquaDark;
      }
      &:active {
        color: $brandBlackGrape;
      }
    }
    :global(.platform-active) & {
      :active {
        color: $brandAquaDark;
      }
    }
  }
}
