@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Modal Email Change */
/* ---------------------------------------------------------------- */
.modalEmailChange {
  min-width: 424px;
  @media (max-width: 767px) {
    min-width: 100%;
  }
  /* ---------------------------------------------------------------- */
  /* Scrollable Content */
  /* ---------------------------------------------------------------- */
  > div {
    /* ---------------------------------------------------------------- */
    /* Text */
    /* ---------------------------------------------------------------- */
    p {
      display: block;
    }
  }
}
