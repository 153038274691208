@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Form Control */
/* ---------------------------------------------------------------- */
.formControl {
  align-items: center;
  display: flex;
  position: relative;
  @media (max-width: 896px) {
    flex-direction: column;
  }
  /* ---------------------------------------------------------------- */
  /* Recaptcha Message */
  /* ---------------------------------------------------------------- */
  small {
    margin: 17px 0 -2px;
    @media (max-width: 1536px) {
      margin-top: 15px;
    }
    @media (max-width: 1280px) {
      margin-top: 13px;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Steps Text */
  /* ---------------------------------------------------------------- */
  > p {
    color: $mono4;
    font-size: 15px;
    line-height: 23px;
    margin: 0 24px;
    @media (max-width: 896px) {
      margin-bottom: -7px;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Submit */
  /* ---------------------------------------------------------------- */
  .nav[type='submit'] {
    min-width: 166px;
    @media (max-width: 1536px) {
      min-width: 146px;
    }
    @media (max-width: 1280px) {
      min-width: 130px;
    }
  }
  button[class] {
    border-radius: 9px;
    height: 72px;
    justify-content: center;
    min-width: 166px;
    @media (max-width: 1536px) {
      height: 64px;
      min-width: 146px;
    }
    @media (max-width: 1280px) {
      height: 56px;
      min-width: 130px;
    }
    &:first-child {
      margin-left: auto;
      @media (max-width: 767px) {
        margin-left: 0;
      }
    }
    span {
      font-size: 19px;
      font-weight: $bold;
      @media (max-width: 1536px) {
        font-size: 18px;
      }
      @media (max-width: 1280px) {
        font-size: 17px;
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Default Button */
  /* ---------------------------------------------------------------- */
  .default[type='submit'] {
    min-width: 188px;
    @media (max-width: 1536px) {
      min-width: 172px;
    }
    @media (max-width: 1280px) {
      min-width: 158px;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Step 1 */
  /* ---------------------------------------------------------------- */
  &.step1 {
    display: block;
    p {
      @media (max-width: 896px) {
        order: 2;
      }
    }
    button[type='submit'] {
      @media (max-width: 896px) {
        margin-bottom: 18px;
        order: 1;
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Step 2 */
  /* ---------------------------------------------------------------- */
  &.step2 {
    display: block;
    button[type='button'] {
      margin-left: 0;
      margin-right: auto;
      order: 1;
      @media (max-width: 896px) {
        margin-bottom: 18px;
        margin-right: 0;
        order: 2;
      }
    }
    p {
      order: 3;
      @media (max-width: 896px) {
        order: 3;
      }
    }
    button[type='submit'] {
      order: 4;
      @media (max-width: 896px) {
        margin-bottom: 24px;
        order: 1;
      }
    }
    > span {
      order: 2;
      @media (max-width: 375px) {
        top: 80px;
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Submitting */
  /* ---------------------------------------------------------------- */
  &.submitting {
    /* ---------------------------------------------------------------- */
    /* Button */
    /* ---------------------------------------------------------------- */
    > button:last-of-type {
      span {
        color: transparent;
      }
    }
    /* ---------------------------------------------------------------- */
    /* Spinner */
    /* ---------------------------------------------------------------- */
    .spinner {
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
