@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* -------------------------------------------------------------- */
/* Bookmark List */
/* -------------------------------------------------------------- */
.threeColumnBookmarks {
  display: grid;
  grid-gap: 32px;
  grid-template-columns: repeat(3, 1fr);
  margin-top: 32px;
  width: 100%;
  @media (max-width: 1024px) {
    grid-row-gap: 24px;
    margin-top: 24px;
  }
  @media (max-width: 896px) {
    grid-template-columns: auto;
    grid-template-rows: repeat(3, 1fr);
  }
  /* -------------------------------------------------------------- */
  /* Bookmark Item */
  /* -------------------------------------------------------------- */
  article {
    flex-direction: column;
    @media (max-width: 896px) {
      flex-direction: row;
    }
    @media (max-width: 576px) {
      flex-direction: column;
    }
    /* -------------------------------------------------------------- */
    /* Image */
    /* -------------------------------------------------------------- */
    > span {
      width: 100%;
      @media (max-width: 896px) {
        width: 36%;
      }
      @media (max-width: 767px) {
        width: 40%;
      }
      @media (max-width: 576px) {
        width: 100%;
      }
    }
    /* -------------------------------------------------------------- */
    /* Social Container */
    /* -------------------------------------------------------------- */
    > div > div > div:first-of-type {
      margin: 0;
    }
  }
}
