@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* PeopleCert Passport Error */
/* ---------------------------------------------------------------- */
.modalPCertPassportError {
  padding-bottom: 0;
  /* ---------------------------------------------------------------- */
  /* Containers */
  /* ---------------------------------------------------------------- */
  > div {
    align-items: center;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    justify-content: center;
    position: relative;
    &:first-child,
    &:last-child {
      align-items: center;
      flex-grow: 1;
      width: 100%;
    }
    &:last-child {
      min-height: 114px;
      @media (max-width: 1536px) {
        min-height: 106px;
      }
      @media (max-width: 1408px) {
        min-height: 98px;
      }
      @media (max-width: 1280px) {
        min-height: 90px;
      }
      @media (max-width: 767px) {
        min-height: 82px;
      }
      @media (max-width: 375px) {
        min-height: 64px;
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Copy */
  /* ---------------------------------------------------------------- */
  > div > div {
    display: flex;
    flex-direction: column;
    /* ---------------------------------------------------------------- */
    /* Title */
    /* ---------------------------------------------------------------- */
    h1 {
      color: $brandBlackGrape;
      font-family: $secondary;
      font-size: 63px;
      line-height: 70px;
      margin-bottom: 25px;
      @media (max-width: 1536px) {
        font-size: 54px;
        line-height: 60px;
        margin-bottom: 22px;
      }
      @media (max-width: 1280px) {
        font-size: 45px;
        line-height: 52px;
        margin-bottom: 20px;
      }
      @media (max-width: 767px) {
        font-size: 24px;
        line-height: 32px;
        margin-bottom: 18px;
      }
      @media (max-width: 375px) {
        font-size: 20px;
        line-height: 26px;
        margin-bottom: 13px;
      }
      &:first-child {
        margin-top: -13px;
        @media (max-width: 1536px) {
          margin-top: -11px;
        }
        @media (max-width: 1280px) {
          margin-top: -10px;
        }
        @media (max-width: 767px) {
          margin-top: -8px;
        }
        @media (max-width: 375px) {
          margin-top: -6px;
        }
      }
      &:last-child {
        margin-bottom: -13px;
        @media (max-width: 1536px) {
          margin-bottom: -11px;
        }
        @media (max-width: 1280px) {
          margin-bottom: -10px;
        }
        @media (max-width: 767px) {
          margin-bottom: -8px;
        }
        @media (max-width: 375px) {
          margin-bottom: -6px;
        }
      }
    }
    /* ---------------------------------------------------------------- */
    /* Text */
    /* ---------------------------------------------------------------- */
    > div {
      color: $brandBlackGrape;
      font-family: $secondary;
      font-size: 32px;
      line-height: 40px;
      margin-bottom: 15px;
      a {
        text-decoration: none;
        &:focus {
          text-decoration: underline;
        }
      }
      @media (max-width: 1536px) {
        font-size: 28px;
        line-height: 36px;
        margin-bottom: 12px;
      }
      @media (max-width: 1280px) {
        font-size: 24px;
        line-height: 32px;
        margin-bottom: 9px;
      }
      @media (max-width: 767px) {
        font-size: 24px;
        line-height: 32px;
      }
      @media (max-width: 375px) {
        font-size: 20px;
        line-height: 26px;
        margin-bottom: 12px;
      }
      &:last-child {
        margin-bottom: -9px;
        @media (max-width: 1536px) {
          margin-bottom: -8px;
        }
        @media (max-width: 375px) {
          margin-bottom: -6px;
        }
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Logo */
  /* ---------------------------------------------------------------- */
  > div > svg {
    flex-shrink: 0;
    height: 35px;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 165px;
    @media (max-width: 375px) {
      height: 32px;
      width: 156px;
    }
    * {
      fill: $brandBlackGrape;
    }
  }
}
