@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Modal Roles */
/* ---------------------------------------------------------------- */
.modalRoles {
  /* ---------------------------------------------------------------- */
  /* Containers */
  /* ---------------------------------------------------------------- */
  > div {
    align-items: center;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    justify-content: center;
    position: relative;
    &:first-child,
    &:last-child {
      align-items: center;
      flex-grow: 1;
      width: 100%;
    }
    &:last-child {
      min-height: 114px;
      @media (max-width: 1536px) {
        min-height: 106px;
      }
      @media (max-width: 1408px) {
        min-height: 98px;
      }
      @media (max-width: 1280px) {
        min-height: 90px;
      }
      @media (max-width: 767px) {
        min-height: 82px;
      }
      @media (max-width: 375px) {
        min-height: 64px;
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Title */
  /* ---------------------------------------------------------------- */
  > div > p {
    font-family: $secondary;
    font-size: 72px;
    line-height: 70px;
    margin: 0 0 123px;
    @media (max-width: 1664px) {
      font-size: 64px;
      line-height: 63px;
      margin-bottom: 108px;
    }
    @media (max-width: 1536px) {
      font-size: 56px;
      line-height: 56px;
      margin: 0 0 92px;
    }
    @media (max-width: 1280px) {
      font-size: 48px;
      line-height: 50px;
      margin-bottom: 84px;
    }
    @media (max-width: 767px) {
      font-size: 32px;
      line-height: 34px;
      margin-bottom: 74px;
    }
    @media (max-width: 576px) {
      margin-bottom: 66px;
    }
    @media (max-width: 420px) {
      margin-bottom: 58px;
    }
    @media (max-width: 375px) {
      font-size: 28px;
      line-height: 30px;
      margin-bottom: 43px;
    }
  }
  /* ---------------------------------------------------------------- */
  /* List */
  /* ---------------------------------------------------------------- */
  > div:nth-child(2) {
    @media (max-width: 576px) {
      width: 100%;
    }
  }
  > div > ul {
    display: flex;
    margin: 0 -24px 123px;
    @media (max-width: 1664px) {
      margin-bottom: 108px;
    }
    @media (max-width: 1536px) {
      margin: 0 -22px 92px;
    }
    @media (max-width: 1280px) {
      margin: 0 -20px 84px;
    }
    @media (max-width: 767px) {
      margin: 0 -12px 74px;
    }
    @media (max-width: 576px) {
      justify-content: center;
      margin-bottom: 66px;
      width: 100%;
    }
    @media (max-width: 420px) {
      margin: 0 -10px 58px;
    }
    @media (max-width: 375px) {
      margin-bottom: 43px;
    }
    /* ---------------------------------------------------------------- */
    /* Title */
    /* ---------------------------------------------------------------- */
    p {
      color: $brandBlackGrape;
      font-family: $primary;
      font-size: 20px;
      font-weight: $bold;
      line-height: 27px;
      margin: 0 0 20px;
      position: relative;
      z-index: 1;
      @include fast-transition($props: 'color');
      @media (max-width: 1536px) {
        font-size: 19px;
        line-height: 26px;
        margin: 0 0 16px;
      }
      @media (max-width: 1280px) {
        font-size: 18px;
        line-height: 25px;
        margin: 0 0 12px;
      }
      @media (max-width: 576px) {
        font-size: 17px;
        line-height: 24px;
        margin: 0 0 8px;
      }
      @media (max-width: 420px) {
        font-size: 16px;
        line-height: 23px;
        margin-bottom: -5px;
      }
      @media (max-width: 375px) {
        font-size: 15px;
        line-height: 22px;
        margin-bottom: -4px;
      }
      &:last-child {
        margin-bottom: -6px;
      }
    }
    /* ---------------------------------------------------------------- */
    /* Description */
    /* ---------------------------------------------------------------- */
    span {
      color: $brandBlackGrape;
      display: table;
      font-family: $primary;
      font-size: 17px;
      line-height: 25px;
      margin-bottom: -6px;
      position: relative;
      z-index: 1;
      @include fast-transition($props: 'color');
      @media (max-width: 1536px) {
        font-size: 16px;
        line-height: 24px;
      }
      @media (max-width: 1280px) {
        font-size: 15px;
        line-height: 23px;
      }
      @media (max-width: 576px) {
        font-size: 14px;
        line-height: 22px;
      }
      @media (max-width: 420px) {
        display: none;
      }
    }
    /* ---------------------------------------------------------------- */
    /* Button */
    /* ---------------------------------------------------------------- */
    button {
      cursor: pointer;
      position: relative;
      touch-action: manipulation;
      &::after {
        background-color: rgba($brandBlackGrape, 0.15);
        border-radius: 20px;
        content: '';
        display: block;
        height: calc(100% + 30px);
        left: -15px;
        opacity: 0;
        position: absolute;
        top: -15px;
        // Prevents element 'shudder' on hover in Safari
        -webkit-transform: translate3d(0, 0, 0);
        width: calc(100% + 30px);
        z-index: 0;
        @include fast-transition($props: 'opacity background-color');
        @media (max-width: 375px) {
          border-radius: 12px;
          height: calc(100% + 16px);
          left: -8px;
          top: -8px;
          width: calc(100% + 16px);
        }
      }
      &:focus {
        &::after {
          opacity: 1;
        }
        p,
        span {
          text-decoration: underline;
        }
      }
      :global(.platform-desktop) & {
        &:hover {
          &::after {
            opacity: 1;
          }
        }
        &:active {
          &::after {
            background-color: rgba($brandBlackGrape, 0.25);
            opacity: 1;
          }
        }
      }
      :global(.platform-mobile) & {
        &:active {
          &::after {
            background-color: rgba($brandBlackGrape, 0.5);
            opacity: 1;
          }
          p,
          span {
            color: $white;
          }
          [data-target='fill'] {
            fill: $white;
          }
          [data-target='stroke'] {
            stroke: $white;
          }
        }
      }
    }
    /* ---------------------------------------------------------------- */
    /* Item */
    /* ---------------------------------------------------------------- */
    li {
      align-items: center;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      margin: 0 24px;
      text-align: justify;
      width: 228px;
      @media (max-width: 1536px) {
        margin: 0 22px;
        width: 214px;
      }
      @media (max-width: 1280px) {
        margin: 0 20px;
        width: 201px;
      }
      @media (max-width: 767px) {
        margin: 0 12px;
        width: 152px;
      }
      @media (max-width: 576px) {
        flex-shrink: 0;
        max-width: 100%;
        width: calc(33% - 24px);
      }
      @media (max-width: 420px) {
        margin: 0 16px;
        width: calc(33% - 32px);
      }
      @media (max-width: 375px) {
        margin: 0 12px;
        width: calc(33% - 24px);
      }
      /* ---------------------------------------------------------------- */
      /* Icon */
      /* ---------------------------------------------------------------- */
      svg {
        height: 72px;
        margin-bottom: 31px;
        position: relative;
        width: 72px;
        z-index: 1;
        @media (max-width: 1536px) {
          height: 68px;
          margin: 0 0 27px;
          width: 68px;
        }
        @media (max-width: 1280px) {
          height: 64px;
          margin: 0 0 23px;
          width: 64px;
        }
        @media (max-width: 576px) {
          height: 60px;
          margin: 0 0 12px;
          width: 60px;
        }
        @media (max-width: 375px) {
          height: 56px;
          margin: 0 0 6px;
          width: 56px;
        }
        [data-target='fill'] {
          @include fast-transition($props: 'fill');
        }
        [data-target='stroke'] {
          @include fast-transition($props: 'stroke');
        }
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Logo */
  /* ---------------------------------------------------------------- */
  > div > svg {
    flex-shrink: 0;
    height: 35px;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 165px;
    @media (max-width: 375px) {
      height: 32px;
      width: 156px;
    }
    * {
      fill: $brandBlackGrape;
    }
  }
}

:global(.roles-modal-open .MuiBackdrop-root) {
  transition: none !important;
}

:global(.roles-modal-open .MuiDialog-container) {
  transition: none !important;
}
