@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* -------------------------------------------------------------- */
/* Bookmark List */
/* -------------------------------------------------------------- */
.fullWidth {
  display: grid;
  grid-auto-rows: 1fr;
  grid-gap: 32px;
  margin-top: 32px;
  width: 100%;
  @media (max-width: 1024px) {
    grid-row-gap: 24px;
    margin-top: 24px;
  }
}
