@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Modals */
/* ---------------------------------------------------------------- */
.modals {
  display: block;
  > div {
    margin: 0 0 -10px;
    > button {
      margin: 0 10px 10px 0;
    }
  }
}
