@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* -------------------------------------------------------------- */
/* Achievements Skeleton */
/* -------------------------------------------------------------- */
.skeleton {
  margin-top: -4px;
  overflow: hidden;
  position: relative;
  @media (max-width: 1152px) and (min-width: 768px), (max-width: 360px) {
    margin-bottom: 20px;
    margin-left: -32px;
    margin-right: -32px;
    margin-top: -24px !important;
    overflow: hidden;
    padding-bottom: 24px;
    padding-left: 32px;
    padding-right: 32px;
    padding-top: 24px;
    position: relative;
  }
  @media (max-width: 1024px) and (min-width: 768px), (max-width: 360px) {
    margin-bottom: 16px;
    margin-left: -24px;
    margin-right: -24px;
    padding-left: 24px;
    padding-right: 24px;
  }
  @media (max-width: 375px) {
    margin-top: -3px;
  }
  @media (max-width: 360px) {
    margin-bottom: 12px;
    margin-left: -16px;
    margin-right: -16px;
    padding-bottom: 12px;
    padding-left: 16px;
    padding-right: 16px;
  }
  &::after {
    @include skeleton-shimmer;
    @include skeleton-shimmer-color($color: $white);
    @media (max-width: 767px) and (min-width: 361px) {
      @include skeleton-shimmer-color($color: $pageBackground);
    }
    @media (max-width: 360px) {
      @include skeleton-shimmer-color($color: $pageBackground);
    }
  }
  /* -------------------------------------------------------------- */
  /* Title */
  /* -------------------------------------------------------------- */
  > p {
    align-items: center;
    color: rgba($brandBlackGrape, 50%);
    display: flex;
    font-family: $secondary;
    font-size: 18px;
    font-weight: $semibold;
    letter-spacing: 0.16px;
    line-height: 22px;
    margin-bottom: -5px;
    text-transform: uppercase;
    @media (max-width: 1536px) {
      font-size: 17px;
      line-height: 21px;
    }
    @media (max-width: 1280px) {
      font-size: 16px;
      line-height: 20px;
    }
    @media (max-width: 1024px) {
      font-size: 15px;
      line-height: 19px;
    }
    @media (max-width: 767px) {
      font-size: 16px;
      line-height: 20px;
    }
    &::after {
      background-color: $mono1;
      color: transparent;
      content: 'Your Achievements';
      font-size: inherit;
      line-height: inherit;
      text-transform: inherit;
      @include rounded-skeleton-text;
    }
  }
  /* -------------------------------------------------------------- */
  /* Link Container */
  /* -------------------------------------------------------------- */
  > div:last-child {
    > span {
      background-color: $mono1;
      display: block;
      height: 48px;
      width: 175px;
      @include rounded-skeleton-text;
    }
  }
  > ul {
    position: relative;
    z-index: 1;
  }
  /* -------------------------------------------------------------- */
  /* Achievements Item */
  /* -------------------------------------------------------------- */
  > ul > li {
    border-color: $mono1;
    &::after {
      @media (max-width: 1152px) and (min-width: 768px), (max-width: 360px) {
        background-color: $mono1;
      }
    }
    @media (max-width: 1152px) and (min-width: 768px), (max-width: 360px) {
      &:first-child::before {
        background-color: $mono1;
      }
    }
    > span {
      border-color: $mono1;
      bottom: 0;
      display: block;
      left: 0;
      overflow: hidden;
      position: absolute;
      right: 0;
      top: 0;
      z-index: 1;
      &::after {
        @include skeleton-shimmer;
        @include skeleton-shimmer-color($color: $white);
        @media (max-width: 360px) {
          @include skeleton-shimmer-color($color: $pageBackground);
        }
      }
    }
    /* -------------------------------------------------------------- */
    /* Border */
    /* -------------------------------------------------------------- */
    hr {
      background-color: $mono1;
    }
    /* -------------------------------------------------------------- */
    /* Badge Text */
    /* -------------------------------------------------------------- */
    p {
      &::after {
        background-color: $mono1;
        color: transparent;
        content: 'ProPath Project Expert';
        font-size: inherit;
        line-height: inherit;
        @include rounded-skeleton-text;
      }
    }
    /* -------------------------------------------------------------- */
    /* Progress Text / Progress State Text */
    /* -------------------------------------------------------------- */
    > div > span,
    em {
      &::after {
        background-color: $mono1;
        color: transparent;
        font-size: inherit;
        line-height: inherit;
        @include rounded-skeleton-text;
      }
    }
    /* -------------------------------------------------------------- */
    /* Progress Text */
    /* -------------------------------------------------------------- */
    > div > span::after {
      content: '1 of 3 completed';
    }
    /* -------------------------------------------------------------- */
    /* Progress State Text */
    /* -------------------------------------------------------------- */
    em::after {
      content: 'In Progress';
    }
    /* -------------------------------------------------------------- */
    /* Badge Icon */
    /* -------------------------------------------------------------- */
    div:nth-of-type(1) div {
      background-color: $mono1;
      flex-shrink: 0;
      height: 36px;
      margin-right: 16px;
      width: 36px;
      @include rounded-skeleton-text($radius: '10px');
      @media (max-width: 1536px) {
        height: 32px;
        margin-right: 12px;
        width: 32px;
      }
      @media (max-width: 1280px) {
        height: 28px;
        margin-right: 8px;
        width: 28px;
      }
      @media (max-width: 1024px) {
        height: 24px;
        width: 24px;
      }
    }
    /* -------------------------------------------------------------- */
    /* Trophy Icon */
    /* -------------------------------------------------------------- */
    > div:nth-of-type(2) div {
      background-color: $mono1;
      flex-shrink: 0;
      height: 20px;
      width: 19px;
      @media (max-width: 1536px) {
        height: 19px;
        width: 18px;
      }
      @media (max-width: 1280px) {
        height: 18px;
        width: 17px;
      }
      @media (max-width: 1024px) {
        height: 17px;
        width: 16px;
      }
      @media (max-width: 896px) and (min-width: 768px), (max-width: 360px) {
        bottom: 2px;
        height: 16px;
        position: absolute;
        width: 15px;
      }
    }
  }
}

@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}
