@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Switch Account Item */
/* ---------------------------------------------------------------- */
.switchAccountItem {
  @include fast-transition($props: 'background-color, font-variation-settings');
  @media (max-width: 767px) {
    border: none;
    height: 64px;
    position: relative;
  }
  &:last-child::after {
    @media (min-width: 768px) {
      content: none;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Border */
  /* ---------------------------------------------------------------- */
  &::after {
    background-color: $mono2;
    bottom: 0;
    content: '';
    height: 1px;
    position: absolute;
    right: 0;
    width: calc(100% - 36px);
    @media (max-width: 767px) {
      display: none;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Button */
  /* ---------------------------------------------------------------- */
  > button[class] {
    /* ---------------------------------------------------------------- */
    /* Mobile Border */
    /* ---------------------------------------------------------------- */
    @media (max-width: 767px) {
      &[class]::after {
        background-color: $grey;
        bottom: 0;
        content: '';
        height: 1px;
        left: 36px;
        position: absolute;
        right: 0;
        width: calc(100% - 36px);
        z-index: 0;
      }
    }
    /* ---------------------------------------------------------------- */
    /* Mobile Active Border */
    /* ---------------------------------------------------------------- */
    @media (max-width: 767px) {
      &[class]::before {
        background-color: $brandAqua;
        bottom: 0;
        content: '';
        height: 3px;
        left: 36px;
        position: absolute;
        right: 0;
        width: 0;
        z-index: 1;
        @include fast-transition($props: 'background-color, width');
      }
    }
    /* ---------------------------------------------------------------- */
    /* Button Text */
    /* ---------------------------------------------------------------- */
    span {
      padding-left: 12px;
    }
    /* ---------------------------------------------------------------- */
    /* Interactive States */
    /* ---------------------------------------------------------------- */
    &:focus {
      @media (min-width: 768px) {
        background-color: $greyLight;
      }
      @media (max-width: 767px) {
        &::before {
          background-color: $brandBerry;
          width: calc(100% - 36px);
        }
      }
      span {
        text-decoration: underline;
      }
    }
    :global(.platform-desktop) & {
      &:hover {
        @media (min-width: 768px) {
          background-color: $greyLight;
        }
        @media (max-width: 767px) {
          &::before {
            width: calc(100% - 36px);
          }
        }
      }
      &:active {
        @media (min-width: 768px) {
          background-color: $grey;
        }
        @media (max-width: 767px) {
          &::before {
            background-color: $brandAquaLightPastel;
            width: calc(100% - 36px);
          }
        }
      }
    }
    :global(.platform-mobile) & {
      &:active {
        @media (min-width: 768px) {
          background-color: $greyLight;
        }
        @media (max-width: 767px) {
          &::before {
            width: calc(100% - 36px);
          }
        }
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Selected */
  /* ---------------------------------------------------------------- */
  &.selected {
    @include variable-fonts($weight: 700);
  }
}
