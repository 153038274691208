@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Paths */
/* ---------------------------------------------------------------- */
.paths {
  background-color: $white;
  border: 1px solid $brandGreyLight;
  border-radius: 4px;
  display: block;
  margin-bottom: 36px;
  padding: 36px 66px 18px 18px;
  position: relative;
  @media (max-width: 1536px) {
    padding: 36px 58px 18px 18px;
  }
  @media (max-width: 1280px) {
    margin-bottom: 32px;
    padding: 32px 48px 16px 16px;
  }
  @media (max-width: 1024px) {
    margin-bottom: 24px;
  }
  @media (max-width: 1152px) and (min-width: 768px), (max-width: 576px) {
    background: transparent;
    border: none;
    padding: 0;
  }
  @media (max-width: 767px) {
    margin-bottom: 32px;
  }
  @media (max-width: 375px) {
    margin-bottom: 24px;
  }
  &:last-child {
    margin-bottom: 0;
  }
  /* ---------------------------------------------------------------- */
  /* Top Bar: Navigation / Designation Progression */
  /* ---------------------------------------------------------------- */
  > div:nth-child(1) {
    @media (max-width: 1152px) and (min-width: 768px), (max-width: 576px) {
      background-color: $white;
      border: 1px solid $brandGreyLight;
      border-radius: 4px;
      padding: 24px 56px;
      position: relative;
    }
    @media (max-width: 576px) {
      border-radius: 0;
      margin: 0 -24px;
    }
    @media (max-width: 375px) {
      margin: 0 -16px;
      padding: 24px 48px;
    }
    /* ---------------------------------------------------------------- */
    /* Designation Progression */
    /* ---------------------------------------------------------------- */
    p {
      color: $brandBlackGrape;
      font-size: 20px;
      line-height: 27px;
      margin: -6px 0;
      padding: 0 0 24px;
      text-align: center;
      @media (max-width: 1664px) {
        font-size: 19px;
        line-height: 26px;
      }
      @media (max-width: 1536px) {
        font-size: 18px;
        line-height: 25px;
        padding: 0 0 21px;
      }
      @media (max-width: 1280px) {
        font-size: 17px;
        line-height: 24px;
        padding: 0 0 17px;
      }
      @media (max-width: 1152px) and (min-width: 768px), (max-width: 576px) {
        display: flex;
        flex-direction: column;
        font-size: 16px;
        line-height: 23px;
        padding: 0;
      }
      @media (max-width: 576px) {
        text-align: left;
      }
      span:last-child {
        font-weight: $semibold;
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Slider */
  /* ---------------------------------------------------------------- */
  > ul {
    display: flex;
  }
  /* ---------------------------------------------------------------- */
  /* Qualifications List */
  /* ---------------------------------------------------------------- */
  > ul > li > ul {
    display: grid;
    grid-auto-columns: calc(25% - 12px);
    grid-auto-flow: column;
    grid-gap: 12px;
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
    overflow-y: hidden;
    padding-bottom: 18px;
    scroll-snap-type: inline proximity;
    scrollbar-color: $brandAqua $mono1;
    scrollbar-width: thin;
    touch-action: pan-x;
    @media (max-width: 1536px) {
      grid-auto-columns: calc(25% - 8px);
      grid-gap: 8px;
    }
    @media (max-width: 1408px) {
      grid-auto-columns: calc(33.33% - 16px);
      grid-gap: 16px;
    }
    @media (max-width: 1280px) {
      padding-bottom: 16px;
    }
    @media (max-width: 1152px) and (min-width: 768px), (max-width: 576px) {
      display: flex;
      flex-direction: column;
      margin-top: 16px;
      overflow-x: hidden;
      padding-bottom: 0;
    }
    @media (max-width: 375px) {
      grid-gap: 12px;
    }
    &::-webkit-scrollbar {
      -webkit-appearance: none;
      height: 10px;
      @media (max-width: 1280px) {
        height: 8px;
      }
    }
    &::-webkit-scrollbar-track {
      background-color: $mono1;
      border-radius: 8px;
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    }
    &::-webkit-scrollbar-thumb {
      background-color: $brandAqua;
      border-radius: 8px;
    }
    > li {
      scroll-snap-align: start;
    }
    /* ---------------------------------------------------------------- */
    /* Badges Mobile Line */
    /* ---------------------------------------------------------------- */
    &::after {
      @media (max-width: 1152px) and (min-width: 768px), (max-width: 576px) {
        background-color: $brandGreyLight;
        bottom: 0;
        content: '';
        height: calc(100% - 56px);
        left: 14px;
        position: absolute;
        top: 28px;
        width: 4px;
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* ProPath */
  /* ---------------------------------------------------------------- */
  &.ProPath {
    /* ---------------------------------------------------------------- */
    /* Qualifications List */
    /* ---------------------------------------------------------------- */
    > ul > li > ul {
      scrollbar-color: $productProPath $mono1;
      &::-webkit-scrollbar-thumb {
        background-color: $productProPath;
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* ITIL */
  /* ---------------------------------------------------------------- */
  &.ITIL-4 {
    /* ---------------------------------------------------------------- */
    /* Qualifications List */
    /* ---------------------------------------------------------------- */
    > ul > li > ul {
      scrollbar-color: $productItil $mono1;
      &::-webkit-scrollbar-thumb {
        background-color: $productItil;
      }
    }
  }
}
