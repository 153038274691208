@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Image */
/* ---------------------------------------------------------------- */
.header-image {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 10px;
  height: 445px;
  margin: 0 auto 42px auto;
  position: relative;
  width: 792px;
  @media (max-width: 1024px) {
    height: 345px;
    margin-bottom: 34px;
    width: 592px;
  }
  @media (max-width: 767px) {
    height: 345px;
    margin-bottom: 26px;
    width: 100%;
  }
  @media (max-width: 540px) {
    height: 184px;
    margin-bottom: 18px;
  }
}
/* ---------------------------------------------------------------- */
/* Video */
/* ---------------------------------------------------------------- */
.header-video {
  font-size: 0;
  line-height: 0;
  margin: 0 auto 42px auto;
  max-width: 792px;
  position: relative;
  width: 100%;
  .iframeContainer {
    height: 0;
    padding-bottom: 56.25%;
    position: relative;
  }
  iframe {
    border-radius: 10px;
    bottom: 0;
    height: 100%;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
  }
}
