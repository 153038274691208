@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Summary */
/* ---------------------------------------------------------------- */
.summary {
  align-items: center;
  background-color: white;
  border-color: $brandGreyLight;
  border-radius: 9px;
  border-style: solid;
  border-width: 1px;
  box-sizing: border-box;
  display: flex;
  margin: 32px 0 64px;
  padding: 32px;
  position: relative;
  width: auto;
  @include no-user-select;
  @media (max-width: 1536px) {
    margin: 28px 0 56px;
    padding: 28px;
  }
  @media (max-width: 1280px) {
    margin: 24px 0 48px;
    padding: 24px;
  }
  @media (max-width: 1024px) {
    margin: 20px 0 40px;
    padding: 20px;
  }
  @media (max-width: 640px) {
    align-items: flex-start;
    border-radius: 4px;
    flex-direction: column;
  }
  @media (max-width: 375px) {
    margin: 16px 0 32px;
    padding: 16px;
  }
  /* -------------------------------------------------------------- */
  /* Left */
  /* -------------------------------------------------------------- */
  > form {
    display: inline-flex;
    margin-right: 20px;
    overflow: hidden;
    position: relative;
    @media (max-width: 1536px) {
      margin-right: 18px;
    }
    @media (max-width: 1280px) {
      margin-right: 16px;
    }
    @media (max-width: 640px) {
      margin-right: 0;
    }
    /* ------------------------------------------------------------ */
    /* Input / Image Container */
    /* ------------------------------------------------------------ */
    > div:nth-child(1) {
      clip-path: circle(50% at 50% 50%);
      overflow: hidden;
      /* ------------------------------------------------------------ */
      /* Loading Spinner */
      /* ------------------------------------------------------------ */
      &::after {
        background-color: $brandBlackGrape;
        background-image: url('/images/icons/button-loading.svg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: 56px 56px;
        border-radius: 50%;
        display: block;
        flex-shrink: 0;
        height: 64px;
        position: absolute;
        top: 0;
        width: 64px;
        @media (max-width: 1536px) {
          background-size: 52px 52px;
          height: 60px;
          width: 60px;
        }
        @media (max-width: 1280px) {
          background-size: 48px 48px;
          height: 56px;
          width: 56px;
        }
        @media (max-width: 375px) {
          background-size: 44px 44px;
          height: 52px;
          width: 52px;
        }
      }
    }
    /* ------------------------------------------------------------ */
    /* Text Container */
    /* ------------------------------------------------------------ */
    > div:nth-child(2) {
      align-items: flex-start;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-left: 20px;
      @media (max-width: 1536px) {
        margin-left: 18px;
      }
      @media (max-width: 1280px) {
        margin-left: 16px;
      }
      @media (max-width: 375px) {
        margin-left: 12px;
      }
    }
    /* ------------------------------------------------------------ */
    /* Input */
    /* ------------------------------------------------------------ */
    input[type='file'] {
      cursor: pointer;
      height: calc(100% + 25px);
      left: 0;
      opacity: 0;
      overflow: hidden;
      position: absolute;
      top: -25px;
      width: 64px;
      z-index: 1;
      @media (max-width: 1536px) {
        width: 60px;
      }
      @media (max-width: 1280px) {
        width: 56px;
      }
      @media (max-width: 375px) {
        width: 52px;
      }
      /* ---------------------------------------------------------- */
      /* Focus / Hover / Active */
      /* ---------------------------------------------------------- */
      &:focus + label {
        picture::after {
          background-color: rgba($brandAqua, 0.7);
        }
      }
      :global(.platform-desktop) & {
        &:hover {
          + label > picture::after {
            background-color: rgba($brandAqua, 0.7);
          }
        }
        &:active {
          + label > picture::after {
            background-color: rgba($brandAqua, 0.7);
          }
        }
      }
      :global(.platform-mobile) & {
        &:active {
          + label > picture::after {
            background-color: rgba($brandAqua, 0.7);
          }
        }
      }
    }
    /* ------------------------------------------------------------ */
    /* Label */
    /* ------------------------------------------------------------ */
    label {
      align-items: center;
      display: flex;
      /* ---------------------------------------------------------- */
      /* Image */
      /* ---------------------------------------------------------- */
      > picture {
        border-radius: 50%;
        display: block;
        flex-shrink: 0;
        height: 64px;
        object-fit: cover;
        overflow: hidden;
        position: relative;
        width: 64px;
        @media (max-width: 1536px) {
          height: 60px;
          width: 60px;
        }
        @media (max-width: 1280px) {
          height: 56px;
          width: 56px;
        }
        @media (max-width: 375px) {
          height: 52px;
          width: 52px;
        }
        /* -------------------------------------------------------- */
        /* Img */
        /* -------------------------------------------------------- */
        > img {
          display: block;
          height: 64px;
          object-fit: cover;
          position: relative;
          width: 64px;
          @media (max-width: 1536px) {
            height: 60px;
            width: 60px;
          }
          @media (max-width: 1280px) {
            height: 56px;
            width: 56px;
          }
          @media (max-width: 375px) {
            height: 52px;
            width: 52px;
          }
        }
        /* -------------------------------------------------------- */
        /* Camera Icon */
        /* -------------------------------------------------------- */
        &::after {
          background-color: rgba($brandBlackGrape, 0.7);
          background-image: url('/images/icons/camera.svg');
          background-position: center;
          background-repeat: no-repeat;
          background-size: 30px 24px;
          content: '';
          display: block;
          height: 64px;
          left: 0;
          position: absolute;
          top: 0;
          width: 64px;
          @include fast-transition($props: 'background-color');
          @media (max-width: 1536px) {
            background-size: 29px 23px;
            height: 60px;
            width: 60px;
          }
          @media (max-width: 1280px) {
            background-size: 28px 22px;
            height: 56px;
            width: 56px;
          }
          @media (max-width: 375px) {
            background-size: 27px 21px;
            height: 52px;
            width: 52px;
          }
        }
      }
    }
  }
  /* ---------------------------------------------------------- */
  /* Name */
  /* ---------------------------------------------------------- */
  > form > div > span {
    color: $brandBlackGrape;
    display: table;
    font-size: 20px;
    font-weight: $bold;
    letter-spacing: normal;
    line-height: 26px;
    margin: -5px 0 -6px;
    @include user-select;
    @media (max-width: 1536px) {
      font-size: 19px;
      line-height: 25px;
    }
    @media (max-width: 1280px) {
      font-size: 18px;
      line-height: 24px;
    }
    @media (max-width: 375px) {
      font-size: 17px;
      line-height: 23px;
    }
  }
  /* ---------------------------------------------------------- */
  /* Button */
  /* ---------------------------------------------------------- */
  button {
    color: rgba($brandBlackGrape, 0.7);
    cursor: pointer;
    font-family: $primary;
    font-size: 18px;
    font-weight: $bold;
    letter-spacing: normal;
    line-height: 24px;
    margin: 10px 0 -5px;
    touch-action: manipulation;
    @include fast-transition($props: 'color');
    @include no-user-select;
    @media (max-width: 1536px) {
      font-size: 17px;
      line-height: 23px;
      margin-top: 9px;
    }
    @media (max-width: 1280px) {
      font-size: 16px;
      line-height: 22px;
      margin-top: 8px;
    }
    @media (max-width: 375px) {
      font-size: 14px;
      line-height: 21px;
      margin-top: 7px;
    }
    &:focus {
      color: $mono5;
      text-decoration: underline;
    }
    :global(.platform-desktop) & {
      &:hover {
        color: $brandAqua;
      }
      &:active {
        color: $mono5;
      }
    }
    :global(.platform-mobile) & {
      &:active {
        color: $brandAqua;
      }
    }
  }
  /* -------------------------------------------------------------- */
  /* Right */
  /* -------------------------------------------------------------- */
  > div {
    display: block;
    font-size: 17px;
    line-height: 21px;
    margin: -4px 0 -4px auto;
    text-align: right;
    white-space: nowrap;
    @include user-select;
    @media (max-width: 1536px) {
      font-size: 16px;
      line-height: 20px;
    }
    @media (max-width: 1280px) {
      font-size: 15px;
      line-height: 19px;
    }
    @media (max-width: 640px) {
      margin-left: 0;
      margin-top: 17px;
      text-align: left;
    }
    @media (max-width: 375px) {
      margin-top: 13px;
    }
    > strong {
      display: block;
      font-size: 18px;
      font-weight: $semibold;
      letter-spacing: normal;
      line-height: 22px;
      margin: 7px 0 0;
      @media (max-width: 1536px) {
        font-size: 17px;
        line-height: 21px;
        margin: 6px 0 0;
      }
      @media (max-width: 1280px) {
        font-size: 16px;
        line-height: 20px;
        margin: 5px 0 0;
      }
      @media (max-width: 640px) {
        margin: 4px 0 0;
      }
    }
  }
  /* ------------------------------------------------------------ */
  /* Loading State */
  /* ------------------------------------------------------------ */
  &.loading {
    form > div:nth-child(1) {
      input {
        pointer-events: none;
      }
      &::after {
        content: '';
      }
    }
  }
}
