@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Paths Skeleton */
/* ---------------------------------------------------------------- */
.skeleton {
  background-color: $white;
  border-color: $mono1;
  overflow: hidden;
  @media (min-width: 1153px), (max-width: 767px) and (min-width: 577px) {
    &::before {
      z-index: 9;
      @include skeleton-shimmer;
      @include skeleton-shimmer-color($color: $white);
    }
  }
  @media (max-width: 1152px) and (min-width: 768px), (max-width: 576px) {
    background: transparent;
  }
  @media (max-width: 576px) {
    overflow: visible;
  }
  /* ---------------------------------------------------------------- */
  /* Top Bar: Navigation / Designation Progression */
  /* ---------------------------------------------------------------- */
  > div:nth-child(1) {
    @media (max-width: 1152px) and (min-width: 768px), (max-width: 576px) {
      background-color: $white;
      border-color: $mono1;
    }
    @media (max-width: 1152px) and (min-width: 768px), (max-width: 576px) {
      &::before {
        @include skeleton-shimmer;
        @include skeleton-shimmer-color($color: $white);
      }
    }
    &::after {
      background-color: transparent;
    }
    > div {
      background-color: $mono1;
      height: calc(50% - 1px);
      position: absolute;
      width: 48px;
      @media (max-width: 1536px) {
        width: 40px;
      }
      @media (max-width: 1280px) {
        width: 32px;
      }
      @media (max-width: 1152px) and (min-width: 768px), (max-width: 576px) {
        height: 100%;
        width: 40px;
      }
      @media (max-width: 576px) {
        width: 40px;
      }
      @media (max-width: 375px) {
        width: 32px;
      }
      &:first-child {
        right: 0;
        top: 0;
        @media (max-width: 1152px) and (min-width: 768px), (max-width: 576px) {
          left: 0;
          right: auto;
        }
      }
      &:last-child {
        bottom: 0;
        right: 0;
      }
    }
    /* ---------------------------------------------------------------- */
    /* Designation Progression */
    /* ---------------------------------------------------------------- */
    p {
      display: flex;
      flex-direction: row;
      justify-content: center;
      padding: 0 0 19px;
      @media (max-width: 1536px) {
        padding: 0 0 17px;
      }
      @media (max-width: 1280px) {
        padding: 0 0 13px;
      }
      @media (max-width: 1152px) and (min-width: 768px), (max-width: 576px) {
        flex-direction: column;
        padding: 0 0 0;
      }
      span {
        &:first-child {
          margin-right: 4px;
          @media (max-width: 1152px) and (min-width: 768px),
            (max-width: 576px) {
            margin-right: 0;
          }
          &::after {
            background-color: $mono1;
            color: transparent;
            content: 'You are 1 exam away from ';
            @include rounded-skeleton-text;
          }
        }
        &:last-child {
          margin-left: 4px;
          @media (max-width: 1152px) and (min-width: 768px),
            (max-width: 576px) {
            margin-left: 0;
          }
          &::after {
            background-color: $mono1;
            color: transparent;
            content: 'ProPath Agile Project Expert';
            @include rounded-skeleton-text;
          }
        }
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Qualifications List */
  /* ---------------------------------------------------------------- */
  > ul {
    @media (max-width: 1152px) and (min-width: 768px), (max-width: 576px) {
      &::before {
        z-index: 9;
        @include skeleton-shimmer;
        @include skeleton-shimmer-color($color: $pageBackground);
      }
    }
    > li > ul {
      pointer-events: none;
      scrollbar-color: $mono1 $mono1;
      &::-webkit-scrollbar-track {
        box-shadow: none;
      }
      &::-webkit-scrollbar-thumb {
        background-color: $mono1;
      }
      @media (max-width: 1152px) and (min-width: 768px), (max-width: 576px) {
        &::after {
          background-color: $mono1;
        }
      }
      > li {
        /* ---------------------------------------------------------------- */
        /* Badges Container */
        /* ---------------------------------------------------------------- */
        > div {
          &::before,
          &::after {
            background-color: $mono1;
          }
          > div {
            background-color: $mono1;
            border-color: $mono1;
          }
        }
        /* ---------------------------------------------------------------- */
        /* Text */
        /* ---------------------------------------------------------------- */
        > span {
          @media (min-width: 1153px),
            (max-width: 767px) and (min-width: 577px) {
            margin-top: 0;
          }
          > span {
            &:first-child {
              &::after {
                background-color: $mono1;
                color: transparent;
                content: 'ITIL 4';
                @include rounded-skeleton-text;
              }
            }
            &:last-child {
              &::after {
                background-color: $mono1;
                color: transparent;
                content: 'Foundation';
                @include rounded-skeleton-text;
              }
            }
          }
        }
      }
    }
  }
}

@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}
