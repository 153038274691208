@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
.content-panel {
  a {
    color: $brandBerry;
  }
  .intro-paragraph {
    p {
      font-size: 17px;
      line-height: 25px;
      margin-bottom: 29px;
      @media (max-width: 1536px) {
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 28px;
      }
      @media (max-width: 1280px) {
        font-size: 15px;
        line-height: 23px;
        margin-bottom: 27px;
      }
      @media (max-width: 375px) {
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 24px;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  div:last-of-type > section > div > *:last-child {
    margin-bottom: 0;
  }
}
