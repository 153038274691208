@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Account Settings */
/* ---------------------------------------------------------------- */
.accountSettings {
  display: table;
  margin: -6px 0;
  width: 100%;
  @media (max-width: 767px) {
    display: flex;
    flex-direction: column;
  }
}
