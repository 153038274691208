@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* TextArea */
/* ---------------------------------------------------------------- */
.textarea {
  border-radius: 11px;
  overflow: hidden;
  position: relative;
  /* ---------------------------------------------------------------- */
  /* Pseudo-element  */
  /* ---------------------------------------------------------------- */
  &::before {
    background-color: $white;
    bottom: 0;
    content: '';
    display: block;
    height: 48px;
    left: 30px;
    pointer-events: none;
    position: absolute;
    right: 30px;
    top: 1px;
    @include fast-transition($props: 'background-color');
    @media (max-width: 1536px) {
      height: 42px;
      left: 26px;
      right: 26px;
    }
    @media (max-width: 1280px) {
      height: 38px;
      left: 22px;
      right: 22px;
    }
    @media (max-width: 1024px) {
      height: 32px;
      left: 18px;
      right: 18px;
    }
  }
  /* ---------------------------------------------------------------- */
  /* TextArea */
  /* ---------------------------------------------------------------- */
  textarea {
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    appearance: none;
    background-color: $white;
    border-color: $mono2;
    border-radius: 11px;
    border-style: solid;
    border-width: 1px;
    box-sizing: border-box;
    caret-color: $brandBlackGrape;
    color: $brandBlackGrape;
    display: inline-block;
    filter: none;
    font-family: $primary;
    font-size: 20px;
    font-weight: normal;
    height: 148px;
    letter-spacing: normal;
    line-height: normal;
    margin: 0;
    outline: none;
    overflow: auto;
    padding: 48px 30px 0;
    resize: none;
    scrollbar-color: $brandAqua $mono1;
    scrollbar-width: thin;
    vertical-align: middle;
    width: 100%;
    @include no-user-select;
    @include fast-transition($props: 'color, border-color, background-color');
    /* ---------------------------------------------------------------- */
    /* Scroll Bar  */
    /* ---------------------------------------------------------------- */
    &::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 8px;
    }
    &::-webkit-scrollbar-track {
      background-color: $mono1;
      border-radius: 8px;
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    }
    &::-webkit-scrollbar-thumb {
      background-color: $brandAqua;
      border-radius: 8px;
    }
    @media (max-width: 1536px) {
      font-size: 19px;
      // height: 184px;
      padding: 42px 26px 0;
    }
    @media (max-width: 1280px) {
      font-size: 18px;
      // height: 166px;
      padding: 38px 22px 0;
    }
    @media (max-width: 1024px) {
      font-size: 17px;
      // height: 150px;
      padding: 32px 18px 0;
    }
    @media (max-width: 767px) {
      font-size: 16px;
    }
    /* ---------------------------------------------------------------- */
    /* Hide Placeholder */
    /* ---------------------------------------------------------------- */
    &::-webkit-input-placeholder {
      opacity: 0 !important;
    }
    &:-moz-placeholder {
      opacity: 0 !important;
    }
    &::-moz-placeholder {
      opacity: 0 !important;
    }
    &:-ms-input-placeholder {
      opacity: 0 !important;
    }
    &::placeholder {
      opacity: 0 !important;
    }
    /* ---------------------------------------------------------------- */
    /* Focus */
    /* ---------------------------------------------------------------- */
    &:focus {
      border-color: $brandBlackGrape;
      @include user-select;
    }
    /* ---------------------------------------------------------------- */
    /* Hover */
    /* ---------------------------------------------------------------- */
    :global(.platform-desktop) & {
      &:hover {
        border-color: $mono3;
        /* ---------------------------------------------------------------- */
        /* Hover Focus */
        /* ---------------------------------------------------------------- */
        &:focus {
          border-color: $brandBlackGrape;
        }
      }
    }
    /* ---------------------------------------------------------------- */
    /* Read Only */
    /* ---------------------------------------------------------------- */
    &:read-only {
      background-color: $mono1;
      color: $mono4;
      pointer-events: none;
      &::selection {
        @include no-user-select;
      }
      ~ label {
        pointer-events: none;
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Invalid */
  /* ---------------------------------------------------------------- */
  &.invalid {
    textarea {
      border-color: red;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Disabled */
  /* ---------------------------------------------------------------- */
  &.disabled {
    &::before {
      background-color: $mono1;
      pointer-events: none;
    }
    textarea {
      background-color: $pageBackground;
      border-color: $mono2;
      color: $mono2;
      pointer-events: none;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Read only */
  /* ---------------------------------------------------------------- */
  &.readOnly {
    &::before {
      background-color: $mono1;
      pointer-events: none;
    }
  }
}
