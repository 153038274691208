@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Featured Resources Skeleton */
/* ---------------------------------------------------------------- */
.skeleton {
  overflow: hidden;
  padding-bottom: 36px;
  position: relative;
  @media (max-width: 1280px) {
    padding-bottom: 32px;
  }
  @media (max-width: 1024px) {
    padding-bottom: 24px;
  }
  @media (max-width: 767px) {
    padding-bottom: 32px;
  }
  @media (max-width: 375px) {
    padding-bottom: 24px;
  }
  &:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
  }
  svg {
    height: 100%;
    width: 100%;
    * {
      fill: $mono1;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Title Container */
  /* ---------------------------------------------------------------- */
  > div:nth-child(1) {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 36px;
    position: relative;
    @media (max-width: 1280px) {
      margin-bottom: 32px;
    }
    @media (max-width: 1024px) {
      margin-bottom: 24px;
    }
    /* ---------------------------------------------------------------- */
    /* Title */
    /* ---------------------------------------------------------------- */
    > span {
      font-family: $secondary;
      font-size: 32px;
      font-weight: $semibold;
      line-height: 40px;
      padding-right: 24px;
      position: relative;
      @media (max-width: 1664px) {
        font-size: 29px;
        line-height: 36px;
      }
      @media (max-width: 1536px) {
        font-size: 26px;
        line-height: 33px;
        padding-right: 20px;
      }
      @media (max-width: 1408px) {
        font-size: 23px;
        line-height: 29px;
      }
      @media (max-width: 1280px) {
        font-size: 20px;
        line-height: 26px;
        padding-right: 16px;
      }
      @media (max-width: 896px) {
        padding-right: 0;
      }
      @media (max-width: 375px) {
        font-size: 19px;
        line-height: 25px;
      }
      &::before {
        @include skeleton-shimmer;
        @include skeleton-shimmer-color($color: $pageBackground);
      }
      &::after {
        background-color: $mono1;
        color: transparent;
        content: 'Featured Resources';
        font-size: inherit;
        line-height: inherit;
        @include rounded-skeleton-text;
      }
    }
    /* ---------------------------------------------------------------- */
    /* Desktop CTA */
    /* ---------------------------------------------------------------- */
    > div {
      display: block;
      @media (max-width: 896px) and (min-width: 768px), (max-width: 576px) {
        display: none;
      }
    }
    > div > span {
      background-color: $mono1;
      display: block;
      height: 48px;
      opacity: 1;
      position: relative;
      width: 225px;
      @include rounded-skeleton-text;
      &::after {
        @include skeleton-shimmer;
        @include skeleton-shimmer-color($color: $pageBackground);
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Featured Resources Card Container */
  /* ---------------------------------------------------------------- */
  > ul {
    display: flex;
    position: relative;
    z-index: 1;
    @media (max-width: 896px) and (min-width: 768px), (max-width: 576px) {
      flex-direction: column;
    }
    /* ---------------------------------------------------------------- */
    /* Columns */
    /* ---------------------------------------------------------------- */
    > li {
      display: flex;
      flex-direction: column;
      width: 50%;
      @media (max-width: 896px) and (min-width: 768px), (max-width: 576px) {
        width: 100%;
      }
      /* ---------------------------------------------------------------- */
      /* Large Card Container x 1 */
      /* ---------------------------------------------------------------- */
      &:first-child {
        display: flex;
        margin-right: 36px;
        @media (max-width: 1280px) {
          margin-right: 32px;
        }
        @media (max-width: 1024px) {
          margin-right: 24px;
        }
        @media (max-width: 896px) and (min-width: 768px), (max-width: 576px) {
          display: block;
          margin-right: 0;
        }
        .skeletonItem {
          footer > span {
            background-color: $mono1;
            height: 48px;
            overflow: hidden;
            width: 198px;
            @include rounded-skeleton-text;
            @media (max-width: 896px) and (min-width: 768px),
              (max-width: 576px) {
              background-color: transparent;
              height: auto;
              width: auto;
            }
          }
          .copy > *::after {
            @include rounded-skeleton-text;
          }
          .copy > span::after {
            content: 'Lorem ipsum dolor sit amet adipiscing elit sed do eiusmod ut labore.';
          }
          .copy > p::after {
            /* stylelint-disable-next-line max-line-length */
            content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt incididuntut sit do aliqua sit do amet.';
          }
        }
      }
      /* ---------------------------------------------------------------- */
      /* Small Card Container x 3 */
      /* ---------------------------------------------------------------- */
      &:last-child {
        > div {
          margin-bottom: 12px;
          @media (max-width: 896px) and (min-width: 768px), (max-width: 576px) {
            margin-bottom: 0;
          }
          &:last-child {
            border: 0;
            margin-bottom: 0;
          }
          .copy > *::after {
            @include rounded-skeleton-text;
          }
          .copy > span::after {
            content: 'Lorem ipsum dolor sit amet adipiscing elit.';
          }
          .copy > p::after {
            /* stylelint-disable-next-line max-line-length */
            content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do labore.';
          }
        }
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Mobile CTA Container */
  /* ---------------------------------------------------------------- */
  > div:last-child {
    display: none;
    justify-content: flex-end;
    margin-top: 20px;
    position: relative;
    @media (max-width: 896px) and (min-width: 768px), (max-width: 576px) {
      display: flex;
      margin-top: 13px;
    }
    > span {
      background-color: $mono1;
      display: block;
      height: 0;
      opacity: 0;
      position: relative;
      width: 225px;
      @include rounded-skeleton-text;
      &::after {
        @include skeleton-shimmer;
        @include skeleton-shimmer-color($color: $pageBackground);
      }
      @media (max-width: 896px) and (min-width: 768px), (max-width: 576px) {
        height: 48px;
        opacity: 1;
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Row Layout */
  /* ---------------------------------------------------------------- */
  &.rows {
    /* ---------------------------------------------------------------- */
    /* Featured Resources Card Container */
    /* ---------------------------------------------------------------- */
    > ul {
      @media (min-width: 897px), (max-width: 767px) and (min-width: 577px) {
        flex-direction: column;
      }
      /* ---------------------------------------------------------------- */
      /* Row Container */
      /* ---------------------------------------------------------------- */
      > li {
        @media (min-width: 897px), (max-width: 767px) and (min-width: 577px) {
          width: 100%;
        }
        /* ---------------------------------------------------------------- */
        /* Large Card Container x 1 */
        /* ---------------------------------------------------------------- */
        &:first-child {
          @media (min-width: 897px), (max-width: 767px) and (min-width: 577px) {
            margin-bottom: 16px;
          }
          /* ---------------------------------------------------------------- */
          /* Article */
          /* ---------------------------------------------------------------- */
          > div {
            @media (min-width: 897px),
              (max-width: 767px) and (min-width: 577px) {
              flex-direction: row;
            }
            /* ---------------------------------------------------------------- */
            /* Image */
            /* ---------------------------------------------------------------- */
            > span {
              @media (min-width: 897px),
                (max-width: 767px) and (min-width: 577px) {
                height: auto;
                max-height: 100%;
                min-height: 320px;
                width: 50%;
              }
              @media (max-width: 1536px) {
                min-height: 280px;
              }
              @media (max-width: 1408px) {
                min-height: 260px;
              }
              @media (max-width: 1280px) {
                min-height: 240px;
              }
              @media (max-width: 1152px) {
                min-height: 220px;
              }
              @media (max-width: 1024px) {
                min-height: 200px;
              }
              @media (max-width: 896px) and (min-width: 768px),
                (max-width: 576px) {
                height: 208px;
              }
              @media (max-width: 375px) {
                height: 175px;
              }
            }
            > div {
              @media (min-width: 897px),
                (max-width: 767px) and (min-width: 577px) {
                height: auto;
              }
            }
          }
        }
        /* ---------------------------------------------------------------- */
        /* Small Card Container */
        /* ---------------------------------------------------------------- */
        &:last-child {
          @media (min-width: 897px), (max-width: 767px) and (min-width: 577px) {
            display: grid;
            grid-auto-rows: 1fr;
            grid-row-gap: 16px;
          }
          /* ---------------------------------------------------------------- */
          /* Row */
          /* ---------------------------------------------------------------- */
          article {
            @media (min-width: 897px),
              (max-width: 767px) and (min-width: 577px) {
              margin: 0;
            }
          }
        }
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Row Layout - Grid (If Over 3 Resources) */
  /* ---------------------------------------------------------------- */
  &.grid {
    /* ---------------------------------------------------------------- */
    /* Small Card Container */
    /* ---------------------------------------------------------------- */
    > ul > li:last-child {
      @media (min-width: 897px), (max-width: 767px) and (min-width: 577px) {
        grid-column-gap: 16px;
        grid-template-columns: repeat(2, 1fr);
      }
    }
  }
}
/* ---------------------------------------------------------------- */
/* Featured Resources Item Skeleton */
/* ---------------------------------------------------------------- */
.skeletonItem {
  background-color: $white;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 0;
  overflow: hidden;
  position: relative;
  width: 100%;
  @media (max-width: 896px) and (min-width: 768px), (max-width: 576px) {
    background-color: transparent;
    border-bottom: 1px solid $mono1;
    flex-direction: column;
  }
  &::after {
    @include skeleton-shimmer;
    @include skeleton-shimmer-color($color: $white);
    @media (max-width: 896px) and (min-width: 768px), (max-width: 576px) {
      @include skeleton-shimmer-color($color: $pageBackground);
    }
  }
  /* ---------------------------------------------------------------- */
  /* Image */
  /* ---------------------------------------------------------------- */
  > span {
    background-color: $mono1;
    display: block;
    flex-shrink: 0;
    height: 320px;
    max-height: 42.5%;
    overflow: hidden;
    position: relative;
    width: 100%;
    @media (max-width: 1536px) {
      height: 280px;
      max-height: 40%;
    }
    @media (max-width: 1408px) {
      height: 240px;
      max-height: 35%;
    }
    @media (max-width: 1280px) {
      height: 200px;
      max-height: 32.5%;
    }
    @media (max-width: 1152px) {
      height: 190px;
      max-height: 30%;
    }
    @media (max-width: 1024px) {
      height: 180px;
      max-height: 27.5%;
    }
    @media (max-width: 896px) and (min-width: 768px), (max-width: 576px) {
      height: 208px;
      max-height: 100%;
    }
    @media (max-width: 375px) {
      height: 175px;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Content */
  /* ---------------------------------------------------------------- */
  &:not(.primary):last-child > div {
    @media (max-width: 896px) and (min-width: 768px), (max-width: 576px) {
      padding-bottom: 0;
    }
  }
  > div {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 32px;
    @media (max-width: 1536px) {
      padding: 28px;
    }
    @media (max-width: 1280px) {
      padding: 24px;
    }
    @media (max-width: 1024px) {
      padding: 20px;
    }
    @media (max-width: 896px) and (min-width: 768px), (max-width: 576px) {
      padding: 20px 0 8px;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Header - Publications + Social Container */
  /* ---------------------------------------------------------------- */
  header {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: -9px -8px 0;
    @media (max-width: 1536px) {
      margin: -7px -7px 0;
    }
    @media (max-width: 1280px) {
      margin: -7px -6px 0;
    }
    @media (max-width: 1024px) {
      margin: -6px -4px 0;
    }
    @media (max-width: 896px) and (min-width: 768px), (max-width: 576px) {
      margin-top: -7px;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Publications Text + Social Container */
  /* ---------------------------------------------------------------- */
  .skeletonSocial,
  .publication > span {
    margin: 6px 8px 5px;
    @media (max-width: 1536px) {
      margin: 5px 7px;
    }
    @media (max-width: 1280px) {
      margin: 5px 6px 4px;
    }
    @media (max-width: 1024px) {
      margin: 4px 5px;
    }
  }
  .skeletonSocial {
    @media (max-width: 896px) and (min-width: 768px), (max-width: 576px) {
      margin: 0 0 8px;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Publication */
  /* ---------------------------------------------------------------- */
  .publication {
    display: flex;
    flex-wrap: wrap;
    font-size: 17px;
    line-height: 25px;
    @media (max-width: 1536px) {
      font-size: 16px;
      line-height: 24px;
    }
    @media (max-width: 1280px) {
      font-size: 15px;
      line-height: 23px;
    }
    @media (max-width: 1024px) {
      font-size: 14px;
      line-height: 22px;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Copy Container */
  /* ---------------------------------------------------------------- */
  .copy {
    margin-top: 21px;
    @media (max-width: 1536px) {
      margin-top: 17px;
    }
    @media (max-width: 1280px) {
      margin-top: 13px;
    }
    @media (max-width: 896px) and (min-width: 768px), (max-width: 576px) {
      margin-top: 10px;
    }
    @media (max-width: 375px) {
      margin-top: 11px;
    }
  }
  .publication > span {
    height: 17px;
    line-height: 17px;
    white-space: nowrap;
    @media (max-width: 1536px) {
      height: 16px;
      line-height: 16px;
    }
    @media (max-width: 1280px) {
      height: 15px;
      line-height: 15px;
    }
    @media (max-width: 1024px) {
      height: 14px;
      line-height: 14px;
    }
    @media (max-width: 896px) and (min-width: 768px), (max-width: 576px) {
      height: 15px;
      line-height: 15px;
    }
    &:nth-child(1)::after {
      content: 'December 15, 2020';
    }
    &:nth-child(2) {
      color: $brandBerry;
      &::after {
        content: '15 min read';
      }
    }
    &::after {
      background-color: $mono1;
      color: transparent;
      font-size: inherit;
      line-height: inherit;
      @include rounded-skeleton-text;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Title */
  /* ---------------------------------------------------------------- */
  .copy > span {
    display: block;
    font-family: $secondary;
    font-size: 20px;
    font-weight: $semibold;
    line-height: 28px;
    margin-bottom: 11px;
    margin-top: -7px;
    position: relative;
    @media (max-width: 1536px) {
      font-size: 19px;
      line-height: 27px;
      margin-bottom: 7px;
      margin-top: -6px;
    }
    @media (max-width: 1280px) {
      font-size: 18px;
      line-height: 26px;
      margin-bottom: 5px;
      margin-top: -5px;
    }
    @media (max-width: 1024px) {
      font-size: 17px;
      line-height: 25px;
      margin-bottom: 3px;
    }
    @media (max-width: 896px) and (min-width: 768px), (max-width: 576px) {
      font-family: $primary;
      font-weight: $bold;
      margin-top: -7px;
    }
    @media (max-width: 767px) {
      font-size: 16px;
      line-height: 24px;
    }
    @media (max-width: 576px) {
      margin-top: -6px;
    }
    @media (max-width: 480px) {
      font-size: 15px;
      line-height: 23px;
    }
    @media (max-width: 375px) {
      font-size: 14px;
      line-height: 21px;
      margin-top: -7px;
    }
    &:last-child {
      margin-bottom: -7px;
    }
    &::after {
      background-color: $mono1;
      color: transparent;
      font-size: inherit;
      line-height: inherit;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Excerpt */
  /* ---------------------------------------------------------------- */
  .copy > p {
    color: $brandBlackGrape;
    display: block;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: -6px;
    position: relative;
    @media (max-width: 1536px) {
      font-size: 16px;
      line-height: 24px;
    }
    @media (max-width: 1280px) {
      font-size: 15px;
      line-height: 23px;
    }
    @media (max-width: 1024px) {
      font-size: 14px;
      line-height: 21px;
      margin-bottom: -5px;
    }
    @media (max-width: 896px) and (min-width: 768px), (max-width: 576px) {
      display: none;
    }
    &::after {
      background-color: $mono1;
      color: transparent;
      font-size: inherit;
      line-height: inherit;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Footer - Cta Container */
  /* ---------------------------------------------------------------- */
  &:not(.primary):last-child footer {
    @media (max-width: 896px) and (min-width: 768px), (max-width: 576px) {
      margin-bottom: -8px;
    }
    @media (max-width: 350px) {
      margin-bottom: -12px;
    }
  }
  footer {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: auto 0 0;
    padding-top: 28px;
    @media (max-width: 1536px) {
      padding-top: 24px;
    }
    @media (max-width: 1280px) {
      padding-top: 20px;
    }
    @media (max-width: 896px) and (min-width: 768px), (max-width: 576px) {
      margin-left: -16px;
      margin-right: -16px;
      padding-top: 21px;
    }
    @media (max-width: 375px) {
      padding-top: 19px;
    }
    @media (max-width: 350px) {
      align-items: flex-start;
      flex-direction: column;
      padding-top: 20px;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Hyperlink */
  /* ---------------------------------------------------------------- */
  footer > span {
    display: table;
    font-family: $secondary;
    font-size: 17px;
    font-weight: $bold;
    line-height: 20px;
    margin: -3px 0 -4px;
    min-width: 0;
    text-decoration: none;
    @media (max-width: 1536px) {
      font-size: 16px;
      line-height: 19px;
    }
    @media (max-width: 1280px) {
      font-size: 15px;
      line-height: 18px;
    }
    @media (max-width: 1024px) {
      font-size: 14px;
      line-height: 17px;
    }
    @media (max-width: 896px) and (min-width: 768px), (max-width: 576px) {
      font-size: 17px;
      line-height: 20px;
      margin: 0 0 8px;
    }
    @media (max-width: 480px) {
      font-size: 16px;
      line-height: 19px;
    }
    @media (max-width: 375px) {
      font-size: 15px;
      line-height: 18px;
    }
    &::before {
      background-color: $mono1;
      color: transparent;
      content: 'Read now';
      font-size: inherit;
      line-height: inherit;
      @include rounded-skeleton-text;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Social Mobile + Hyperlink */
  /* ---------------------------------------------------------------- */
  .skeletonSocial,
  footer > span {
    @media (max-width: 896px) and (min-width: 768px), (max-width: 576px) {
      margin-left: 16px;
      margin-right: 16px;
    }
  }
}
/* ---------------------------------------------------------------- */
/* Social Skeleton */
/* ---------------------------------------------------------------- */
.skeletonSocial {
  align-items: center;
  display: flex;
  font-weight: $semibold;
  height: 17px;
  margin: 0;
  white-space: nowrap;
  @media (max-width: 1536px) {
    height: 16px;
  }
  @media (max-width: 1280px) {
    height: 15px;
  }
  @media (max-width: 1024px) {
    height: 14px;
  }
  @media (max-width: 896px) and (min-width: 768px), (max-width: 576px) {
    height: 17px;
    margin-right: 20px;
  }
  @media (max-width: 480px) {
    height: 16px;
  }
  @media (max-width: 375px) {
    height: 15px;
  }
  /* ---------------------------------------------------------------- */
  /* Bookmark */
  /* ---------------------------------------------------------------- */
  .bookmark {
    display: flex;
    margin-right: 12px;
    @media (max-width: 1280px) {
      margin-right: 10px;
    }
    @media (max-width: 1024px) {
      margin-right: 8px;
    }
    @media (max-width: 896px) and (min-width: 768px), (max-width: 576px) {
      margin-right: 16px;
    }
    @media (max-width: 480px) {
      margin-right: 14px;
    }
    @media (max-width: 375px) {
      margin-right: 12px;
    }
    /* ---------------------------------------------------------------- */
    /* Button + Icon */
    /* ---------------------------------------------------------------- */
    span {
      background-color: $mono1;
      clip-path: polygon(100% 0%, 100% 100%, 50% 70%, 0 100%, 0 0);
      height: 17px;
      transform: translateZ(0);
      width: 13px;
      @media (max-width: 1536px) {
        height: 16px;
        width: 13px;
      }
      @media (max-width: 1280px) {
        height: 15px;
        width: 12px;
      }
      @media (max-width: 1024px) {
        height: 14px;
        width: 11px;
      }
      @media (max-width: 896px) and (min-width: 768px), (max-width: 576px) {
        height: 17px;
        width: 13px;
      }
      @media (max-width: 480px) {
        height: 16px;
        width: 13px;
      }
      @media (max-width: 375px) {
        height: 15px;
        width: 12px;
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Likes */
  /* ---------------------------------------------------------------- */
  .likes {
    align-items: center;
    display: flex;
    font-size: 17px;
    font-weight: $semibold;
    @media (max-width: 1536px) {
      font-size: 16px;
    }
    @media (max-width: 1280px) {
      font-size: 15px;
    }
    @media (max-width: 1024px) {
      font-size: 14px;
    }
    @media (max-width: 896px) and (min-width: 768px), (max-width: 576px) {
      font-size: 17px;
    }
    @media (max-width: 480px) {
      font-size: 16px;
    }
    @media (max-width: 375px) {
      font-size: 15px;
    }
    /* ---------------------------------------------------------------- */
    /* Button + Icon */
    /* ---------------------------------------------------------------- */
    span:nth-child(1) {
      backface-visibility: hidden;
      background-color: transparent;
      height: 17px;
      margin-right: 8px;
      transform: translateZ(0);
      width: 19px;
      @media (max-width: 1536px) {
        height: 16px;
        width: 18px;
      }
      @media (max-width: 1280px) {
        height: 15px;
        margin-right: 7px;
        width: 17px;
      }
      @media (max-width: 1024px) {
        height: 14px;
        width: 16px;
      }
      @media (max-width: 896px) and (min-width: 768px), (max-width: 576px) {
        height: 17px;
        margin-right: 12px;
        width: 19px;
      }
      @media (max-width: 480px) {
        height: 16px;
        margin-right: 10px;
        width: 18px;
      }
      @media (max-width: 375px) {
        height: 15px;
        margin-right: 8px;
        width: 17px;
      }
      &:only-child {
        margin-right: 0;
      }
    }
    /* ---------------------------------------------------------------- */
    /* Text */
    /* ---------------------------------------------------------------- */
    > span:nth-child(2) {
      &::after {
        background-color: $mono1;
        color: transparent;
        content: '4 Likes';
        font-size: inherit;
        line-height: 17px;
        @include rounded-skeleton-text;
        @media (max-width: 1536px) {
          line-height: 16px;
        }
      }
    }
  }
}

@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}
