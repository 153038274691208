@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
.noCertification {
  background-color: white;
  padding: 30px 20px;
  text-align: center;
  & > p {
    color: $otherBestPractices;
    padding: 3px;
  }
  & > button {
    min-width: 200px;
  }
  & > .title {
    margin-top: 1em;
  }
  & > .info {
    margin-bottom: 3em;
  }
}
