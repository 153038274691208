@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Filters Container */
/* ---------------------------------------------------------------- */
.filtersContainer {
  margin: 0 0 56px;
  @media (max-width: 1664px) {
    margin: 0 0 52px;
  }
  @media (max-width: 1536px) {
    margin: 0 0 48px;
  }
  @media (max-width: 1408px) {
    margin: 0 0 44px;
  }
  @media (max-width: 1280px) {
    margin: 0 0 40px;
  }
  @media (max-width: 1152px) {
    margin: 0 0 36px;
  }
  @media (max-width: 1024px) {
    margin: 0 0 32px;
  }
  @media (max-width: 896px) {
    margin: 0 0 28px;
  }
  /* ---------------------------------------------------------------- */
  /* Filters */
  /* ---------------------------------------------------------------- */
  > div:nth-child(1) {
    display: grid;
    grid-auto-columns: minmax(0, 1fr);
    grid-auto-flow: column;
    grid-column-gap: 24px;
    margin-bottom: 32px;
    position: relative;
    @media (max-width: 1536px) {
      grid-column-gap: 20px;
      margin-bottom: 28px;
    }
    @media (max-width: 1280px) {
      grid-column-gap: 16px;
      margin-bottom: 24px;
    }
    @media (max-width: 1024px) {
      grid-column-gap: 12px;
      margin-bottom: 20px;
    }
    @media (max-width: 520px) {
      grid-auto-flow: row;
      grid-row-gap: 12px;
    }
    &:last-child {
      margin-bottom: 0;
    }
    /* ---------------------------------------------------------------- */
    /* Best Practice / Content Type Filters */
    /* ---------------------------------------------------------------- */
    > div {
      &:nth-child(2),
      &:nth-child(3) {
        @media (max-width: 520px) {
          display: none;
        }
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Selected Filters */
  /* ---------------------------------------------------------------- */
  > div:nth-child(2) {
    display: flex;
    height: 51px;
    margin-bottom: -16px;
    margin-top: -1px;
    overflow-x: auto;
    scroll-snap-type: inline proximity;
    scrollbar-color: $brandAqua $mono1;
    scrollbar-width: thin;
    @media (max-width: 1536px) {
      height: 47px;
    }
    @media (max-width: 767px) {
      flex-wrap: wrap;
      height: auto;
      margin: -5px -4px;
      overflow: hidden;
    }
    &::-webkit-scrollbar {
      -webkit-appearance: none;
      height: 8px;
    }
    &::-webkit-scrollbar-track {
      background-color: $mono1;
      border-radius: 8px;
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    }
    &::-webkit-scrollbar-thumb {
      background-color: $brandAqua;
      border-radius: 8px;
    }
    > button {
      scroll-snap-align: start;
      div {
        @include no-user-select;
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Show Filters Button */
  /* ---------------------------------------------------------------- */
  .showFiltersButton {
    color: $brandBlackGrape;
    cursor: pointer;
    font-size: 16px;
    justify-self: flex-end;
    line-height: 20px;
    margin-bottom: -4px;
    text-decoration: underline;
    touch-action: manipulation;
    @include fast-transition($props: 'color');
    @media (max-width: 375px) {
      font-size: 15px;
      line-height: 19px;
    }
    &:focus {
      color: $brandBerry;
      text-decoration: none;
    }
    :global(.platform-desktop) & {
      &:hover {
        color: $brandBerry;
      }
      &:active {
        color: darken($brandBerry, 10%);
      }
    }
    :global(.platform-mobile) & {
      &:active {
        color: $brandBerry;
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Show Filters - Mobile */
  /* ---------------------------------------------------------------- */
  &.showFilters {
    > div:nth-child(1) {
      > div {
        /* ---------------------------------------------------------------- */
        /* Best Practice / Content Type Filters  */
        /* ---------------------------------------------------------------- */
        &:nth-child(2),
        &:nth-child(3) {
          @media (max-width: 520px) {
            display: block;
          }
        }
      }
      /* ---------------------------------------------------------------- */
      /* Show Filters Button  */
      /* ---------------------------------------------------------------- */
      .showFiltersButton {
        display: none;
      }
    }
  }
}
