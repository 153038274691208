@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
.table-of-contents {
  background-color: $white;
  margin: 56px 0;
  padding: 56px 0;
  @media (max-width: 1536px) {
    margin: 48px 0;
    padding: 48px 0;
  }
  @media (max-width: 1280px) {
    margin: 40px 0;
    padding: 40px 0;
  }
  @media (max-width: 1024px) {
    margin: 32px 0;
    padding: 32px 0;
  }
  @media (max-width: 375px) {
    margin: 24px 0;
    padding: 24px 0;
  }
  p {
    font-size: 17px;
    margin-bottom: 32px;
    @media (max-width: 1152px) {
      font-size: 16px;
    }
  }
  h2 {
    color: $brandBlackGrape;
    font-size: 32px;
    font-weight: $semibold;
    line-height: 40px;
    margin-bottom: 36px;
    @media (max-width: 1536px) {
      font-size: 28px;
      line-height: 36px;
      margin-bottom: 32px;
    }
    @media (max-width: 1280px) {
      font-size: 24px;
      line-height: 32px;
      margin-bottom: 28px;
    }
    @media (max-width: 375px) {
      font-size: 22px;
      line-height: 30px;
      margin-bottom: 26px;
    }
    span {
      display: block;
      margin: -7px 0 -9px;
      @media (max-width: 1536px) {
        margin: -7px 0;
      }
      @media (max-width: 375px) {
        margin: -6px 0 -7px;
      }
    }
  }
  .list {
    display: flex;
    margin: 56px -15px 0 -15px;
    width: auto;
    @media (max-width: 1536px) {
      margin-top: 48px;
    }
    @media (max-width: 1280px) {
      margin-top: 40px;
    }
    @media (max-width: 1024px) {
      margin-top: 32px;
    }
    @media (max-width: 767px) {
      flex-direction: column;
      margin-left: 0;
      margin-right: 0;
    }
    @media (max-width: 375px) {
      margin-top: 24px;
    }
    ul {
      padding: 0 15px;
      width: 50%;
      @media (max-width: 767px) {
        padding: 0;
        width: 100%;
        &:last-of-type {
          li:last-child {
            padding-bottom: 0;
          }
        }
      }
      li {
        border-bottom: 1px solid $greyLight;
        padding: 24px 0;
        &:first-child {
          padding-top: 0;
        }
        &:last-child {
          border: 0;
          padding-bottom: 0;
        }
        a {
          color: $brandBlackGrape;
          font-size: 20px;
          font-weight: $bold;
          @include no-user-select;
          @media (max-width: 1152px) {
            font-size: 17px;
          }
        }
      }
      &:first-child {
        @media (max-width: 767px) {
          li {
            &:last-child {
              border-bottom: 1px solid $greyLight;
              padding-bottom: 24px;
            }
          }
        }
      }
      &:last-child {
        @media (max-width: 767px) {
          li {
            &:first-child {
              padding-top: 24px;
            }
          }
        }
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Back To Top Button */
  /* ---------------------------------------------------------------- */
  .backToTopButton {
    animation-duration: 0.2s;
    animation-fill-mode: forwards;
    animation-timing-function: ease-in-out;
    background-color: $brandAquaPastel !important;
    height: 48px;
    min-width: unset;
    opacity: 0;
    padding: 0 24px !important;
    position: fixed;
    right: calc(50% - 832px);
    top: 20px;
    visibility: hidden;
    z-index: 99;
    @include fast-transition($mob: true, $props: 'opacity, visibility');
    @media (max-width: 1808px) {
      right: 72px;
    }
    @media (max-width: 1664px) {
      height: 40px;
      padding: 0 16px !important;
      right: 64px;
    }
    @media (max-width: 1536px) {
      height: 32px;
      padding: 0 12px !important;
      right: 56px;
    }
    @media (max-width: 1408px) {
      height: 48px;
      padding: 0 !important;
      right: 48px;
      width: 48px;
    }
    @media (max-width: 1280px) {
      right: 40px;
    }
    @media (max-width: 1152px) {
      height: 44px;
      right: 32px;
      width: 44px;
    }
    @media (max-width: 1024px) {
      height: 36px;
      width: 36px;
    }
    @media (max-width: 767px) {
      height: 48px;
      right: 8px;
      top: 16px;
      width: 48px;
    }
    @media (max-width: 375px) {
      height: 44px;
      width: 44px;
    }
    &:focus {
      background-color: $brandAquaDarkPastel !important;
    }
    :global(.platform-desktop) & {
      &:hover {
        background-color: $brandAquaLightPastel !important;
      }
      &:active {
        background-color: $brandAquaDarkPastel !important;
      }
    }
    :global(.platform-mobile) & {
      &:active {
        background-color: $brandAquaLightPastel !important;
      }
    }
    svg {
      display: none;
      @media (max-width: 1408px) {
        display: block;
        height: 22px;
        margin: 0 auto;
        transform: rotate(-90deg);
        width: 9px;
      }
      @media (max-width: 1152px) {
        height: 20px;
        width: 8px;
      }
      @media (max-width: 1024px) {
        height: 18px;
        width: 7px;
      }
      @media (max-width: 767px) {
        height: 22px;
        width: 9px;
      }
      @media (max-width: 375px) {
        height: 20px;
        width: 8px;
      }
    }
    span {
      font-size: 16px;
      @media (max-width: 1664px) {
        font-size: 15px;
      }
      @media (max-width: 1536px) {
        font-size: 13px;
        letter-spacing: -0.25px;
      }
      @media (max-width: 1408px) {
        display: none;
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Show Back To Top Button */
  /* ---------------------------------------------------------------- */
  .showButton {
    > a {
      opacity: 1;
      visibility: visible;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Hide Back To Top Button */
  /* ---------------------------------------------------------------- */
  .hideButton {
    > a {
      opacity: 0;
      visibility: hidden;
    }
  }
}
