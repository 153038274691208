@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Add Card Form */
/* ---------------------------------------------------------------- */
.addCardForm {
  /* ---------------------------------------------------------------- */
  /* Make Default Question */
  /* ---------------------------------------------------------------- */
  .makeDefault {
    margin-bottom: 0;
    position: static;
    width: auto;
    /* ---------------------------------------------------------------- */
    /* Checkbox */
    /* ---------------------------------------------------------------- */
    > div {
      bottom: 20px;
      margin-bottom: 0;
      position: absolute;
      @media (max-width: 1536px) {
        bottom: 16px;
      }
      @media (max-width: 1280px) {
        bottom: 12px;
      }
      @media (max-width: 767px) {
        bottom: 8px;
      }
      @media (max-width: 640px) {
        bottom: auto;
        position: relative;
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Buttons */
  /* ---------------------------------------------------------------- */
  .buttons {
    justify-content: flex-end;
    @media (max-width: 520px) {
      flex-wrap: wrap;
      justify-content: center;
    }
    button {
      @media (max-width: 520px) {
        width: calc(50% - 6px);
      }
      span {
        font-family: $primary;
        font-weight: $regular;
      }
      &:nth-of-type(1) {
        margin-right: 24px;
        @media (max-width: 1536px) {
          margin-right: 20px;
        }
        @media (max-width: 1280px) {
          margin-right: 16px;
        }
        @media (max-width: 767px) {
          margin-right: 12px;
        }
        @media (max-width: 520px) {
          order: 1;
        }
      }
      &:nth-of-type(2) {
        @media (max-width: 520px) {
          order: 2;
        }
      }
    }
  }
}
/* ---------------------------------------------------------------- */
/* Spinner */
/* ---------------------------------------------------------------- */
.spinner {
  background-color: $brandBlackGrape;
  background-image: url('/images/icons/button-loading.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: 56px 56px;
  border-radius: 50%;
  display: block;
  flex-shrink: 0;
  height: 64px;
  margin-right: 12px;
  top: 0;
  width: 64px;
  @media (max-width: 1536px) {
    background-size: 48px 48px;
    height: 56px;
    margin-right: 10px;
    width: 56px;
  }
  @media (max-width: 1280px) {
    background-size: 40px 40px;
    height: 48px;
    margin-right: 8px;
    width: 48px;
  }
  @media (max-width: 520px) {
    margin-right: 12px;
  }
  ~ button {
    @media (max-width: 520px) {
      max-width: calc(50% - 36px) !important;
    }
  }
}

.addCardForm[class] {
  display: grid;
}
