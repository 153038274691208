@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Consent */
/* ---------------------------------------------------------------- */
.consent {
  /* ---------------------------------------------------------------- */
  /* Question */
  /* ---------------------------------------------------------------- */
  > div {
    margin: 0;
  }
  /* ---------------------------------------------------------------- */
  /* Recaptcha Message */
  /* ---------------------------------------------------------------- */
  small {
    margin: 13px 0 -3px;
    padding-left: 56px;
    @media (max-width: 1536px) {
      padding-left: 53px;
    }
    @media (max-width: 1280px) {
      padding-left: 51px;
    }
    @media (max-width: 1024px) {
      padding-left: 48px;
    }
  }
}
