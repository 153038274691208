@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* -------------------------------------------------------------- */
/* Curated Page */
/* -------------------------------------------------------------- */
.curatedPage {
  /* -------------------------------------------------------------- */
  /* Title Container */
  /* -------------------------------------------------------------- */
  > div > div > div:nth-child(1) {
    margin-bottom: 40px;
    @media (max-width: 1536px) {
      margin-bottom: 36px;
    }
    @media (max-width: 1408px) {
      margin-bottom: 32px;
    }
    @media (max-width: 1280px) {
      margin-bottom: 28px;
    }
    @media (max-width: 1024px) {
      margin-bottom: 24px;
    }
  }
  /* -------------------------------------------------------------- */
  /* Title */
  /* -------------------------------------------------------------- */
  h1 {
    font-family: $secondary;
    font-size: 32px;
    line-height: 39px;
    margin-bottom: -7px;
    @media (max-width: 1536px) {
      font-size: 30px;
      line-height: 36px;
      margin-bottom: -6px;
    }
    @media (max-width: 1280px) {
      font-size: 27px;
      line-height: 33px;
    }
    @media (max-width: 1024px) {
      font-size: 24px;
      line-height: 30px;
    }
    &:first-child {
      margin-top: -7px;
      @media (max-width: 1536px) {
        margin-top: -6px;
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Description */
  /* ---------------------------------------------------------------- */
  .description {
    font-family: $secondary;
    font-size: 20px;
    line-height: 27px;
    margin: 33px 0 40px;
    @media (max-width: 1536px) {
      font-size: 19px;
      line-height: 26px;
      margin: 29px 0 36px;
    }
    @media (max-width: 1408px) {
      margin: 25px 0 32px;
    }
    @media (max-width: 1280px) {
      font-size: 18px;
      line-height: 25px;
      margin: 22px 0 28px;
    }
    @media (max-width: 1024px) {
      font-size: 17px;
      line-height: 24px;
      margin: 18px 0 25px;
    }
    @media (max-width: 767px) {
      font-size: 16px;
      line-height: 23px;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Rich Text */
  /* ---------------------------------------------------------------- */
  .richText {
    margin: 0;
    padding-bottom: 36px;
    padding-top: 36px;
    @media (max-width: 1280px) {
      padding-bottom: 32px;
      padding-top: 32px;
    }
    @media (max-width: 1024px) {
      padding-bottom: 24px;
      padding-top: 24px;
    }
    @media (max-width: 767px) {
      padding-bottom: 32px;
      padding-top: 32px;
    }
    @media (max-width: 375px) {
      padding-bottom: 24px;
      padding-top: 24px;
    }
    &:last-child {
      padding-bottom: 0;
    }
  }
}
