@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Filter List */
/* ---------------------------------------------------------------- */
.filtersList {
  background-color: $white;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  left: 0;
  margin: 12px 0 0;
  position: absolute;
  width: 100%;
  z-index: 3;
  @media (max-width: 1536px) {
    margin: 10px 0 0;
  }
  @media (max-width: 1280px) {
    margin: 8px 0 0;
  }
  @media (max-width: 1024px) {
    margin: 6px 0 0;
  }
  /* ---------------------------------------------------------------- */
  /* List */
  /* ---------------------------------------------------------------- */
  ul {
    display: flex;
    flex-wrap: wrap;
    padding: 8px;
    @media (max-width: 1536px) {
      padding: 10px;
    }
    @media (max-width: 1280px) {
      padding: 12px;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Item */
  /* ---------------------------------------------------------------- */
  li {
    font-size: 13px;
    list-style-type: none;
    margin: 8px;
    @media (max-width: 1536px) {
      margin: 6px;
    }
    @media (max-width: 1280px) {
      margin: 4px;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Button */
  /* ---------------------------------------------------------------- */
  button {
    align-items: center;
    border: 1px solid $mono1;
    border-radius: 19px;
    color: $brandBlackGrape;
    cursor: pointer;
    display: inline-flex;
    font-family: $primary;
    font-size: 17px;
    height: 36px;
    padding: 0 24px;
    touch-action: manipulation;
    white-space: nowrap;
    width: auto;
    @include fast-transition($props: 'background-color');
    @include no-user-select;
    @media (max-width: 1536px) {
      font-size: 16px;
      height: 32px;
      padding: 0 20px;
    }
    @media (max-width: 1280px) {
      font-size: 15px;
      padding: 0 16px;
    }
    &:focus {
      background-color: $greyDark;
      text-decoration: underline;
    }
    :global(.platform-desktop) & {
      &:hover {
        background-color: $greyDark;
      }
    }
    /* ---------------------------------------------------------------- */
    /* Close Icon */
    /* ---------------------------------------------------------------- */
    svg {
      height: 14px;
      margin-left: 12px;
      width: 14px;
      @media (max-width: 1536px) {
        height: 13px;
        margin-left: 10px;
        width: 13px;
      }
      @media (max-width: 1280px) {
        height: 12px;
        margin-left: 8px;
        width: 12px;
      }
      * {
        stroke: $white;
      }
    }
    /* ---------------------------------------------------------------- */
    /* Selected */
    /* ---------------------------------------------------------------- */
    &.filtered-selected {
      background-color: $brandBlackGrape;
      color: $white;
      &:focus {
        background-color: rgba($brandBlackGrape, 0.9);
      }
      :global(.platform-desktop) & {
        &:hover {
          background-color: rgba($brandBlackGrape, 0.9);
        }
      }
    }
  }
}
