@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* -------------------------------------------------------------- */
/* Resource Hub List */
/* -------------------------------------------------------------- */
.resourceHubList {
  display: grid;
  grid-auto-rows: 1fr;
  grid-row-gap: 24px;
  @media (max-width: 1280px) {
    grid-row-gap: 20px;
  }
  @media (max-width: 1024px) {
    grid-row-gap: 16px;
  }
  @media (max-width: 896px) and (min-width: 768px), (max-width: 576px) {
    grid-auto-rows: auto;
    grid-row-gap: 32px;
  }
  /* -------------------------------------------------------------- */
  /* Resource Hub Item */
  /* -------------------------------------------------------------- */
  article {
    height: 100%;
    /* -------------------------------------------------------------- */
    /* Content Container */
    /* -------------------------------------------------------------- */
    > div {
      height: auto;
    }
  }
  /* -------------------------------------------------------------- */
  /* Skeleton  */
  /* -------------------------------------------------------------- */
  &.skeleton {
    grid-auto-rows: auto;
    /* -------------------------------------------------------------- */
    /* Skeleton Items */
    /* -------------------------------------------------------------- */
    li {
      background-color: $mono1;
      height: 194px;
      overflow: hidden;
      position: relative;
      width: 100%;
      @media (max-width: 896px) and (min-width: 768px), (max-width: 576px) {
        height: 208px;
      }
      @media (max-width: 375px) {
        height: 175px;
      }
      &::after {
        animation: shimmer 1s infinite;
        background-image: linear-gradient(
          90deg,
          rgba($mono2, 0) 0,
          rgba($mono2, 0.2) 20%,
          rgba($mono2, 0.5) 60%,
          rgba($mono2, 0)
        );
        bottom: 0;
        content: '';
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        transform: translateX(-100%);
      }
      /* -------------------------------------------------------------- */
      /* Skeleton Button */
      /* -------------------------------------------------------------- */
      &:last-child {
        border-radius: 11px;
        height: 48px;
        justify-self: center;
        margin-top: 24px;
        width: 256px;
        @media (max-width: 1536px) {
          margin-top: 20px;
          width: 224px;
        }
        @media (max-width: 1280px) {
          margin-top: 12px;
          width: 192px;
        }
        @media (max-width: 1024px) {
          margin-top: 8px;
        }
      }
    }
  }
}
/* -------------------------------------------------------------- */
/* No Results */
/* -------------------------------------------------------------- */
.noResults {
  align-items: center;
  background-color: $mono1;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: 48px;
  text-align: center;
  width: 100%;
  @media (max-width: 1536px) {
    padding: 40px;
  }
  @media (max-width: 1280px) {
    padding: 32px;
  }
  @media (max-width: 767px) {
    padding: 32px 16px;
  }
  @media (max-width: 375px) {
    padding: 24px 16px;
  }
  /* -------------------------------------------------------------- */
  /* Icon */
  /* -------------------------------------------------------------- */
  > svg {
    height: 36px;
    margin: 0 0 8px;
    width: 36px;
    @media (max-width: 1536px) {
      height: 32px;
      width: 32px;
    }
    @media (max-width: 1280px) {
      height: 28px;
      width: 28px;
    }
  }
  /* -------------------------------------------------------------- */
  /* Title */
  /* -------------------------------------------------------------- */
  > h2 {
    font-family: $secondary;
    font-size: 20px;
    line-height: 26px;
    margin-bottom: -3px;
    @media (max-width: 1536px) {
      font-size: 19px;
      line-height: 25px;
    }
    @media (max-width: 1280px) {
      font-size: 18px;
      line-height: 24px;
    }
  }
}
/* -------------------------------------------------------------- */
/* Show More Button */
/* -------------------------------------------------------------- */
.showMoreButton {
  background-color: $mono1;
  border-radius: 11px;
  cursor: pointer;
  display: block;
  font-size: 16px;
  font-weight: $semibold;
  height: 48px;
  margin: 48px auto 0;
  touch-action: manipulation;
  width: 256px;
  @include fast-transition($props: 'background-color');
  @media (max-width: 1536px) {
    margin-top: 40px;
    width: 224px;
  }
  @media (max-width: 1280px) {
    font-size: 15px;
    margin-top: 32px;
    width: 192px;
  }
  @media (max-width: 1024px) {
    margin-top: 24px;
  }
  &:focus {
    background-color: $mono2;
    text-decoration: underline;
  }
  :global(.platform-desktop) & {
    &:hover {
      background-color: $mono2;
    }
    &:active {
      background-color: $mono3;
    }
  }
  :global(.platform-mobile) & {
    &:active {
      background-color: $mono2;
    }
  }
}
/* -------------------------------------------------------------- */
/* Search Results */
/* -------------------------------------------------------------- */
.searchResults {
  margin: 0 0 32px;
  @media (max-width: 1536px) {
    margin: 0 0 28px;
  }
  @media (max-width: 1280px) {
    margin: 0 0 24px;
  }
  @media (max-width: 1024px) {
    margin: 0 0 20px;
  }
  p {
    font-size: 20px;
    line-height: 28px;
    margin: -6px 0;
    @media (max-width: 1536px) {
      font-size: 19px;
      line-height: 27px;
    }
    @media (max-width: 1280px) {
      font-size: 18px;
      line-height: 26px;
    }
    @media (max-width: 1024px) {
      font-size: 17px;
      line-height: 25px;
    }
  }
}

.sortByLabel {
  text-align: end;

  > span {
    margin-bottom: 20px;
    align-items: center;
    background-color: #282d3c !important;
    border: 1px solid;
    border-radius: 19px;
    color: #fff !important;
    display: inline-flex;
    height: 36px;
    padding: 0 24px;
    white-space: nowrap;
  }
}

@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}
