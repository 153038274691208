@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
.cardSocial {
  align-items: center;
  color: $brandBlackGrape;
  display: flex;
  font-size: 15px;
  font-weight: $semibold;
  line-height: 32px;
  margin: 0;
  @media (max-width: 767px) {
    font-size: 17px;
    margin-right: 20px;
  }
  @media (max-width: 580px) {
    font-size: 15px;
  }
  @media (max-width: 350px) {
    display: none;
  }
  .bookmark {
    display: flex;
    margin-right: 18px;
  }
  .likes {
    align-items: center;
    color: $brandBlackGrape;
    display: flex;
    font-size: 17px;
    font-weight: $semibold;
    > svg {
      margin-right: 8px;
    }
  }
}
