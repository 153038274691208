@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Filter Selected */
/* ---------------------------------------------------------------- */
.filtersSelected {
  align-items: center;
  background-color: $brandBlackGrape;
  border: 1px solid;
  border-radius: 19px;
  color: $white;
  cursor: pointer;
  display: inline-flex;
  font-family: $primary;
  font-size: 17px;
  height: 36px;
  margin: 0 8px;
  padding: 0 24px;
  touch-action: manipulation;
  white-space: nowrap;
  width: auto;
  @include fast-transition($props: 'background-color');
  @media (max-width: 1536px) {
    font-size: 16px;
    height: 32px;
    margin: 0 8px;
    padding: 0 20px;
  }
  @media (max-width: 1280px) {
    font-size: 15px;
    margin: 0 4px;
    padding: 0 16px;
  }
  @media (max-width: 767px) {
    display: none;
    margin: 4px;
  }
  &:first-child {
    @media (min-width: 768px) {
      margin-left: 0;
    }
  }
  &:last-child {
    @media (min-width: 768px) {
      margin-right: 0;
    }
  }
  &:focus {
    background-color: rgba($brandBlackGrape, 0.9);
    text-decoration: underline;
  }
  &:active {
    color: $white;
  }
  :global(.platform-desktop) & {
    &:hover {
      background-color: rgba($brandBlackGrape, 0.9);
    }
  }
  &:nth-child(-n + 5) {
    @media (max-width: 767px) {
      display: flex;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Close Icon */
  /* ---------------------------------------------------------------- */
  svg {
    height: 14px;
    margin-left: 12px;
    width: 14px;
    @media (max-width: 1536px) {
      height: 13px;
      margin-left: 10px;
      width: 13px;
    }
    @media (max-width: 1536px) {
      height: 12px;
      margin-left: 8px;
      width: 12px;
    }
    * {
      stroke: $white;
    }
  }
  > div {
    font-family: $primary;
    font-size: 17px;
    line-height: 36px;
    margin-right: 8px;
    white-space: nowrap;
  }
}
