@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Slider Slides */
/* ---------------------------------------------------------------- */
.sliderSlides {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  @include transition($mob: true, $props: 'opacity', $duration: '0.6s');
  /* ---------------------------------------------------------------- */
  /* Active Slide */
  /* ---------------------------------------------------------------- */
  &.activeSlide {
    opacity: 1;
    transform: translateX(0);
  }
  /* ---------------------------------------------------------------- */
  /* Next Slide(s) */
  /* ---------------------------------------------------------------- */
  &.nextSlide {
    opacity: 0;
    position: absolute;
    right: -100%;
    transform: translateX(100%);
  }
}
