@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Payment Information */
/* ---------------------------------------------------------------- */
.paymentInformation {
  /* -------------------------------------------------------------- */
  /* Title */
  /* -------------------------------------------------------------- */
  > h3 {
    align-items: baseline;
    display: flex;
    justify-content: space-between;
    margin: -6px 0;
    padding-top: 40px;
    @media (max-width: 1536px) {
      padding-top: 36px;
    }
    @media (max-width: 1280px) {
      padding-top: 32px;
    }
    @media (max-width: 576px) {
      flex-direction: column;
      margin: 0;
    }
    @media (max-width: 375px) {
      padding-top: 28px;
    }
    /* ------------------------------------------------------------ */
    /* Text */
    /* ------------------------------------------------------------ */
    > span {
      color: $brandBlackGrape;
      display: block;
      font-size: 20px;
      line-height: 27px;
      @media (max-width: 1536px) {
        font-size: 19px;
        line-height: 26px;
      }
      @media (max-width: 1280px) {
        font-size: 18px;
        line-height: 25px;
      }
      @media (max-width: 767px) {
        font-size: 17px;
        line-height: 24px;
      }
      @media (max-width: 576px) {
        margin: -6px 0 12px;
      }
      @media (max-width: 375px) {
        font-size: 16px;
        line-height: 23px;
        margin-bottom: 8px;
      }
    }
    /* ---------------------------------------------------------- */
    /* Button */
    /* ---------------------------------------------------------- */
    > button {
      color: $brandBlackGrape;
      cursor: pointer;
      display: inline-block;
      font-family: $primary;
      font-size: 18px;
      font-weight: $bold;
      line-height: 24px;
      white-space: nowrap;
      @include fast-transition($props: 'color');
      @include no-user-select;
      @media (max-width: 1536px) {
        font-size: 17px;
        line-height: 23px;
      }
      @media (max-width: 1280px) {
        font-size: 16px;
        line-height: 22px;
      }
      @media (max-width: 767px) {
        font-size: 18px;
        line-height: 24px;
      }
      @media (max-width: 576px) {
        margin-bottom: -5px;
      }
      @media (max-width: 375px) {
        font-size: 17px;
        line-height: 23px;
      }
      &:focus {
        color: $mono5;
        text-decoration: underline;
        svg * {
          fill: $mono5;
        }
      }
      :global(.platform-desktop) & {
        &:hover {
          color: gray; // Delete this after PeopleCert+ Transition
          // color: $brandAqua;
          svg * {
            fill: $brandAqua;
          }
        }
        &:active {
          color: $mono5;
          svg * {
            fill: $mono5;
          }
        }
      }
      :global(.platform-mobile) & {
        &:active {
          color: $brandAqua;
          svg * {
            fill: $brandAqua;
          }
        }
      }
      > svg {
        display: inline-block;
        fill: $brandBlackGrape;
        height: 10px;
        margin-left: 15px;
        width: 6px;
        @media (max-width: 375px) {
          margin-left: 11px;
        }
        * {
          @include fast-transition($props: 'fill');
        }
      }
    }
  }
  /* -------------------------------------------------------------- */
  /* Text */
  /* -------------------------------------------------------------- */
  > p {
    color: $brandBlackGrape;
    font-size: 17px;
    line-height: 25px;
    margin: -6px 0;
    @media (max-width: 1536px) {
      font-size: 16px;
      line-height: 24px;
    }
    @media (max-width: 1280px) {
      font-size: 15px;
      line-height: 23px;
    }
    @media (max-width: 375px) {
      font-size: 14px;
      line-height: 20px;
    }
    button {
      cursor: pointer;
      display: inline-block;
      font-size: inherit;
      text-decoration: underline;
      @include fast-transition($props: 'color');
      &:focus {
        color: darken($brandAqua, 20%);
      }
      :global(.platform-desktop) & {
        &:hover {
          color: darken($brandAqua, 20%);
        }
        &:active {
          color: $mono5;
        }
      }
      :global(.platform-mobile) & {
        &:active {
          color: darken($brandAqua, 20%);
        }
      }
    }
  }
  > h3 + p {
    padding-top: 20px;
  }
}
