@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Slider Navigation */
/* ---------------------------------------------------------------- */
.sliderNavigation {
  /* ---------------------------------------------------------------- */
  /* Button Seperator */
  /* ---------------------------------------------------------------- */
  &::after {
    background-color: rgba($brandBlackGrape, 0.2);
    content: '';
    display: block;
    height: 2px;
    position: absolute;
    right: 0;
    top: calc(50% - 1px);
    width: 48px;
    @media (max-width: 1536px) {
      width: 40px;
    }
    @media (max-width: 1280px) {
      width: 32px;
    }
    @media (max-width: 1152px) and (min-width: 768px), (max-width: 576px) {
      content: none;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Button */
  /* ---------------------------------------------------------------- */
  button {
    background-color: $brandGreyLight;
    cursor: pointer;
    display: block;
    height: calc(50% - 1px);
    outline: none;
    position: absolute;
    touch-action: manipulation;
    width: 48px;
    @include medium-transition($mob: true, $props: 'background-color');
    @media (max-width: 1536px) {
      width: 40px;
    }
    @media (max-width: 1280px) {
      width: 32px;
    }
    @media (max-width: 1152px) and (min-width: 768px), (max-width: 576px) {
      height: 100%;
      width: 40px;
    }
    @media (max-width: 576px) {
      width: 40px;
    }
    @media (max-width: 375px) {
      width: 32px;
    }
    &:not([disabled]) {
      &:focus {
        background-color: darken($brandGreyLight, 7.5%);
        &::after {
          border-color: $brandBlackGrape;
        }
      }
      :global(.platform-desktop) & {
        &:hover {
          background-color: darken($brandGreyLight, 7.5%);
        }
        &:active {
          background-color: darken($brandGreyLight, 15%);
        }
      }
      :global(.platform-mobile) & {
        &:active {
          background-color: darken($brandGreyLight, 7.5%);
        }
      }
    }
    /* ---------------------------------------------------------------- */
    /* Focus Ring */
    /* ---------------------------------------------------------------- */
    &::after {
      border: 2px dashed transparent;
      border-radius: 50%;
      box-sizing: border-box;
      content: '';
      display: block;
      height: 28px;
      left: 50%;
      margin: -15px 0 0 -15px;
      position: absolute;
      top: 50%;
      width: 28px;
      @include medium-transition($props: 'border-color');
      @media (max-width: 1536px) {
        height: 27px;
        margin: -14px 0 0 -14px;
        width: 27px;
      }
      @media (max-width: 1408px) {
        margin-top: -15px;
      }
      @media (max-width: 1280px) {
        height: 25px;
        margin: -14px 0 0 -13px;
        width: 25px;
      }
    }
    /* ---------------------------------------------------------------- */
    /* Chevron */
    /* ---------------------------------------------------------------- */
    svg {
      height: 14px;
      width: 8px;
      path {
        fill: $brandBlackGrape;
        @include medium-transition($mob: true, $props: 'fill');
      }
    }
    /* ---------------------------------------------------------------- */
    /* Previous Slide Button */
    /* ---------------------------------------------------------------- */
    &:first-child {
      right: 0;
      top: 0;
      @media (max-width: 1152px) and (min-width: 768px), (max-width: 576px) {
        left: 0;
        right: auto;
      }
      &::after {
        margin-left: -13px;
        @media (max-width: 1536px) {
          margin-left: -12px;
        }
      }
      svg {
        transform: rotate(-180deg);
      }
    }
    /* ---------------------------------------------------------------- */
    /* Next Slide Button */
    /* ---------------------------------------------------------------- */
    &:last-child {
      bottom: 0;
      right: 0;
    }
    /* ---------------------------------------------------------------- */
    /* Disabled */
    /* ---------------------------------------------------------------- */
    &[disabled] {
      cursor: default;
      svg path {
        fill: rgba($brandBlackGrape, 0.2);
      }
    }
  }
}
