@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Personal Info */
/* ---------------------------------------------------------------- */
.personalInfo {
  /* -------------------------------------------------------------- */
  /* Form */
  /* -------------------------------------------------------------- */
  > form {
    /* ---------------------------------------------------------- */
    /* Input */
    /* ---------------------------------------------------------- */
    input {
      border-radius: 9px;
      @media (max-width: 640px) {
        border-radius: 4px;
      }
      /* ------------------------------------------------------------ */
      /* Button Row */
      /* ------------------------------------------------------------ */
      > div:last-child {
        margin-top: 0;
        /* ---------------------------------------------------------- */
        /* Button */
        /* ---------------------------------------------------------- */
        button {
          align-self: flex-end;
          min-width: 200px;
        }
      }
    }
  }
}
