@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Accordion Item */
/* ---------------------------------------------------------------- */
.accordionItem {
  &:last-child > div:nth-child(2) > div {
    padding-bottom: 5px;
  }
  &:first-child > div:nth-child(1) {
    padding-top: 13px;
    @media (max-width: 1536px) {
      padding-top: 11px;
    }
    @media (max-width: 1280px) {
      padding-top: 9px;
    }
  }
  /* -------------------------------------------------------------- */
  /* Top Bar */
  /* -------------------------------------------------------------- */
  > div:nth-child(1) {
    border-bottom: 1px solid rgba($color: $brandBlackGrape, $alpha: 0.2);
    padding: 48px 0 24px;
    @media (max-width: 1536px) {
      padding: 40px 0 24px;
    }
    @media (max-width: 1280px) {
      padding: 32px 0 24px;
    }
    @media (max-width: 375px) {
      padding: 24px 0;
    }
    /* ------------------------------------------------------------ */
    /* Inner */
    /* ------------------------------------------------------------ */
    > div {
      align-items: center;
      display: flex;
      position: relative;
      /* ---------------------------------------------------------- */
      /* Question Title */
      /* ---------------------------------------------------------- */
      > h3 {
        color: $brandBlackGrape;
        font-size: 20px;
        font-weight: $semibold;
        line-height: 27px;
        margin: -5px 0 -6px;
        padding-right: 52px;
        text-transform: none;
        @media (max-width: 1536px) {
          font-size: 19px;
          line-height: 26px;
          padding-right: 48px;
        }
        @media (max-width: 1280px) {
          font-size: 18px;
          line-height: 25px;
          padding-right: 44px;
        }
        @media (max-width: 767px) {
          font-size: 17px;
          line-height: 24px;
          margin-bottom: -5px;
          margin-top: -5px;
          width: 100%;
        }
      }
      /* ---------------------------------------------------------- */
      /* Title Button */
      /* ---------------------------------------------------------- */
      > button {
        background-color: $mono1;
        border-radius: 50%;
        cursor: pointer;
        display: block;
        flex-shrink: 0;
        height: 40px;
        position: absolute;
        right: 0;
        top: 50%;
        touch-action: manipulation;
        transform: translateY(-50%);
        width: 40px;
        @media (max-width: 1536px) {
          height: 36px;
          width: 36px;
        }
        @media (max-width: 1280px) {
          height: 32px;
          width: 32px;
        }
        /* Focus Ring */
        &:focus::before {
          border-color: $mono3;
        }
        /* Focus Ring */
        &::before {
          border-color: transparent;
          border-radius: 50%;
          border-style: dashed;
          border-width: 2px;
          box-sizing: border-box;
          content: '';
          height: calc(100% + 12px);
          left: -6px;
          pointer-events: none;
          position: absolute;
          top: -6px;
          width: calc(100% + 12px);
          @include fast-transition($props: 'border-color');
          @media (max-width: 1280px) {
            height: calc(100% + 10px);
            left: -5px;
            top: -5px;
            width: calc(100% + 10px);
          }
        }
        /* Plus / Minus Icons */
        span {
          box-sizing: border-box;
          display: block;
          height: 16px;
          left: 12px;
          margin-top: -8px;
          position: absolute;
          top: 50%;
          transform: rotate(0deg);
          width: 16px;
          will-change: transform;
          @include fast-transition($mob: true, $props: 'transform');
          @media (max-width: 1536px) {
            left: 10px;
            margin-top: -7px;
          }
          @media (max-width: 1280px) {
            height: 14px;
            left: 9px;
            margin-top: -6px;
            width: 14px;
          }
          &::before,
          &::after {
            border-bottom: solid 2px $mono3;
            bottom: 7px;
            box-sizing: border-box;
            content: '';
            display: block;
            height: 0;
            position: absolute;
            width: 16px;
            will-change: width, transform;
            @include fast-transition($mob: true, $props: 'width, transform');
            @media (max-width: 1280px) {
              bottom: 6px;
              width: 14px;
            }
          }
          &::before {
            transform: rotate(90deg);
            width: 0;
          }
        }
      }
    }
  }
  /* -------------------------------------------------------------- */
  /* Content */
  /* -------------------------------------------------------------- */
  > div:nth-child(2) {
    opacity: 1;
    overflow: hidden;
    transform: scaleY(1);
    transform-origin: 0 0;
    visibility: visible;
    will-change: height, opacity, transform;
    @include medium-transition(
      $firefox: false,
      $mob: true,
      $props: 'opacity, height, transform'
    );
    /* ------------------------------------------------------------ */
    /* Inner */
    /* ------------------------------------------------------------ */
    > div {
      padding: 48px 0 64px;
      position: relative;
      @media (max-width: 1536px) {
        padding: 40px 0 56px;
      }
      @media (max-width: 1280px) {
        padding: 32px 0 48px;
      }
      @media (max-width: 375px) {
        padding: 24px 0 40px;
      }
    }
  }
  /* -------------------------------------------------------------- */
  /* Closed */
  /* -------------------------------------------------------------- */
  &.closed {
    /* ------------------------------------------------------------ */
    /* Prevent Content Children From Being Focused */
    /* ------------------------------------------------------------ */
    &:not(.animating) > div:nth-child(2) > * {
      visibility: hidden;
    }
    /* ------------------------------------------------------------ */
    /* Content */
    /* ------------------------------------------------------------ */
    > div:nth-child(2) {
      height: 0;
      opacity: 0;
      padding-bottom: 0;
      transform: scaleY(0.6);
    }
    /* ------------------------------------------------------------ */
    /* Button */
    /* ------------------------------------------------------------ */
    > div:nth-child(1) > div > button {
      span {
        transform: rotate(180deg);
        &::before {
          width: 16px;
          @media (max-width: 1280px) {
            width: 14px;
          }
        }
        &::after,
        &::before {
          @media (max-width: 1280px) {
            bottom: 5px;
          }
        }
      }
    }
  }
  /* -------------------------------------------------------------- */
  /* Animation */
  /* -------------------------------------------------------------- */
  &.animating,
  &.animating + .accordionItem {
    will-change: margin-top, margin-bottom;
    @include medium-transition($mob: true, $props: 'margin-top, margin-bottom');
  }
  &:not(.closed) + .accordionItem {
    margin-top: -48px;
    @media (max-width: 1536px) {
      margin-top: -40px;
    }
    @media (max-width: 1408px) {
      margin-top: -32px;
    }
    @media (max-width: 1280px) {
      margin-top: -24px;
    }
    @media (max-width: 767px) {
      margin-top: -32px;
    }
    @media (max-width: 640px) {
      margin-top: -28px;
    }
    @media (max-width: 375px) {
      margin-top: -24px;
    }
  }
}
