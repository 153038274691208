@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Recaptcha Message */
/* ---------------------------------------------------------------- */
.recaptchaMessage {
  margin: -15px 0 -3px;
  @media (max-width: 1536px) {
    margin-top: -12px;
  }
  @media (max-width: 1408px) {
    margin-top: -8px;
  }
  @media (max-width: 1280px) {
    margin-top: -4px;
  }
  @media (max-width: 1024px) {
    margin-top: 0;
  }
}
/* ---------------------------------------------------------------- */
/* Add New Card Container */
/* ---------------------------------------------------------------- */
.addNewCard {
  margin: 0 0 32px;
  @media (max-width: 1536px) {
    margin: 0 0 28px;
  }
  @media (max-width: 1408px) {
    margin: 0 0 24px;
  }
  @media (max-width: 1280px) {
    margin: 0 0 20px;
  }
  @media (max-width: 1024px) {
    margin: 0 0 16px;
  }
}
