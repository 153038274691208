@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Sidebar */
/* ---------------------------------------------------------------- */
.sidebar {
  background-color: $white;
  box-sizing: border-box;
  padding: 36px 0 72px 36px;
  position: relative;
  width: 33.333%;
  z-index: 2;
  @media (max-width: 1664px) {
    padding: 36px 0 64px 36px;
  }
  @media (max-width: 1536px) {
    padding: 36px 0 56px 36px;
  }
  @media (max-width: 1408px) {
    padding: 32px 0 48px 32px;
  }
  @media (max-width: 1280px) {
    padding: 32px 0 32px 32px;
  }
  @media (max-width: 1152px) {
    padding: 32px 0 32px 32px;
  }
  @media (max-width: 1024px) {
    padding: 24px 0 24px 24px;
  }
  @media (max-width: 767px) {
    background-color: transparent;
    padding: 0 0 32px;
    width: 100%;
  }
  @media (max-width: 375px) {
    padding: 0 0 24px;
  }
  > * {
    &:first-child {
      margin-top: 0;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
  &::before {
    background-color: $white;
    bottom: 0;
    content: '';
    left: 0;
    position: absolute;
    right: -100%;
    top: 2px;
    z-index: -1;
    @media only screen and (min-width: 768px) and (max-width: 1280px) {
      right: 0;
    }
    @media (max-width: 767px) {
      display: none;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Top Padding */
  /* ---------------------------------------------------------------- */
  &.verticalPaddingLarge {
    padding-top: 72px;
    @media (max-width: 1664px) {
      padding-top: 64px;
    }
    @media (max-width: 1536px) {
      padding-top: 56px;
    }
    @media (max-width: 1408px) {
      padding-top: 48px;
    }
    @media (max-width: 1280px) {
      padding-bottom: 40px;
      padding-top: 40px;
    }
    @media (max-width: 1152px) {
      padding-bottom: 40px;
      padding-top: 40px;
    }
    @media (max-width: 1024px) {
      padding-bottom: 40px;
      padding-top: 40px;
    }
    @media (max-width: 767px) {
      padding-bottom: 40px;
      padding-top: 40px;
    }
    @media (max-width: 375px) {
      padding-bottom: 32px;
      padding-top: 32px;
    }
  }
}
