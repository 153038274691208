@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Main */
/* ---------------------------------------------------------------- */
.main {
  display: flex;
  flex-grow: 1;
  overflow: hidden;
  position: relative;
  /* ---------------------------------------------------------------- */
  /* Container */
  /* ---------------------------------------------------------------- */
  > div {
    display: flex;
    flex-grow: 1;
    @media (max-width: 767px) {
      flex-direction: column;
    }
  }
  &::before {
    @media (max-width: 767px) {
      background-color: transparent;
      bottom: 0;
      content: '';
      left: 0;
      pointer-events: none;
      position: fixed;
      right: 0;
      top: 72px;
      z-index: 97;
      @include transition($mob: true, $props: 'background-color');
    }
    :global(.overlay) & {
      @media (max-width: 767px) {
        background-color: rgba($black, 0.5);
        transition-duration: 0.2s;
      }
    }
  }
}
