@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Icon */
/* ---------------------------------------------------------------- */
.icon {
  align-items: center;
  border-color: transparent;
  border-radius: 50%;
  border-style: solid;
  border-width: 2px;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-flex;
  flex-shrink: 0;
  height: 48px;
  justify-content: center;
  position: relative;
  touch-action: manipulation;
  vertical-align: top;
  width: 48px;
  @include fast-transition($props: 'border-color, background-color');
  /* -------------------------------------------------------------- */
  /* Focus Ring */
  /* -------------------------------------------------------------- */
  &::after {
    border-color: transparent;
    border-radius: 50%;
    border-style: dashed;
    border-width: 2px;
    box-sizing: content-box;
    content: '';
    display: block;
    height: 100%;
    left: -2px;
    pointer-events: none;
    position: absolute;
    top: -2px;
    width: 100%;
    @include fast-transition($props: 'border-color');
  }
  /* -------------------------------------------------------------- */
  /* Icon */
  /* -------------------------------------------------------------- */
  svg {
    flex-shrink: 0;
    transform: scale(1);
    transform-origin: 50% 50%;
    @include fast-transition($props: 'transform');
  }
  /* -------------------------------------------------------------- */
  /* Primary - SVG path should use a fill and no stroke */
  /* -------------------------------------------------------------- */
  &.primary {
    background-color: $greyXXLight;
    svg * {
      fill: $mono3;
      stroke: none;
      @include fast-transition($props: 'fill');
    }
    &:focus {
      background-color: $greyXLight;
      &::after {
        border-color: $brandBerry;
      }
      svg * {
        fill: $brandBlackGrape;
      }
    }
    :global(.platform-desktop) & {
      &:hover {
        background-color: $greyXLight;
        svg * {
          fill: $brandBlackGrape;
        }
      }
      &:active {
        background-color: $greyXLight;
        border-color: $brandBlackGrape;
        svg * {
          fill: $brandBlackGrape;
        }
      }
    }
    :global(.platform-mobile) & {
      &:active {
        background-color: $greyXLight;
        border-color: $brandBlackGrape;
        svg * {
          fill: $brandBlackGrape;
        }
      }
    }
    &.active,
    &[aria-current='page'] {
      background-color: $greyXXLight;
      svg * {
        fill: $brandBerry;
      }
    }
    &.disabled {
      background-color: $mono4;
      svg * {
        fill: $mono1;
      }
    }
  }
  /* -------------------------------------------------------------- */
  /* Secondary - SVG path should use a 2px stroke and no fill */
  /* -------------------------------------------------------------- */
  &.secondary {
    background-color: $greyXLight;
    svg * {
      fill: none;
      stroke: $brandBlackGrape;
      stroke-width: 2px;
      @include fast-transition($props: 'stroke, fill');
    }
    &:focus {
      background-color: $greyXLight;
      &::after {
        border-color: $mono3;
      }
      svg * {
        stroke: $mono3;
      }
    }
    :global(.platform-desktop) & {
      &:hover {
        background-color: $greyXXLight;
        svg * {
          stroke: $mono3;
        }
      }
      &:active {
        background-color: $greyXLight;
        border-color: $brandBlackGrape;
        svg * {
          stroke: $brandBlackGrape;
        }
      }
    }
    :global(.platform-mobile) & {
      &:active {
        background-color: $greyXXLight;
        border-color: $brandBlackGrape;
        svg * {
          stroke: $brandBlackGrape;
        }
      }
    }
    &.active,
    &[aria-current='page'] {
      background-color: $brandBerry;
      svg * {
        fill: $white;
        stroke: $white;
      }
    }
    &.disabled {
      background-color: $mono4;
      svg * {
        stroke: $mono1;
      }
    }
  }
  /* -------------------------------------------------------------- */
  /* Active */
  /* -------------------------------------------------------------- */
  &.active,
  &[aria-current='page'] {
    pointer-events: none;
    svg {
      transform: scale(1.2);
    }
    &.secondary:focus::after {
      border-color: $white;
    }
  }
  /* -------------------------------------------------------------- */
  /* Disabled */
  /* -------------------------------------------------------------- */
  &.disabled {
    background-color: $mono4;
    cursor: default;
    pointer-events: none;
    &:focus::after {
      content: none;
    }
  }
}
