@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* -------------------------------------------------------------- */
/* Maturity Models */
/* -------------------------------------------------------------- */
.maturityModels {
  margin-bottom: 48px;
  @media (max-width: 1408px) {
    margin-bottom: 44px;
  }
  @media (max-width: 1024px) {
    margin-bottom: 40px;
  }
  @media (max-width: 767px) {
    margin-bottom: 32px;
  }
  @media (max-width: 375px) {
    margin-bottom: 24px;
  }
}
/* -------------------------------------------------------------- */
/* Maturity Model Item */
/* -------------------------------------------------------------- */
.maturityModelItem {
  border: 1px solid $grey;
  border-radius: 4px;
  color: $white;
  display: flex;
  flex-direction: column;
  padding: 28px 32px;
  position: relative;
  @media (max-width: 1536px) {
    padding: 24px 28px;
  }
  @media (max-width: 1280px) {
    padding: 20px 24px;
  }
  @media (max-width: 1152px) and (min-width: 768px), (max-width: 360px) {
    border: none;
    border-radius: 0;
    margin: 0 -32px;
  }
  @media (max-width: 1024px) and (min-width: 768px), (max-width: 360px) {
    margin: 0 -24px;
  }
  &:first-child {
    margin-bottom: 24px;
    @media (max-width: 1536px) {
      margin-bottom: 20px;
    }
    @media (max-width: 1280px) {
      margin-bottom: 16px;
    }
  }
  > * {
    position: relative;
    z-index: 1;
  }
  /* -------------------------------------------------------------- */
  /* Title */
  /* -------------------------------------------------------------- */
  h3 {
    color: $white;
    font-family: $secondary;
    font-size: 18px;
    font-weight: $semibold;
    line-height: 26px;
    margin-bottom: 14px;
    margin-top: -7px;
    text-transform: uppercase;
    @media (max-width: 1536px) {
      font-size: 17px;
      line-height: 24px;
      margin-bottom: 10px;
      margin-top: -6px;
    }
    @media (max-width: 1280px) {
      font-size: 16px;
      line-height: 22px;
      margin-bottom: 4px;
      margin-top: -5px;
    }
    @media (max-width: 1024px) {
      font-size: 15px;
      line-height: 21px;
    }
  }
  /* -------------------------------------------------------------- */
  /* Text */
  /* -------------------------------------------------------------- */
  p {
    font-size: 20px;
    line-height: 30px;
    margin: 0 0 auto;
    @media (max-width: 1536px) {
      font-size: 19px;
      line-height: 28px;
    }
    @media (max-width: 1280px) {
      font-size: 18px;
      line-height: 27px;
    }
    @media (max-width: 1024px) {
      font-size: 17px;
      line-height: 25px;
    }
  }
  /* -------------------------------------------------------------- */
  /* Slice Artwork */
  /* -------------------------------------------------------------- */
  &::after {
    animation: fade-slice 0.35s ease-in-out;
    background-image: url('/images/artwork/post-slice.svg');
    background-repeat: no-repeat;
    background-size: cover;
    bottom: -1px;
    content: '';
    height: auto;
    opacity: 0.2;
    position: absolute;
    right: 0;
    top: -1px;
    width: 52.5%;
    z-index: 0;
  }
  /* -------------------------------------------------------------- */
  /* Logo / Button Container */
  /* -------------------------------------------------------------- */
  > div {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: -8px;
    padding-top: 41px;
    @media (max-width: 1664px) {
      padding-top: 37px;
    }
    @media (max-width: 1536px) and (min-width: 768px), (max-width: 480px) {
      align-items: flex-start;
      flex-direction: column;
      padding-top: 34px;
    }
    @media (max-width: 1408px) {
      padding-top: 30px;
    }
    @media (max-width: 1280px) {
      padding-top: 26px;
    }
    @media (max-width: 1024px) {
      padding-top: 22px;
    }
  }
  /* -------------------------------------------------------------- */
  /* Link */
  /* -------------------------------------------------------------- */
  a {
    max-width: 175px;
    @media (max-width: 1024px) and (min-width: 768px), (max-width: 360px) {
      padding: 0 7px 0 24px;
    }
  }
  /* -------------------------------------------------------------- */
  /* Logo / Link Container */
  /* -------------------------------------------------------------- */
  > div > svg,
  > div > div {
    margin: 8px;
  }
  /* -------------------------------------------------------------- */
  /* P3M3 */
  /* -------------------------------------------------------------- */
  &.p3m3 {
    background-color: $productProPath;
    /* -------------------------------------------------------------- */
    /* Logo */
    /* -------------------------------------------------------------- */
    > div > svg {
      height: 49px;
      width: 188px;
      @media (max-width: 1664px) {
        height: 40px;
        width: 153px;
      }
    }
  }
  /* -------------------------------------------------------------- */
  /* ITIL */
  /* -------------------------------------------------------------- */
  &.itil {
    background-color: $productItil;
    /* -------------------------------------------------------------- */
    /* Logo */
    /* -------------------------------------------------------------- */
    > div > svg {
      height: 49px;
      width: 147px;
      @media (max-width: 1664px) {
        height: 40px;
        width: 118px;
      }
    }
  }
  &.loading {
    button {
      > span:nth-child(1) {
        color: transparent;
      }
      svg:nth-child(3) {
        visibility: hidden;
      }
    }
  }
  .spinner {
    left: 0;
    margin-left: auto;
    margin-right: auto;
    position: absolute;
    right: -13px;
  }
}

@keyframes fade-slice {
  from {
    opacity: 0;
  }
}
