@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
html,
body {
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  text-rendering: optimizelegibility;
  -webkit-text-size-adjust: 100%;
  color: #282d3d;
  font-family: Montserrat, sans-serif;
  font-size: 17px;
  line-height: 1.25;
}

#myAxelosResetPassword,
#root {
  display: flex;
  flex-grow: 1;
  @media (max-width: 767px) {
    flex-direction: column;
  }
}

.container {
  box-sizing: border-box;
  margin: 0 auto;
  max-width: 1600px;
  padding: 0 96px;
  min-width: 320px;
  width: 100%;
  @media (max-width: 1536px) {
    padding: 0 88px;
  }
  @media (max-width: 1408px) {
    padding: 0 80px;
  }
  @media (max-width: 1280px) {
    padding: 0 72px;
  }
  @media (max-width: 1152px) {
    padding: 0 64px;
  }
  @media (max-width: 1024px) {
    padding: 0 56px;
  }
  @media (max-width: 896px) {
    padding: 0 48px;
  }
  @media (max-width: 832px) {
    padding: 0 40px;
  }
  @media (max-width: 375px) {
    padding: 0 32px;
  }
}

.myAxelosIntro {
  align-items: center;
  box-sizing: border-box;
  display: flex;
  position: relative;
  width: 42.5%;
  @media (max-width: 767px) {
    padding: 40px 0;
    width: 100%;
  }
  @media (max-width: 375px) {
    padding: 32px 0;
  }
  .image {
    background-color: $brandBlackGrape;
    background-image: url('/images/artwork/bg-signin.jpg');
    background-position: 100% 50%;
    background-repeat: no-repeat;
    background-size: cover;
    bottom: 0;
    left: calc(calc(calc(calc(100vw - 1600px) / 2) + 96px) * -1);
    position: absolute;
    right: 0;
    top: 0;
    @media (max-width: 1600px) {
      left: -96px;
    }
    @media (max-width: 1536px) {
      left: -88px;
    }
    @media (max-width: 1408px) {
      left: -80px;
    }
    @media (max-width: 1280px) {
      left: -72px;
    }
    @media (max-width: 1152px) {
      left: -64px;
    }
    @media (max-width: 1024px) {
      left: -56px;
    }
    @media (max-width: 896px) {
      left: -48px;
    }
    @media (max-width: 832px) {
      left: -40px;
    }
    @media (max-width: 767px) {
      background-position: 100% 25%;
      right: -40px;
    }
    @media (max-width: 375px) {
      left: -32px;
      right: -32px;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Container */
  /* ---------------------------------------------------------------- */
  > div {
    display: flex;
    flex-direction: column;
    max-width: 430px;
    padding-right: 96px;
    position: relative;
    width: 100%;
    @media (max-width: 1536px) {
      padding-right: 88px;
    }
    @media (max-width: 1408px) {
      padding-right: 80px;
    }
    @media (max-width: 1280px) {
      padding-right: 72px;
    }
    @media (max-width: 1152px) {
      padding-right: 64px;
    }
    @media (max-width: 1024px) {
      padding-right: 56px;
    }
    @media (max-width: 896px) {
      padding-right: 48px;
    }
    @media (max-width: 832px) {
      padding-right: 40px;
    }
    @media (max-width: 767px) {
      padding-right: 0;
    }
    /* ---------------------------------------------------------------- */
    /* Title */
    /* ---------------------------------------------------------------- */
    h1 {
      color: $white;
      font-family: 'Montserrat', sans-serif;
      font-size: 32px;
      line-height: 39px;
      margin: -8px 0;
      @media (max-width: 1536px) {
        font-size: 30px;
        line-height: 37px;
      }
      @media (max-width: 1280px) {
        font-size: 28px;
        line-height: 35px;
      }
      @media (max-width: 1024px) {
        font-size: 26px;
        line-height: 32px;
        margin: -6px 0;
      }
      @media (max-width: 896px) {
        font-size: 25px;
        line-height: 31px;
      }
      @media (max-width: 832px) {
        font-size: 24px;
        line-height: 30px;
      }
      @media (max-width: 375px) {
        font-size: 21px;
        line-height: 27px;
      }
      span {
        color: $brandAqua;
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Background Slice */
  /* ---------------------------------------------------------------- */
  .backgroundSlice {
    background-image: url('/images/artwork/bg-slice.svg');
    background-repeat: no-repeat;
    background-size: cover;
    bottom: 0;
    height: 600px;
    max-width: 303px;
    pointer-events: none;
    position: absolute;
    right: 0;
    width: 100%;
    z-index: 2;
    @media (max-width: 767px) {
      bottom: auto;
      right: -40px;
      top: 0;
    }
    @media (max-width: 375px) {
      right: -32px;
    }
  }
  .pcLoginInfo {
    color: $white;
    font-size: 14px;
    line-height: 1.5;
    margin: 30px 0;
    text-align: justify;

    a,
    a:active {
      color: #6de2e2;
      &:hover {
        color: inherit;
      }
    }
  }
}

.myAxelosForm {
  align-items: center;
  background-color: #f9f9f9;
  box-sizing: border-box;
  display: flex;
  flex: 1;
}

.myAxelosForm {
  align-items: center;
  background-color: $pageBackground;
  box-sizing: border-box;
  display: flex;
  flex: 1;
  @media (max-width: 767px) {
    align-items: flex-start;
  }
  /* ---------------------------------------------------------------- */
  /* Container */
  /* ---------------------------------------------------------------- */
  .formContainer {
    margin: 0 0 0 auto;
    max-width: 576px;
    position: relative;
    text-align: center;
    width: 100%;
    @media (max-width: 1920px) {
      margin: 0 auto;
      padding-left: calc(calc(calc(100vw - 1600px) / 2) + 96px);
    }
    @media (max-width: 1600px) {
      padding-left: 96px;
    }
    @media (max-width: 1536px) {
      max-width: 512px;
      padding-left: 88px;
    }
    @media (max-width: 1408px) {
      padding-left: 80px;
    }
    @media (max-width: 1280px) {
      max-width: 448px;
      padding-left: 72px;
    }
    @media (max-width: 1152px) {
      padding-left: 64px;
    }
    @media (max-width: 1024px) {
      padding-left: 56px;
    }
    @media (max-width: 896px) {
      padding-left: 48px;
    }
    @media (max-width: 832px) {
      padding-left: 40px;
    }
    @media (max-width: 767px) {
      padding: 40px 0;
      text-align: start;
    }
    @media (max-width: 375px) {
      padding: 32px 0;
    }
    /* ---------------------------------------------------------------- */
    /* Heading */
    /* ---------------------------------------------------------------- */
    h2 {
      color: $brandBlackGrape;
      font-size: 32px;
      line-height: 39px;
      margin: -7px 0;
      text-align: center;
      @media (max-width: 1536px) {
        font-size: 30px;
        line-height: 37px;
      }
      @media (max-width: 1280px) {
        font-size: 28px;
        line-height: 35px;
      }
      @media (max-width: 1024px) {
        font-size: 26px;
        line-height: 32px;
      }
      @media (max-width: 896px) {
        font-size: 25px;
        line-height: 31px;
      }
      @media (max-width: 832px) {
        font-size: 24px;
        line-height: 30px;
      }
      @media (max-width: 375px) {
        font-size: 21px;
        line-height: 27px;
      }
      + h3 {
        padding-top: 48px;
        @media (max-width: 1536px) {
          padding-top: 40px;
        }
        @media (max-width: 1280px) {
          padding-top: 32px;
        }
        @media (max-width: 767px) {
          padding-top: 24px;
        }
        @media (max-width: 375px) {
          padding-top: 20px;
        }
      }
    }
    h3 {
      color: $brandBlackGrape;
      display: none;
      font-size: 18px;
      line-height: 23px;
      margin: -6px 0;
      text-align: center;
      @media (max-width: 1536px) {
        font-size: 17px;
        line-height: 22px;
      }
      @media (max-width: 1280px) {
        font-size: 16px;
        line-height: 21px;
      }
      @media (max-width: 767px) {
        font-size: 15px;
        line-height: 20px;
      }
    }
    /* ---------------------------------------------------------------- */
    /* Hide Intro (Please provide the following details.) */
    /* ---------------------------------------------------------------- */
    .intro {
      display: none;
    }
    .helpLink {
      display: none;
    }
    /* ---------------------------------------------------------------- */
    /* Logo */
    /* ---------------------------------------------------------------- */
    svg {
      height: 59px;
      // margin: 48px 0 64px;
      margin: 0 auto;
      margin-bottom: 36px;
      width: 222px;
      @media (max-width: 1536px) {
        height: 57px;
        // margin: 40px 0 56px;
        width: 209px;
      }
      @media (max-width: 1408px) {
        // margin: 40px 0 52px;
      }
      @media (max-width: 1280px) {
        height: 55px;
        // margin: 32px 0 48px;
        width: 202px;
      }
      @media (max-width: 1152px) {
        // margin: 32px 0 44px;
      }
      @media (max-width: 1024px) {
        // margin: 32px 0 40px;
      }
      @media (max-width: 767px) {
        height: 40px;
        // margin: 24px 0 32px;
        width: 147px;
      }
      @media (max-width: 375px) {
        height: 35px;
        // margin: 20px 0 28px;
        width: 128px;
      }
    }
  }

  .entry {
    display: block !important;
    input {
      padding: 0px 10px;
    }
  }

  .buttons button,
  .btn-register {
    align-items: center;
    border: 1px solid transparent;
    border-radius: 11px;
    box-sizing: border-box;
    cursor: pointer;
    display: inline-flex;
    flex-shrink: 0;
    font-family: 'Open Sans', sans-serif;
    font-size: 19px;
    height: 64px;
    justify-content: center;
    line-height: normal;
    padding: 0 48px;
    position: relative;
    touch-action: manipulation;
    vertical-align: top;
    white-space: nowrap;
    @include fast-transition($props: 'background-color, color');
    @include no-user-select;
    @media (max-width: 1280px) {
      font-size: 18px;
      height: 56px;
      padding: 0 40px;
    }
    @media (max-width: 767px) {
      font-size: 17px;
      height: 48px;
      padding: 0 28px;
    }
    &:focus {
      text-decoration: underline;
    }
  }

  .buttonSubmit {
    display: flex;
  }

  .submitOtpButtons {
    justify-content: space-between !important;

    @media (max-width: 576px) {
      flex-direction: column;
      gap: 10px;
    }
  }

  .next {
    background-color: $brandAqua;
    color: $brandBlackGrape;
    font-weight: $bold;
    width: 266px;
    align-items: center;
    @media (max-width: 1280px) {
      width: 232px;
    }
    @media (max-width: 767px) {
      width: 192px;
    }
    @media (max-width: 375px) {
      width: 176px;
    }
    &:focus {
      background-color: $brandAquaDark;
    }
    .platform-desktop & {
      &:hover {
        background-color: $brandAquaLight;
      }
      &:active {
        background-color: $brandAquaDark;
      }
    }
    .platform-mobile & {
      &:active {
        background-color: $brandAquaLight;
      }
    }
  }

  .buttons {
    margin-top: 64px;
    display: flex;
    justify-content: space-between;
    @media (max-width: 1536px) {
      margin-top: 56px;
    }
    @media (max-width: 1408px) {
      margin-top: 52px;
    }
    @media (max-width: 1280px) {
      margin-top: 48px;
    }
    @media (max-width: 1152px) {
      margin-top: 44px;
    }
    @media (max-width: 1024px) {
      margin-top: 40px;
    }
    @media (max-width: 767px) {
      margin-top: 32px;
    }
    @media (max-width: 375px) {
      margin-top: 28px;
    }
  }

  label {
    align-self: flex-start;
    color: #282d3d;
    cursor: pointer;
    display: table;
    font-family: Open Sans, sans-serif;
    font-size: 20px;
    left: 1px;
    line-height: 1;
    margin: -2px 0 10px;
    touch-action: manipulation;
    @media (max-width: 1536px) {
      font-size: 19px;
      margin-bottom: 9px;
    }
    @media (max-width: 1280px) {
      font-size: 18px;
      margin-bottom: 8px;
    }
    @media (max-width: 1024px) {
      font-size: 17px;
      margin-bottom: 6px;
    }
  }

  input {
    -webkit-touch-callout: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: #fff;
    border: 1px solid #c4c5ca;
    border-radius: 9px;
    box-sizing: border-box;
    caret-color: #282d3d;
    color: #282d3d;
    display: inline-block;
    filter: none;
    font-family: Open Sans, sans-serif;
    font-size: 18px;
    font-weight: 400;
    height: 56px;
    letter-spacing: normal;
    line-height: normal;
    order: 1;
    outline: none;
    padding: 0 18px;
    resize: none;
    transition-duration: 0.175s;
    transition-property: border-color, background-color;
    transition-timing-function: ease-in-out;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    vertical-align: middle;
    width: 100%;
    @include no-user-select;
    @include fast-transition($props: 'border-color, background-color');
    &:invalid {
      box-shadow: none;
    }
    &:-webkit-autofill {
      background: $white;
      transition: background-color 10000s ease-in-out 0s;
    }
    &:-moz-autofill {
      background: #fff;
    }
    &:-moz-autofill-preview {
      background: #fff;
    }
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active,
    &:-moz-autofill,
    &:-moz-autofill-preview {
      -webkit-box-shadow: 0 0 0 30px white inset;
    }
    &:-webkit-autofill::first-line {
      font-size: inherit;
    }
    &::-ms-clear {
      display: none;
      height: 0;
      width: 0;
    }
    &::-webkit-contacts-auto-fill-button {
      display: none !important;
      pointer-events: none;
      position: absolute;
      right: 0;
      visibility: hidden;
    }
    &::-webkit-credentials-auto-fill-button {
      display: none;
      visibility: hidden;
    }
    &::-webkit-caps-lock-indicator {
      display: none;
      visibility: hidden;
    }
    &::-ms-reveal {
      display: none;
      height: 0;
      width: 0;
    }
    &::-webkit-clear-button {
      display: none;
    }
    &::-webkit-search-cancel-button {
      display: none;
    }
    @media (max-width: 1024px) {
      font-size: 17px;
      height: 56px;
      padding: 0 18px;
    }
  }

  input:focus {
    border-color: #282d3d;
  }

  .myAxelosForm svg {
    height: 59px;
    // margin: 48px 0 64px;
    margin: 0 auto;
    width: 222px;
    @media (max-width: 1536px) {
      height: 57px;
      // margin: 40px 0 56px;
      width: 209px;
    }
    @media (max-width: 1408px) {
      // margin: 40px 0 52px;
    }
    @media (max-width: 1280px) {
      height: 55px;
      // margin: 32px 0 48px;
      width: 202px;
    }
    @media (max-width: 1152px) {
      // margin: 32px 0 44px;
    }
    @media (max-width: 1024px) {
      // margin: 32px 0 40px;
    }
    @media (max-width: 767px) {
      height: 40px;
      // margin: 24px 0 32px;
      width: 147px;
    }
    @media (max-width: 375px) {
      height: 35px;
      // margin: 20px 0 28px;
      width: 128px;
    }
  }

  .entryItem {
    margin-top: 30px;
  }

  .entryItemLeftButton {
    margin-top: 30px;
    text-align: left;
  }

  #api,
  .buttons {
    margin-top: 64px;
    display: flex;
    @media (max-width: 1536px) {
      margin-top: 56px;
    }
    @media (max-width: 1408px) {
      margin-top: 52px;
    }
    @media (max-width: 1280px) {
      margin-top: 48px;
    }
    @media (max-width: 1152px) {
      margin-top: 44px;
    }
    @media (max-width: 1024px) {
      margin-top: 40px;
    }
    @media (max-width: 767px) {
      margin-top: 32px;
    }
    @media (max-width: 375px) {
      margin-top: 28px;
    }
  }

  .wrapper {
    position: relative;
  }

  .wrapper span {
    position: absolute;
    right: 18px;
    top: 20px;
  }

  #forgotPassword {
    color: #282d3d;
    cursor: pointer;
    font-family: Open Sans, sans-serif;
    font-size: 17px;
    line-height: 22px;
    margin-top: -10px;
    right: 0px;
    position: absolute;
    text-decoration: none;
    top: calc(100% + 16px);
    transition-duration: 0.175s;
    transition-property: opacity, color;
    transition-timing-function: ease-in-out;
    @media (max-width: 1536px) {
      font-size: 16px;
    }
    @media (max-width: 1280px) {
      font-size: 15px;
      margin-top: -10px;
    }
  }

  .reveal {
    background-image: url('/images/artwork/reveal.svg');
    background-repeat: no-repeat;
    background-size: contain;
    cursor: pointer;
    display: block;
    height: 16px;
    opacity: 1;
    position: absolute;
    right: 18px;
    // top: 48px;
    touch-action: manipulation;
    width: 26px;
    @include fast-transition($props: 'background-color, opacity');
    @media (max-width: 1536px) {
      top: 46px;
    }
    @media (max-width: 1280px) {
      top: 44px;
    }
    @media (max-width: 1024px) {
      top: 41px;
    }
    .platform-desktop & {
      &:hover {
        opacity: 0.7;
      }
    }
  }

  .revealActive {
    background-image: url('/images/artwork/reveal-hover.svg');
    background-repeat: no-repeat;
    background-size: contain;
    cursor: pointer;
    display: block;
    height: 16px;
    opacity: 1;
    position: absolute;
    right: 18px;
    // top: 48px;
    touch-action: manipulation;
    width: 26px;
    @include fast-transition($props: 'background-color, opacity');
    @media (max-width: 1536px) {
      top: 46px;
    }
    @media (max-width: 1280px) {
      top: 44px;
    }
    @media (max-width: 1024px) {
      top: 41px;
    }
    .platform-desktop & {
      &:hover {
        opacity: 0.7;
      }
    }
  }

  // .create {
  //   margin-top: 15px;
  // }

  .register {
    color: #282d3d;
    cursor: default;
    display: block !important;
    font-family: 'Open Sans', sans-serif;
    font-size: 17px;
    line-height: 22px;
    text-align: center;
    //position: absolute;
    margin-top: 15px;
    text-decoration: none;
    top: calc(100% + 16px);
    transition-duration: 0.175s;
    transition-property: opacity, color;
    transition-timing-function: ease-in-out;
  }
}
.register a {
  color: #6de2e2;
}

.verifyText {
  display: flex;
  margin: 17px 0px;
}

.sendNewCodeDiv {
  display: block;
  align-self: center;
}

.sendNewCode {
  cursor: pointer;
  &:hover {
    color: darken(red, 10%);
  }
}

.confirmPassword {
  margin: 15px 0px;
}

.errorMsg {
  color: red;
  font-size: 14px;
}

.errorMsgDiv {
  display: flex;
  margin: 13px 0px;
}

.passwordFormat {
  margin-top: 30px;
  font-family: Open Sans, sans serif;
  text-align: left;
  line-height: 1.2;
}

div.passwordFormat ul {
  list-style-type: disc;
  margin-left: 30px;
  margin-top: 10px;
}

div.passwordFormat p {
  margin-top: 10px;
}

.wrapper {
  position: relative;
  border: none;
}

.wrapper span {
  position: absolute;
  right: 18px;
  top: 20px;
}

.reveal:hover {
  opacity: 0.7;
}

.revealActive:hover {
  opacity: 0.7;
}

.revealActive {
  background-image: url('/images/artwork/reveal-hover.svg');
  background-repeat: no-repeat;
  background-size: contain;
  cursor: pointer;
  display: block;
  height: 16px;
  opacity: 1;
  position: absolute;
  right: 18px;
  // top: 48px;
  touch-action: manipulation;
  width: 26px;
  @include fast-transition($props: 'background-color, opacity');
  @media (max-width: 1536px) {
    top: 46px;
  }
  @media (max-width: 1280px) {
    top: 44px;
  }
  @media (max-width: 1024px) {
    top: 41px;
  }
  .platform-desktop & {
    &:hover {
      opacity: 0.7;
    }
  }
}

.textLeft {
  text-align: left;
}

// .buttonNew {

//   padding: 8px 16px;
//   background: #009579;
//   border: none;
//   outline: none;
//   border-radius: 2px;
//   cursor: pointer;
// }

.buttonNew,
.buttonLoading {
  align-items: center;
  position: absolute;
  background-color: $brandAqua;
  color: $brandBlackGrape;
  font-weight: $bold;
  width: 266px;
  border: 1px solid transparent;
  border-radius: 11px;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-flex;
  flex-shrink: 0;
  font-family: 'Open Sans', sans-serif;
  font-size: 19px;
  height: 64px;
  justify-content: center;
  line-height: normal;
  padding: 0 48px;
  position: relative;
  touch-action: manipulation;
  vertical-align: top;
  white-space: nowrap;
  @include fast-transition($props: 'background-color, color');
  @include no-user-select;
  @media (max-width: 1280px) {
    font-size: 18px;
    height: 56px;
    padding: 0 40px;
  }
  @media (max-width: 767px) {
    font-size: 17px;
    height: 48px;
    padding: 0 28px;
  }
  @media (max-width: 576px) {
    width: 100% !important;
  }
  @media (max-width: 1024px) and (min-width: 577px) {
    width: auto !important;
  }
}

.buttonNewVerifyEmail,
.buttonLoadingVerifyEmail {
  align-items: center;
  position: absolute;
  background-color: $brandAqua;
  color: $brandBlackGrape;
  font-weight: $bold;
  width: 335px;
  border: 1px solid transparent;
  border-radius: 11px;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-flex;
  flex-shrink: 0;
  font-family: 'Open Sans', sans-serif;
  font-size: 19px;
  height: 64px;
  justify-content: center;
  line-height: normal;
  padding: 0 48px;
  position: relative;
  touch-action: manipulation;
  vertical-align: top;
  white-space: nowrap;

  @include fast-transition($props: 'background-color, color');
  @include no-user-select;

  @media (max-width: 1280px) {
    font-size: 18px;
    height: 56px;
    padding: 0 40px;
  }

  @media (max-width: 767px) {
    font-size: 17px;
    height: 48px;
    padding: 0 28px;
  }

  @media (max-width: 576px) {
    width: 100% !important;
  }

  @media (max-width: 1024px) and (min-width: 577px) {
    width: auto !important;
  }
}

.buttonLoading:active {
  background: $brandAqua;
}

.buttonNew:active {
  background: $brandAqua;
}

.button__text {
  color: $brandBlackGrape;
  transition: all 0.2s;
  padding-top: auto;
}

.buttonLoading::after,
.buttonLoadingVerifyEmail::after {
  background: $brandAqua;
  color: #ffffff;
  content: '';
  position: relative;
  width: 16px;
  height: 16px;
  border: 4px solid transparent;
  border-top-color: #ffffff;
  border-radius: 50%;
  animation: buttonLoading-spinner 1s ease infinite;
}

@keyframes buttonLoading-spinner {
  from {
    transform: rotate(0turn);
  }

  to {
    transform: rotate(1turn);
  }
}
