@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Buttons */
/* ---------------------------------------------------------------- */
.buttons {
  background-color: $pageBackground !important;
  display: block;
  p {
    font-family: $primary;
    font-size: 16px;
    font-weight: $semibold;
    margin: 16px 0 12px;
    text-decoration: underline;
  }
  > div {
    margin: 10px 0 -10px;
    > button {
      margin-bottom: 10px;
    }
    /* ---------------------------------------------------------------- */
    /* Icon Link */
    /* ---------------------------------------------------------------- */
    a {
      margin: 0 10px 10px 0;
    }
    /* ---------------------------------------------------------------- */
    /* Buttons - Secondary Large / Secondary Small / Icon */
    /* ---------------------------------------------------------------- */
    &:nth-of-type(3),
    &:nth-of-type(4),
    &:nth-of-type(5),
    &:nth-of-type(6) {
      > button {
        margin-right: 10px;
      }
    }
    /* ---------------------------------------------------------------- */
    /* Buttons - Minimum Width */
    /* ---------------------------------------------------------------- */
    &:nth-of-type(7) {
      > button {
        min-width: 240px;
        &:last-child {
          margin-left: 10px;
        }
      }
    }
    /* ---------------------------------------------------------------- */
    /* Buttons - Disabled */
    /* ---------------------------------------------------------------- */
    &:nth-of-type(8) {
      > button {
        &:nth-child(5),
        &:nth-child(6),
        &:nth-child(7),
        &:nth-child(8),
        &:nth-child(9),
        &:nth-child(10) {
          margin-left: 5px;
          margin-right: 5px;
        }
        &:last-child,
        &:nth-last-child(2) {
          min-width: 160px;
        }
        &:last-child {
          margin-left: 10px;
        }
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* SVG */
  /* ---------------------------------------------------------------- */
  svg[data-name='bookmark-primary'] {
    height: 17px;
    width: 12px;
  }
  svg[data-name='bookmark-secondary'] {
    height: 17px;
    width: 12px;
  }
}
