@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* -------------------------------------------------------------- */
/* Header */
/* -------------------------------------------------------------- */
.header {
  

  @media (max-width: 1535px) {
    margin-top: 100px;
  }
  @media (max-width: 1023px) {
    margin-top: 140px;
  }
  @media (max-width: 767px) {
    margin-top: 90px;
  }
  @media (width: 540px) {
    margin-top: 180px;
  }
  @media (max-width: 539px) {
    margin-top: 230px;
  }
  @media (max-width: 375px) {
    margin-top: 280px;
  }
  @media (max-width: 360px) {
    margin-top: 290px;
  }

  background-color: $white;
  box-shadow: 0 0 6px rgba($black, 0.15);
  display: block;
  flex-shrink: 0;
  height: 96px;
  position: relative;
  width: 100%;
  z-index: 99;
  @media (max-width: 1280px) {
    height: 88px;
  }
  @media (max-width: 767px) {
    height: 72px;
  }
  /* -------------------------------------------------------------- */
  /* Container */
  /* -------------------------------------------------------------- */
  > div {
    align-items: center;
    display: flex;
    height: 100%;
    position: relative;
    z-index: 2;
  }
  /* ---------------------------------------------------------------- */
  /* Bookmarks Link */
  /* ---------------------------------------------------------------- */
  .bookmark {
    margin-right: 20px;
    @media (max-width: 1280px) and (min-width: 897px), (max-width: 375px) {
      margin-right: 16px;
    }
    @media (max-width: 896px) and (min-width: 768px) {
      margin-right: 12px;
    }
    @media (max-width: 767px) {
      margin-left: auto;
    }
    /* ---------------------------------------------------------------- */
    /* Icon */
    /* ---------------------------------------------------------------- */
    svg {
      height: 17px;
      width: 12px;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Sign In Button */
  /* ---------------------------------------------------------------- */
  .signIn {
    height: 48px;
    margin-left: auto;
    padding: 0 32px;
    @media (max-width: 1024px) {
      height: 44px;
      padding: 0 24px;
    }
    @media (max-width: 767px) {
      height: 40px;
      padding: 0 16px;
    }
    /* ---------------------------------------------------------------- */
    /* Text */
    /* ---------------------------------------------------------------- */
    span {
      font-size: 16px;
      font-weight: $semibold;
    }
    /* ---------------------------------------------------------------- */
    /* Icon */
    /* ---------------------------------------------------------------- */
    svg {
      height: 15px;
      margin-left: 31px;
      width: 9px;
      path {
        fill: $brandBlackGrape;
      }
    }
  }
  &.menuAnimating {
    &::before {
      background-color: rgba($black, 0.5);
    }
  }

  nav > ul {
    overflow: auto;

    @media (width: 540px) {
      margin-top: 180px !important;
    }
    @media (max-width: 539px) {
      margin-top: 230px !important;
    }
    @media (max-width: 375px) {
      margin-top: 280px !important;
    }
    @media (max-width: 360px) {
      margin-top: 290px !important;
    }
  }
}

.infoBanner {
  display: none;
  position: fixed;
  height: 60px;
  top: 0;
  width: auto;
  z-index: 100;

  color: #fff;
  padding: 10px 20px;
  background: #ff3200;
  font-size: 14px;
  overflow: auto;
  text-align: justify;

  @media (min-width: 1920px) {
    text-align: center;
  }
  @media (max-width: 1535px) {
    height: 80px;
  }
  @media (max-width: 1023px) {
    height: 120px;
  }
  @media (max-width: 767px) {
    height: 70px;
    padding: 10px;
  }
  @media (width: 540px) {
    height: 160px;
    padding: 10px;
  }
  @media (max-width: 539px) {
    height: 210px;
    padding: 10px;
  }
  @media (max-width: 375px) {
    height: 280px;
    padding: 10px;
  }
  @media (max-width: 360px) {
    height: 270px;
    padding: 10px;
  }

  a {
    color: #fff;
    font-weight: 800;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
}
