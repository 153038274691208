@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Contents Heading */
/* ---------------------------------------------------------------- */
.contents-heading {
  display: block;
  margin: 56px 0 48px;
  @media (max-width: 1536px) {
    margin: 48px 0 40px;
  }
  @media (max-width: 1280px) {
    margin: 40px 0 32px;
  }
  @media (max-width: 1024px) {
    margin: 32px 0 24px;
  }
  @media (max-width: 375px) {
    margin: 24px 0 16px;
  }
  + div {
    margin-top: 48px;
    @media (max-width: 1536px) {
      margin-top: 40px;
    }
    @media (max-width: 1280px) {
      margin-top: 32px;
    }
    @media (max-width: 1024px) {
      margin-top: 24px;
    }
    @media (max-width: 375px) {
      margin-top: 22px;
    }
  }
  h2 {
    border-bottom: 1px solid $brandBlackGrape;
    display: flex;
    font-size: 32px;
    font-weight: $bold;
    line-height: 40px;
    margin: -10px 0 -9px;
    padding: 0 0 20px;
    scroll-margin-top: calc(96px + 20px);
    width: 100%;
    @include medium-transition($mob: true, $props: 'border-color');
    @media (max-width: 1536px) {
      font-size: 28px;
      line-height: 36px;
      margin: -9px 0 -8px;
      padding: 0 0 18px;
    }
    @media (max-width: 1280px) {
      font-size: 24px;
      line-height: 32px;
      margin: -8px 0 -7px;
      padding: 0 0 16px;
      scroll-margin-top: calc(80px + 20px);
    }
    @media (max-width: 375px) {
      font-size: 22px;
      line-height: 30px;
      margin: -7px 0 -6px;
      padding: 0 0 15px;
      scroll-margin-top: calc(72px + 20px);
    }
    &:target {
      border-color: $brandBerry;
      border-width: 2px;
    }
  }
}
