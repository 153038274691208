@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* -------------------------------------------------------------- */
/* Publications Item */
/* -------------------------------------------------------------- */
.publicationsItem {
  background-color: $white;
  border: 1px solid $grey;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  min-height: 133px;
  padding: 28px 32px 28px 164px;
  position: relative;
  @media (max-width: 1536px) {
    min-height: 0;
    padding: 24px 28px;
  }
  @media (max-width: 1280px) {
    padding: 20px 24px;
  }
  @media (max-width: 1152px) and (min-width: 768px), (max-width: 360px) {
    background-color: transparent;
    border-radius: 0;
    border-width: 0 0 1px;
    padding: 20px 0;
  }
  /* -------------------------------------------------------------- */
  /* Image / Title / Description Container */
  /* -------------------------------------------------------------- */
  > div:nth-child(1) {
    margin-bottom: 36px;
    @media (max-width: 1536px) {
      margin-bottom: 32px;
      min-height: 125px;
      padding-left: 124px;
      position: relative;
    }
    @media (max-width: 1280px) {
      margin-bottom: 28px;
      min-height: 122px;
      padding-left: 116px;
    }
    @media (max-width: 1024px) {
      margin-bottom: 24px;
    }
    @media (max-width: 1024px) and (min-width: 768px), (max-width: 360px) {
      display: flex;
      flex-wrap: wrap;
      min-height: 0;
      padding-left: 0;
    }
    /* -------------------------------------------------------------- */
    /* Title */
    /* -------------------------------------------------------------- */
    > div:nth-of-type(1) {
      margin: 0;
    }
    /* -------------------------------------------------------------- */
    /* Description */
    /* -------------------------------------------------------------- */
    > div:nth-of-type(2) {
      margin: 0;
    }
  }
  /* -------------------------------------------------------------- */
  /* Image */
  /* -------------------------------------------------------------- */
  img {
    height: 133px;
    left: 32px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100px;
    @media (max-width: 1536px) {
      height: 125px;
      left: 0;
      width: 96px;
    }
    @media (max-width: 1280px) {
      height: 122px;
      width: 92px;
    }
    @media (max-width: 1024px) and (min-width: 768px), (max-width: 360px) {
      height: 117px;
      position: relative;
      top: auto;
      transform: none;
      width: 88px;
    }
  }
  /* -------------------------------------------------------------- */
  /* Title */
  /* -------------------------------------------------------------- */
  h4 {
    color: $brandBlackGrape;
    font-family: $primary;
    font-size: 18px;
    font-weight: $bold;
    line-height: 22px;
    margin-bottom: 15px;
    margin-top: -4px;
    @media (max-width: 1536px) {
      font-size: 17px;
      line-height: 21px;
      margin-bottom: 12px;
    }
    @media (max-width: 1280px) {
      font-size: 16px;
      line-height: 20px;
    }
    @media (max-width: 1024px) {
      font-size: 15px;
      line-height: 19px;
    }
    @media (max-width: 1024px) and (min-width: 768px), (max-width: 360px) {
      box-sizing: border-box;
      margin-bottom: -4px;
      padding-left: 16px;
      width: calc(100% - 92px);
    }
    &:last-child {
      margin-bottom: -4px;
    }
  }
  /* -------------------------------------------------------------- */
  /* Description */
  /* -------------------------------------------------------------- */
  p {
    font-size: 17px;
    line-height: 21px;
    margin: 0 0 -4px;
    @media (max-width: 1536px) {
      font-size: 16px;
      line-height: 20px;
    }
    @media (max-width: 1280px) {
      font-size: 15px;
      line-height: 19px;
    }
    @media (max-width: 1024px) {
      font-size: 14px;
      line-height: 18px;
    }
    @media (max-width: 1024px) and (min-width: 768px), (max-width: 360px) {
      margin-top: -4px;
      padding-top: 16px;
    }
  }
  /* -------------------------------------------------------------- */
  /* Price / Cta Container */
  /* -------------------------------------------------------------- */
  > div:nth-child(2) {
    display: flex;
    @media (max-width: 832px) and (min-width: 768px), (max-width: 350px) {
      flex-direction: column;
    }
    /* -------------------------------------------------------------- */
    /* Price Container */
    /* -------------------------------------------------------------- */
    > div {
      padding: 0 16px 0 0;
      @media (max-width: 832px) and (min-width: 768px), (max-width: 350px) {
        align-items: center;
        display: flex;
        margin: -5px 0 -4px;
        padding: 0;
      }
      @media (max-width: 767px) {
        padding-left: 116px;
      }
      @media (max-width: 440px) {
        padding-left: 0;
      }
    }
  }
  /* -------------------------------------------------------------- */
  /* Price Title */
  /* -------------------------------------------------------------- */
  h5 {
    color: rgba($brandBlackGrape, 0.7);
    font-family: $primary;
    font-size: 17px;
    font-weight: $bold;
    line-height: 21px;
    margin: -4px 0 8px;
    @media (max-width: 1536px) {
      font-size: 16px;
      line-height: 20px;
    }
    @media (max-width: 1280px) {
      font-size: 15px;
      line-height: 19px;
    }
    @media (max-width: 1024px) {
      font-size: 14px;
      line-height: 18px;
    }
    @media (max-width: 832px) and (min-width: 768px), (max-width: 350px) {
      line-height: 19px;
      margin: 0;
    }
  }
  /* -------------------------------------------------------------- */
  /* Price Value */
  /* -------------------------------------------------------------- */
  > div > div > span {
    display: block;
    font-size: 20px;
    font-weight: $semibold;
    line-height: 24px;
    margin-bottom: -5px;
    @media (max-width: 1536px) {
      font-size: 19px;
      line-height: 23px;
    }
    @media (max-width: 1280px) {
      font-size: 18px;
      line-height: 22px;
    }
    @media (max-width: 1024px) {
      font-size: 17px;
      line-height: 21px;
    }
    @media (max-width: 832px) and (min-width: 768px), (max-width: 350px) {
      margin: 0;
      padding-left: 12px;
    }
    @media (max-width: 767px) {
      font-size: 16px;
      line-height: 20px;
    }
    @media (max-width: 480px) {
      font-size: 15px;
      line-height: 19px;
    }
  }
  /* -------------------------------------------------------------- */
  /* Cta */
  /* -------------------------------------------------------------- */
  a[class][href] {
    height: 44px;
    margin-left: auto;
    @media (max-width: 1664px) {
      padding: 0 5px 0 24px;
    }
    @media (max-width: 1536px) {
      height: 42px;
    }
    @media (max-width: 1280px) {
      height: 41px;
    }
    @media (max-width: 1024px) {
      height: 39px;
    }
    @media (max-width: 832px) and (min-width: 768px), (max-width: 350px) {
      align-self: flex-start;
      margin-left: 0;
      margin-top: 16px;
    }
    @media (max-width: 767px) {
      height: 38px;
    }
    @media (max-width: 375px) {
      height: 37px;
    }
  }
}
