@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Legal Text */
/* ---------------------------------------------------------------- */
.text {
  max-width: 990px;
  @media (max-width: 1536px) {
    max-width: 790px;
  }
  @media (max-width: 1152px) {
    max-width: 690px;
  }
  @media (max-width: 895px) {
    margin-top: 32px;
    max-width: 100%;
    order: 2;
  }
  @media (max-width: 375px) {
    margin-top: 24px;
  }
  p {
    color: $mono2;
    font-size: 15px;
    line-height: 24px;
    width: 100%;
    + p {
      margin-top: 10px;
    }
  }
}
