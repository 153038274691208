@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Burger Menu Button */
/* ---------------------------------------------------------------- */
.burgerMenuButton {
  display: none;
  @media (max-width: 767px) {
    align-self: center;
    cursor: pointer;
    display: inline-flex;
    flex-shrink: 0;
    height: 22px;
    margin-left: auto;
    order: 3;
    position: relative;
    touch-action: manipulation;
    width: 36px;
    z-index: 7;
  }
  > span,
  > span::before,
  > span::after {
    background-color: $brandBlackGrape;
    display: block;
    height: 3px;
    position: absolute;
    width: 36px;
    @include fast-transition($mob: true, $props: 'transform, background-color');
  }
  > span {
    display: block;
    &::before,
    &::after {
      content: '';
    }
    &::before {
      top: 10px;
      @include fast-transition($mob: true, $props: 'transform, opacity');
    }
    &::after {
      top: 20px;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Focus Ring */
  /* ---------------------------------------------------------------- */
  &::after {
    border: 2px solid transparent;
    border-radius: 2px;
    box-sizing: border-box;
    content: '';
    height: calc(100% + 13px);
    left: -5px;
    position: absolute;
    top: -6px;
    width: calc(100% + 10px);
    @include fast-transition($props: 'border-color', $timing: 'ease-out');
  }
  &:focus::after {
    border-color: $brandBerry;
  }
  /* ---------------------------------------------------------------- */
  /* Mobile Menu Active */
  /* ---------------------------------------------------------------- */
  &.activeMobileMenu {
    > span {
      transform: translate3d(0, 10px, 0) rotate(45deg);
      &::before {
        opacity: 0;
        transform: rotate(-45deg) translate3d(-5.71429px, -6px, 0);
      }
      &::after {
        transform: translate3d(0, -20px, 0) rotate(-90deg);
      }
    }
    > span,
    > span::before,
    > span::after {
      background-color: $black;
    }
    &:focus::after {
      border-color: $brandBlackGrape;
      border-radius: 6px;
      height: calc(100% + 16px);
      left: -4px;
      top: -8px;
      width: calc(100% + 8px);
    }
  }
}
