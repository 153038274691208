@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Organisation */
/* ---------------------------------------------------------------- */
.organisation {
  font-size: 20px;
  line-height: 26px;
  margin: -5px 0 -6px;
  @media (max-width: 1536px) {
    font-size: 19px;
    line-height: 25px;
  }
  @media (max-width: 1280px) {
    font-size: 18px;
    line-height: 24px;
  }
  @media (max-width: 640px) {
    font-size: 17px;
    line-height: 23px;
  }
  @media (max-width: 375px) {
    font-size: 16px;
    line-height: 22px;
  }
}
