@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Voucher Code */
/* ---------------------------------------------------------------- */
.voucherCode {
  margin: 34px 0 0;
  position: relative;
  @media (max-width: 1536px) {
    margin-top: 30px;
  }
  @media (max-width: 1280px) {
    margin-top: 26px;
  }
  @media (max-width: 1024px) {
    margin-top: 23px;
  }
  /* ---------------------------------------------------------------- */
  /* Error */
  /* ---------------------------------------------------------------- */
  > span {
    color: red;
    display: block;
    font-size: 17px;
    line-height: 24px;
    margin: 7px 0 -100px;
    @media (max-width: 1536px) {
      font-size: 16px;
      line-height: 23px;
      margin-top: 6px;
    }
    @media (max-width: 1408px) {
      margin-top: 5px;
      margin-bottom: -110px;
    }
    @media (max-width: 1280px) {
      font-size: 15px;
      line-height: 20px;
      margin-bottom: -86px;
      margin-top: 4px;
    }
    @media (max-width: 1024px) {
      margin-top: 3px;
      margin-bottom: -110px;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Button */
  /* ---------------------------------------------------------------- */
  .button {
    border-radius: 9px;
    height: 72px;
    justify-content: center;
    min-width: 88px;
    position: absolute;
    right: 0;
    top: 0;
    width: 35%;
    @media (max-width: 1536px) {
      height: 64px;
    }
    @media (max-width: 1280px) {
      height: 56px;
    }
    span {
      font-family: $primary;
      font-size: 19px;
      font-weight: $regular;
      transition: none;
      @media (max-width: 1536px) {
        font-size: 18px;
      }
      @media (max-width: 1280px) {
        font-size: 17px;
      }
    }
  }
  input {
    padding-right: calc(26px + 35%);
    @media (max-width: 1536px) {
      padding-right: calc(22px + 35%);
    }
    @media (max-width: 1280px) {
      padding-right: calc(18px + 35%);
    }
  }
  /* ---------------------------------------------------------------- */
  /* Submitting */
  /* ---------------------------------------------------------------- */
  &.submitting {
    button {
      span {
        color: transparent;
      }
    }
    /* ---------------------------------------------------------------- */
    /* Spinner */
    /* ---------------------------------------------------------------- */
    .spinner {
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
