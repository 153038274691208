/* ---------------------------------------------------------------- */
/* HTML */
/* ---------------------------------------------------------------- */
html {
  color: $brandBlackGrape;
  display: flex;
  font-family: $primary;
  font-size: 17px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  line-height: 1.25;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  text-rendering: optimizeLegibility;
  -webkit-text-size-adjust: 100%;
  &.lock-screen {
    overflow: hidden;
  }
}
/* ---------------------------------------------------------------- */
/* Body */
/* ---------------------------------------------------------------- */
body {
  background-color: $pageBackground;
  display: flex;
  flex-direction: column;
  height: 100%;
  min-width: 320px;
  width: 100%;
  @media only screen and (min-width: 768px) and (max-width: 1280px) {
    overflow-x: hidden;
  }
}
/* ---------------------------------------------------------------- */
/* Root */
/* ---------------------------------------------------------------- */
#root {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
/* ---------------------------------------------------------------- */
/* Container */
/* ---------------------------------------------------------------- */
.container {
  box-sizing: border-box;
  margin: 0 auto;
  max-width: 1920px;
  min-width: 320px;
  padding: 0 72px;
  width: 100%;
  @media (max-width: 1664px) {
    padding: 0 64px;
  }
  @media (max-width: 1536px) {
    padding: 0 56px;
  }
  @media (max-width: 1408px) {
    padding: 0 48px;
  }
  @media (max-width: 1280px) {
    padding: 0 40px;
  }
  @media (max-width: 1152px) {
    padding: 0 32px;
  }
  @media (max-width: 1024px) {
    padding: 0 24px;
  }
  @media (max-width: 375px) {
    padding: 0 16px;
  }
  &.small {
    max-width: 1210px;
    padding: 0 32px;
    @media (max-width: 1024px) {
      padding: 0 24px;
    }
    @media (max-width: 375px) {
      padding: 0 16px;
    }
  }
}

.grecaptcha-badge {
  visibility: hidden;
}

// Accessible visually hidden class
.visuallyHidden:not(:focus):not(:active) {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}
