@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Menu */
/* ---------------------------------------------------------------- */
.skeleton {
  display: flex;
  position: relative;
  @media (max-width: 1024px) {
    order: 1;
  }
  @media (max-width: 375px) {
    justify-content: space-between;
    width: 100%;
  }
  &::after {
    @include skeleton-shimmer;
    @include skeleton-shimmer-color($color: $brandBlackGrape);
  }
  /* -------------------------------------------------------------- */
  /* Menu List */
  /* -------------------------------------------------------------- */
  > div {
    align-items: center;
    background-color: $brandBlackGrapeLight;
    height: 24px;
    margin-right: 45px;
    position: relative;
    width: 24px;
    @include rounded-skeleton-text($radius: '4px');
    @media (max-width: 1536px) {
      margin-right: 25px;
    }
    @media (max-width: 1152px) {
      margin-right: 15px;
    }
    @media (max-width: 375px) {
      height: 22px;
      width: 22px;
    }
    &:last-child {
      margin-right: 0;
    }
  }
}

@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}
