/* User Select */
@mixin user-select() {
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
}
/* No User Select */
@mixin no-user-select() {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
/* Fast Transition */
@mixin fast-transition(
  $ie: false,
  $firefox: true,
  $mob: false,
  $timing: ease-in-out,
  $props: 'all'
) {
  transition-duration: 0.175s;
  transition-property: #{$props};
  transition-timing-function: #{$timing};
  @if not($ie) {
    :global(.browser-ie) & {
      transition: none;
    }
  }
  @if not($firefox) {
    :global(.browser-firefox) & {
      transition: none;
    }
  }
  @if not($mob) {
    :global(.platform-mobile) & {
      transition: none;
    }
  }
}
/* Middle Transition */
@mixin medium-transition(
  $ie: false,
  $firefox: true,
  $mob: false,
  $timing: ease-in-out,
  $props: 'all'
) {
  transition-duration: 0.35s;
  transition-property: #{$props};
  transition-timing-function: #{$timing};
  @if not($ie) {
    .browser-ie & {
      transition: none;
    }
  }
  @if not($firefox) {
    :global(.browser-firefox) & {
      transition: none;
    }
  }
  @if not($mob) {
    .platform-mobile & {
      transition: none;
    }
  }
}
/* Slow Transition */
@mixin slow-transition(
  $ie: false,
  $firefox: true,
  $mob: false,
  $timing: ease-in-out,
  $props: 'all'
) {
  transition-duration: 0.5s;
  transition-property: #{$props};
  transition-timing-function: #{$timing};
  @if not($ie) {
    :global(.browser-ie) & {
      transition: none;
    }
  }
  @if not($firefox) {
    :global(.browser-firefox) & {
      transition: none;
    }
  }
  @if not($mob) {
    :global(.platform-mobile) & {
      transition: none;
    }
  }
}
/* Transition */
@mixin transition(
  $ie: false,
  $firefox: true,
  $mob: false,
  $timing: ease-in-out,
  $props: 'all',
  $duration: '0s'
) {
  transition-duration: #{$duration};
  transition-property: #{$props};
  transition-timing-function: #{$timing};
  @if not($ie) {
    :global(.browser-ie) & {
      transition: none;
    }
  }
  @if not($firefox) {
    :global(.browser-firefox) & {
      transition: none;
    }
  }
  @if not($mob) {
    :global(.platform-mobile) & {
      transition: none;
    }
  }
}
/* Font Variation Settings */
@mixin variable-fonts($weight: '400', $width: null) {
  font-weight: #{$weight};
  @if $width {
    font-variation-settings: 'wght' #{$weight}, 'wdth' #{$width};
  } @else {
    font-variation-settings: 'wght' #{$weight};
  }
}
/* No breaking rounded corners */
@mixin rounded-skeleton-text($radius: '99em') {
  box-decoration-break: clone;
  @if $radius {
    border-radius: #{$radius};
  }
}
/* Skeleton shimmer */
@mixin skeleton-shimmer($duration: '1') {
  animation: shimmer #{$duration}s infinite;
  background-color: transparent;
  bottom: 0;
  content: '';
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transform: translateX(-100%);
}
/* Skeleton shimmer color */
@mixin skeleton-shimmer-color($color: null) {
  background-image: linear-gradient(
    90deg,
    rgba($color, 0) 0,
    rgba($color, 0.5) 20%,
    rgba($color, 0.8) 60%,
    rgba($color, 0)
  );
}
