@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Checkbox */
/* ---------------------------------------------------------------- */
.checkbox {
  display: flex;
  position: relative;
  &:focus {
    background: blue;
  }
  @media (max-width: 375px) {
    margin-bottom: 8px;
  }
  /* ---------------------------------------------------------------- */
  /* Input - Input */
  /* ---------------------------------------------------------------- */
  input {
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    appearance: none;
    background-color: $white;
    border-color: $mono2;
    border-radius: 8px;
    border-style: solid;
    border-width: 1px;
    box-sizing: border-box;
    cursor: pointer;
    display: inline-block;
    filter: none;
    flex-shrink: 0;
    height: 32px;
    margin: 0;
    outline: none;
    resize: none;
    vertical-align: middle;
    width: 100%;
    width: 32px;
    @include no-user-select;
    @include fast-transition($props: 'border-color, background-color');
    /* ---------------------------------------------------------------- */
    /* Hover */
    /* ---------------------------------------------------------------- */
    :global(.platform-desktop) & {
      &:hover {
        border-color: $mono3;
      }
    }
    /* ---------------------------------------------------------------- */
    /* Tick */
    /* ---------------------------------------------------------------- */
    ~ svg {
      flex-shrink: 0;
      height: 17px;
      left: 5px;
      opacity: 0;
      pointer-events: none;
      position: absolute;
      top: 8px;
      transform: scale(0.875);
      transform-origin: 50% 50%;
      width: 22px;
      @include fast-transition($props: 'opacity, transform');
      * {
        fill: $brandBlackGrape;
      }
    }
    /* ---------------------------------------------------------------- */
    /* Checked */
    /* ---------------------------------------------------------------- */
    &:checked {
      ~ svg {
        opacity: 1;
        transform: scale(1);
      }
    }
    /* ---------------------------------------------------------------- */
    /* Label */
    /* ---------------------------------------------------------------- */
    ~ label {
      margin-top: 2px;
      padding-left: 23px;
      @media (max-width: 1536px) {
        margin-top: 3px;
        padding-left: 20px;
      }
      @media (max-width: 1280px) {
        padding-left: 18px;
      }
      @media (max-width: 1024px) {
        padding-left: 15px;
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Invalid */
  /* ---------------------------------------------------------------- */
  &.invalid {
    @media (max-width: 375px) {
      margin-bottom: 0;
    }
    input {
      border-color: red;
    }
    /* ---------------------------------------------------------------- */
    /* Warning message */
    /* ---------------------------------------------------------------- */
    + p {
      margin-left: 55px;
      margin-top: 7px;
      position: relative;
      @media (max-width: 1536px) {
        margin-left: 52px;
        margin-top: 6px;
      }
      @media (max-width: 1280px) {
        margin-left: 50px;
        margin-top: 5px;
      }
      @media (max-width: 1024px) {
        margin-left: 47px;
        margin-top: 4px;
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Read Only */
  /* ---------------------------------------------------------------- */
  &.readOnly {
    input {
      background-color: $mono1;
      pointer-events: none;
      + svg path {
        fill: $mono4;
      }
      ~ label {
        color: $mono4;
        pointer-events: none;
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Disabled */
  /* ---------------------------------------------------------------- */
  &.disabled {
    input {
      background-color: $pageBackground;
      border-color: $mono2;
      color: $mono2;
      pointer-events: none;
    }
  }
}
