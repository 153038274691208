@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Content Type Item */
/* ---------------------------------------------------------------- */
.bookmarkItem {
  background-color: $white;
  box-shadow: 0 0 10px rgba($black, 10%);
  display: flex;
  position: relative;
  width: 100%;
  @media (max-width: 576px) {
    background-color: transparent;
    box-shadow: none;
    flex-direction: column;
  }
  /* ---------------------------------------------------------------- */
  /* Image */
  /* ---------------------------------------------------------------- */
  > span {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: block;
    flex-shrink: 0;
    min-height: 194px;
    overflow: hidden;
    position: relative;
    width: 28%;
    @media (max-width: 1280px) {
      width: 32%;
    }
    @media (max-width: 896px) {
      width: 36%;
    }
    @media (max-width: 767px) {
      width: 40%;
    }
    @media (max-width: 576px) {
      height: 208px;
      min-height: 0;
      width: 100%;
    }
    @media (max-width: 375px) {
      height: 175px;
    }
    /* ---------------------------------------------------------------- */
    /* Placeholder image */
    /* ---------------------------------------------------------------- */
    &.placeholderImage {
      background-image: url('/images/placeholders/resource-hub-list.svg');
      background-position: right;
      background-size: cover;
    }
    /* ---------------------------------------------------------------- */
    /* 63 Deg Overlay */
    /* ---------------------------------------------------------------- */
    span {
      background-image: url('/images/artwork/post-slice.svg');
      background-repeat: no-repeat;
      background-size: cover;
      bottom: -1px;
      height: auto;
      position: absolute;
      right: -1px;
      top: -1px;
      width: 52.5%;
      z-index: 2;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Content */
  /* ---------------------------------------------------------------- */
  > div {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 32px;
    width: 100%;
    @media (max-width: 1536px) {
      padding: 28px;
    }
    @media (max-width: 1280px) {
      padding: 24px;
    }
    @media (max-width: 1024px) {
      padding: 20px;
    }
    @media (max-width: 576px) {
      padding: 20px 0 0;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Header - Publications + Social Container */
  /* ---------------------------------------------------------------- */
  header {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: -9px -8px 0;
    @media (max-width: 1536px) {
      margin: -7px -7px 0;
    }
    @media (max-width: 1280px) {
      margin: -7px -6px 0;
    }
    @media (max-width: 1024px) {
      margin: -6px -4px 0;
    }
    @media (max-width: 576px) {
      margin-top: -7px;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Publications Text + Social Container */
  /* ---------------------------------------------------------------- */
  .social,
  .publication > span {
    margin: 6px 8px 5px;
    @media (max-width: 1536px) {
      margin: 5px 7px;
    }
    @media (max-width: 1280px) {
      margin: 5px 6px 4px;
    }
    @media (max-width: 1024px) {
      margin: 4px 5px;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Social */
  /* ---------------------------------------------------------------- */
  .social {
    @media (max-width: 576px) {
      margin: 0 0 8px;
    }
    @media (max-width: 896px) and (min-width: 768px) {
      height: 14px;
    }
    /* ---------------------------------------------------------------- */
    /* Bookmark */
    /* ---------------------------------------------------------------- */
    div:first-child {
      @media (max-width: 896px) and (min-width: 768px) {
        margin-right: 8px;
      }
      /* ---------------------------------------------------------------- */
      /* Button + Icon */
      /* ---------------------------------------------------------------- */
      button,
      svg {
        @media (max-width: 896px) and (min-width: 768px) {
          height: 14px;
          width: 11px;
        }
      }
    }
    /* ---------------------------------------------------------------- */
    /* Likes */
    /* ---------------------------------------------------------------- */
    div:last-child {
      @media (max-width: 896px) and (min-width: 768px) {
        font-size: 14px;
      }
      /* ---------------------------------------------------------------- */
      /* Button + Icon */
      /* ---------------------------------------------------------------- */
      button,
      svg {
        @media (max-width: 896px) and (min-width: 768px) {
          height: 14px;
          width: 16px;
        }
      }
      /* ---------------------------------------------------------------- */
      /* Button */
      /* ---------------------------------------------------------------- */
      button {
        @media (max-width: 896px) and (min-width: 768px) {
          margin-right: 6px;
        }
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Publication */
  /* ---------------------------------------------------------------- */
  .publication {
    color: rgba($color: $mono5, $alpha: 0.7);
    display: flex;
    flex-wrap: wrap;
    font-size: 17px;
    line-height: 25px;
    @media (max-width: 1536px) {
      font-size: 16px;
      line-height: 24px;
    }
    @media (max-width: 1280px) {
      font-size: 15px;
      line-height: 23px;
    }
    @media (max-width: 1024px) {
      font-size: 14px;
      line-height: 22px;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Copy Container */
  /* ---------------------------------------------------------------- */
  .copy {
    margin-top: 21px;
    @media (max-width: 1536px) {
      margin-top: 17px;
    }
    @media (max-width: 1280px) {
      margin-top: 13px;
    }
    @media (max-width: 1024px) {
      margin-top: 14px;
    }
    @media (max-width: 767px) {
      margin-top: 15px;
    }
    @media (max-width: 576px) {
      margin-top: 10px;
    }
    @media (max-width: 375px) {
      margin-top: 11px;
    }
  }
  .publication > span {
    height: 17px;
    line-height: 17px;
    white-space: nowrap;
    @media (max-width: 1536px) {
      height: 16px;
      line-height: 16px;
    }
    @media (max-width: 1280px) {
      height: 15px;
      line-height: 15px;
    }
    @media (max-width: 1024px) {
      height: 14px;
      line-height: 14px;
    }
    @media (max-width: 576px) {
      height: 15px;
      line-height: 15px;
    }
    &:nth-child(2) {
      color: $brandBerry;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Title */
  /* ---------------------------------------------------------------- */
  h3 {
    color: $brandBlackGrape;
    display: block;
    font-family: $secondary;
    font-size: 20px;
    font-weight: $bold;
    line-height: 28px;
    margin-bottom: 11px;
    margin-top: -6px;
    position: relative;
    @media (max-width: 1536px) {
      font-size: 19px;
      line-height: 27px;
      margin-bottom: 7px;
    }
    @media (max-width: 1280px) {
      font-size: 18px;
      line-height: 26px;
      margin-bottom: 5px;
    }
    @media (max-width: 1024px) {
      font-size: 17px;
      line-height: 25px;
      margin-bottom: 3px;
    }
    @media (max-width: 576px) {
      font-family: $primary;
      font-weight: $bold;
    }
    @media (max-width: 767px) {
      font-size: 16px;
      line-height: 24px;
    }
    @media (max-width: 480px) {
      font-size: 15px;
      line-height: 23px;
    }
    @media (max-width: 375px) {
      font-size: 14px;
      line-height: 21px;
    }
    &:last-child {
      margin-bottom: -7px;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Excerpt */
  /* ---------------------------------------------------------------- */
  .copy > p {
    color: $brandBlackGrape;
    display: block;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: -6px;
    position: relative;
    @media (max-width: 1536px) {
      font-size: 16px;
      line-height: 24px;
    }
    @media (max-width: 1280px) {
      font-size: 15px;
      line-height: 23px;
    }
    @media (max-width: 1024px) {
      font-size: 14px;
      line-height: 21px;
      margin-bottom: -5px;
    }
    @media (max-width: 576px) {
      display: none;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Footer - Cta Container */
  /* ---------------------------------------------------------------- */
  footer {
    align-items: flex-start;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: auto 0 0;
    padding-top: 28px;
    @media (max-width: 1536px) {
      padding-top: 24px;
    }
    @media (max-width: 1280px) {
      padding-top: 20px;
    }
    @media (max-width: 985px) {
      flex-direction: column;
    }
    @media (max-width: 896px) {
      flex-direction: row;
    }
    @media (max-width: 576px) {
      margin-bottom: -8px;
      margin-left: -16px;
      margin-right: -16px;
      padding-top: 21px;
    }
    @media (max-width: 375px) {
      padding-top: 19px;
    }
    @media (max-width: 456px) {
      align-items: flex-start;
      flex-direction: column;
      margin-bottom: -12px;
      padding-top: 20px;
    }
    /* ---------------------------------------------------------------- */
    /* No Title or Description */
    /* ---------------------------------------------------------------- */
    &:nth-child(2) {
      padding-top: 0;
      @media (max-width: 576px) {
        padding-top: 14px;
      }
      @media (max-width: 375px) {
        padding-top: 11px;
      }
      @media (max-width: 350px) {
        padding-top: 13px;
      }
      a {
        margin-top: 18px;
        @media (max-width: 1536px) {
          margin-top: 14px;
        }
        @media (max-width: 1280px) {
          margin-top: 11px;
        }
        @media (max-width: 1024px) {
          margin-top: 12px;
        }
        @media (max-width: 576px) {
          margin-top: 0;
        }
        @media (max-width: 767px) and (min-width: 577px) {
          margin-top: 13px;
        }
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Hyperlink */
  /* ---------------------------------------------------------------- */
  .hyperlink {
    display: table;
    font-family: $secondary;
    font-size: 17px;
    font-weight: $bold;
    line-height: 20px;
    margin: -3px 0 -4px;
    min-width: 0;
    text-decoration: none;
    @media (max-width: 1536px) {
      font-size: 16px;
      line-height: 19px;
    }
    @media (max-width: 1280px) {
      font-size: 15px;
      line-height: 18px;
    }
    @media (max-width: 1024px) {
      font-size: 14px;
      line-height: 17px;
    }
    @media (max-width: 576px) {
      font-size: 17px;
      line-height: 20px;
      margin: 0 0 8px;
    }
    @media (max-width: 480px) {
      font-size: 16px;
      line-height: 19px;
    }
    @media (max-width: 375px) {
      font-size: 15px;
      line-height: 18px;
    }
    &:focus {
      text-decoration: underline;
    }
    > svg {
      height: 12px;
      margin-left: 16px;
      width: 7px;
      @media (max-width: 1536px) {
        margin-left: 12px;
        position: relative;
        top: 1px;
      }
      @media (max-width: 1280px) {
        margin-left: 8px;
      }
      @media (max-width: 576px) {
        margin-left: 16px;
      }
      @media (max-width: 480px) {
        margin-left: 12px;
      }
      @media (max-width: 375px) {
        margin-left: 8px;
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Social Mobile + Hyperlink */
  /* ---------------------------------------------------------------- */
  .social,
  .hyperlink {
    @media (max-width: 576px) {
      margin-left: 16px;
      margin-right: 16px;
    }
  }
  /* ---------------------------------------------------------------- */
  /* New Icon */
  /* ---------------------------------------------------------------- */
  &.isNewArticle {
    > div {
      position: relative;
      @media (max-width: 896px) {
        padding-top: 30px;
      }
      @media (max-width: 576px) {
        padding-top: 20px;
      }
    }
    /* ---------------------------------------------------------------- */
    /* Icon */
    /* ---------------------------------------------------------------- */
    .newIcon {
      background-color: $brandAqua;
      border-bottom-left-radius: 4px;
      box-sizing: border-box;
      color: $brandBlackGrape;
      font-family: $primary;
      font-size: 10px;
      font-weight: $semibold;
      line-height: 16px;
      padding-left: 12px;
      position: absolute;
      right: 0;
      top: 0;
      width: 54px;
      @media (max-width: 576px) {
        border-radius: 4px;
        padding-left: 0;
        text-align: center;
        top: 17px;
      }
      @media (max-width: 375px) {
        text-align: center;
        width: 42px;
      }
    }
  }
}
