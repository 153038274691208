@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Sign Up Page */
/* ---------------------------------------------------------------- */
.signUpPage {
  @media only screen and (min-width: 768px) and (max-width: 1280px) {
    overflow: unset;
    z-index: 99;
  }
  /* ---------------------------------------------------------------- */
  /* Hide footer */
  /* ---------------------------------------------------------------- */
  + footer {
    display: none;
  }
}
/* ---------------------------------------------------------------- */
/* Sign Up Content */
/* ---------------------------------------------------------------- */
.signUpContent {
  display: flex;
  flex-direction: column;
  height: 100%;
  /* ---------------------------------------------------------------- */
  /* Title */
  /* ---------------------------------------------------------------- */
  h1 {
    font-family: $secondary;
    font-size: 32px;
    line-height: 39px;
    margin-bottom: -7px;
    @media (max-width: 1536px) {
      font-size: 30px;
      line-height: 36px;
      margin-bottom: -6px;
    }
    @media (max-width: 1280px) {
      font-size: 27px;
      line-height: 33px;
    }
    @media (max-width: 1024px) {
      font-size: 24px;
      line-height: 30px;
    }
    &:first-child {
      margin-top: -7px;
      @media (max-width: 1536px) {
        margin-top: -6px;
      }
    }
    + form {
      margin-top: 39px;
      @media (max-width: 1536px) {
        margin-top: 35px;
      }
      @media (max-width: 1408px) {
        margin-top: 31px;
      }
      @media (max-width: 1280px) {
        margin-top: 28px;
      }
      @media (max-width: 1024px) {
        margin-top: 24px;
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Description */
  /* ---------------------------------------------------------------- */
  > p {
    font-family: $secondary;
    font-size: 20px;
    line-height: 27px;
    margin: 33px 0 40px;
    white-space: pre-line;
    @media (max-width: 1536px) {
      font-size: 19px;
      line-height: 26px;
      margin: 29px 0 36px;
    }
    @media (max-width: 1408px) {
      margin: 25px 0 32px;
    }
    @media (max-width: 1280px) {
      font-size: 18px;
      line-height: 25px;
      margin: 22px 0 28px;
    }
    @media (max-width: 1024px) {
      font-size: 17px;
      line-height: 24px;
      margin: 18px 0 25px;
    }
    @media (max-width: 767px) {
      font-size: 16px;
      line-height: 23px;
    }
  }
}
/* ---------------------------------------------------------------- */
/* Sign Up Form */
/* ---------------------------------------------------------------- */
.signUpForm {
  /* ---------------------------------------------------------------- */
  /* Recaptcha Widget */
  /* ---------------------------------------------------------------- */
  > div:nth-child(1) {
    display: block;
  }
  /* ---------------------------------------------------------------- */
  /* Fieldsets (Personal Info & Payment Details) */
  /* ---------------------------------------------------------------- */
  fieldset {
    margin: 0 0 48px;
    @media (max-width: 1536px) {
      margin: 0 0 44px;
    }
    @media (max-width: 1408px) {
      margin: 0 0 40px;
    }
    @media (max-width: 1280px) {
      margin: 0 0 36px;
    }
    @media (max-width: 1024px) {
      margin: 0 0 32px;
    }
    > *:last-child {
      margin: 0;
    }
  }
}
