@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* -------------------------------------------------------------- */
/* Candidate Number List Skeleton */
/* -------------------------------------------------------------- */
.skeleton {
  position: relative;
  /* -------------------------------------------------------------- */
  /* Title */
  /* -------------------------------------------------------------- */
  > p {
    align-items: center;
    color: rgba($brandBlackGrape, 50%);
    display: flex;
    font-family: $secondary;
    font-size: 18px;
    font-weight: $semibold;
    letter-spacing: 0.16px;
    line-height: 22px;
    margin-bottom: 13px;
    margin-top: -4px;
    overflow: hidden;
    position: relative;
    text-transform: uppercase;
    @media (max-width: 1536px) {
      font-size: 17px;
      line-height: 21px;
      margin-bottom: 10px;
    }
    @media (max-width: 1280px) {
      font-size: 16px;
      line-height: 20px;
      margin-bottom: 6px;
    }
    @media (max-width: 1024px) {
      font-size: 15px;
      line-height: 19px;
      margin-bottom: 7px;
    }
    @media (max-width: 767px) {
      font-size: 16px;
      line-height: 20px;
      margin-bottom: 5px;
    }
    &::before {
      @include skeleton-shimmer;
      @include skeleton-shimmer-color($color: $white);
      @media (max-width: 767px) {
        @include skeleton-shimmer-color($color: $pageBackground);
      }
    }
    &::after {
      background-color: $mono1;
      color: transparent;
      content: 'Your Digital Badges';
      font-size: inherit;
      line-height: inherit;
      @include rounded-skeleton-text;
    }
  }
  /* -------------------------------------------------------------- */
  /* Text */
  /* -------------------------------------------------------------- */
  > span {
    display: block;
    font-size: 17px;
    line-height: 25px;
    margin-bottom: -6px;
    overflow: hidden;
    position: relative;
    @media (max-width: 1536px) {
      font-size: 16px;
      line-height: 24px;
    }
    @media (max-width: 1280px) {
      font-size: 15px;
      line-height: 23px;
    }
    @media (max-width: 1024px) and (min-width: 768px) {
      font-size: 14px;
      line-height: 21px;
      margin-bottom: -5px;
    }
    &::before {
      @include skeleton-shimmer;
      @include skeleton-shimmer-color($color: $white);
      @media (max-width: 767px) {
        @include skeleton-shimmer-color($color: $pageBackground);
      }
    }
    &::after {
      background-color: $mono1;
      color: transparent;
      content: 'You will view all your earned badges here';
      font-size: inherit;
      line-height: inherit;
      @include rounded-skeleton-text;
    }
  }
}

@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}
