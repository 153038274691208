@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Modal Form */
/* ---------------------------------------------------------------- */
.modalForm {
  width: 100%;
  /* ---------------------------------------------------------------- */
  /* Form */
  /* ---------------------------------------------------------------- */
  form,
  fieldset {
    display: flex;
    flex-direction: column;
  }
  /* ---------------------------------------------------------------- */
  /* Recaptcha */
  /* ---------------------------------------------------------------- */
  small {
    color: $mono4;
    font-size: 14px;
    margin-left: auto;
    margin-top: -3px;
    max-width: 280px;
    padding-right: 16px;
    @media (max-width: 1280px) {
      font-size: 13px;
      max-width: 250px;
    }
    @media (max-width: 520px) {
      margin: 20px auto 0;
      max-width: 100%;
      order: 3;
      text-align: center;
    }
  }
  /* ---------------------------------------------------------------- */
  /* Buttons */
  /* ---------------------------------------------------------------- */
  .buttons {
    justify-content: flex-end;
    @media (max-width: 520px) {
      flex-wrap: wrap;
      justify-content: center;
    }
    button {
      @media (max-width: 520px) {
        width: calc(50% - 6px);
      }
      &:nth-of-type(1) {
        margin-right: 24px;
        @media (max-width: 1536px) {
          margin-right: 20px;
        }
        @media (max-width: 1280px) {
          margin-right: 16px;
        }
        @media (max-width: 767px) {
          margin-right: 12px;
        }
        @media (max-width: 520px) {
          order: 1;
        }
      }
      &:nth-of-type(2) {
        @media (max-width: 520px) {
          order: 2;
        }
      }
    }
  }
}
