@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Edit Card Form */
/* ---------------------------------------------------------------- */
.editCardForm {
  /* ---------------------------------------------------------------- */
  /* Form */
  /* ---------------------------------------------------------------- */
  form {
    position: relative;
  }
  /* ---------------------------------------------------------------- */
  /* Card Info */
  /* ---------------------------------------------------------------- */
  fieldset:nth-of-type(1) {
    margin: 0 0 32px;
    @media (max-width: 1536px) {
      margin: 0 0 28px;
    }
    @media (max-width: 1280px) {
      margin: 0 0 24px;
    }
    @media (max-width: 1024px) {
      margin: 0 0 20px;
    }
    /* ---------------------------------------------------------------- */
    /* Icon / Text Container */
    /* ---------------------------------------------------------------- */
    > div {
      align-items: center;
      background-color: $white;
      border-color: $mono2;
      border-radius: 9px;
      border-style: solid;
      border-width: 1px;
      box-sizing: border-box;
      display: flex;
      margin-top: 0;
      padding: 32px;
      width: auto;
      @include no-user-select;
      @media (max-width: 1280px) {
        padding: 28px;
      }
      @media (max-width: 1024px) {
        padding: 24px;
      }
      @media (max-width: 640px) {
        align-items: flex-start;
        flex-direction: column;
        justify-content: center;
        padding: 24px 24px 25px;
      }
      @media (max-width: 375px) {
        min-height: 56px;
        padding: 16px;
      }
      > div {
        position: relative;
      }
    }
    /* ---------------------------------------------------------------- */
    /* Icon */
    /* ---------------------------------------------------------------- */
    svg {
      flex-shrink: 0;
      height: 36px;
      left: 0;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 36px;
      @media (max-width: 375px) {
        height: 32px;
        width: 32px;
      }
    }
    /* ---------------------------------------------------------------- */
    /* Text */
    /* ---------------------------------------------------------------- */
    p {
      color: $brandBlackGrape;
      font-size: 20px;
      letter-spacing: normal;
      line-height: 25px;
      margin: -5px 0;
      padding: 0 0 0 51px;
      @include user-select;
      @media (max-width: 1536px) {
        font-size: 19px;
        line-height: 25px;
      }
      @media (max-width: 1280px) {
        font-size: 18px;
        line-height: 24px;
      }
      @media (max-width: 520px) {
        font-size: 17px;
        line-height: 23px;
        padding: 0 0 0 51px;
      }
      @media (max-width: 460px) {
        font-size: 16px;
        line-height: 22px;
      }
      @media (max-width: 375px) {
        font-size: 15px;
        line-height: 21px;
        padding: 0 0 0 48px;
      }
      strong {
        font-weight: $bold;
      }
      span {
        font-style: italic;
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Expiration Date / Expiration Year */
  /* ---------------------------------------------------------------- */
  fieldset:nth-of-type(2) {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 0 -8px;
    > * {
      margin-left: 8px;
      margin-right: 8px;
    }
    > div {
      width: calc(50% - 16px);
      @media (max-width: 576px) {
        width: calc(100% - 16px);
      }
    }
  }
  /* ----------------------------------------------------------------------------------------------------- */
  /* First Name / Last Name Communication address / Address line two / City / Postcode / Country / Default */
  /* ----------------------------------------------------------------------------------------------------- */
  fieldset:last-of-type {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 0 -8px;
    position: static;
    > * {
      margin-left: 8px;
      margin-right: 8px;
    }
    > div {
      width: calc(50% - 16px);
      @media (max-width: 576px) {
        width: calc(100% - 16px);
      }
      &:nth-of-type(3),
      &:nth-of-type(4),
      &:nth-of-type(7) {
        width: calc(100% - 16px);
      }
      &:nth-last-of-type(2) {
        @media (min-width: 641px) {
          margin-bottom: 0;
        }
      }
      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Checkbox */
  /* ---------------------------------------------------------------- */
  .checkbox[class] {
    position: static;
    width: auto;
    > div {
      bottom: 20px;
      position: absolute;
      @media (max-width: 1536px) {
        bottom: 16px;
      }
      @media (max-width: 1280px) {
        bottom: 12px;
      }
      @media (max-width: 767px) {
        bottom: 8px;
      }
      @media (max-width: 640px) {
        bottom: auto;
        position: relative;
      }
    }
  }
  /* ---------------------------------------------------------------- */
  /* Buttons */
  /* ---------------------------------------------------------------- */
  .buttons {
    justify-content: flex-end;
    @media (max-width: 520px) {
      flex-wrap: wrap;
      justify-content: center;
    }
    button {
      @media (max-width: 520px) {
        width: calc(50% - 6px);
      }
      span {
        font-family: $primary;
        font-weight: $regular;
      }
      &:nth-of-type(1) {
        margin-right: 24px;
        @media (max-width: 1536px) {
          margin-right: 20px;
        }
        @media (max-width: 1280px) {
          margin-right: 16px;
        }
        @media (max-width: 767px) {
          margin-right: 12px;
        }
        @media (max-width: 520px) {
          order: 1;
        }
      }
      &:nth-of-type(2) {
        @media (max-width: 520px) {
          order: 2;
        }
      }
    }
  }
}
/* ---------------------------------------------------------------- */
/* Spinner */
/* ---------------------------------------------------------------- */
.spinner {
  background-color: $brandBlackGrape;
  background-image: url('/images/icons/button-loading.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: 56px 56px;
  border-radius: 50%;
  display: block;
  flex-shrink: 0;
  height: 64px;
  margin-right: 12px;
  top: 0;
  width: 64px;
  @media (max-width: 1536px) {
    background-size: 48px 48px;
    height: 56px;
    margin-right: 10px;
    width: 56px;
  }
  @media (max-width: 1280px) {
    background-size: 40px 40px;
    height: 48px;
    margin-right: 8px;
    width: 48px;
  }
  @media (max-width: 520px) {
    margin-right: 12px;
  }
  ~ button {
    @media (max-width: 520px) {
      max-width: calc(50% - 36px) !important;
    }
  }
}
