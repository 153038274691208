@import "modules/theme/sass/mixins.scss"; @import "modules/theme/sass/variables.scss";
/* ---------------------------------------------------------------- */
/* Filter Selected */
/* ---------------------------------------------------------------- */
.filtersSortBy {
  select {
    white-space: normal;
    padding: 10px 34px 10px 10px !important;
  }

  @media only screen and (min-width: 521px) and (max-width: 1475px) {
    select {
      height: auto !important;
      font-size: 16px;
    }
  }

  @media (max-width: 510px) {
    select {
      height: 55px !important;
    }
  }

  @media (max-width: 1370px) {
    select {
      word-break: break-all;
    }
  }

  @media (min-width: 1024px) {
    padding: 0 20px 0 10px;

    select {
      white-space: normal;
      padding: 0 5px;
    }

    svg {
      right: 10px;
    }
  }
}
